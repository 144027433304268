import React, { useState, useRef } from "react";
import L from "leaflet"; // eslint-disable-line no-unused-vars
import searchIcon from "../../../../medias/images/icone-recherche.svg";
import { useOutsideAlerter } from "../../../../utils/useOutsideAlerter";

const endpoint = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
const parameters =
  ".json?country=fr&proximity=ip&types=place%2Cpostcode%2Caddress&access_token=pk.eyJ1IjoieXZhaW5tIiwiYSI6ImNrMnZyZzdyODA3aWIzaHFsNDJ3eDlpMzMifQ.bSF9_23BfhATwmQFfGryTw";

function Geocoding({ lMap, disabled }) {
  const [text, setText] = useState("");
  const [features, setFeatures] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setFeatures([]);
    setActiveIndex(-1);
  });

  function handleTextChange(text) {
    setText(text);
    if (text.length > 3) {
      const req = `${endpoint}${encodeURIComponent(text.trim())}${parameters}`;
      fetch(req).then(function (response) {
        return response.json().then(function (json) {
          setFeatures(json.features);
        });
      });
    }
  }

  function selectFeature(index) {
    const feature = features[index];
    lMap.setView(L.latLng(feature.center[1], feature.center[0]), 19);
    setText(feature.place_name);
    setFeatures([]);
    setActiveIndex(-1);
  }

  function handleKeyDown(e) {
    if (e.key === "ArrowUp") {
      if (activeIndex < 1) {
        setActiveIndex(features.length - 1);
      } else {
        setActiveIndex(activeIndex - 1);
      }
    } else if (e.key === "ArrowDown") {
      if (activeIndex === features.length - 1) {
        setActiveIndex(0);
      } else {
        setActiveIndex(activeIndex + 1);
      }
    } else if (e.key === "Enter") {
      selectFeature(activeIndex);
    } else if (e.key === "Escape") {
      setActiveIndex(-1);
      setFeatures([]);
    }
  }

  const backgroundSize = window.innerWidth > 512 ? "24px" : "20px";

  return (
    <div
      className="recherche-adresse"
      onKeyDown={(e) => handleKeyDown(e)}
      ref={wrapperRef}
    >
      <div className="champ-recherche">
        <input
          disabled={disabled}
          type="text"
          onChange={(e) => handleTextChange(e.target.value)}
          value={text}
          placeholder="Recherche par adresse"
          style={{
            backgroundImage: `url(${searchIcon})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: backgroundSize,
            backgroundPositionX: "6px",
            backgroundPositionY: "4px",
            backgroundColor: "transparent",
            fontFamily: "Lato Regular",
          }}
        />
      </div>
      <div className="liste-adresse">
        {features.map((feature, index) => {
          const [street, ...zipCity] = feature.place_name.split(",");
          const addressClass =
            index === activeIndex ? "adresse active" : "adresse";
          return (
            <div
              className={addressClass}
              key={feature.id}
              onClick={() => selectFeature(index)}
            >
              <div>{street}</div>
              <div>{zipCity}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { Geocoding };
