import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_READING_STATS } from "../_GraphQL/queries";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { Loader } from "../../../Common/components/Loader";
import { EyeIcon } from "../../../Common/components/Images/EyeIcon";

function ShowStats({ data }) {
  const readerSet = data.readerSet.length;
  const missingReaderSet = data.missingReaderSet.length;
  const percent = ((readerSet / (readerSet + missingReaderSet)) * 100).toFixed(
    2
  );

  return (
    <>
      <p>Le fichier a été lu par {percent}% des utilisateurs.</p>
      <div className="colonnes">
        <div className="lu">
          <h3>Lu par</h3>
          <ul>
            {data.readerSet.map((r) => (
              <li key={`reader-${r.id}`}>{`${r.firstName} ${r.lastName}`}</li>
            ))}
          </ul>
        </div>
        <div className="non-lu">
          <h3>Non lu par</h3>
          {data.missingReaderSet.map((r) => (
            <li
              key={`missing-reader-${r.id}`}
            >{`${r.firstName} ${r.lastName}`}</li>
          ))}
        </div>
      </div>
    </>
  );
}

function GetStats({ projectIdOrSlug, documentId, close, documentName }) {
  const { loading, error, data } = useQuery(GET_READING_STATS, {
    variables: {
      projectIdOrSlug: projectIdOrSlug,
      documentId: documentId,
    },
  });

  if (loading) return <Loader />;

  return (
    <div>
      <h1>Statistiques de consultations du fichier {documentName}</h1>
      {error ? (
        <IciErrorMessage
          header={"Erreur lors du chargement des statistiques."}
          error={error}
        />
      ) : (
        <ShowStats data={data} />
      )}
      <button className="bt-texte bt-bleu" onClick={close}>
        Fermer
      </button>
    </div>
  );
}

function Stats({ documentName, projectIdOrSlug, documentId }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="stats">
      <button
        className="bt-img-rond bt-bleu"
        title="Voir les statistiques de consultation du document"
        onClick={() => setModalOpen(true)}
      >
        <EyeIcon />
      </button>
      {modalOpen ? (
        <div className="couverture">
          <div className="popup">
            <GetStats
              projectIdOrSlug={projectIdOrSlug}
              documentId={documentId}
              documentName={documentName}
              close={() => setModalOpen(false)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Stats;
