import React, { useState } from "react";
import { USER_TYPES } from "../../../utils/Constants";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { Loader } from "../../../Common/components/Loader";

function ProfileForm({
  currentUser,
  updateProfile,
  error,
  loading,
  toggleMode,
}) {
  const [firstName, setFirstName] = useState(currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser.lastName);
  const [name, setName] = useState(
    currentUser.profile &&
      currentUser.profile.nature.code === USER_TYPES.LEGAL_PERSON
      ? currentUser.profile.name
      : null
  );

  const legalPerson =
    currentUser.profile &&
    currentUser.profile.nature.code === USER_TYPES.LEGAL_PERSON;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        updateProfile({
          variables: {
            userId: currentUser.id,
            firstName: firstName,
            lastName: lastName,
            name: name,
          },
        });
      }}
    >
      {error ? <IciErrorMessage error={error} /> : null}
      <div className="champs-form">
        {legalPerson ? (
          <div className="champ">
            <label className="label">Raison sociale</label>
            <input
              type="text"
              className="rond gris"
              disabled={loading}
              placeholder="Raison sociale"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        ) : (
          <>
            <div className="champ">
              <label className="label">Nom</label>
              <input
                className="rond gris"
                disabled={loading}
                placeholder="Nom"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="champ">
              <label className="label">Prénom</label>
              <input
                className="rond gris"
                type="text"
                disabled={loading}
                placeholder="Prénom"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </>
        )}
      </div>
      <div className="boutons">
        {loading ? (
          <Loader />
        ) : (
          <div>
            <button
              className="annuler"
              onClick={(e) => {
                e.preventDefault();
                setFirstName(currentUser.firstName);
                setLastName(currentUser.lastName);
                setName(currentUser.name);
                toggleMode();
              }}
            >
              Annuler
            </button>
            <button className="valider" type="submit">
              Enregistrer
            </button>
          </div>
        )}
      </div>
    </form>
  );
}

export { ProfileForm };
