import React from "react";
import { CREATE_TEMP_TOKEN_FOR_EXPORT_SHP } from "../../_GraphQL/mutations";
import { useMutation } from "@apollo/client";
import {
  addErrorToastMessage,
  getMessageTextFromApolloError,
} from "../../../../_GraphQL/message";
import {
  useCollaborativeMapDispatch,
  ACTIONS,
} from "../../../../Contexts/CollaborativeMapProvider";
import exportImg from "../../../../medias/images/icone-exporter.svg";

let domain = "";

if (process.env.NODE_ENV !== "production") {
  let port = parseInt(process.env.REACT_APP_PORT, 10) + 5000;
  domain = `http://localhost:${port}`;
}

const ExportShapefile = ({ taskId, disabled }) => {
  const dispatch = useCollaborativeMapDispatch();
  const [createTempTokenForExportShp] = useMutation(
    CREATE_TEMP_TOKEN_FOR_EXPORT_SHP,
    {
      onError: (error) => {
        dispatch({ type: ACTIONS.EXPORT_ENDED });
        addErrorToastMessage(getMessageTextFromApolloError(error));
      },
      onCompleted: ({ createTempTokenForExportShp }) => {
        const url = `${domain}/participate/export/?rt=${createTempTokenForExportShp.token}`;
        fetch(url)
          .then(function (response) {
            // Response.ok est une propriété de la réponse de fetch
            // Elle doit être à "true" si tout va bien
            if (!response.ok) {
              dispatch({ type: ACTIONS.EXPORT_ENDED });
              throw Error(response.statusText);
            }
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => {
                // process your JSON data further
                dispatch({ type: ACTIONS.EXPORT_ENDED });
                throw Error(data.message ? data.message : "Erreur inconnue.");
              });
            }
            return response;
          })
          .then((response) => response.blob())
          .then((blob) => {
            let type = blob.type.split("/").pop();
            type = type === "plain" ? "txt" : type;
            let d = document.createElement("a");
            d.className = "download"; // utile ?
            d.download = "export." + type;
            d.href = URL.createObjectURL(blob);
            document.body.appendChild(d);
            d.click();
            d.parentElement.removeChild(d);
            dispatch({ type: ACTIONS.EXPORT_ENDED });
          })
          .catch((err) => {
            dispatch({ type: ACTIONS.EXPORT_ENDED });
            addErrorToastMessage({
              header: "Erreur lors de l'export",
              content: err.message.toString(),
            });
          });
      },
    }
  );

  return (
    <div className="outil">
      <button
        key="export"
        onClick={(e) => {
          e.preventDefault();
          dispatch({ type: ACTIONS.EXPORT_STARTED });
          createTempTokenForExportShp({
            variables: { taskId: taskId },
          }).catch(() => {
            /* Le catch sert à éviter un unhandled rejection error en cas d'erreur réseau. */
          });
        }}
        disabled={disabled}
      >
        <img src={exportImg} alt="modifier" />
        <span>Exporter</span>
      </button>
    </div>
  );
};

export default ExportShapefile;
