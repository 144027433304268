import React from "react";
import { useMutation } from "@apollo/client";
import { DELETE_FEATURE } from "../../_GraphQL/mutations";
import {
  GET_LAYER_WITH_POINT_FEATURES_LIGHT,
  GET_LAYER_WITH_LINE_FEATURES_LIGHT,
  GET_LAYER_WITH_POLY_FEATURES_LIGHT,
  USER_TASK_CONTRIB_COUNTER,
} from "../../../../Common/_GraphQL/queries";
import { COLLABORATIVE_MAP_GEOMETRY_TYPES } from "../../../../utils/Constants";
import { IciErrorMessage } from "../../../../Common/components/ErrorMessage";
import {
  ACTIONS,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";

function DeleteFeature({
  layerId,
  featureId,
  geometryTypeCode,
  handleDeleteCompleted,
  taskId,
}) {
  const dispatch = useCollaborativeMapDispatch();
  const [deleteFeature, { loading, error }] = useMutation(DELETE_FEATURE, {
    update(cache, { data: { deleteFeature } }) {
      let query = GET_LAYER_WITH_POINT_FEATURES_LIGHT;
      if (geometryTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE) {
        query = GET_LAYER_WITH_LINE_FEATURES_LIGHT;
      } else if (
        geometryTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
      ) {
        query = GET_LAYER_WITH_POLY_FEATURES_LIGHT;
      }

      const layer = cache.readQuery({
        query: query,
        variables: {
          layerId: layerId,
        },
      }).layer;

      const featureId = deleteFeature.id;

      let newLayer = {};

      if (layer.pointFeatureSet) {
        newLayer = {
          ...layer,
          pointFeatureSet: layer.pointFeatureSet.filter(
            (feature) => feature.id !== featureId
          ),
        };
      }
      if (layer.lineFeatureSet) {
        newLayer = {
          ...layer,
          lineFeatureSet: layer.lineFeatureSet.filter(
            (feature) => feature.id !== featureId
          ),
        };
      }
      if (layer.polyFeatureSet) {
        newLayer = {
          ...layer,
          polyFeatureSet: layer.polyFeatureSet.filter(
            (feature) => feature.id !== featureId
          ),
        };
      }

      cache.writeQuery({
        query: query,
        data: { layer: newLayer },
        variables: {
          layerId: layerId,
        },
      });

      const contribCount = cache.readQuery({
        query: USER_TASK_CONTRIB_COUNTER,
        variables: { taskIdOrSlug: taskId },
      });

      if (contribCount) {
        cache.writeQuery({
          query: USER_TASK_CONTRIB_COUNTER,
          variables: { taskIdOrSlug: taskId },
          data: {
            userTaskContribCounter: contribCount.userTaskContribCounter - 1,
          },
        });
      }
    },
    onError() {},
    onCompleted() {
      dispatch({ type: ACTIONS.DELETE_FEATURE_COMPLETED });
      handleDeleteCompleted();
    },
  });

  return (
    <>
      {loading ? (
        <p>Chargement...</p>
      ) : (
        <>
          {error ? <IciErrorMessage error={error} /> : null}
          <p>
            La géométrie va être supprimée, ainsi que les réponses aux questions
            du formulaire attaché.
          </p>
          <p>Confirmez-vous l'opération ?</p>
          <div className="boutons">
            <button
              className="valider"
              onClick={() =>
                deleteFeature({ variables: { featureId: featureId } })
              }
            >
              Confirmer
            </button>
            <button
              className="annuler"
              onClick={() => dispatch({ type: ACTIONS.DELETE_CANCELLED })}
            >
              Annuler
            </button>
          </div>
        </>
      )}
    </>
  );
}

export { DeleteFeature };
