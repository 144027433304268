import React from "react";

function LineIcon({ active }) {
  const stroke = active ? "#ffffff" : "#979797";
  return (
    <svg width="35" height="8" viewBox="0 0 40 8">
      <path
        d="M34.5.5H0"
        transform="translate(2 4)"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle
        cx="3"
        cy="3"
        r="3"
        transform="translate(1 1)"
        fill="#fff"
        stroke="#181818"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        cx="3"
        cy="3"
        r="3"
        transform="translate(33 1)"
        fill="#fff"
        stroke="#181818"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
}

export { LineIcon };
