import React from "react";
import { useMutation, ApolloConsumer, gql } from "@apollo/client";
import { CREATE_TEMP_TOKEN_FOR_DOCUMENT_DOWNLOAD } from "../_GraphQL/mutations";
import { DocumentIcon } from "../../../Common/components/Images/DocumentIcon";
import { DateTime } from "luxon";
import { ManagePermissions } from "./ManagePermissions";
import { DeleteFile } from "./DeleteFile";
import Stats from "./Stats";
import { addErrorToastMessage } from "../../../_GraphQL/message";
import { Move } from "./Move";

function DocumentLink({ documentId, name, domain }) {
  const [createTempTokenForDocumentDownload] = useMutation(
    CREATE_TEMP_TOKEN_FOR_DOCUMENT_DOWNLOAD,
    {
      onError() {}, // Nécessaire pour éviter les erreurs de type "Unhandled Rejection"
      onCompleted({ createTempTokenForDocumentDownload }) {
        const url = `${domain}/fichiers/document/?rt=${createTempTokenForDocumentDownload.token}`;
        fetch(url)
          .then(function (response) {
            // Response.ok est une propriété de la réponse de fetch
            // Elle doit être à "true" si tout va bien
            if (!response.ok) {
              throw Error(response.statusText);
            }
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => {
                throw Error(data.message ?? "Erreur inconnue.");
              });
            }
            return response;
          })
          .then((response) => response.blob())
          .then((blob) => {
            let d = document.createElement("a");
            d.className = "download";
            d.download = name;
            d.href = URL.createObjectURL(blob);
            document.body.appendChild(d);
            d.click();
            d.parentElement.removeChild(d);
          })
          .catch((error) => {
            addErrorToastMessage({
              header: "Erreur",
              content: error.message,
              autoClose: false,
            });
          });
      },
    }
  );

  return (
    <a
      href={`/fichiers/document/${documentId}`}
      download
      onClick={(e) => {
        e.preventDefault();
        createTempTokenForDocumentDownload({
          variables: {
            documentId: documentId,
          },
        });
      }}
    >
      <DocumentIcon extension={name.split(".").pop()} />
      {name}
    </a>
  );
}

const DocumentList = ({
  sorter,
  column,
  direction,
  data,
  parentId,
  refetch,
  projectIdOrSlug,
  folderSetOdd,
}) => {
  let domain = "";

  if (process.env.NODE_ENV !== "production") {
    let port = parseInt(process.env.REACT_APP_PORT, 10) + 5000;
    domain = `http://localhost:${port}`;
  }

  const documentSet = [...data.currUserDocumentSet];
  const testAgainst = folderSetOdd ? 1 : 0;

  return (
    <ApolloConsumer>
      {(client) =>
        documentSet.sort(sorter(column, direction)).map((doc, i) => {
          return (
            <div
              className={i % 2 === testAgainst ? "ligne" : "ligne impair"}
              key={doc.id}
            >
              <div className="nom">
                <DocumentLink
                  domain={domain}
                  documentId={doc.id}
                  name={doc.name}
                />
              </div>
              <div className="date">
                {doc.creationDate === null
                  ? null
                  : DateTime.fromISO(doc.creationDate).toFormat(
                      "'Le 'DD' à 'HH'h'mm"
                    )}
              </div>
              <div className="actions">
                {data.currUserCanManagePermissions &&
                data.currUserCanViewStats ? (
                  <>
                    {data.currUserCanViewStats ? (
                      <Stats
                        projectIdOrSlug={projectIdOrSlug}
                        documentId={doc.id}
                        documentName={doc.name}
                      />
                    ) : null}
                    {data.currUserCanManagePermissions ? (
                      <ManagePermissions
                        documentId={doc.id}
                        fileType="document"
                        refetch={refetch}
                        name={doc.name}
                      />
                    ) : null}
                  </>
                ) : null}
                {doc.canDelete || doc.canMove ? (
                  <>
                    {doc.canDelete ? (
                      <DeleteFile
                        documentId={doc.id}
                        documentName={doc.name}
                        parentId={parentId}
                      />
                    ) : null}
                    {doc.canMove ? (
                      <Move
                        projectIdOrSlug={projectIdOrSlug}
                        sorter={sorter}
                        sourceId={doc.id}
                        sourceName={doc.name}
                        parentId={parentId}
                        sourceType="document"
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
              <div className="selectionner">
                <input
                  type="checkbox"
                  checked={doc.selectedForDownload}
                  onChange={() =>
                    client.writeFragment({
                      id: `DocumentType:${doc.id}`,
                      fragment: gql`
                        fragment DocumentSelectedForDownload on DocumentType {
                          selectedForDownload
                        }
                      `,
                      data: {
                        selectedForDownload: !doc.selectedForDownload,
                      },
                    })
                  }
                />
              </div>
            </div>
          );
        })
      }
    </ApolloConsumer>
  );
};

export default DocumentList;
