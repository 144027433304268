import React from "react";

function DownloadIcon() {
  return (
    <svg
      id="iconGF_download"
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
    >
      <g id="cloud_download-24px" transform="translate(0 4)">
        <path
          id="Path"
          d="M64.5,20.007a25.024,25.024,0,0,0-46.667-6.625A19.861,19.861,0,0,0,20,53H63.333A16.512,16.512,0,0,0,64.5,20.007Z"
        />
        <path
          id="Combined_Shape"
          d="M8.017,32.355.554,25.127a1.791,1.791,0,0,1,0-2.59,1.934,1.934,0,0,1,2.676,0L8,27.157V1.9A1.95,1.95,0,0,1,10,0a1.95,1.95,0,0,1,2,1.9V28.126l5.772-5.589a1.935,1.935,0,0,1,2.675,0,1.79,1.79,0,0,1,0,2.59l-8.609,8.337a1.926,1.926,0,0,1-1.582.521A2.125,2.125,0,0,1,10,34,1.968,1.968,0,0,1,8.017,32.355Z"
          transform="translate(30 11)"
          fill="#3049d7"
        />
      </g>
    </svg>
  );
}

export { DownloadIcon };
