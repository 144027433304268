import React from "react";
import { useQuery } from "@apollo/client";
import { DEFAULT_DOC_GRP_PERM_SET_CURR_SITE_GRP_SET } from "../_GraphQL/queries";
import { ManageDocumentPermissionsForm } from "./ManageDocumentPermissionsForm";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import * as constants from "../../../utils/Constants";
import { Loader } from "../../../Common/components/Loader";

function ManageDocumentPermissionsQuery({
  handleCheckChange,
  addGroupPermission,
  projectIdOrSlug,
  initGroupPermissionsRows,
  groupPermissionsRows,
  style,
}) {
  const { loading, error, data } = useQuery(
    DEFAULT_DOC_GRP_PERM_SET_CURR_SITE_GRP_SET,
    {
      variables: {
        projectIdOrSlug: projectIdOrSlug,
      },
    }
  );

  if (loading) return <Loader />;

  if (error) {
    return (
      <IciErrorMessage
        Header={"Impossible de récupérer les groupes."}
        error={error}
      />
    );
  }

  let initialGroupPermissionsRows = [];
  data.currWebsiteGroupSet.forEach((group) => {
    initialGroupPermissionsRows.push({
      group: group,
      canViewDocument: false,
      canDeleteDocument: false,
      canMoveDocument: false,
    });

    let groupPermissions = data.defaultDocumentGroupPermissionSet.filter(
      (a) => a.group.id === group.id
    );

    groupPermissions.forEach((gp) => {
      let perm = constants.FILEMANAGER_PERMISSIONS[gp.permission];
      initialGroupPermissionsRows.find((o) => o.group.id === gp.group.id)[
        perm
      ] = true;
    });
  });

  return (
    <ManageDocumentPermissionsForm
      style={style}
      addGroupPermission={addGroupPermission}
      initialGroupPermissionsRows={initialGroupPermissionsRows}
      groupPermissionsRows={groupPermissionsRows}
      handleCheckChange={handleCheckChange}
      initGroupPermissionsRows={initGroupPermissionsRows}
    />
  );
}

export { ManageDocumentPermissionsQuery };
