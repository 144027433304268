import React from "react";
import MessageDisplayer from "./MessageDisplayer";
import { COMPONENTS } from "../../utils/Constants";

const MessageDisplayerToast = () => {
  return (
    <div className="toastmessages">
      <MessageDisplayer location={COMPONENTS.COMMON.INDEX} />
    </div>
  );
};

export { MessageDisplayerToast };
