import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_FILES } from "../_GraphQL/queries";
import { GET_PROJECT_BY_ID_OR_SLUG } from "../../../Common/_GraphQL/queries";
import plusImg from "../../../medias/images/plus.svg";
import separateurImg from "../../../medias/images/separateur.svg";
import { BreadCrumb } from "./BreadCrumb";
import { Loader } from "../../../Common/components/Loader";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { PAGES } from "../../../utils/Constants";

function FileManagerLayout({ children, page }) {
  const { projectIdOrSlug, folderId } = useParams();

  const {
    loading: loadingProject,
    error: errorProject,
    data: dataProject,
  } = useQuery(GET_PROJECT_BY_ID_OR_SLUG, {
    variables: {
      projectIdOrSlug: projectIdOrSlug,
    },
  });

  const { loading, error, data, client, refetch } = useQuery(GET_FILES, {
    variables: {
      parentId: folderId,
    },
  });

  if (errorProject) {
    return (
      <div className="fichiers">
        <div className="corps">
          <IciErrorMessage error={errorProject} />
        </div>
      </div>
    );
  }

  if (loadingProject || !dataProject) return null;

  if (!dataProject.projectByIdOrSlug.rootFolder) {
    return (
      <div className="fichiers">
        <div className="corps">
          <div className="erreur">
            Ce projet n&apos;est pas configuré correctement pour le gesionnaire
            de fichiers. Il manque un dossier racine.
          </div>
        </div>
      </div>
    );
  }

  if (!dataProject.projectByIdOrSlug.rootFolder.canRead) {
    return (
      <div className="fichiers">
        <div className="corps">
          <div className="erreur">
            Vous n'êtes pas habilité à accéder aux documents de ce projet.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fichiers">
      <div className="colonne-gauche">
        {page === PAGES.FM_FILE_LIST ? (
          <>
            {loading ? null : (
              <>
                <div className="element-menu"></div>
                {data && data.currUserCanAddFolder ? (
                  <div className="element-menu">
                    <Link
                      to={`/${projectIdOrSlug}/documents/${folderId}/nouveau-dossier`}
                    >
                      <img src={plusImg} alt="+"></img>
                      Ajouter un dossier
                    </Link>
                  </div>
                ) : null}
                <div className="element-menu">
                  <img src={separateurImg} alt=""></img>
                </div>
                {data && data.currUserCanAddDocument ? (
                  <div className="element-menu">
                    <Link
                      to={`/${projectIdOrSlug}/documents/${folderId}/ajout-documents`}
                    >
                      <img src={plusImg} alt="+"></img>
                      Ajouter des documents
                    </Link>
                  </div>
                ) : null}
              </>
            )}
          </>
        ) : (
          <div className="element-menu">
            <Link to={`/${projectIdOrSlug}/documents/${folderId}`}>
              <span className="symbol">🠔&nbsp;&nbsp;</span>
              Retour
            </Link>
          </div>
        )}
      </div>
      <div className="marge-bleue"></div>
      <div className="chemin">
        {loading ? null : (
          <BreadCrumb projectIdOrSlug={projectIdOrSlug} folderId={folderId} />
        )}
      </div>
      <div className="bouton-ajouts">
        {page === PAGES.FM_FILE_LIST ? (
          <>
            {loading ? null : (
              <>
                {data && data.currUserCanAddFolder ? (
                  <Link
                    className="bt-texte bt-bleu bt-img-et-texte"
                    to={`/${projectIdOrSlug}/documents/${folderId}/nouveau-dossier`}
                  >
                    <span className="img">+</span>
                    <span>Dossier</span>
                  </Link>
                ) : null}
                {data && data.currUserCanAddDocument ? (
                  <Link
                    className="bt-texte bt-bleu bt-img-et-texte"
                    to={`/${projectIdOrSlug}/documents/${folderId}/ajout-documents`}
                  >
                    <span className="img">+</span>
                    <span>Documents</span>
                  </Link>
                ) : null}
              </>
            )}
          </>
        ) : null}
      </div>
      <div className="corps">
        {loading ? (
          <Loader />
        ) : (
          <>
            {error ? (
              <IciErrorMessage error={error} />
            ) : (
              <>
                {data ? (
                  <>
                    {children({
                      parentId: folderId,
                      projectIdOrSlug: projectIdOrSlug,
                      currUserFolderSet: data.currUserFolderSet,
                      currUserCanManagePermissions:
                        data.currUserCanManagePermissions,
                      data: data,
                      refetch: refetch,
                      client: client,
                    })}
                  </>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
      <div className="marge-droite"></div>
    </div>
  );
}

export { FileManagerLayout };
