import React from "react";

function getBg(percent) {
  if (percent > 83) return "#88b840";
  if (percent > 66) return "#c3d323";
  if (percent > 59) return "#f9ec35";
  if (percent > 42) return "#ED8D1F";
  if (percent > 25) return "#E86523";
  return "#E34017";
}

function Progress({ percent }) {
  return (
    <div className="progres">
      <div
        className="barre"
        style={{
          width: `${percent}%`,
          backgroundColor: getBg(percent),
        }}
      ></div>
    </div>
  );
}

export { Progress };
