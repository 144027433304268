import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_TASK_BY_ID_OR_SLUG } from "../_GraphQL/queries";
import ErrorMessage from "../../../Common/components/ErrorMessage";
import { Loader } from "../../../Common/components/Loader";
import { Controller } from "../components/Controller";

const CollaborativeMap = () => {
  let { taskIdOrSlug } = useParams();

  const { error, loading, data } = useQuery(GET_TASK_BY_ID_OR_SLUG, {
    variables: { taskIdOrSlug: taskIdOrSlug },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return <Controller task={data.getTaskByIdOrSlug} />;
};

export { CollaborativeMap };
