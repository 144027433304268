import React, { useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  CURR_USER_TASK_SET_FROM_SLUG_OR_ID,
  TASK_IDEAS_COUNTER,
} from "../_GraphQL/queries";
import { Loader } from "../../../Common/components/Loader";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import {
  TASK_TYPES,
  BASE_MAPS,
  MAPBOX_TOKEN,
  IDEAS_BUTTON,
} from "../../../utils/Constants";
import { MapContainer, TileLayer, GeoJSON, Popup } from "react-leaflet";
import {
  ACTIONS,
  useCollaborativeMapDispatch,
} from "../../../Contexts/CollaborativeMapProvider";
import { WysiwygReader } from "../../../Common/components/WysiwygReader";

const customStreets =
  "https://api.mapbox.com/styles/v1/yvainm/ckpxr3tbs18mo17mfkgup1jtq/tiles/256/{z}/{x}/{y}?access_token={accessToken}";

const customTopo =
  "https://api.mapbox.com/styles/v1/yvainm/ckpxqql691d2717qu4y0megqq/tiles/256/{z}/{x}/{y}?access_token={accessToken}";

function IdeasCounter({ taskId }) {
  const { loading, error, data } = useQuery(TASK_IDEAS_COUNTER, {
    variables: { taskId: taskId },
  });

  if (loading || error || !data) return null;

  const counter = data.taskIdeasCounter;
  return (
    <div className="bulle-compteurs">
      <span className="compteur-idees">{`${counter.ideas} idée${
        counter.ideas > 1 ? "s" : ""
      }`}</span>
      <span className="compteur-contributeurs">{`${
        counter.contributors
      } contributeur${counter.contributors > 1 ? "s" : ""}`}</span>
    </div>
  );
}

function IdeaPopup({ task }) {
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useCollaborativeMapDispatch();

  function handleclose() {
    if (popupRef && popupRef.current) {
      popupRef.current._map.closePopup();
    }
  }

  return (
    <Popup
      ref={popupRef}
      closeButton={false}
      minWidth={400}
      maxWidth={400}
      maxHeight={200}
    >
      <div className="bulle-idee">
        <div className="bulle-desc-idee">
          <h4>{task.name}</h4>
          <WysiwygReader raw={task.description} />
          <IdeasCounter taskId={task.id} />
        </div>
        <div className="bulle-boutons">
          <button
            onClick={() => {
              dispatch({ type: ACTIONS.IDEAS_PARTICIPATE_CLICKED, task: task });
              navigate(`./${task.id}`, {
                state: { from: IDEAS_BUTTON.CONTRIBUTE },
              });
            }}
          >
            Participer
          </button>
          <button
            onClick={() => {
              dispatch({
                type: ACTIONS.IDEAS_SHOW_CONTRIBUTIONS_CLICKED,
                task: task,
              });
              navigate(`./${task.id}`, {
                state: { from: IDEAS_BUTTON.SHOW_CONTRIBUTIONS },
              });
            }}
          >
            Voir les idées
          </button>
          <button onClick={handleclose}>Fermer</button>
        </div>
      </div>
    </Popup>
  );
}

function IdeaSelector({ project, taskSet }) {
  const position = [project.tileLayer.latitude, project.tileLayer.longitude];
  const baseMap = project.tileLayer.mapId;
  return (
    <div className="carte idees selection-tache">
      <div className="zone-travail">
        <MapContainer
          center={position}
          zoom={project.tileLayer.zoomInit}
          scrollWheelZoom={true}
        >
          {baseMap === BASE_MAPS.SATELLITE ? (
            <TileLayer
              id={baseMap}
              accessToken={MAPBOX_TOKEN}
              url="https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
            />
          ) : null}

          {baseMap === BASE_MAPS.STREETS ? (
            <TileLayer accessToken={MAPBOX_TOKEN} url={customStreets} />
          ) : null}

          {baseMap === BASE_MAPS.TOPOGRAPHIC ? (
            <TileLayer accessToken={MAPBOX_TOKEN} url={customTopo} />
          ) : null}
          {taskSet
            .filter((task) => task.ideaFeature)
            .map((task) => (
              <GeoJSON
                key={`ideaFeature-{task.id}`}
                data={JSON.parse(task.ideaFeature.data)}
              >
                <IdeaPopup task={task} />
              </GeoJSON>
            ))}
        </MapContainer>
      </div>
    </div>
  );
}

function Ideas() {
  const { projectIdOrSlug } = useParams();
  const { loading, error, data } = useQuery(
    CURR_USER_TASK_SET_FROM_SLUG_OR_ID,
    { variables: { projectSlugOrId: projectIdOrSlug } }
  );

  if (loading) return <Loader />;

  if (error) return <IciErrorMessage error={error} />;

  const project = data.projectByIdOrSlug;
  const taskSet = data.currUserTaskSetFromSlugOrId.filter(
    (task) => task.taskType.code === TASK_TYPES.IDEAS
  );

  if (!project.tileLayer || taskSet.length === 0) {
    return (
      <div className="carte idees selection-tache">
        <div className="zone-travail">
          Ce projet n'est pas configuré correctement pour l'appel à idées.
        </div>
      </div>
    );
  }

  return <IdeaSelector project={project} taskSet={taskSet} />;
}

export { Ideas };
