import deleteFeatureStep2Img from "../../../medias/images/aide/supprimer-element-etape-2-1080-810.png";
import deleteFeatureStep3Img from "../../../medias/images/aide/supprimer-element-etape-3-1080-810.png";
import deleteFeatureStep4Img from "../../../medias/images/aide/supprimer-element-etape-4-1080-810.png";

function HelpDeleteFeature() {
  return (
    <div>
      <h1>Étapes pour supprimer un élément</h1>
      <p>
        1. Cliquer sur l'élément à supprimer, le panneau info s'ouvre : cliquer
        sur supprimer
      </p>
      <img
        className="capture-aide"
        src={deleteFeatureStep2Img}
        alt="Illustration de la sélection d'un élément à supprimer."
      />
      <p>2. Cliquer sur confirmer</p>
      <img
        className="capture-aide"
        src={deleteFeatureStep3Img}
        alt="Illustration de la confirmation de suppression."
      />
      <p>3. L'élément a été supprimé</p>
      <img
        className="capture-aide"
        src={deleteFeatureStep4Img}
        alt="Illustration d'un élément supprimé."
      />
    </div>
  );
}

export { HelpDeleteFeature };
