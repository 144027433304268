import React from "react";
import { Link } from "react-router-dom";
import { ApolloConsumer, useMutation } from "@apollo/client";
import { GET_LOGIN_TOKEN } from "../_GraphQL/mutations";
import { CURR_USER_CURR_SITE_HAS_ACCESS } from "../_GraphQL/queries";
import { isConnectedVar } from "../../_GraphQL/graphQLClient";
import { AUTH_TOKEN } from "../../utils/Constants";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    errorMessage: "",
    errorHeader: "",
    errorVisible: false,
  };

  showError(header, message) {
    this.setState({
      errorHeader: header,
      errorMessage: message,
      errorVisible: true,
    });
  }

  hideError() {
    this.setState({
      errorHeader: "",
      errorMessage: "",
      errorVisible: false,
    });
  }

  handleCancel = (e) => {
    if (e.detail === 0) {
      return;
    }
    e.preventDefault();
    this.props.hideLogin();
  };

  render() {
    return (
      <div className="login">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div>
            <input
              placeholder="Adresse email"
              type="email"
              name="email"
              required={true}
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </div>
          <div>
            <input
              placeholder="Mot de passe"
              required={true}
              type="password"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </div>
          {this.state.errorVisible ? (
            <ErrorMessage
              errorHeader={this.state.errorHeader}
              errorMessage={this.state.errorMessage}
            />
          ) : null}
          <div className="bt-connexion">
            <ApolloConsumer>
              {(client) => (
                <button
                  type="submit"
                  name="notsubmit"
                  onClick={() => this._confirm(client)}
                >
                  Connexion
                </button>
              )}
            </ApolloConsumer>
          </div>
        </form>
        <div className="mdp-oublie">
          <Link to="/compte/mdp-oublie">Mot de passe oublié ?</Link>
        </div>
      </div>
    );
  }

  _confirm = async (client) => {
    const { email, password } = this.state;

    this.props
      .loginMutation({
        variables: {
          email,
          password,
        },
      })
      .then((res) => {
        const { token } = res.data.tokenAuth;
        this._saveUserData(token);

        client
          .query({
            query: CURR_USER_CURR_SITE_HAS_ACCESS,
            fetchPolicy: "network-only",
          })
          .then((res2) => {
            if (res2.data.currUserCurrSiteHasAccess) {
              isConnectedVar(true);
            } else {
              localStorage.removeItem(AUTH_TOKEN);
              this.showError(
                "Erreur lors de l'authentification",
                "Vous n'êtes pas autorisé·e à accéder à ce site"
              );
            }
          });
      })
      .catch((e) => {
        const title = "Erreur lors de l'authentification";
        if (e.graphQLErrors) {
          if (e.graphQLErrors.message) {
            this.showError(title, e.graphQLErrors.message);
          } else if (e.graphQLErrors[0]) {
            this.showError(title, e.graphQLErrors[0].message);
          } else {
            // todo: gérer les erreurs réseau
            this.showError(title, "Une erreur est survenue");
          }
        }
      });
  };

  _saveUserData = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
  };
}

class ErrorMessage extends React.Component {
  render() {
    return (
      <div className="erreur">
        <h1>{this.props.errorHeader}</h1>
        <p>{this.props.errorMessage}</p>
      </div>
    );
  }
}

function LoginMut(props) {
  const [loginMutation] = useMutation(GET_LOGIN_TOKEN);
  return <Login loginMutation={loginMutation} {...props} />;
}

export default LoginMut;
