import { useEffect, useState } from "react";
import { Editor, EditorState, convertFromRaw } from "draft-js";

export function WysiwygReader({ raw }) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    try {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(raw)))
      );
    } catch (e) {}
  }, [raw]);

  return (
    <Editor editorState={editorState} onChange={() => {}} readonly={true} />
  );
}
