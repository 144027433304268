import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GET_FILES } from "../_GraphQL/queries";
import { CREATE_FOLDER } from "../_GraphQL/mutations";
import ManageFolderPermissionsQuery from "../components/ManageFolderPermissionsQuery";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { PAGES, FILEMANAGER_PERMISSIONS } from "../../../utils/Constants";
import {
  addErrorToastMessage,
  getMessageTextFromApolloError,
} from "../../../_GraphQL/message";
import { Loader } from "../../../Common/components/Loader";
import { FileManagerLayout } from "../components/FileManagerLayout";
import { Switch } from "../../../Common/components/Switch";

const viewFolderTrigger = [
  "change_folder_",
  "move_folder_",
  "delete_folder_",
  "add_folder_to_folder_",
  "add_document_to_folder_",
];

function AddFolder({
  parentId,
  projectIdOrSlug,
  currUserFolderSet,
  currUserCanManagePermissions,
}) {
  const [folderName, setFolderName] = useState("");
  const [defaultPermissions, setDefaultPermissions] = useState(true);
  const [groupPermissionsRows, setGroupPermissionsRows] = useState([]);
  const [groupPermissions, setGroupPermissions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let gp = [];
    groupPermissionsRows.forEach((gpr) => {
      if (gpr.canViewFolder)
        gp.push({
          groupId: gpr.group.id,
          permission: "view_folder_",
        });
      if (gpr.canMoveFolder)
        gp.push({
          groupId: gpr.group.id,
          permission: "move_folder_",
        });
      if (gpr.canChangeFolder)
        gp.push({
          groupId: gpr.group.id,
          permission: "change_folder_",
        });
      if (gpr.canDeleteFolder)
        gp.push({
          groupId: gpr.group.id,
          permission: "delete_folder_",
        });
      if (gpr.canAddFolder)
        gp.push({
          groupId: gpr.group.id,
          permission: "add_folder_to_folder_",
        });
      if (gpr.canAddDocument)
        gp.push({
          groupId: gpr.group.id,
          permission: "add_document_to_folder_",
        });
    });
    setGroupPermissions(gp);
  }, [groupPermissionsRows]);

  const [createFolder, { loading, error }] = useMutation(CREATE_FOLDER, {
    update(cache, { data: { createFolder } }) {
      const data = cache.readQuery({
        query: GET_FILES,
        variables: {
          parentId: parentId,
        },
      });

      cache.writeQuery({
        query: GET_FILES,
        variables: { parentId: parentId },
        data: {
          ...data,
          currUserFolderSet: data.currUserFolderSet.concat([
            createFolder.folder,
          ]),
        },
      });
    },
    onCompleted() {
      navigate(`/${projectIdOrSlug}/documents/${parentId}`);
    },
    onError(e) {
      addErrorToastMessage(getMessageTextFromApolloError(e));
    },
  });

  function handleCheckChange(group, permission, checked) {
    let a = groupPermissionsRows.find((al) => al.group.id === group.id);
    let x = groupPermissionsRows.filter((al) => al.group.id !== group.id);

    let perm = FILEMANAGER_PERMISSIONS[permission];
    a[perm] = checked;
    if (checked) {
      if (viewFolderTrigger.includes(permission) && !a.canViewFolder) {
        a.canViewFolder = true;
      }
    } else {
      if (permission === "view_folder_") {
        a.canViewFolder = false;
        a.canMoveFolder = false;
        a.canChangeFolder = false;
        a.canDeleteFolder = false;
        a.canAddFolder = false;
        a.canAddDocument = false;
      }
    }
    setGroupPermissionsRows(x.concat(a));
  }

  return (
    <div className="ajout">
      <h1>Création d'un nouveau dossier</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (currUserFolderSet.find((f) => f.name === folderName)) {
            addErrorToastMessage({
              header: "Ce dossier existe déjà",
              content:
                "Vous ne pouvez pas ajouter deux dossiers du même nom dans le même dossier.",
            });
            return;
          }
          if (!defaultPermissions && groupPermissions.length === 0) {
            addErrorToastMessage({
              header: "Permission manquante",
              content: "Vous devez sélectionner au moins une permission.",
            });
            return;
          }
          if (folderName.length > 100) {
            addErrorToastMessage({
              header: "Nom de fichier trop long !",
              content:
                "La longueur d'un nom de dossier ne doit pas excéder 100 caractères.",
            });
            return;
          }
          createFolder({
            variables: {
              parentId: parentId,
              projectIdOrSlug: projectIdOrSlug,
              name: folderName,
              groupPermissions: groupPermissions,
            },
          });
        }}
      >
        {error ? <IciErrorMessage error={error} /> : null}
        <div className="formulaire">
          <input
            autoFocus
            name="create-folder"
            required={true}
            placeholder="Nom du dossier"
            type="text"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
        </div>
        {currUserCanManagePermissions ? (
          <div className="permissions">
            <div className="choix">
              <label htmlFor="permissions">
                Utiliser les permissions par défaut ?
              </label>
              <Switch
                name="permissions"
                value="defaultPermissions"
                checked={defaultPermissions}
                onChange={() => setDefaultPermissions(!defaultPermissions)}
              />
            </div>
            {!defaultPermissions ? (
              <ManageFolderPermissionsQuery
                projectIdOrSlug={projectIdOrSlug}
                addGroupPermission={(groupId, permission) =>
                  setGroupPermissions(
                    groupPermissions.concat([
                      { groupId: groupId, permission: permission },
                    ])
                  )
                }
                handleCheckChange={handleCheckChange}
                groupPermissionsRows={groupPermissionsRows}
                initGroupPermissionsRows={(gpr) => setGroupPermissionsRows(gpr)}
              />
            ) : null}
          </div>
        ) : null}
        {loading ? (
          <Loader />
        ) : (
          <div className="boutons">
            <Link
              to={`/${projectIdOrSlug}/documents/${parentId}`}
              className="bt-texte bt-bleu-secondaire"
            >
              Annuler
            </Link>
            <button className="bt-texte bt-bleu" type="submit">
              Créer
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

function AddFolderLayout() {
  return (
    <FileManagerLayout page={PAGES.FM_ADD_FOLDER}>
      {(props) => <AddFolder {...props} />}
    </FileManagerLayout>
  );
}

export { AddFolderLayout as AddFolder };
