import React from "react";

function EditIcon() {
  return (
    <svg
      id="icontoolbox_edit"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="35"
      height="35"
      viewBox="0 0 41.053 40"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Mask"
            d="M0,28V22.43L16.862,6l5.717,5.57L5.718,28ZM20.526,2.892,23.085.4a1.417,1.417,0,0,1,1.971,0l3.272,3.188a1.331,1.331,0,0,1,0,1.921L25.769,8Z"
          />
        </clipPath>
      </defs>
      <g id="colors_moyen" transform="translate(6.158 6)">
        <path
          id="Mask-2"
          d="M0,28V22.43L16.862,6l5.717,5.57L5.718,28ZM20.526,2.892,23.085.4a1.417,1.417,0,0,1,1.971,0l3.272,3.188a1.331,1.331,0,0,1,0,1.921L25.769,8Z"
          transform="translate(0)"
        />
        <g
          id="colors_moyen-2"
          transform="translate(0)"
          clipPath="url(#clip-path)"
        >
          <g id="colors_moyen-3" transform="translate(-6.501 -6)">
            <rect id="Rectangle" width="41.053" height="40" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export { EditIcon };
