import React from "react";
import { CURR_USER_HAS_UNREAD_NOTIFICATIONS } from "../_GraphQL/queries";
import { useQuery } from "@apollo/client";
import someNotifImg from "../../medias/images/icon-notif.svg";
import notifImg from "../../medias/images/icon-sans-notif.svg";

function NotificationsIcon() {
  const { loading, error, data } = useQuery(CURR_USER_HAS_UNREAD_NOTIFICATIONS);

  if (loading || error || !data || !data.currUserHasUnreadNotifications) {
    return <img src={notifImg} alt="notifications" />;
  }

  return <img src={someNotifImg} alt="notifications" />;
}

export { NotificationsIcon };
