import React from "react";
import { useMutation } from "@apollo/client";
import { CURRENT_USER_UPDATE_PASSWORD } from "../_GraphQL/mutations";
import { PasswordForm } from "./PasswordForm";
import { COMPONENTS } from "../../../utils/Constants";
import {
  getMessageTextFromApolloError,
  addErrorMessage,
  addSuccessMessage,
} from "../../../_GraphQL/message";

function PasswordMutation({ currentUser, editMode, toggleMode }) {
  const [currentUserUpdatePassword, { loading, error }] = useMutation(
    CURRENT_USER_UPDATE_PASSWORD,
    {
      onError(error) {
        let message = getMessageTextFromApolloError(error);
        message.location = COMPONENTS.COMMON.PROFILE;
        addErrorMessage(message);
      },
      onCompleted() {
        addSuccessMessage({
          location: COMPONENTS.COMMON.PROFILE,
          header: "Mot de passe mis à jour",
          content: "Le nouveau mot de passe a été enregistré.",
        });
        toggleMode();
      },
    }
  );

  return (
    <div className="formulaire-infos">
      <PasswordForm
        loading={loading}
        error={error}
        toggleMode={toggleMode}
        currentUser={currentUser}
        updatePassword={currentUserUpdatePassword}
        editMode={editMode}
      />
    </div>
  );
}

export { PasswordMutation };
