import React from "react";

function PolyIcon({ active }) {
  const stroke = active ? "#ffffff" : "#979797";
  const fill = active ? "rgba(255,255,255,0.4)" : "rgba(151,151,151,0.2)";
  return (
    <svg width="35" height="35" viewBox="0 0 40 40.5">
      <rect
        id="Rectangle"
        width="32"
        height="32"
        transform="translate(3 3)"
        fill={fill}
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle
        id="Oval"
        cx="3"
        cy="3"
        r="3"
        fill="#fff"
        stroke="#181818"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Oval-2"
        cx="3"
        cy="3"
        r="3"
        transform="translate(32)"
        fill="#fff"
        stroke="#181818"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Oval-3"
        cx="3"
        cy="3"
        r="3"
        transform="translate(32 32)"
        fill="#fff"
        stroke="#181818"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Oval-4"
        cx="3"
        cy="3"
        r="3"
        transform="translate(0 32)"
        fill="#fff"
        stroke="#181818"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
}

export { PolyIcon };
