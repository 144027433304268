import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_TEMP_TOKEN_FOR_THUMBNAIL } from "../../_GraphQL/mutations";
import ErrorMessage from "../../../../Common/components/ErrorMessage";

const carouselPropsAreEqual = (prevProps, nextProps) => {
  return (
    prevProps.fqf.questionnairemediaSet === nextProps.fqf.questionnairemediaSet
  );
};

const CarouselImage = ({ media, sources, setSources }) => {
  const [img, setImg] = useState(null);
  const [loadImgError, setLoadImgError] = useState();

  const [createTempTokenForThumbnail, { error }] = useMutation(
    CREATE_TEMP_TOKEN_FOR_THUMBNAIL,
    {
      onCompleted: ({ createTempTokenForThumbnail }) => {
        fetch(`/participate/thumbnail/?rt=${createTempTokenForThumbnail.token}`)
          .then(function (response) {
            // Response.ok est une propriété de la réponse de fetch
            // Elle doit être à "true" si tout va bien
            if (!response.ok) {
              throw Error(response.statusText);
            }
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => {
                // process your JSON data further
                throw Error(data.message ? data.message : "Erreur inconnue.");
              });
            }
            return response;
          })
          .then((response) =>
            response.blob().then((blob) => {
              const source = URL.createObjectURL(blob);
              setSources([
                ...sources,
                {
                  imageId: media.id,
                  source: source,
                },
              ]);
              setImg(
                <img alt={media.name} key={`media-${media.id}`} src={source} />
              );
            })
          )
          .catch((err) => {
            setLoadImgError(err.message.toString());
          });
      },
    }
  );

  useEffect(() => {
    const source = sources.find((source) => source.imageId === media.id);

    if (source) {
      setImg(
        <img alt={media.name} key={`media-${media.id}`} src={source.source} />
      );
    } else {
      async function loadImage() {
        createTempTokenForThumbnail({
          variables: { thumbnailId: media.id },
        }).catch(() => {
          /* Le catch sert à éviter un unhandled rejection error en cas d'erreur réseau. */
        });
      }
      loadImage();
    }
  }, [media, sources, createTempTokenForThumbnail]);

  if (loadImgError) {
    return (
      <div>
        <h1>Erreur lors du chargement de l&apos;image</h1>
        {loadImgError}
      </div>
    );
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return img;
};

const Carousel = React.memo(({ fqf, sources, setSources }) => {
  const mediaSet = fqf.questionnairemediaSet;
  const total = mediaSet.length;

  const [data, setData] = useState({
    currImage: null,
    images: [],
    index: 0,
  });

  useEffect(() => {
    const prov = async () => {
      if (!mediaSet || mediaSet.length === 0) return null;
      const media = mediaSet[0];
      const image = (
        <CarouselImage
          media={media}
          sources={sources}
          setSources={setSources}
        />
      );
      const imgObj = {
        id: media.id,
        img: image,
        index: 0,
        name: media.name.split("\\").pop().split("/").pop(),
      };
      setData({ currImage: imgObj, images: [imgObj], index: 0 });
    };
    prov();
  }, [mediaSet, sources, setSources]);

  const handleChange = async (index) => {
    const cacheImg = data.images.find((im) => im.index === index);
    if (cacheImg) {
      setData({
        currImage: cacheImg,
        images: data.images,
        index: index,
      });
    } else {
      const media = mediaSet[index];
      const image = (
        <CarouselImage
          media={media}
          sources={sources}
          setSources={setSources}
        />
      );
      const imgObj = {
        id: media.id,
        img: image,
        index: index,
        name: media.name.split("\\").pop().split("/").pop(),
      };
      setData({
        images: data.images.concat([imgObj]),
        currImage: imgObj,
        index: index,
      });
    }
  };

  const handleNext = async () => {
    let nextIndex;

    if (mediaSet.length === 1 || mediaSet.length === data.index + 1) {
      nextIndex = 0;
    } else {
      nextIndex = data.index + 1;
    }
    handleChange(nextIndex);
  };

  const handlePrev = async () => {
    let prevIndex;

    if (mediaSet.length === 1) {
      prevIndex = 0;
    } else if (data.index === 0) {
      prevIndex = mediaSet.length - 1;
    } else {
      prevIndex = data.index - 1;
    }

    handleChange(prevIndex);
  };

  return (
    <div className="element carousel">
      <div className="corps">
        <div className="image">
          {data.currImage ? data.currImage.img : null}
        </div>

        {data.currImage ? (
          <div className="titre">
            {total > 1 ? (
              <div className="compteur">{`${data.index + 1}/${total}`}</div>
            ) : null}
          </div>
        ) : null}
      </div>
      {mediaSet.length > 1 ? (
        <>
          <button className="navigation precedent" onClick={() => handlePrev()}>
            &lt;
          </button>
          <button className="navigation suivant" onClick={() => handleNext()}>
            &gt;
          </button>
        </>
      ) : null}
    </div>
  );
}, carouselPropsAreEqual);

export { Carousel, CarouselImage };
