import React from "react";
import fullScreenIcon from "../../../../medias/images/fullscreen.svg";
import menuScreenIcon from "../../../../medias/images/menu-screen.svg";
import {
  useFullScreen,
  useFullScreenDispatch,
} from "../../../../Contexts/FullScreenProvider";
import { Tip } from "../../../../Common/components/Tip";
import tipFullScreenImg from "../../../../medias/images/aide/tip/aide-plein-ecran.png";

function FullScreen({ disabled }) {
  const dispatch = useFullScreenDispatch();
  const fullScreen = useFullScreen();
  const title = fullScreen ? "Sortir du plein écran" : "Mettre en plein écran";
  return (
    <div className="plein-ecran">
      <button
        className="bt-img"
        onClick={() => dispatch({ type: "fullScreen" })}
        title={title}
        disabled={disabled}
      >
        <img src={fullScreen ? menuScreenIcon : fullScreenIcon} alt={title} />
      </button>
      <Tip>
        <div>
          Plein écran
          <br />
          <img
            className="img-ombre-flotte"
            src={tipFullScreenImg}
            alt="Bouton plein écran"
          />
          <br />
          permet de cacher le menu pour obtenir une plus grande surface de
          travail sur la carte.
        </div>
      </Tip>
    </div>
  );
}

export { FullScreen };
