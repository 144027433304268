import React from "react";

function DocumentGroupPermissionsFormRow({
  groupPermissionsRow,
  handleCheckChange,
  even,
}) {
  return (
    <tr className={even ? "pair" : "impair"}>
      <td>{groupPermissionsRow.group.name}</td>
      <td align="center">
        <input
          type="checkbox"
          value="view_document_"
          checked={groupPermissionsRow.canViewDocument}
          onChange={({ target: { value, checked } }) =>
            handleCheckChange(groupPermissionsRow.group, value, checked)
          }
        />
      </td>
      <td align="center">
        <input
          type="checkbox"
          value="move_document_"
          checked={groupPermissionsRow.canMoveDocument}
          onChange={({ target: { value, checked } }) =>
            handleCheckChange(groupPermissionsRow.group, value, checked)
          }
        />
      </td>
      <td align="center">
        <input
          type="checkbox"
          value="delete_document_"
          checked={groupPermissionsRow.canDeleteDocument}
          onChange={({ target: { value, checked } }) =>
            handleCheckChange(groupPermissionsRow.group, value, checked)
          }
        />
      </td>
    </tr>
  );
}

export { DocumentGroupPermissionsFormRow };
