import { gql } from "@apollo/client";

import {
  QUESTIONNAIRE_FIELD_FRAG,
  FEATURE_FRAG,
  LABEL_FRAG,
  STYLE_FRAG,
} from "../../../Common/_GraphQL/fragments";

export const GET_TASK_BY_ID_OR_SLUG = gql`
  query GetTaskByIdOrSlug($taskIdOrSlug: String!) {
    getTaskByIdOrSlug(idOrSlug: $taskIdOrSlug) {
      id
      name
      subtitle
      slug
      excerpt
      description
      useApicarto
      municipalities
      geocodingActive
      taskType {
        id
        code
      }
      ideaFeature {
        layer {
          id
          name
          editable
          lineColor
          lineStyle {
            id
            pathOptions
            geoTypeCode
            usePattern
            patternTypeCode
            patternOptions
            shapeOptions
          }
          polyColor
          polyStyle {
            id
            pathOptions
            geoTypeCode
            usePattern
            patternTypeCode
            patternOptions
            shapeOptions
          }
          marker
          markerColor
          visibleByDefault
          creatorVisible
          restrictionsBetweenUsers
          multipleFilled
          userCanViewOthersInfos
          userCanEditOthersInfos
          userCanEditOthersGeometries
          userCanDeleteOthersGeometries
          isCheckedForDisplay @client
          toolPermissionSet {
            id
            codename
          }
          questionnaire {
            id
            name
            questionnairefieldSet {
              ...QuestionnaireFieldFrag
            }
          }
          layergeometrySet {
            id
            geometryType {
              ...LabelFrag
            }
            decorator {
              id
            }
            icon
            text
            isSelectedForDisplay @client
            layer {
              visibleByDefault
            }
          }
        }
        ...FeatureFrag
      }
      tileLayer {
        name
        latitude
        longitude
        zoomInit
        mapId
      }
      layerSet {
        id
        position
        name
        editable
        lineColor
        lineStyle {
          id
          pathOptions
          geoTypeCode
          usePattern
          patternTypeCode
          patternOptions
          shapeOptions
        }
        polyColor
        polyStyle {
          id
          pathOptions
          geoTypeCode
          usePattern
          patternTypeCode
          patternOptions
          shapeOptions
        }
        marker
        markerColor
        visibleByDefault
        creatorVisible
        restrictionsBetweenUsers
        multipleFilled
        userCanViewOthersInfos
        userCanEditOthersInfos
        userCanEditOthersGeometries
        userCanDeleteOthersGeometries
        isCheckedForDisplay @client
        toolPermissionSet {
          id
          codename
        }
        questionnaire {
          id
          name
          questionnairefieldSet {
            ...QuestionnaireFieldFrag
          }
        }
        layergeometrySet {
          id
          geometryType {
            ...LabelFrag
          }
          decorator {
            id
          }
          icon
          text
          isSelectedForDisplay @client
          layer {
            visibleByDefault
          }
        }
      }
      styleSet {
        ...StyleFrag
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_FRAG}
  ${LABEL_FRAG}
  ${STYLE_FRAG}
  ${FEATURE_FRAG}
`;

export const CURR_USER_TASK_SET_FROM_SLUG_OR_ID = gql`
  query currUserTaskSetFromSlugOrId($projectSlugOrId: String!) {
    currUserTaskSetFromSlugOrId(projectSlugOrId: $projectSlugOrId) {
      id
      name
      subtitle
      slug
      excerpt
      description
      illustration
      illustrationPath
      taskType {
        id
        code
      }
      ideaFeature {
        layer {
          id
          name
          editable
          lineColor
          lineStyle {
            id
            pathOptions
            geoTypeCode
            usePattern
            patternTypeCode
            patternOptions
            shapeOptions
          }
          polyColor
          polyStyle {
            id
            pathOptions
            geoTypeCode
            usePattern
            patternTypeCode
            patternOptions
            shapeOptions
          }
          marker
          markerColor
          visibleByDefault
          creatorVisible
          restrictionsBetweenUsers
          multipleFilled
          userCanViewOthersInfos
          userCanEditOthersInfos
          userCanEditOthersGeometries
          userCanDeleteOthersGeometries
          isCheckedForDisplay @client
          toolPermissionSet {
            id
            codename
          }
          questionnaire {
            id
            name
            questionnairefieldSet {
              ...QuestionnaireFieldFrag
            }
          }
          layergeometrySet {
            id
            geometryType {
              ...LabelFrag
            }
            decorator {
              id
            }
            icon
            text
            isSelectedForDisplay @client
            layer {
              visibleByDefault
            }
          }
        }
        ...FeatureFrag
      }
    }
    projectByIdOrSlug(projectIdOrSlug: $projectSlugOrId) {
      id
      name
      tileLayer {
        name
        latitude
        longitude
        zoomInit
        mapId
      }
    }
  }
  ${FEATURE_FRAG}
  ${QUESTIONNAIRE_FIELD_FRAG}
`;

export const GET_FEATURE = gql`
  query Feature($featureId: ID!) {
    feature(featureId: $featureId) {
      ...FeatureFrag
    }
  }
  ${FEATURE_FRAG}
`;

export const DECORATOR_NOT_FILLED = gql`
  query DecoratorNotFilled($decoratorId: ID!, $layerId: ID!) {
    decoratorNotFilled(decoratorId: $decoratorId, layerId: $layerId)
  }
`;

export const TASK_IDEAS_COUNTER = gql`
  query TaskIdeasCounter($taskId: ID!) {
    taskIdeasCounter(taskId: $taskId) {
      ideas
      contributors
    }
  }
`;
