import React, { useRef, useEffect } from "react";
import { animated, useSpring } from "react-spring";

function ErrorField({ error }) {
  const ref = useRef(null);
  const [style, animate] = useSpring(() => ({ height: "0px" }), []);

  useEffect(() => {
    animate({
      height: (error ? ref.current.offsetHeight : 0) + "px",
    });
  }, [animate, ref, error]);

  return (
    <animated.div style={style}>
      <div className="erreur" ref={ref}>
        {error}
      </div>
    </animated.div>
  );
}

export { ErrorField };
