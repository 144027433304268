import React from "react";
import { GET_FEATURE } from "../../_GraphQL/queries";
import {
  useCollaborativeMapDispatch,
  useCollaborativeMap,
  ACTIONS,
} from "../../../../Contexts/CollaborativeMapProvider";
import { DELETE_FILLED_QUESTIONNAIRE } from "../../../Questionnaire/_GraphQL/mutations";
import { USER_TASK_CONTRIB_COUNTER } from "../../../../Common/_GraphQL/queries";
import { useMutation } from "@apollo/client";
import { IciErrorMessage } from "../../../../Common/components/ErrorMessage";

function DeleteFilledQuestionnaire({ featureId, taskId }) {
  const dispatch = useCollaborativeMapDispatch();
  const context = useCollaborativeMap();
  const [deleteFilledQuestionnaire, { loading, error }] = useMutation(
    DELETE_FILLED_QUESTIONNAIRE,
    {
      update(cache) {
        const { feature } = cache.readQuery({
          query: GET_FEATURE,
          variables: {
            featureId: featureId,
          },
        });

        cache.writeQuery({
          query: GET_FEATURE,
          data: {
            feature: {
              ...feature,
              filledQuestionnaireSet: feature.filledQuestionnaireSet.filter(
                (fq) => fq.id !== context.selectedFilledQuestionnaireId
              ),
            },
          },
          variables: {
            featureId: featureId,
          },
        });

        const contribCount = cache.readQuery({
          query: USER_TASK_CONTRIB_COUNTER,
          variables: { taskIdOrSlug: taskId },
        });

        if (contribCount) {
          cache.writeQuery({
            query: USER_TASK_CONTRIB_COUNTER,
            variables: { taskIdOrSlug: taskId },
            data: {
              userTaskContribCounter: contribCount.userTaskContribCounter - 1,
            },
          });
        }
      },
      onError() {},
      onCompleted() {
        dispatch({ type: ACTIONS.DELETE_FILLED_Q_COMPLETED });
      },
    }
  );
  return (
    <>
      {loading ? (
        <p>Chargement...</p>
      ) : (
        <>
          {error ? <IciErrorMessage error={error} /> : null}
          <p>La contribution va être supprimée.</p>
          <p>Confirmez-vous l'opération ?</p>
          <div className="boutons">
            <button
              className="valider"
              onClick={() =>
                deleteFilledQuestionnaire({
                  variables: {
                    filledQuestionnaireId:
                      context.selectedFilledQuestionnaireId,
                  },
                })
              }
            >
              Confirmer
            </button>
            <button
              className="annuler"
              onClick={() => dispatch({ type: ACTIONS.DELETE_CANCELLED })}
            >
              Annuler
            </button>
          </div>
        </>
      )}
    </>
  );
}

export { DeleteFilledQuestionnaire };
