import React from "react";

function IdeasFilter({ themes, toggleTheme }) {

  return (
    <div className="filtre-idees">
      <h4>Filtrer par thèmes</h4>
      <div className="element">
        {themes.map((qflabel) => {
          const selectClassName = qflabel.selected
            ? "select-elem selected"
            : "select-elem";
          return (
            <div className="element select" key={`theme-${qflabel.id}`}>
              <div
                className={selectClassName}
                onClick={() =>
                  toggleTheme(
                    qflabel
                  )
                }
              >
                <p>{qflabel.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { IdeasFilter };
