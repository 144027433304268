import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_FOLDER_GENEALOGY } from "../_GraphQL/queries";
import { globalUnselectForDownload } from "../../../_GraphQL/mutations";
import ErrorMessage from "../../../Common/components/ErrorMessage";

function BreadCrumb({ projectIdOrSlug, folderId }) {
  const { loading, error, data, client } = useQuery(GET_FOLDER_GENEALOGY, {
    fetchPolicy: "network-only",
    variables: {
      folderId: folderId,
    },
  });

  if (loading) return null;

  if (error) {
    return (
      <ErrorMessage
        Header={"Erreur lors de la récupération du profil."}
        error={error}
      />
    );
  }

  if (!data) return null;

  let lastFolderId = data.folderGenealogy[data.folderGenealogy.length - 1].id;

  return (
    <>
      {data.folderGenealogy.map((f) => {
        if (f.id === lastFolderId) {
          return (
            <span key={`bc-section-${f.name}-${f.id}`} className="active">
              {f.name}
            </span>
          );
        } else {
          return (
            <React.Fragment key={`bc-section-${f.name}-${f.id}`}>
              <Link
                key={`bc-section-${f.name}-${f.id}`}
                to={`/${projectIdOrSlug}/documents/${f.id}`}
                onClick={() => globalUnselectForDownload(client, folderId)}
              >
                {f.name}
              </Link>
              &nbsp;/&nbsp;
            </React.Fragment>
          );
        }
      })}
    </>
  );
}

export { BreadCrumb };
