import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CURR_USER_TASK_SET_FROM_SLUG_OR_ID } from "../../_GraphQL/queries";
import tipTaskDescription from "../../../../medias/images/aide/tip/aide-description-tache.png";
import tipTaskSelect from "../../../../medias/images/aide/tip/aide-selection-tache.png";

import {
  ACTIONS,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";
import { Tip } from "../../../../Common/components/Tip";

function TaskDescription({ disabled }) {
  const dispatch = useCollaborativeMapDispatch();
  return (
    <div className="description-tache">
      <button
        className="bt-img"
        onClick={() => dispatch({ type: ACTIONS.TASK_INFOS_CLICKED })}
        title="Description de la tâche en cours"
        disabled={disabled}
      >
        i
      </button>
      <Tip>
        <div>
          Cliquer sur{" "}
          <img
            className="img-ombre-flotte"
            src={tipTaskDescription}
            alt="Description tâche en cours"
          />{" "}
          permet d'afficher des informations complémentaires sur le travail en
          cours.
        </div>
      </Tip>
    </div>
  );
}

function QuickTask({ disabled }) {
  const { projectIdOrSlug, taskIdOrSlug } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(
    CURR_USER_TASK_SET_FROM_SLUG_OR_ID,
    { variables: { projectSlugOrId: projectIdOrSlug } }
  );

  if (loading || error) return null;

  const currTask = data.currUserTaskSetFromSlugOrId.find((task) => {
    if (/^\d+$/.test(taskIdOrSlug)) {
      return task.id === taskIdOrSlug;
    } else {
      return task.slug === taskIdOrSlug;
    }
  });

  const selectedValue = `/${projectIdOrSlug}/inventaires/${taskIdOrSlug}`;

  return (
    <>
      <TaskDescription
        description={currTask.description}
        name={currTask.name}
        disabled={disabled}
      />
      <div className="tache-rapide">
        <select
          value={selectedValue}
          onChange={(e) => navigate(e.target.value)}
          disabled={disabled}
        >
          {data.currUserTaskSetFromSlugOrId
            .filter((task) => task.taskType.code === currTask.taskType.code)
            .map((task) => {
              // On n'utilise plus ?? car la chaine vide "" est considérée comme vrai
              let taskIdentification = task.id;
              if (task.slug) {
                taskIdentification = task.slug;
              }
              const optionValue = `/${projectIdOrSlug}/inventaires/${taskIdentification}`;
              let name = task.name;
              if (optionValue === selectedValue) {
                if (name.length > 45) {
                  name = name.substring(0, 43) + "...";
                }
              }
              return (
                <option
                  key={`project-${task.id}-${task.name}`}
                  value={optionValue}
                >
                  {name}
                </option>
              );
            })}
        </select>
        <Tip>
          <div>
            <img
              className="img-ombre-flotte"
              src={tipTaskSelect}
              alt="Selection tâche"
            />{" "}
            permet de sélectionner un autre sujet de travail.
          </div>
        </Tip>
      </div>
    </>
  );
}

export { QuickTask };
