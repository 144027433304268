import React from "react";
import { Initials } from "../../../../Common/components/Initials";
import { getInitials, getDisplayName } from "../../../../utils/utils";
import { DateTime } from "luxon";
import {
  ACTIONS,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";

function Contrib({ filledQuestionnaire }) {
  const dispatch = useCollaborativeMapDispatch();
  const initials = getInitials(filledQuestionnaire.user);
  const displayName = getDisplayName(filledQuestionnaire.user);
  return (
    <div
      className="contribution"
      onClick={() =>
        dispatch({
          type: ACTIONS.FILLED_QUESTIONNAIRE_CLICKED,
          filledQuestionnaireId: filledQuestionnaire.id,
        })
      }
    >
      <Initials displayName={initials} />
      <div className="texte-contrib">
        <p className="nom-contrib">{displayName}</p>
        <p className="date-contrib">
          {DateTime.fromISO(filledQuestionnaire.creationDate).toFormat(
            "'Le 'DD' à 'HH' h 'mm"
          )}
        </p>
      </div>
      <div className="fleche"></div>
    </div>
  );
}

export { Contrib };
