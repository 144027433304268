// Constants
import { COMPONENTS } from "./Constants";

// GraphQL
import { addErrorMessage } from "../_GraphQL/message";

export const handleError = (error, location) => {
  const { graphQLErrors, networkError } = error;

  if (graphQLErrors) {
    addErrorMessage({
      header: "Erreur lors du traitement de la requête",
      location: location || COMPONENTS.COMMON.INDEX,
      messageList: graphQLErrors.map((gqle) => gqle.message),
      autoClose: false,
    });
  }

  if (networkError) {
    addErrorMessage({
      header: "Erreur réseau",
      content: networkError.toString(),
      location: location || COMPONENTS.COMMON.INDEX,
      autoClose: false,
    });
  }
};
