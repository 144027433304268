// React
import React from "react";

// Component
import FeatureListContainer from "./FeatureListContainer";

const SelectedForDisplayLayer = (props) =>
  props.layers
    .filter((layer) => layer.isCheckedForDisplay)
    .map((checkedForDisplayLayer) => (
      <FeatureListContainer
        key={`feature-list-container-${checkedForDisplayLayer.id}`}
        layer={checkedForDisplayLayer}
        handleFeatureClick={props.handleFeatureClick}
        handlePmEdit={props.handlePmEdit}
        activeFeature={props.activeFeature}
      />
    ));

export default SelectedForDisplayLayer;
