// React
import React, { Component, useState, useRef } from "react";

// Constants
import {
  COLLABORATIVE_MAP_TOOLS,
  COLLABORATIVE_MAP_PERMISSIONS,
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
} from "../../../../utils/Constants";

// Components
import ExportShapefile from "./ExportShapefile";

import validImg from "../../../../medias/images/icone-valider.svg";
import cancelIcon from "../../../../medias/images/icone-annuler.svg";

import tipPointImg from "../../../../medias/images/aide/tip/aide-outil-point.png";
import tipPointSelectThemeImg from "../../../../medias/images/aide/tip/aide-outil-point-selection-theme.png";

import tipLineImg from "../../../../medias/images/aide/tip/aide-outil-ligne.png";
import lineThemeHelpImg from "../../../../medias/images/aide/tip/aide-outil-ligne-selection-theme.png";

import tipPolyImg from "../../../../medias/images/aide/tip/aide-outil-surface.png";
import tipPolySelectThemeImg from "../../../../medias/images/aide/tip/aide-outil-surface-selection-theme.png";

import tipEditImg from "../../../../medias/images/aide/tip/aide-outil-modifier.png";

import { useOutsideAlerter } from "../../../../utils/useOutsideAlerter";
import { Tip } from "../../../../Common/components/Tip";
import { PointIcon } from "../../../../Common/components/Images/PointIcon";
import { PolyIcon } from "../../../../Common/components/Images/PolyIcon";
import { LineIcon } from "../../../../Common/components/Images/LineIcon";
import { ModifyIcon } from "../../../../Common/components/Images/ModifyIcon";
import { positionSort } from "../../../../utils/utils";

const geometryTypeToPermission = {
  [COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT]:
    COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER,
  [COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE]:
    COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER,
  [COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON]:
    COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER,
};

const geometryTypeToTool = {
  [COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT]: COLLABORATIVE_MAP_TOOLS.ADD_POINT,
  [COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE]: COLLABORATIVE_MAP_TOOLS.ADD_LINE,
  [COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON]:
    COLLABORATIVE_MAP_TOOLS.ADD_POLYGON,
};

const GEOMETRY_TYPE_CODE_TO_NAME = {
  [COLLABORATIVE_MAP_TOOLS.ADD_POINT]: {
    name: "Point",
    img: (active) => <PointIcon active={active} />,
  },
  [COLLABORATIVE_MAP_TOOLS.ADD_LINE]: {
    name: "Ligne",
    img: (active) => <LineIcon active={active} />,
  },
  [COLLABORATIVE_MAP_TOOLS.ADD_POLYGON]: {
    name: "Surface",
    img: (active) => <PolyIcon active={active} />,
  },
};

function TipPoint() {
  return (
    <div>
      Sélectionner l'outil{" "}
      <img className="img-ombre-flotte" src={tipPointImg} alt="Outil point" />{" "}
      pour placer un élément sur la carte.
      <br />
      <br />
      Si une liste s'affiche, sélectionner un thème pour placer un élément sur
      la carte.
      <br />
      <img
        className="img-ombre-flotte"
        src={tipPointSelectThemeImg}
        alt="Sélection thème"
      />
    </div>
  );
}

function TipLine() {
  return (
    <div>
      Sélectionner l'outil{" "}
      <img className="img-ombre-flotte" src={tipLineImg} alt="Outil ligne" />{" "}
      pour tracer une ligne sur la carte.
      <br />
      <br />
      Si une liste s'affiche, sélectionner un thème pour tracer une ligne sur la
      carte.
      <br />
      <img
        className="img-ombre-flotte"
        src={lineThemeHelpImg}
        alt="Sélection thème"
      />
    </div>
  );
}

function TipPoly() {
  return (
    <div>
      Sélectionner l'outil{" "}
      <img className="img-ombre-flotte" src={tipPolyImg} alt="Outil surface" />{" "}
      pour dessiner une surface sur la carte.
      <br />
      <br />
      Si une liste s'affiche, sélectionner un thème pour dessiner une surface
      sur la carte.
      <br />
      <img
        className="img-ombre-flotte"
        src={tipPolySelectThemeImg}
        alt="Sélection thème"
      />
    </div>
  );
}

function TipEdit() {
  return (
    <div>
      L'outil{" "}
      <img className="img-ombre-flotte" src={tipEditImg} alt="modifier" />{" "}
      permet de :
      <ul>
        <li>déplacer un point sur la carte</li>
        <li>modifier une ligne sur la carte</li>
        <li>modifier une surface sur la carte</li>
      </ul>
      Une fois l'outil sélectionné, cliquer sur l'élément à modifier sur la
      carte.
    </div>
  );
}

const GEOMETRY_TYPE_CODE_TO_TIP = {
  [COLLABORATIVE_MAP_TOOLS.ADD_POINT]: <TipPoint />,
  [COLLABORATIVE_MAP_TOOLS.ADD_LINE]: <TipLine />,
  [COLLABORATIVE_MAP_TOOLS.ADD_POLYGON]: <TipPoly />,
};

function GeoButton({
  availableTools,
  activeLayer,
  activeTool,
  handelButtonClick,
  tool,
  disabled,
  mapPanelHeight,
}) {
  const [open, setOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => (open ? setOpen(false) : null));
  let className = open ? "couche-outil displayed" : "couche-outil hidden";

  return (
    <div ref={wrapperRef} className="outil">
      <button
        onClick={() => setOpen(!open)}
        className={activeTool === tool ? "active" : ""}
        disabled={disabled}
      >
        {GEOMETRY_TYPE_CODE_TO_NAME[tool].img(activeTool === tool)}
        <span>{GEOMETRY_TYPE_CODE_TO_NAME[tool].name}</span>
      </button>
      <Tip>{GEOMETRY_TYPE_CODE_TO_TIP[tool]}</Tip>
      <div
        className={className}
        style={{
          overflowY: "auto",
          maxHeight: `${mapPanelHeight}px`,
        }}
      >
        {availableTools[tool].map((layer) => (
          <button
            onClick={() => {
              handelButtonClick(tool, layer);
              setOpen(false);
            }}
            key={layer.id}
            className={activeLayer === layer ? "active" : ""}
            disabled={disabled}
          >
            {layer.name}
          </button>
        ))}
      </div>
    </div>
  );
}

function CreationTools({
  layerSet,
  toolPermissionSet,
  activeLayer,
  activeTool,
  handelButtonClick,
  activeFeature,
  mapPanelHeight,
}) {
  let availableTools = {};
  if (layerSet) {
    layerSet
      .filter((layer) => layer.editable && layer.isCheckedForDisplay)
      .sort(positionSort)
      .forEach((layer) => {
        layer.layergeometrySet
          .filter((layerGeo) => layerGeo.isSelectedForDisplay)
          .forEach((layergeometry) => {
            const geometryType = layergeometry.geometryType;
            const tool = geometryTypeToTool[geometryType.code];

            if (
              toolPermissionSet.some(
                (tp) =>
                  tp.codename ===
                  geometryTypeToPermission[geometryType.code] + layer.id
              )
            ) {
              if (availableTools[tool]) {
                availableTools[tool].push(layer);
              } else {
                availableTools[tool] = [layer];
              }
            }
          });
      });
  }
  let buttons = [];
  for (const tool in availableTools) {
    if (availableTools[tool].length > 1) {
      buttons.push(
        <GeoButton
          activeLayer={activeLayer}
          activeTool={activeTool}
          disabled={
            activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY &&
            activeFeature
          }
          availableTools={availableTools}
          handelButtonClick={handelButtonClick}
          key={tool}
          tool={tool}
          mapPanelHeight={mapPanelHeight}
        />
      );
    } else {
      buttons.push(
        <div className="outil" key={tool}>
          <button
            className={activeTool === tool ? "active" : ""}
            disabled={
              activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY &&
              activeFeature
            }
            onClick={() => handelButtonClick(tool, availableTools[tool][0])}
          >
            {GEOMETRY_TYPE_CODE_TO_NAME[tool].img(activeTool === tool)}

            <span>{GEOMETRY_TYPE_CODE_TO_NAME[tool].name}</span>
          </button>
          <Tip>{GEOMETRY_TYPE_CODE_TO_TIP[tool]}</Tip>
        </div>
      );
    }
  }
  return buttons;
}

class Tools extends Component {
  constructor(props) {
    super(props);
    this.editGeoButtonRef = React.createRef();
    this.handelButtonClick = this.handelButtonClick.bind(this);
  }

  handelButtonClick(tool, layer, ref) {
    if (
      this.props.activeTool === tool &&
      (layer == null || this.props.activeLayer.id === layer.id)
    ) {
      if (ref && ref.current) {
        ref.current.blur();
      }
      this.props.setActiveTool(COLLABORATIVE_MAP_TOOLS.NONE, layer);
    } else {
      this.props.setActiveTool(tool, layer);
    }
  }

  render() {
    let toolPermissionSet = [];

    if (this.props.task.layerSet) {
      this.props.task.layerSet
        .filter((layer) => layer.editable && layer.isCheckedForDisplay)
        .forEach((layer) => {
          if (layer.toolPermissionSet) {
            layer.toolPermissionSet.forEach((tp) => {
              if (
                !toolPermissionSet.some(
                  (toolPerm) => toolPerm.codename === tp.codename
                )
              ) {
                toolPermissionSet.push(tp);
              }
            });
          }
        });
    }

    return (
      <div className="barre-outils">
        <div className="separateur-panneaux"></div>
        <CreationTools
          layerSet={this.props.task.layerSet}
          toolPermissionSet={toolPermissionSet}
          activeLayer={this.props.activeLayer}
          activeTool={this.props.activeTool}
          handelButtonClick={this.handelButtonClick}
          activeFeature={this.props.activeFeature}
          mapPanelHeight={this.props.mapPanelHeight}
        />

        {toolPermissionSet.some((tp) =>
          tp.codename.startsWith(
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER
          )
        ) ? (
          <div className="outil">
            <button
              className={
                this.props.activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY
                  ? "active"
                  : ""
              }
              key="edit"
              disabled={
                (this.props.activeTool === COLLABORATIVE_MAP_TOOLS.ADD_LINE ||
                  this.props.activeTool ===
                    COLLABORATIVE_MAP_TOOLS.ADD_POLYGON) &&
                this.props.featureHistory.index > 0
              }
              onClick={() =>
                this.handelButtonClick(
                  COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY,
                  undefined,
                  this.editGeoButtonRef
                )
              }
            >
              <ModifyIcon
                active={
                  this.props.activeTool ===
                  COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY
                }
              />
              <span>Modifier</span>
            </button>
            <Tip>
              <TipEdit />
            </Tip>
          </div>
        ) : null}
        {toolPermissionSet.some((tp) =>
          tp.codename.startsWith(
            COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER
          )
        ) ? (
          <ExportShapefile
            taskId={this.props.task.id}
            disabled={this.props.activeTool !== COLLABORATIVE_MAP_TOOLS.NONE}
          />
        ) : null}

        {this.props.activeTool === COLLABORATIVE_MAP_TOOLS.ADD_POINT ||
        this.props.activeTool === COLLABORATIVE_MAP_TOOLS.ADD_LINE ||
        this.props.activeTool === COLLABORATIVE_MAP_TOOLS.ADD_POLYGON ||
        this.props.activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY ? (
          <div className="outil">
            <button
              className="cancel"
              key="cancel"
              onClick={() => this.props.handleCancelClick()}
            >
              <img src={cancelIcon} alt="Annuler" />
              <span>Annuler</span>
            </button>
          </div>
        ) : null}

        {this.props.activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY &&
        this.props.featureHistory.index > 0 ? (
          <div className="outil">
            <button onClick={() => this.props.handleValidateClick()}>
              <img src={validImg} alt="valider" />
              <span>Valider</span>
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export { Tools };
