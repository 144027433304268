import React from "react";

function LockIcon() {
  return (
    <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 80">
      <path
        d="M139.81,314.25h-3.75v-7.62a18.75,18.75,0,1,0-37.5,0v7.62H94.81a7.59,7.59,0,0,0-7.5,7.62V360a7.59,7.59,0,0,0,7.5,7.62h45a7.59,7.59,0,0,0,7.5-7.62v-38.1A7.59,7.59,0,0,0,139.81,314.25Zm-22,34.34a7.5,7.5,0,1,1,7.5-7.5A7.5,7.5,0,0,1,117.81,348.59Zm11.5-35h-23v-7.46a11.5,11.5,0,0,1,23,0Z"
        transform="translate(-87.31 -287.59)"
      />
    </svg>
  );
}

export { LockIcon };
