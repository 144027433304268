import React from "react";
import { ErrorField } from "../../../Common/components/Fields/Common/ErrorField";
import { Loader } from "../../../Common/components/Loader";

const QuestionnaireForm = ({
  mutate,
  loading,
  error,
  called,
  fields,
  cancel,
  handleSubmit,
}) => {
  if (loading) return <Loader />;

  if (called && !error) {
    // Inutile de faire un nouveau render le parent va fermer
    // ça évite de faire de requête de recevoir un warning sur le
    // fait qu'on essaye de changer l'état d'un composant "unmount"
    return null;
  }

  return (
    <form>
      <ErrorField error={error} />
      <div>{fields}</div>
      <div className="boutons">
        <button className="valider" onClick={(e) => handleSubmit(e, mutate)}>
          Enregistrer
        </button>
        <button className="annuler" onClick={(e) => cancel(e)}>
          Annuler
        </button>
      </div>
    </form>
  );
};

export { QuestionnaireForm };
