import React, { forwardRef, useImperativeHandle } from "react";
import { truncateDecimals } from "../../../utils/utils";
import { Field } from "./Common/Field";

function verifyValue(field, value) {
  if (!value) {
    if (field.required) {
      return {
        valid: false,
        error: "Ce champ est obligatoire.",
      };
    }
  } else {
    if (field.minimum && value < field.minimum) {
      return {
        valid: false,
        error: `Le nombre doit être supérieur à ${field.minimum}.`,
      };
    }
    if (field.maximum && value > field.maximum) {
      return {
        valid: false,
        error: `Le nombre doit être inférieur à ${field.maximum}.`,
      };
    }
    if (!field.decimal && !Number.isInteger(parseInt(value))) {
      return {
        valid: false,
        error: `Le nombre doit être entier.`,
      };
    }
  }

  return {
    valid: true,
  };
}

const NumberInput = forwardRef(({ qField, value, setValue, setError }, ref) => {
  useImperativeHandle(ref, () => ({
    checkForErrors() {
      const verif = verifyValue(qField, value);
      if (!verif.valid) {
        setError(verif.error);
      } else {
        setError(null);
      }
      return verif;
    },
  }));

  const handleBlur = () =>
    setTimeout(() => {
      const verif = verifyValue(qField, value);
      if (verif.valid) {
        setError(null);
      } else {
        setError(verif.error);
      }
    }, 100);

  let step = 1;

  if (qField.decimal) {
    step = qField.decimalDigits ? 1 / Math.pow(10, qField.decimalDigits) : 0.01;
    if (value !== "" && !isNaN(value)) {
      value = truncateDecimals(
        value,
        parseInt(qField.decimalDigits, 10)
      ).toString();
    }
  } else {
    if (value !== "" && !isNaN(value)) {
      value = parseInt(value, 10).toString();
    }
  }

  return (
    <input
      type="number"
      step={step}
      max={qField.maximum}
      min={qField.minimum}
      placeholder={qField.placeHolder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
      required={qField.required}
      disabled={qField.readOnly}
    />
  );
});

function NumberField({
  featureId,
  filledQId,
  qField,
  filledQField,
  mode,
  value,
  setValue,
  inputRef,
  labelRef,
  canEdit,
}) {
  if (value === undefined || value === null) value = "";

  return (
    <Field
      featureId={featureId}
      filledQId={filledQId}
      qField={qField}
      filledQField={filledQField}
      mode={mode}
      createValue={value}
      createSetValue={setValue}
      inputRef={inputRef}
      labelRef={labelRef}
      canEdit={canEdit}
    >
      {(setError, value, setValue, ref) => (
        <NumberInput
          qField={qField}
          value={value}
          setValue={setValue}
          setError={setError}
          ref={ref}
        />
      )}
    </Field>
  );
}

export { NumberField };
