/******************************* */
/*           COMMON              */
/******************************* */
export const AUTH_TOKEN = "auth-token";

// Messages
export const MESSAGE_TYPE = {
  POSITIVE: "MESSAGE_TYPE_POSITIVE",
  WARNING: "MESSAGE_TYPE_WARNING",
  NEGATIVE: "MESSAGE_TYPE_NEGATIVE",
};

// Components
export const COMPONENTS = {
  COMMON: {
    INDEX: "COMPONENT_COMMON_INDEX",
    PROFILE: "COMPONENT_COMMON_PROFILE",
  },
  COLLABORATIVE_MAP: {
    CONTROLLER: "COMPONENT_COLLABORATIVE_MAP_CONTROLLER",
  },
  FILEMANAGER: {
    MANAGE_PERMISSIONS: "COMPONENT_FILEMANAGER_MANAGE_PERMISSIONS",
  },
};

// Token MapBox
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoieXZhaW5tIiwiYSI6ImNrMnZyZzdyODA3aWIzaHFsNDJ3eDlpMzMifQ.bSF9_23BfhATwmQFfGryTw";

// Clé ign
export const IGN_KEY = "rcvlahlyq4mm22jvo1rklhpb";
// API carto
export const URL_APICARTO = "https://apicarto.ign.fr/api/cadastre/";

/******************************* */
/*      COLLABORATIVE MAP        */
/******************************* */
export const COLLABORATIVE_MAP_GEOMETRY_TYPES = {
  POINT: "COLLABORATIVE_MAP_GEOMETRY_TYPE_POINT",
  LINE: "COLLABORATIVE_MAP_GEOMETRY_TYPE_LINE",
  POLYGON: "COLLABORATIVE_MAP_GEOMETRY_TYPE_POLYGON",
};

export const COLLABORATIVE_MAP_TOOLS = {
  NONE: "COLLABORATIVE_MAP_TOOL_NONE",
  ADD_POINT: "COLLABORATIVE_MAP_TOOL_ADD_POINT",
  ADD_LINE: "COLLABORATIVE_MAP_TOOL_ADD_LINE",
  ADD_POLYGON: "COLLABORATIVE_MAP_TOOL_ADD_POLYGON",
  EDIT_GEOMETRY: "COLLABORATIVE_MAP_TOOL_EDIT_GEOMETRY",
  EDIT_QUESTIONNAIRE: "COLLABORATIVE_MAP_TOOL_EDIT_QUESTIONNAIRE",
  DELETE_FEATURE: "COLLABORATIVE_MAP_TOOL_DELETE_FEATURE",
  EXPORT_SHP: "COLLABORATIVE_MAP_TOOL_EXPORT_SHP",
};

export const COLLABORATIVE_MAP_TOOL_TO_GEOMETRY_TYPE_CODE = {
  [COLLABORATIVE_MAP_TOOLS.ADD_POINT]: COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT,
  [COLLABORATIVE_MAP_TOOLS.ADD_LINE]: COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE,
  [COLLABORATIVE_MAP_TOOLS.ADD_POLYGON]:
    COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON,
};

// Collaborative Map Permissions
export const COLLABORATIVE_MAP_PERMISSIONS = {
  ADD_POINT_TO_LAYER: "add_point_to_layer_",
  ADD_LINE_TO_LAYER: "add_line_to_layer_",
  ADD_POLYGON_TO_LAYER: "add_polygon_to_layer_",
  EDIT_GEOMETRY_ON_LAYER: "edit_geometry_on_layer_",
  EDIT_QUESTIONNAIRE_ON_LAYER: "edit_questionnaire_on_layer_",
  DELETE_GEOMETRY_ON_LAYER: "delete_geometry_on_layer_",
  EXPORT_SHP_FROM_LAYER: "export_shp_from_layer_",
  VIEW_OTHERS_FEATURES: "view_others_features_on_layer_",
  VIEW_OTHERS_INFOS: "view_others_infos_on_layer_",
  EDIT_OTHERS_INFOS: "edit_others_infos_on_layer_",
  EDIT_OTHERS_GEOMETRIES: "edit_others_geometries_on_layer_",
  DELETE_OTHERS_GEOMETRIES: "delete_others_geometries_on_layer_",
};

export const PATTERN_TYPES = {
  STRIPES: "PATTERN_TYPE_STRIPES",
  RECTANGLE: "PATTERN_TYPE_RECTANGLE",
  CIRCLE: "PATTERN_TYPE_CIRCLE",
  PATH: "PATTERN_TYPE_PATH",
};

// Caneva de marqueur html
export const DEFAULT_COLOR = "#3388ff";

export const DEFAULT_MARKER_COLOR = "#3388ff";

export const DEFAULT_MARKER_SYMBOL = "circle-15.svg";

export const MARKER_HTML_TEMPLATE = `<svg id="icon_lieux" data-name="icon/lieux" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="55" height="55" viewBox="0 0 55 55">
  <defs>
    SHADOW_EFFECT
    <clipPath id="clip-path">
      <path id="Mask" d="M18.345,45.094a2.325,2.325,0,0,1-1.524-.567A71.687,71.687,0,0,1,4.208,30.688,29.376,29.376,0,0,1,1.052,24.5,17.171,17.171,0,0,1,0,18.791a19.862,19.862,0,0,1,1.511-7.751A17.812,17.812,0,0,1,5.557,5.116a18,18,0,0,1,5.851-3.785,18.677,18.677,0,0,1,13.85,0,18,18,0,0,1,5.851,3.785,17.812,17.812,0,0,1,4.046,5.925,19.863,19.863,0,0,1,1.511,7.751A17.179,17.179,0,0,1,35.614,24.5a29.409,29.409,0,0,1-3.153,6.19A71.782,71.782,0,0,1,19.869,44.527,2.323,2.323,0,0,1,18.345,45.094ZM18.334,9.167a8.25,8.25,0,1,0,8.25,8.25A8.259,8.259,0,0,0,18.334,9.167Z" transform="translate(0 0)" stroke="rgba(0,0,0,0.39)" stroke-miterlimit="10" stroke-width="1"/>
    </clipPath>
  </defs>
  <g id="colors_bordeaux" data-name="colors/bordeaux" transform="translate(9.167 4.583)">
    <path SHADOW_LINK id="Mask-2" data-name="Mask" d="M18.345,45.094a2.325,2.325,0,0,1-1.524-.567A71.687,71.687,0,0,1,4.208,30.688,29.376,29.376,0,0,1,1.052,24.5,17.171,17.171,0,0,1,0,18.791a19.862,19.862,0,0,1,1.511-7.751A17.812,17.812,0,0,1,5.557,5.116a18,18,0,0,1,5.851-3.785,18.677,18.677,0,0,1,13.85,0,18,18,0,0,1,5.851,3.785,17.812,17.812,0,0,1,4.046,5.925,19.863,19.863,0,0,1,1.511,7.751A17.179,17.179,0,0,1,35.614,24.5a29.409,29.409,0,0,1-3.153,6.19A71.782,71.782,0,0,1,19.869,44.527,2.323,2.323,0,0,1,18.345,45.094ZM18.334,9.167a8.25,8.25,0,1,0,8.25,8.25A8.259,8.259,0,0,0,18.334,9.167Z" transform="translate(0 0)" stroke="STROKE_COLOR" stroke-miterlimit="10" stroke-width="7"/>
    <g id="colors_bordeaux-2" data-name="colors/bordeaux" clip-path="url(#clip-path)">
        <rect id="Rectangle" width="55" height="55" fill="MARKER_BG_COLOR" />
    </g>
    <circle cx="18.5" cy="19" r="13" fill="#ffffff"/>
    MARKER_IMAGE
  </g>
</svg>`;

export const MARKER_IMAGE = `<image x="9.5" y="9.5" width="18" height="18" href="/static/back/resources/media/markers/MARKER_ICON" />`;

export const SHADOW_EFFECT = `<filter id="neon">
    <feFlood flood-color="rgb(255,255,128)" flood-opacity="0.5" in="SourceGraphic" />
    <feComposite operator="in" in2="SourceGraphic" />
    <feGaussianBlur stdDeviation="1" />
    <feComponentTransfer result="glow1">
      <feFuncA type="linear" slope="4" intercept="0" />
    </feComponentTransfer>
    <feMerge>
      <feMergeNode in="glow1" />
      <feMergeNode in="SourceGraphic" />
    </feMerge>
  </filter>`;

export const SHADOW_LINK = `filter="url(#neon)"`;

export const TASK_TYPES = {
  INVENTORY: "TASK_TYPE_INVENTORY",
  IDEAS: "TASK_TYPE_IDEAS",
};

/******************************* */
/*        QUESTIONNAIRE          */
/******************************* */
export const QUESTIONNAIRE_FIELD_TYPES = {
  TEXT: "QUESTIONNAIRE_FIELD_TYPE_TEXT",
  RADIO: "QUESTIONNAIRE_FIELD_TYPE_RADIO",
  SELECT: "QUESTIONNAIRE_FIELD_TYPE_SELECT",
  CHECKBOX: "QUESTIONNAIRE_FIELD_TYPE_CHECKBOX",
  DECIMAL: "QUESTIONNAIRE_FIELD_TYPE_DECIMAL",
  DATE: "QUESTIONNAIRE_FIELD_TYPE_DATE",
  IMAGE: "QUESTIONNAIRE_FIELD_TYPE_IMAGE",
  DOCUMENT: "QUESTIONNAIRE_FIELD_TYPE_DOCUMENT",
};

export const QUESTIONNAIRE_FILTER = {
  ORPHELIN: "QUESTIONNAIRE_FILTER_ORPHELIN",
  NON_ORPHELIN: "QUESTIONNAIRE_FILTER_NON_ORPHELIN",
  ALL: "QUESTIONNAIRE_FILTER_ALL",
};

export const QUESTIONNAIRE_MODES = {
  CREATE: "QUESTIONNAIRE_MODE_CREATE",
  CREATION_COMPLETED: "QUESTIONNAIRE_MODE_CREATION_COMPLETED",
  READ: "QUESTIONNAIRE_MODE_READ",
  EDIT: "QUESTIONNAIRE_MODE_EDIT",
  DELETE_FEATURE: "QUESTIONNAIRE_MODE_DELETE_FEATURE",
  DELETE_FILLED_Q: "QUESTIONNAIRE_MODE_DELETE_FILLED_Q",
};

/******************************* */
/*        FILEMANAGER            */
/******************************* */
export const FILEMANAGER_PERMISSIONS = {
  view_folder_: "canViewFolder",
  change_folder_: "canChangeFolder",
  move_folder_: "canMoveFolder",
  delete_folder_: "canDeleteFolder",
  add_folder_to_folder_: "canAddFolder",
  add_document_to_folder_: "canAddDocument",
  view_document_: "canViewDocument",
  move_document_: "canMoveDocument",
  delete_document_: "canDeleteDocument",
};

export const FILEMANAGER_PERMISSIONS_REVERSE = {
  canViewFolder: "view_folder_",
  canChangeFolder: "change_folder_",
  canMoveFolder: "move_folder_",
  canDeleteFolder: "delete_folder_",
  canAddFolder: "add_folder_to_folder_",
  canAddDocument: "add_document_to_folder_",
  canViewDocument: "view_document_",
  canMoveDocument: "move_document_",
  canDeleteDocument: "delete_document_",
};

/******************************* */
/*            USERS              */
/******************************* */
export const USER_TYPES = {
  NATURAL_PERSON: "USER_TYPE_NATURAL_PERSON",
  LEGAL_PERSON: "USER_TYPE_LEGAL_PERSON",
};

export const BASE_MAPS = {
  SATELLITE: "satellite-streets-v12",
  STREETS: "streets-v11",
  TOPOGRAPHIC: "outdoors-v11",
};

export const RIGHT_PANELS = {
  LEGEND: "LEGEND",
  BASE_MAP_SELECTOR: "BASE_MAP_SELECTOR",
  INFOS: "INFOS",
  NONE: "NONE",
};

export const PAGES = {
  FM_FILE_LIST: "FM_FILE_LIST",
  FM_ADD_DOC: "FM_ADD_DOC",
  FM_ADD_FOLDER: "FM_ADD_FOLDER",
};

export const LOCAL_ONLY_FIELD_INIT = "LOCAL_ONLY_FIELD_INIT";

export const IDEAS_BUTTON = {
  SHOW_CONTRIBUTIONS: "SHOW_CONTRIBUTIONS",
  CONTRIBUTE: "CONTRIBUTE",
};
