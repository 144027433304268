import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { CREATE_TOKEN_FOR_PWD_RESET_REQUEST } from "../_GraphQL/mutations";
import { useQuery, useMutation } from "@apollo/client";
import { ErrorMessage } from "../../../Common/components/ErrorMessage";
import { IS_CONNECTED } from "../../../_GraphQL/queries";
import logo from "../../../medias/images/logo-et-texte-blanc.svg";

export function PasswordResetRequest() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [result, setResult] = useState({
    formSubmitted: false,
    success: false,
    error: false,
  });
  const [createToken, { loading, error }] = useMutation(
    CREATE_TOKEN_FOR_PWD_RESET_REQUEST,
    {
      onCompleted() {
        setResult({
          formSubmitted: true,
          success: true,
          error: false,
        });
      },
      onError() {
        setResult({
          formSubmitted: true,
          success: false,
          error: true,
        });
      },
    }
  );

  const {
    loading: isConnectedLoading,
    error: errorIsConnected,
    data,
  } = useQuery(IS_CONNECTED);

  if (isConnectedLoading) return null;

  if (errorIsConnected) return null;

  if (!data) return null;

  if (data.isConnected) {
    navigate("/");
  }
  return (
    <div className="mot-de-passe-oublie">
      <div className="logo">
        <div className="courbes">
          <div className="courbe-bleue">
            <div className="courbe-blanche"></div>
          </div>
        </div>
        <div>
          <img src={logo} alt="Logo - Initiative Cartographique Interactive" />
        </div>
      </div>
      <div className="corps">
        <div className="centre">
          <h1>Mot de passe oublié</h1>
          <p>
            Veuillez renseigner votre adresse email afin d&apos;initialiser la
            procédure de réinitialisation de mot de passe.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              createToken({ variables: { email: email } });
            }}
          >
            {loading ? "Chargement..." : null}
            {result.formSubmitted && result.error && error ? (
              <ErrorMessage error={error} />
            ) : null}
            {result.formSubmitted && result.success ? (
              <p>
                Un mot de passe vous a été envoyé. Pour réinitialiser votre mot
                de passe veuillez consulter votre boite mail.
              </p>
            ) : null}
            {result.formSubmitted && result.success ? null : (
              <>
                <div>
                  <input
                    placeholder="Email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <button type="submit">Envoyer</button>
                </div>
              </>
            )}
          </form>
          <Link className="retour" to="/">
            Retour
          </Link>
        </div>
      </div>
    </div>
  );
}
