import React from "react";
import { useQuery } from "@apollo/client";
import { DEFAULT_FOLDER_GRP_PERM_SET_CURR_SITE_GRP_SET } from "../_GraphQL/queries";
import { Loader } from "../../../Common/components/Loader";
import { ManageFolderPermissionsForm } from "./ManageFolderPermissionsForm";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import * as constants from "../../../utils/Constants";

function ManageFolderPermissionsQuery({
  handleCheckChange,
  addGroupPermission,
  projectIdOrSlug,
  initGroupPermissionsRows,
  groupPermissionsRows,
  style,
}) {
  const { loading, error, data } = useQuery(
    DEFAULT_FOLDER_GRP_PERM_SET_CURR_SITE_GRP_SET,
    {
      variables: {
        projectIdOrSlug: projectIdOrSlug,
      },
    }
  );

  if (loading) return <Loader />;

  if (error) {
    return (
      <IciErrorMessage
        Header={"Impossible de récupérer les groupes."}
        error={error}
      />
    );
  }

  let initialGroupPermissionsRows = [];
  data.currWebsiteGroupSet.forEach((group) => {
    initialGroupPermissionsRows.push({
      group: group,
      canViewFolder: false,
      canMoveFolder: false,
      canChangeFolder: false,
      canDeleteFolder: false,
      canAddFolder: false,
      canAddDocument: false,
    });

    let groupPermissions = data.defaultFolderGroupPermissionSet.filter(
      (a) => a.group.id === group.id
    );

    groupPermissions.forEach((gp) => {
      let perm = constants.FILEMANAGER_PERMISSIONS[gp.permission];
      initialGroupPermissionsRows.find((o) => o.group.id === gp.group.id)[
        perm
      ] = true;
    });
  });

  return (
    <ManageFolderPermissionsForm
      style={style}
      addGroupPermission={addGroupPermission}
      initialGroupPermissionsRows={initialGroupPermissionsRows}
      groupPermissionsRows={groupPermissionsRows}
      handleCheckChange={handleCheckChange}
      initGroupPermissionsRows={initGroupPermissionsRows}
    />
  );
}

export default ManageFolderPermissionsQuery;
