import React from "react";
import { MESSAGE_TYPE, COMPONENTS } from "../../utils/Constants";

const getMessageFromApolloError = (apolloError) => {
  const { graphQLErrors, networkError } = apolloError;

  const message = {
    header: "Une erreur est survenue",
    location: COMPONENTS.COMMON.INDEX,
    type: MESSAGE_TYPE.NEGATIVE,
  };

  if ((!graphQLErrors || graphQLErrors.length === 0) && !networkError) {
    message.content = apolloError.toString();
  }

  if (graphQLErrors && graphQLErrors.length !== 0) {
    message.messageList = graphQLErrors.map(({ message }) => message);
  }

  if (networkError) {
    message.content = networkError.toString();
  }

  return message;
};

const ErrorMessage = ({ error, header = "Une erreur s'est produite" }) => {
  const { graphQLErrors, networkError } = error;

  if ((!graphQLErrors || graphQLErrors.length === 0) && !networkError) {
    return (
      <div className="erreur">
        <h1>Une erreur inconnue est survenue</h1>
        <p>{error.toString()}</p>
      </div>
    );
  }

  if (graphQLErrors && graphQLErrors.length !== 0) {
    return (
      <div className="erreur">
        <h1>{header}</h1>
        <ul>
          {graphQLErrors.map(({ message }) => (
            <li key={Math.random()}>{message}</li>
          ))}
        </ul>
      </div>
    );
  }

  if (networkError) {
    if (
      networkError.message ===
      "JSON.parse: unexpected character at line 1 column 1 of the JSON data"
    ) {
      return (
        <div className="erreur">
          <h1>Une erreur réseau est survenue</h1>
          <p>{networkError.bodyText}</p>
        </div>
      );
    }
    return (
      <div className="erreur">
        <h1>Une erreur réseau est survenue</h1>
        <p>{networkError.toString()}</p>
      </div>
    );
  }

  return <div className="erreur">Une erreur inconnue s'est produite</div>;
};

function getIciErrorTextsFromApolloError(
  apolloError,
  header = "Une erreur s'est produite"
) {
  if (!apolloError) return null;

  const { graphQLErrors, networkError } = apolloError;

  if ((!graphQLErrors || graphQLErrors.length === 0) && !networkError) {
    return {
      header: "Une erreur inconnue est survenue",
      body: <div>{apolloError.toString()}</div>,
    };
  }

  if (graphQLErrors && graphQLErrors.length !== 0) {
    return {
      header: header,
      body: (
        <div>
          <ul>
            {graphQLErrors.map(({ message }) => (
              <li key={Math.random()}>{message}</li>
            ))}
          </ul>
        </div>
      ),
    };
  }

  if (networkError) {
    if (
      networkError.message ===
      "JSON.parse: unexpected character at line 1 column 1 of the JSON data"
    ) {
      return {
        header: "Une erreur réseau est survenue",
        body: <div>{networkError.bodyText}</div>,
      };
    }

    if (
      networkError.result &&
      networkError.result.errors &&
      networkError.result.errors.length > 0 &&
      networkError.result.errors[0].message
    ) {
      return {
        header: "Une erreur réseau est survenue",
        body: <div>{networkError.result.errors[0].message}</div>,
      };
    }

    return {
      header: "Une erreur réseau est survenue",
      body: <div>{networkError.toString()}</div>,
    };
  }

  return {
    header: "Une erreur réseau est survenue",
    body: <div>Erreur inconnue</div>,
  };
}

const IciErrorMessage = ({ error, header = "Une erreur s'est produite" }) => {
  const { graphQLErrors, networkError } = error;

  if ((!graphQLErrors || graphQLErrors.length === 0) && !networkError) {
    return (
      <div className="message-erreur">
        <h1>Une erreur inconnue est survenue</h1>
        <p>{error.toString()}</p>
      </div>
    );
  }

  if (graphQLErrors && graphQLErrors.length !== 0) {
    return (
      <div
        className="message-erreur"
        header={header}
        list={graphQLErrors.map(({ message }) => message)}
      >
        <h1>{header}</h1>
        <div>
          <ul>
            {graphQLErrors.map(({ message }, index) => (
              <li key={`message-erreur-${index}`}>{message}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  if (networkError) {
    if (
      networkError.message ===
      "JSON.parse: unexpected character at line 1 column 1 of the JSON data"
    ) {
      return (
        <div className="message-erreur">
          <h1>Une erreur réseau est survenue</h1>
          <p>{networkError.bodyText}</p>
        </div>
      );
    }

    if (
      networkError.result &&
      networkError.result.errors &&
      networkError.result.errors.length > 0 &&
      networkError.result.errors[0].message
    ) {
      return (
        <div className="message-erreur">
          <h1>Une erreur réseau est survenue</h1>
          <p>{networkError.result.errors[0].message}</p>
        </div>
      );
    }

    return (
      <div className="message-erreur">
        <h1>Une erreur réseau est survenue</h1>
        <p>{networkError.toString()}</p>
      </div>
    );
  }

  return (
    <div className="message-erreur">Une erreur inconnue s'est produite</div>
  );
};

export {
  getMessageFromApolloError,
  getIciErrorTextsFromApolloError,
  ErrorMessage,
  IciErrorMessage,
};
export default ErrorMessage;
