import React from "react";
import { MESSAGE_TYPE } from "../../utils/Constants";
import { useQuery } from "@apollo/client";
import { GET_ALL_MESSAGES } from "../../_GraphQL/queries";
import { messageSetVar } from "../../_GraphQL/message";

const MessageList = ({ items }) => {
  return (
    <ul>
      {items.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ul>
  );
};

const MessageDisplayer = ({ message }) => {
  let messageClasseType = "message";

  if (message.type === MESSAGE_TYPE.POSITIVE) {
    messageClasseType += " succes";
  } else if (message.type === MESSAGE_TYPE.WARNING) {
    messageClasseType += " alerte";
  } else if (message.type === MESSAGE_TYPE.NEGATIVE) {
    messageClasseType += " erreur";
  }

  function removeMessage() {
    messageSetVar(messageSetVar().filter((m) => m.id !== message.id));
  }

  if (message.autoClose) {
    setTimeout(removeMessage, message.closeTime);
  }

  let body;

  if (message.rawHtml) {
    body = (
      <div
        style={{
          maxHeight: 800,
          overflow: "scroll",
        }}
      >
        <div
          className="reset-some-element"
          dangerouslySetInnerHTML={{ __html: message.content }}
        />
      </div>
    );
  } else {
    body = message.content ? (
      message.content
    ) : (
      <MessageList items={message.messageList} />
    );
  }

  return (
    <div className={messageClasseType}>
      <h1>{message.header}</h1>
      <button onClick={() => removeMessage()}>X</button>
      <div>{body}</div>
    </div>
  );
};

const MessagesDisplayer = ({ location }) => {
  const queryResult = useQuery(GET_ALL_MESSAGES);

  if (!queryResult || !queryResult.data) return null; // Ajout d'un transition group ? le transition group devrait-il englober la query ?

  const { data } = queryResult;

  if (!data.messageSet) return null;

  const messageSet = data.messageSet.filter((m) => m.location === location);

  return (
    <>
      {messageSet.map((message) => (
        <MessageDisplayer key={`message_${message.id}`} message={message} />
      ))}
    </>
  );
};

export default MessagesDisplayer;
