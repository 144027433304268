import React from "react";

function MapsIcon({ active }) {
  const fill = active ? "#ffffff" : "#979797";
  return (
    <svg width="34.3" height="26.4" viewBox="0 0 34.3 26.4">
      <path
        d="M11.433,23.32,1.257,26.356l-.3.044C.419,26.4,0,26.077,0,25.667V3.491c0-.337.286-.6.686-.7L11.433,0,22.866,3.08,33.042.044l.3-.044c.534,0,.953.323.953.733V22.91c0,.337-.286.6-.686.7L22.866,26.4Z"
        fill={fill}
      />
    </svg>
  );
}

export { MapsIcon };
