import React from "react";

function PolyPath({ pathOptions, fill }) {
  const { stroke, color, weight, opacity, dashArray, fillOpacity } =
    pathOptions;
  return (
    <path
      stroke={stroke ? color : "none"}
      strokeWidth={stroke ? weight : null}
      strokeOpacity={opacity ?? null}
      strokeDasharray={dashArray ?? null}
      fill={fill}
      d="M 5 5 H 95 V 60 H 5 Z"
      fillOpacity={fillOpacity ?? null}
    ></path>
  );
}

export { PolyPath };
