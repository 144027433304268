import { gql } from "@apollo/client";

export const CURRENT_USER_UPDATE_PASSWORD = gql`
  mutation CurrUserUpdatePassword($password: String!) {
    currUserUpdatePassword(password: $password) {
      passwordUpdated
    }
  }
`;

export const CREATE_TOKEN_FOR_PWD_RESET_REQUEST = gql`
  mutation CreateTokenForPwdResetRequest($email: String!) {
    createTokenForPwdResetRequest(email: $email) {
      success
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignIn(
    $projectIdOrSlug: String!
    $signInSlug: String!
    $email: String!
    $password: String!
    $nature: String!
    $agree: Boolean!
    $firstName: String
    $lastName: String
    $name: String
  ) {
    signIn(
      projectIdOrSlug: $projectIdOrSlug
      signInSlug: $signInSlug
      email: $email
      password: $password
      nature: $nature
      agree: $agree
      firstName: $firstName
      lastName: $lastName
      name: $name
    ) {
      token
    }
  }
`;
