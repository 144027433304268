import React, { Component, createRef } from "react";
import {
  QUESTIONNAIRE_MODES,
  QUESTIONNAIRE_FIELD_TYPES,
} from "../../../utils/Constants";
import { TextField } from "../../../Common/components/Fields/TextField";
import { RadioField } from "../../../Common/components/Fields/RadioField";
import { SelectField } from "../../../Common/components/Fields/SelectField";
import { NumberField } from "../../../Common/components/Fields/NumberField";
import { CheckboxField } from "../../../Common/components/Fields/CheckboxField";
import { DateField } from "../../../Common/components/Fields/DateField";
import { ImageField } from "../../../Common/components/Fields/ImageField";
import {
  CreateFilledQuestionnaire,
  CreateQuestionnaire,
} from "./CreateQuestionnaire";
import { buildTreeAndGetRoots } from "../../../utils/utils";
class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: {},
      fieldRefs: this.initFieldRefs(),
    };
  }

  initFieldRefs() {
    let fieldRefs = [];

    if (this.props.layer.questionnaire) {
      this.props.layer.questionnaire.questionnairefieldSet.forEach((qField) => {
        fieldRefs.push({
          fieldId: qField.id,
          inputRef: createRef(),
          labelRef: createRef(),
        });
      });
    }

    return fieldRefs;
  }

  setAnswer = (fieldId, value) =>
    this.setState((state) => {
      const answers = state.answers;
      answers[fieldId] = value;
      return {
        answers: answers,
      };
    });

  addImages = (fieldId, value) =>
    this.setState((state) => {
      const answers = state.answers;
      const currAnswer = answers[fieldId];
      answers[fieldId] = {
        ...currAnswer,
        imagesToAdd: value,
      };
      return {
        answers: answers,
      };
    });

  handleSubmit = (e, mutate) => {
    const self = this;
    e.preventDefault();

    const errors = this.state.fieldRefs
      .filter((fieldRef) => fieldRef.inputRef.current)
      .map((fieldRef) => {
        return { ...fieldRef.inputRef.current.checkForErrors(), ...fieldRef };
      })
      .filter((checkResult) => !checkResult.valid);

    if (errors.length > 0) {
      const err = errors[0];

      if (err.labelRef && err.labelRef.current && err.labelRef.current.focus) {
        err.labelRef.current.focus();
      }

      return;
    }

    let variables = {};
    let filledFeatureFields = [];
    let selectedLayer;

    if (this.props.layer.questionnaire) {
      selectedLayer = this.props.task.layerSet.find(
        (l) => l.id === this.props.layer.id
      );

      const layerGeometry = selectedLayer.layergeometrySet.find(
        (lf) => lf.geometryType.code === self.props.activeGeometryTypeCode
      );
      if (layerGeometry && layerGeometry.decorator) {
        variables.questionnaireFieldId = layerGeometry.decorator.id;
      }
      let toSort = selectedLayer.questionnaire.questionnairefieldSet;

      filledFeatureFields = toSort.map((ts) => {
        let filledFeatureField = {
          questionnaireFieldId: ts.id,
        };

        const getProperty = {
          [QUESTIONNAIRE_FIELD_TYPES.TEXT]: "text",
          [QUESTIONNAIRE_FIELD_TYPES.RADIO]: "questionnaireFieldLabelId",
          [QUESTIONNAIRE_FIELD_TYPES.SELECT]: "text",
          [QUESTIONNAIRE_FIELD_TYPES.CHECKBOX]: "isChecked",
          [QUESTIONNAIRE_FIELD_TYPES.DECIMAL]: "value",
          [QUESTIONNAIRE_FIELD_TYPES.DATE]: "date",
        };

        if (ts.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.IMAGE) {
          const a = this.state.answers[ts.id];
          if (a) {
            filledFeatureField.imagesToAdd = a.imagesToAdd;
            filledFeatureField.imagesToDelete = a.imagesToDelete;
          }
        } else {
          const qfType = getProperty[ts.fieldType.code];
          if (qfType === "text") {
            filledFeatureField[qfType] = this.state.answers[ts.id]
              ? this.state.answers[ts.id].toString()
              : null;
          } else if (qfType === "value") {
            filledFeatureField[qfType] = this.state.answers[ts.id]
              ? parseFloat(this.state.answers[ts.id])
              : null;
          } else {
            filledFeatureField[qfType] = this.state.answers[ts.id];
          }
        }

        if (filledFeatureField.date === "") {
          filledFeatureField.date = null;
        }

        return filledFeatureField;
      });
    }

    if (!this.props.feature) {
      variables.layerId = this.props.layer.id;
      variables.geometryTypeCode = this.props.activeGeometryTypeCode;
      variables.geoJson = JSON.stringify(
        this.props.createdLeafletLayer.toGeoJSON()
      );

      if (this.props.layer.questionnaire) {
        const layerGeometry = selectedLayer.layergeometrySet.find(
          (lf) => lf.geometryType.code === self.props.activeGeometryTypeCode
        );
        if (layerGeometry && layerGeometry.decorator) {
          variables.questionnaireFieldId = layerGeometry.decorator.id;
        }
      }
    } else {
      variables.featureId = this.props.feature.id;
    }

    variables.filledFeatureFields = filledFeatureFields;

    mutate.call(this, { variables }).catch(() => {
      /* Le catch sert à éviter un unhandled rejection error en cas d'erreur réseau. */
    });
  };

  cancel = (e) => {
    if (e.detail === 0) {
      return;
    }
    e.preventDefault();
    this.props.cancel();
  };

  render() {
    if (this.props.feature) {
      return (
        <CreateFilledQuestionnaire
          fields={this.props.fields({
            answers: this.state.answers,
            fieldRefs: this.state.fieldRefs,
            setAnswer: this.setAnswer,
            addImages: this.addImages,
          })}
          handleSubmit={this.handleSubmit}
          featureId={this.props.feature.id}
          taskId={this.props.task.id}
        />
      );
    } else {
      return (
        <CreateQuestionnaire
          questionnaireComplete={this.props.questionnaireComplete}
          layer={this.props.layer}
          fields={this.props.fields({
            answers: this.state.answers,
            fieldRefs: this.state.fieldRefs,
            setAnswer: this.setAnswer,
            addImages: this.addImages,
          })}
          cancel={this.cancel}
          handleSubmit={this.handleSubmit}
          task={this.props.task}
        />
      );
    }
  }
}

const createRow = (node) => {
  let field = node.associatedObject;
  let FieldComponent = null;

  if (field.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.TEXT) {
    FieldComponent = React.memo(function CreateTextField({
      answer,
      setAnswer,
      inputRef,
      labelRef,
    }) {
      return (
        <TextField
          qField={field}
          value={answer}
          setValue={(value) => setAnswer(field.id, value)}
          mode={QUESTIONNAIRE_MODES.CREATE}
          inputRef={inputRef}
          labelRef={labelRef}
        />
      );
    });
  } else if (field.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.RADIO) {
    FieldComponent = React.memo(function CreateRadioButtonGroupField({
      answer,
      setAnswer,
      inputRef,
      labelRef,
    }) {
      return (
        <RadioField
          qField={field}
          value={answer}
          setValue={(value) => setAnswer(field.id, value)}
          mode={QUESTIONNAIRE_MODES.CREATE}
          inputRef={inputRef}
          labelRef={labelRef}
        />
      );
    });
  } else if (field.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.SELECT) {
    FieldComponent = React.memo(function CreateSelectField({
      answer,
      setAnswer,
      inputRef,
      labelRef,
    }) {
      return (
        <SelectField
          qField={field}
          value={answer}
          setValue={(value) => setAnswer(field.id, value)}
          mode={QUESTIONNAIRE_MODES.CREATE}
          inputRef={inputRef}
          labelRef={labelRef}
        />
      );
    });
  } else if (field.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.CHECKBOX) {
    FieldComponent = React.memo(function CreateCheckboxField({
      answer,
      setAnswer,
      inputRef,
      labelRef,
    }) {
      return (
        <CheckboxField
          qField={field}
          value={answer}
          setValue={(value) => setAnswer(field.id, value)}
          mode={QUESTIONNAIRE_MODES.CREATE}
          inputRef={inputRef}
          labelRef={labelRef}
        />
      );
    });
  } else if (field.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.DECIMAL) {
    FieldComponent = React.memo(function CreateNumberField({
      answer,
      setAnswer,
      inputRef,
      labelRef,
    }) {
      return (
        <NumberField
          qField={field}
          value={answer}
          setValue={(value) => setAnswer(field.id, value)}
          mode={QUESTIONNAIRE_MODES.CREATE}
          inputRef={inputRef}
          labelRef={labelRef}
        />
      );
    });
  } else if (field.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.DATE) {
    FieldComponent = React.memo(function CreateDateField({
      answer,
      setAnswer,
      inputRef,
      labelRef,
    }) {
      return (
        <DateField
          qField={field}
          value={answer}
          setValue={(value) => setAnswer(field.id, value)}
          mode={QUESTIONNAIRE_MODES.CREATE}
          inputRef={inputRef}
          labelRef={labelRef}
        />
      );
    });
  } else if (field.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.IMAGE) {
    FieldComponent = React.memo(function CreateImageField({
      answer,
      addImages,
      inputRef,
      labelRef,
    }) {
      return (
        <ImageField
          qField={field}
          value={answer}
          addImages={(value) => addImages(field.id, value)}
          mode={QUESTIONNAIRE_MODES.CREATE}
          inputRef={inputRef}
          labelRef={labelRef}
        />
      );
    });
  } else {
    FieldComponent = function CreateHiddenField() {
      return <input key={field.id} type="hidden" />;
    };
  }

  let children = [];

  if (node.children) {
    children = node.children
      .sort((nodeA, nodeB) => {
        let orderA = parseInt(nodeA.associatedObject.order, 10);
        let orderB = parseInt(nodeB.associatedObject.order, 10);
        if (orderA < orderB) return -1;
        if (orderA === orderB) return 0;
        return 1;
      })
      .map((child) => createRow(child));
  }

  return {
    name: `row-dynamic-key-${field.id}`,
    component: function createDyanmicRow({
      answers,
      fieldRefs,
      setAnswer,
      addImages,
    }) {
      if (node.parent != null) {
        let parentType = node.parent.associatedObject.fieldType.code;
        let parentValue = answers[node.parent.associatedObject.id];
        let triggerValue;

        if (parentType === QUESTIONNAIRE_FIELD_TYPES.CHECKBOX) {
          triggerValue = node.associatedObject.parentQuestionnaireFieldAnswer;
        } else {
          triggerValue = node.associatedObject.parentQuestionnaireFieldLabel
            ? node.associatedObject.parentQuestionnaireFieldLabel.id
            : null;
        }

        if (parentType === QUESTIONNAIRE_FIELD_TYPES.CHECKBOX) {
          if (
            ((parentValue === undefined ||
              parentValue === null ||
              parentValue === false) &&
              triggerValue === true) ||
            (parentValue === true && triggerValue === false)
          ) {
            return null;
          }
        } else if (parentType === QUESTIONNAIRE_FIELD_TYPES.RADIO) {
          if (!parentValue) return null;
          if (triggerValue) {
            if (parentValue !== triggerValue) {
              return null;
            }
          }
        } else if (parentType === QUESTIONNAIRE_FIELD_TYPES.SELECT) {
          if (!parentValue) return null;
          if (triggerValue) {
            const parentValues = parentValue.split(",");
            if (!parentValues.includes(triggerValue)) {
              return null;
            }
          }
        }
      }

      const answer = answers[field.id];
      const fieldRef = fieldRefs.find(
        (fieldRef) => fieldRef.fieldId === field.id
      );

      return (
        <React.Fragment key={field.id}>
          <FieldComponent
            answer={answer}
            setAnswer={setAnswer}
            addImages={addImages}
            inputRef={fieldRef.inputRef}
            labelRef={fieldRef.labelRef}
          />
          {children.length > 0 ? (
            <>
              {children.map((child) => {
                return (
                  <child.component
                    key={child.name}
                    answers={answers}
                    fieldRefs={fieldRefs}
                    setAnswer={setAnswer}
                    addImages={addImages}
                  />
                );
              })}
            </>
          ) : null}
        </React.Fragment>
      );
    },
  };
};

const createFormFields = (props) => {
  let toSort = buildTreeAndGetRoots(
    props.layer.questionnaire.questionnairefieldSet
  );
  let sorted = [...toSort].sort((a, b) => {
    let orderA = parseInt(a.associatedObject.order, 10);
    let orderB = parseInt(b.associatedObject.order, 10);
    if (orderA < orderB) return -1;
    if (orderA === orderB) return 0;
    return 1;
  });

  const fields = sorted.map((s) => {
    // renvoi une liste de composants
    return createRow(s);
  });

  return function formFields({ answers, setAnswer, addImages, fieldRefs }) {
    return (
      <>
        {fields.map((field) => (
          <field.component
            key={field.name}
            answers={answers}
            setAnswer={setAnswer}
            addImages={addImages}
            fieldRefs={fieldRefs}
          />
        ))}
      </>
    );
  };
};

const BuildQuestionnaire = (props) => {
  if (!props.layer.questionnaire) {
    return (
      <Questionnaire
        fields={() => <p>Confirmez-vous la création de l&apos;élément ?</p>}
        {...props}
      />
    );
  } else {
    const formFields = createFormFields(props);

    return <Questionnaire fields={formFields} {...props} />;
  }
};

export { BuildQuestionnaire as Questionnaire };
