import React from "react";
import {
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  PATTERN_TYPES,
} from "../../../../../utils/Constants";
import { SvgLine } from "./SvgLine";
import { SvgPoly } from "./SvgPoly";
import { SvgPolyStripesPattern } from "./SvgPolyStripesPattern";
import { SvgPolyRectPattern } from "./SvgPolyRectPattern";
import { SvgPolyCirclePattern } from "./SvgPolyCirclePattern";
import { SvgPolyPathPattern } from "./SvgPolyPathPattern";
import { hexToRgbA } from "../../../../../utils/utils";

function Preview({ geoTypeCode, style, noStyleColor }) {
  if (!style) {
    const color = hexToRgbA(noStyleColor ?? "#3388ff", 1.0);
    if (geoTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON) {
      return (
        <SvgPoly
          pathOptions={{
            stroke: true,
            color: color,
            weight: 3,
            opacity: 1.0,
            fill: true,
            fillColor: color,
            fillOpacity: 0.2,
          }}
        />
      );
    } else if (geoTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE) {
      return <SvgLine color={color} weight={3} />;
    } else {
      return null;
    }
  }

  const pathOptions = JSON.parse(style.pathOptions);

  if (geoTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON) {
    if (style.usePattern) {
      const patternOptions = JSON.parse(style.patternOptions);
      const patternTypeCode = style.patternTypeCode;
      if (patternTypeCode === PATTERN_TYPES.STRIPES) {
        return (
          <SvgPolyStripesPattern
            styleId={style.id}
            pathOptions={pathOptions}
            patternOptions={patternOptions}
          />
        );
      } else {
        const shapeOptions = JSON.parse(style.shapeOptions);
        if (patternTypeCode === PATTERN_TYPES.RECTANGLE) {
          return (
            <SvgPolyRectPattern
              styleId={style.id}
              pathOptions={pathOptions}
              patternOptions={patternOptions}
              shapeOptions={shapeOptions}
            />
          );
        } else if (patternTypeCode === PATTERN_TYPES.CIRCLE) {
          return (
            <SvgPolyCirclePattern
              styleId={style.id}
              pathOptions={pathOptions}
              patternOptions={patternOptions}
              shapeOptions={shapeOptions}
            />
          );
        } else {
          return (
            <SvgPolyPathPattern
              styleId={style.id}
              pathOptions={pathOptions}
              patternOptions={patternOptions}
              shapeOptions={shapeOptions}
            />
          );
        }
      }
    } else {
      return <SvgPoly pathOptions={pathOptions} />;
    }
  }

  let color;

  if (pathOptions.hasOwnProperty("stroke")) {
    if (pathOptions.stroke) {
      color = hexToRgbA(
        pathOptions.color ?? "#3388ff",
        pathOptions.opacity ?? 1.0
      );
    }
  } else {
    color = hexToRgbA("#3388ff", 1.0);
  }

  return (
    <SvgLine
      color={color}
      weight={pathOptions.weight ?? 3}
      dashArray={pathOptions.dashArray}
    />
  );
}

export { Preview };
