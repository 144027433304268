import React, { useState, useEffect, useMemo } from "react";
import { GeoJSON, Tooltip } from "react-leaflet";

import { COLLABORATIVE_MAP_TOOLS } from "../../../../utils/Constants";

const dataGouv =
  "https://cadastre.data.gouv.fr/bundler/cadastre-etalab/communes/";

const communeApi = (inseeCode) => `${dataGouv}${inseeCode}/geojson/communes`;
const divisionApi = (inseeCode) => `${dataGouv}${inseeCode}/geojson/sections`;
const parcelleApi = (inseeCode) => `${dataGouv}${inseeCode}/geojson/parcelles`;

const style = {
  fillOpacity: 0,
  color: "#eeeeee",
  weight: 1,
};

function Commune({ commune, handleCommuneClick }) {
  const eventHandlers = useMemo(
    () => ({
      click(e) {
        handleCommuneClick(
          commune.features[0].properties.id,
          e.target.getBounds()
        );
      },
    }),
    [handleCommuneClick, commune.features]
  );

  return (
    <GeoJSON
      pane="noneditable"
      style={style}
      data={commune}
      eventHandlers={eventHandlers}
    />
  );
}

function Division({ division, handleDivisionClick }) {
  const eventHandlers = useMemo(
    () => ({
      click(e) {
        handleDivisionClick(division.properties.code, e.target.getBounds());
      },
    }),
    [handleDivisionClick, division.properties.code]
  );

  return (
    <GeoJSON
      pane="noneditable"
      style={style}
      data={division}
      eventHandlers={eventHandlers}
    />
  );
}

function Parcel({ handleParcelClick, parcel, activeTool }) {
  const eventHandlers = useMemo(
    () => ({
      click(e) {
        handleParcelClick(e.target.getBounds());
      },
    }),
    [handleParcelClick]
  );
  const parcelNum =
    parcel.properties.section +
    parcel.properties.numero.toString().padStart(4, "0");
  return (
    <GeoJSON
      pane="noneditable"
      style={style}
      data={parcel}
      eventHandlers={eventHandlers}
    >
      {activeTool === COLLABORATIVE_MAP_TOOLS.NONE ? (
        <Tooltip>{parcelNum}</Tooltip>
      ) : null}
    </GeoJSON>
  );
}

function ApiCarto({
  handleCommuneClick,
  currentMunicipality,
  handleDivisionClick,
  currentDivision,
  municipalityCodes,
  handleParcelClick,
  cadastre,
  activeTool,
}) {
  const [municipalities, setMunicipalities] = useState([]);
  const [currentMunicipalityDivision, setCurrentMunicipalityDivision] =
    useState({});
  const [currentDivisionParcels, setCurrentDivisionParcels] = useState({});

  function fetchMunicipality(municipalityCodes) {
    const urls = municipalityCodes
      .split(",")
      .map((municipality) => fetch(communeApi(municipality)));
    Promise.all(urls)
      .then((responses) =>
        Promise.all(responses.map((response) => response.json()))
      )
      .then((data) => setMunicipalities(data));
  }

  useEffect(() => {
    fetchMunicipality(municipalityCodes);
  }, [municipalityCodes]);

  useEffect(() => {
    if (currentMunicipality) {
      async function fetchDivisions() {
        if (!currentMunicipalityDivision[currentMunicipality]) {
          const response = await fetch(divisionApi(currentMunicipality));
          response.json().then(function (json) {
            setCurrentMunicipalityDivision({
              ...currentMunicipalityDivision,
              [currentMunicipality]: json,
            });
          });
        }
      }
      fetchDivisions();
    }
  }, [currentMunicipality, currentMunicipalityDivision]);

  useEffect(() => {
    if (currentDivision) {
      async function fetchParcels() {
        if (!currentDivisionParcels[currentMunicipality]) {
          const response = await fetch(parcelleApi(currentMunicipality));
          response.json().then(function (json) {
            setCurrentDivisionParcels({
              ...currentDivisionParcels,
              [currentMunicipality]: json,
            });
          });
        }
      }
      fetchParcels();
    }
  }, [currentDivision, currentDivisionParcels, currentMunicipality]);

  if (!municipalities || municipalities.length === 0) return null;

  if (!cadastre) return null;

  return (
    <>
      {municipalities.map((municipality) => {
        if (municipality && municipality.features) {
          return (
            <Commune
              key={municipality.features[0].id}
              commune={municipality}
              handleCommuneClick={handleCommuneClick}
            />
          );
        } else {
          return null;
        }
      })}

      {currentMunicipality &&
      currentMunicipalityDivision[currentMunicipality] &&
      currentMunicipalityDivision[currentMunicipality].features.length > 0
        ? currentMunicipalityDivision[currentMunicipality].features.map((f) => (
            <Division
              key={f.id}
              communeCode={currentMunicipality}
              handleDivisionClick={handleDivisionClick}
              division={f}
            />
          ))
        : null}
      {currentMunicipality &&
      currentDivision &&
      currentDivisionParcels[currentMunicipality] &&
      currentDivisionParcels[currentMunicipality].features.length > 0
        ? currentDivisionParcels[currentMunicipality].features
            .filter((f) => f.properties.section === currentDivision)
            .map((p) => {
              return (
                <Parcel
                  key={p.properties.id}
                  communeCode={currentMunicipality}
                  handleParcelClick={handleParcelClick}
                  parcel={p}
                  activeTool={activeTool}
                />
              );
            })
        : null}
    </>
  );
}

export default ApiCarto;
