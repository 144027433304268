// React
import React, { useState, useEffect } from "react";

// GraphQL
import { useMutation } from "@apollo/client";
import {
  FOLDER_GRP_PERM_SET_CURR_SITE_GRP_SET,
  DOC_GRP_PERM_SET_CURR_SITE_GRP_SET,
} from "../_GraphQL/queries";
import {
  ADD_REMOVE_FOLDER_GROUP_PERMISSION,
  ADD_REMOVE_DOCUMENT_GROUP_PERMISSION,
} from "../_GraphQL/mutations";

// Components
import { GroupPermissionsForm } from "../../../Common/components/GroupPermission/GroupPermissionsForm";

// Constants
import * as constants from "../../../utils/Constants";
import {
  addErrorToastMessage,
  addSuccessToastMessage,
  getMessageTextFromApolloError,
} from "../../../_GraphQL/message";

const viewFolderTrigger = [
  "change_folder_",
  "move_folder_",
  "delete_folder_",
  "add_folder_to_folder_",
  "add_document_to_folder_",
];
const viewDocTrigger = ["delete_document_", "move_document_"];

function ManageFolderPermissionMutation({
  groups,
  groupPermissionSet,
  mutation,
  fileType,
  folderId,
  documentId,
  handleCancel,
  handleCompleted,
  refetch,
}) {
  const [groupPermissionsRows, setGroupPermissionsRows] = useState([]);
  const [applyRecursively, setApplyRecursively] = useState(false);

  useEffect(() => {
    let groupPermissionsRowSet = [];
    groups.forEach((group) => {
      groupPermissionsRowSet.push({
        group: group,
        canViewFolder: false,
        canChangeFolder: false,
        canMoveFolder: false,
        canDeleteFolder: false,
        canAddFolder: false,
        canAddDocument: false,
        canViewDocument: false,
        canMoveDocument: false,
        canDeleteDocument: false,
      });

      let groupPermissions = groupPermissionSet.filter(
        (a) => a.groupId === group.id
      );
      let suffix = "";
      if (fileType === "folder") {
        suffix = folderId;
      } else {
        suffix = documentId;
      }

      groupPermissions.forEach((gp) => {
        const perm_codename = gp.permission.toString();

        let perm =
          constants.FILEMANAGER_PERMISSIONS[
            perm_codename.substring(0, perm_codename.indexOf(suffix))
          ];
        groupPermissionsRowSet.find((o) => o.group.id === gp.groupId)[
          perm
        ] = true;
      });
    });

    setGroupPermissionsRows(groupPermissionsRowSet);
  }, [
    groupPermissionSet,
    setGroupPermissionsRows,
    groups,
    fileType,
    folderId,
    documentId,
  ]);

  const [addRemoveGroupPermission, { loading, error }] = useMutation(mutation, {
    update(cache, { data }) {
      let query =
        fileType === "folder"
          ? FOLDER_GRP_PERM_SET_CURR_SITE_GRP_SET
          : DOC_GRP_PERM_SET_CURR_SITE_GRP_SET;
      let variables =
        fileType === "folder"
          ? { folderId: folderId }
          : { documentId: documentId };
      let a = cache.readQuery({
        query: query,
        variables: variables,
      });
      let updatedGrpPermSet =
        fileType === "folder"
          ? data.addRemoveFolderGroupPermission.folderGroupPermissionSet
          : data.addRemoveDocumentGroupPermission.documentGroupPermissionSet;

      let updatedData = { currWebsiteGroupSet: a.currWebsiteGroupSet };
      if (fileType === "folder") {
        updatedData.folderGroupPermissionSet = updatedGrpPermSet;
      } else {
        updatedData.documentGroupPermissionSet = updatedGrpPermSet;
      }
      cache.writeQuery({
        query: query,
        variables: variables,
        data: updatedData,
      });
      refetch();
    },
    onCompleted() {
      addSuccessToastMessage({
        header: "Informations mises à jour",
        content: "Les permissions ont été mises à jours avec succès.",
      });
      handleCompleted();
    },
    onError(error) {
      addErrorToastMessage(getMessageTextFromApolloError(error));
    },
    refetchQueries: fileType === "folder" ? "active" : [],
  });

  function handleCheckChange(group, permission, checked) {
    let a = groupPermissionsRows.find((al) => al.group.id === group.id);
    let gpRows = groupPermissionsRows.filter((al) => al.group.id !== group.id);

    let perm = constants.FILEMANAGER_PERMISSIONS[permission];

    a[perm] = checked;
    if (checked) {
      if (viewFolderTrigger.includes(permission) && !a.canViewFolder) {
        a.canViewFolder = true;
      }
      if (viewDocTrigger.includes(permission) && !a.canViewDocument) {
        a.canViewDocument = true;
      }
    } else {
      if (permission === "view_folder_" || permission === "view_document_") {
        a.canViewFolder = false;
        a.canChangeFolder = false;
        a.canMoveFolder = false;
        a.canDeleteFolder = false;
        a.canAddFolder = false;
        a.canAddDocument = false;
        a.canViewDocument = false;
        a.canMoveDocument = false;
        a.canDeleteDocument = false;
      }
    }

    setGroupPermissionsRows(gpRows.concat(a));
  }

  function handleSubmit(mutate) {
    let groupPermissionToRemoveSet = [];
    let groupPermissionToAddSet = [];

    groups.forEach((group) => {
      let groupPermissions = groupPermissionSet.filter(
        (a) => a.groupId === group.id
      );
      let row = groupPermissionsRows.find((gpr) => gpr.group.id === group.id);
      let gpViewFolder = groupPermissions.find(
        (gp) => gp.permission === "view_folder_" + folderId
      );

      if (row.canViewFolder && !gpViewFolder) {
        groupPermissionToAddSet.push({
          groupId: group.id,
          permission: "view_folder_",
        });
      } else if (!row.canViewFolder && gpViewFolder) {
        groupPermissionToRemoveSet.push({
          groupId: group.id,
          permission: "view_folder_",
        });
      }

      let gpChangeFolder = groupPermissions.find(
        (gp) => gp.permission === "change_folder_" + folderId
      );
      if (row.canChangeFolder && !gpChangeFolder) {
        groupPermissionToAddSet.push({
          groupId: group.id,
          permission: "change_folder_",
        });
      } else if (!row.canChangeFolder && gpChangeFolder) {
        groupPermissionToRemoveSet.push({
          groupId: group.id,
          permission: "change_folder_",
        });
      }

      let gpMoveFolder = groupPermissions.find(
        (gp) => gp.permission === "move_folder_" + folderId
      );
      if (row.canMoveFolder && !gpMoveFolder) {
        groupPermissionToAddSet.push({
          groupId: group.id,
          permission: "move_folder_",
        });
      } else if (!row.canMoveFolder && gpMoveFolder) {
        groupPermissionToRemoveSet.push({
          groupId: group.id,
          permission: "move_folder_",
        });
      }

      let gpDeleteFolder = groupPermissions.find(
        (gp) => gp.permission === "delete_folder_" + folderId
      );
      if (row.canDeleteFolder && !gpDeleteFolder) {
        groupPermissionToAddSet.push({
          groupId: group.id,
          permission: "delete_folder_",
        });
      } else if (!row.canDeleteFolder && gpDeleteFolder) {
        groupPermissionToRemoveSet.push({
          groupId: group.id,
          permission: "delete_folder_",
        });
      }

      let gpAddFolder = groupPermissions.find(
        (gp) => gp.permission === "add_folder_to_folder_" + folderId
      );
      if (row.canAddFolder && !gpAddFolder) {
        groupPermissionToAddSet.push({
          groupId: group.id,
          permission: "add_folder_to_folder_",
        });
      } else if (!row.canAddFolder && gpAddFolder) {
        groupPermissionToRemoveSet.push({
          groupId: group.id,
          permission: "add_folder_to_folder_",
        });
      }

      let gpAddDocument = groupPermissions.find(
        (gp) => gp.permission === "add_document_to_folder_" + folderId
      );
      if (row.canAddDocument && !gpAddDocument) {
        groupPermissionToAddSet.push({
          groupId: group.id,
          permission: "add_document_to_folder_",
        });
      } else if (!row.canAddDocument && gpAddDocument) {
        groupPermissionToRemoveSet.push({
          groupId: group.id,
          permission: "add_document_to_folder_",
        });
      }

      let gpViewDocument = groupPermissions.find(
        (gp) => gp.permission === "view_document_" + documentId
      );
      if (row.canViewDocument && !gpViewDocument) {
        groupPermissionToAddSet.push({
          groupId: group.id,
          permission: "view_document_",
        });
      } else if (!row.canViewDocument && gpViewDocument) {
        groupPermissionToRemoveSet.push({
          groupId: group.id,
          permission: "view_document_",
        });
      }

      let gpMoveDocument = groupPermissions.find(
        (gp) => gp.permission === "move_document_" + documentId
      );
      if (row.canMoveDocument && !gpMoveDocument) {
        groupPermissionToAddSet.push({
          groupId: group.id,
          permission: "move_document_",
        });
      } else if (!row.canMoveDocument && gpMoveDocument) {
        groupPermissionToRemoveSet.push({
          groupId: group.id,
          permission: "move_document_",
        });
      }

      let gpDeleteDocument = groupPermissions.find(
        (gp) => gp.permission === "delete_document_" + documentId
      );
      if (row.canDeleteDocument && !gpDeleteDocument) {
        groupPermissionToAddSet.push({
          groupId: group.id,
          permission: "delete_document_",
        });
      } else if (!row.canDeleteDocument && gpDeleteDocument) {
        groupPermissionToRemoveSet.push({
          groupId: group.id,
          permission: "delete_document_",
        });
      }
    });

    let variables = {
      groupPermissionToAddSet: groupPermissionToAddSet,
      groupPermissionToRemoveSet: groupPermissionToRemoveSet,
    };

    if (fileType === "folder") {
      variables.folderId = folderId;
      variables.applyRecursively = applyRecursively;
    } else {
      variables.documentId = documentId;
    }

    mutate({ variables: variables });
  }

  return (
    <GroupPermissionsForm
      groupPermissionsRows={groupPermissionsRows}
      error={error}
      loading={loading}
      mutation={true}
      handleSubmit={() => handleSubmit(addRemoveGroupPermission)}
      handleCancel={handleCancel}
      handleCheckChange={handleCheckChange}
      fileType={fileType}
      applyRecursively={applyRecursively}
      setApplyRecursively={setApplyRecursively}
    />
  );
}

function ManageFolderPermissionMutationSelectType(props) {
  let mutation =
    props.fileType === "folder"
      ? ADD_REMOVE_FOLDER_GROUP_PERMISSION
      : ADD_REMOVE_DOCUMENT_GROUP_PERMISSION;
  return <ManageFolderPermissionMutation mutation={mutation} {...props} />;
}

export default ManageFolderPermissionMutationSelectType;
