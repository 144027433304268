import React, { useEffect, useState } from "react";
import {
  PUBLIC_GET_PROJECT_NAME_BY_ID_OR_SLUG,
  PUBLIC_OPEN_SIGN_IN,
} from "../_GraphQL/queries";
import { SIGN_IN } from "../_GraphQL/mutations";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { USER_TYPES, AUTH_TOKEN } from "../../../utils/Constants";
import { isConnectedVar } from "../../../_GraphQL/graphQLClient";
import ErrorMessage, {
  IciErrorMessage,
} from "../../../Common/components/ErrorMessage";
import spinner from "../../../medias/images/roue-chargement.svg";
import { WysiwygReader } from "../../../Common/components/WysiwygReader";
import logo from "../../../medias/images/logo-et-texte-blanc.svg";

function SignIn({ projectName, noticePath, welcome }) {
  const navigate = useNavigate();
  const { projectIdOrSlug, signInSlug } = useParams();

  const [signIn, { error: mutationError, loading: mutationLoading }] =
    useMutation(SIGN_IN, {
      onError() {
        /** Utile pour empêcher un crash en cas d'erreur */
      },
      onCompleted(data) {
        localStorage.setItem(AUTH_TOKEN, data.signIn.token);
        isConnectedVar(true);
        navigate("/");
      },
    });

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [nature, setNature] = useState(USER_TYPES.NATURAL_PERSON);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [name, setName] = useState("");
  const [agree, setAgree] = useState(false);
  const [managedMutationError, setManagedMutationError] = useState();
  const [validationError, setValidationError] = useState();

  useEffect(() => {
    setManagedMutationError(mutationError);
  }, [mutationError]);

  // verif pas connecté

  return (
    <>
      <div className="inscription-gauche">
        <div className="courbes">
          <div className="courbe-bleue">
            <div className="courbe-blanche"></div>
          </div>
        </div>
        <div className="logo-texte">
          <div className="logo">
            <img src={logo} alt="Logo ICI" />
          </div>
          {welcome ? <WysiwygReader raw={welcome} /> : null}
        </div>
      </div>
      <div className="bloc-formulaire">
        <h1>Inscription</h1>
        <h2>{projectName}</h2>
        {noticePath ? (
          <p>
            Consulter la{" "}
            <a href={`/media/projects/${noticePath}`}>notice d'utilisation</a>
          </p>
        ) : null}
        {validationError ? <IciErrorMessage error={validationError} /> : null}
        {managedMutationError ? (
          <IciErrorMessage error={managedMutationError} />
        ) : null}
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!email) {
                setValidationError("L'adresse mail est obligatoire.");
              } else if (!pwd) {
                setValidationError("Le mot de passe est obligatoire.");
              } else {
                setManagedMutationError(null);
                signIn({
                  variables: {
                    projectIdOrSlug: projectIdOrSlug,
                    signInSlug: signInSlug,
                    email: email,
                    password: pwd,
                    nature: nature,
                    lastName: lastName,
                    firstName: firstName,
                    name: name,
                    agree: agree,
                  },
                });
              }
            }}
          >
            {mutationLoading ? (
              <div className="chargement">
                <div className="preloader">
                  <img src={spinner} alt="icône de chargement" />
                </div>
              </div>
            ) : (
              <>
                <div>
                  <input
                    type="email"
                    placeholder="Adresse Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label>Type d'utilisateur</label>
                  <select
                    value={nature}
                    onChange={(e) => setNature(e.target.value)}
                  >
                    <option value={USER_TYPES.NATURAL_PERSON}>
                      Personne Physique
                    </option>
                    <option value={USER_TYPES.LEGAL_PERSON}>
                      Personne Morale
                    </option>
                  </select>
                </div>
                {nature === USER_TYPES.NATURAL_PERSON ? (
                  <>
                    <div>
                      <input
                        type="text"
                        placeholder="Nom - Optionnel"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Prénom - Optionnel"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <input
                      type="text"
                      placeholder="Raison Sociale - Optionnelle"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                )}
                <div className="aggree">
                  <input
                    id="agree"
                    type="checkbox"
                    checked={agree}
                    onChange={() => setAgree(!agree)}
                  />
                  <label htmlFor="agree">
                    J'accepte les termes et conditions
                  </label>
                </div>
                <div>
                  <button>Je m'inscris</button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

function VerifySignInSlug({ projectName }) {
  const { projectIdOrSlug, signInSlug } = useParams();
  const { loading, error, data } = useQuery(PUBLIC_OPEN_SIGN_IN, {
    variables: { projectIdOrSlug: projectIdOrSlug, signInSlug: signInSlug },
  });

  if (loading)
    return (
      <div className="preloader">
        <img src={spinner} alt="icône de chargement" />
      </div>
    );

  if (error) return <ErrorMessage error={error} />;

  return (
    <SignIn
      projectName={projectName}
      noticePath={data.publicOpenSignIn.noticePath}
      welcome={data.publicOpenSignIn.welcome}
    />
  );
}

function VerifyProjectExist() {
  const { projectIdOrSlug } = useParams();
  const { loading, error, data } = useQuery(
    PUBLIC_GET_PROJECT_NAME_BY_ID_OR_SLUG,
    { variables: { projectIdOrSlug: projectIdOrSlug } }
  );

  return (
    <div className="inscription">
      {loading ? (
        <div className="preloader">
          <img src={spinner} alt="icône de chargement" />
        </div>
      ) : (
        <>
          {error ? <ErrorMessage error={error} /> : null}
          {data ? (
            <VerifySignInSlug
              projectName={data.publicGetProjectNameByIdOrSlug}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

function CheckIfConnected() {
  const isConnected = useReactiveVar(isConnectedVar);

  if (isConnected) {
    return <Navigate to="/" />;
  }

  return <VerifyProjectExist />;
}

export { CheckIfConnected as SignIn };
