import React from "react";
import { useQuery } from "@apollo/client";
import { GET_GROUPS_FOR_CURRENT_SITE, USER_SET } from "../_GraphQL/queries";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { Loader } from "../../../Common/components/Loader";
import { Switch } from "../../../Common/components/Switch";

function Groups({ handleGroupChange, mailGroupIds }) {
  const { loading, error, data } = useQuery(GET_GROUPS_FOR_CURRENT_SITE);

  if (loading) return <Loader />;

  if (error)
    return (
      <IciErrorMessage
        header={"Erreur lors de la récupération des groupes destinataires."}
        error={error}
      />
    );

  return (
    <>
      {data.currWebsiteGroupSet.map((g) => {
        const ck = mailGroupIds.find((x) => x === g.id) ? true : false;
        return (
          <div className="groupe" key={`group-ck-${g.id}`}>
            <input
              type="checkbox"
              name={`group-cb-${g.id}`}
              value={g.id}
              checked={ck}
              onChange={handleGroupChange}
            />
            <label
              htmlFor={`group-cb-${g.id}`}
              onClick={(e) =>
                handleGroupChange({ target: { value: g.id, checked: !ck } })
              }
            >
              {g.name}
            </label>
          </div>
        );
      })}
    </>
  );
}

function Users({ projectIdOrSlug, mailGroupIds }) {
  const { loading, error, data } = useQuery(USER_SET, {
    variables: {
      projectIdOrSlug: projectIdOrSlug,
      groupIds: mailGroupIds.map((mGI) => mGI),
    },
  });

  if (loading) return <Loader />;

  if (error)
    return (
      <IciErrorMessage
        header={"Erreur lors de la récupération des destinataires."}
        error={error}
      />
    );

  return (
    <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
      <table>
        <tbody>
          {data.userSet.map((user, id) => (
            <tr
              className={id % 2 === 0 ? "pair" : "impair"}
              key={`user-row-${user.id}`}
            >
              <td>
                {user.firstName} {user.lastName}
              </td>
              <td>{user.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function AddDocumentMail({
  sendMails,
  setSendMails,
  handleGroupChange,
  mailGroupIds,
  projectIdOrSlug,
}) {
  return (
    <div className="mail">
      <div className="choix">
        <label htmlFor="alerte-by-mail">Prévenir par mail</label>
        <Switch
          name="alerte-by-mail"
          checked={sendMails}
          onChange={setSendMails}
        />
      </div>
      {sendMails ? (
        <div className="deux-colonnes">
          <div>
            <div>
              <label>Groupes destinataires</label>
            </div>
            <Groups
              handleGroupChange={handleGroupChange}
              mailGroupIds={mailGroupIds}
            />
          </div>
          <div>
            <div>
              <label>Destinataires</label>
            </div>
            <Users
              projectIdOrSlug={projectIdOrSlug}
              mailGroupIds={mailGroupIds}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export { AddDocumentMail };
