import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { IS_CONNECTED } from "../../../_GraphQL/queries";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../utils/utils";
import logo from "../../../medias/images/logo-et-texte-blanc.svg";

export function PasswordReset() {
  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);
  const navigate = useNavigate();
  const [result, setResult] = useState({
    formSubmitted: false,
    success: false,
    error: false,
  });
  const [counter, setCounter] = useState(5);

  const { loading, error, data } = useQuery(IS_CONNECTED);

  if (loading) return null;

  if (error) return null;

  if (!data) return null;

  if (data.isConnected) {
    navigate("/");
  }
  const csrftoken = getCookie("csrftoken");

  const urlParams = new URLSearchParams(window.location.search);
  const requestToken = urlParams.get("rt");
  return (
    <div className="mot-de-passe-oublie">
      <div className="logo">
        <div className="courbes">
          <div className="courbe-bleue">
            <div className="courbe-blanche"></div>
          </div>
        </div>
        <div>
          <img src={logo} alt="Logo - Initiative Cartographique Interactive" />
        </div>
      </div>
      <div className="corps">
        <div className="centre">
          <h1 as="h1">Réinitialisation de votre mot de passe</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (pwd !== pwdConfirm) {
                setResult({
                  formSubmitted: true,
                  success: false,
                  error: true,
                  errorMessage: "Les deux mots de passe ne correspondent pas.",
                });
              } else {
                setFetchLoading(true);

                let formData = new FormData();
                formData.append("pwd", pwd);

                let headers = new Headers();
                headers.append("X-CSRFToken", csrftoken);

                fetch(`/compte/reset-pwd/?rt=${requestToken}`, {
                  method: "POST",
                  headers: headers,
                  body: formData,
                })
                  .then(function (response) {
                    // Response.ok est une propriété de la réponse de fetch
                    // Elle doit être à "true" si tout va bien
                    const contentType = response.headers.get("content-type");
                    if (
                      contentType &&
                      contentType.indexOf("application/json") !== -1
                    ) {
                      return response
                        .json()
                        .then((data) => {
                          if (!response.ok) {
                            setResult({
                              formSubmitted: true,
                              success: false,
                              error: true,
                              errorMessage: data.message,
                            });
                            setFetchLoading(false);
                          } else {
                            setResult({
                              formSubmitted: true,
                              success: true,
                              error: false,
                            });

                            let ms = 5000;
                            const interval = 1000;
                            const countdown = setInterval(() => {
                              ms = ms - interval;
                              setCounter(ms / 1000);

                              if (ms <= 0) {
                                clearInterval(countdown);
                                navigate("/");
                              }
                            }, interval);
                            // start timer and redirect.
                            setFetchLoading(false);
                          }
                        })
                        .catch((err) => {
                          setResult({
                            formSubmitted: true,
                            success: false,
                            error: true,
                            errorMessage:
                              "Erreur lors de la lecture de la reponse JSON. " +
                              err.toString(),
                          });
                          setFetchLoading(false);
                        });
                    } else {
                      setResult({
                        formSubmitted: true,
                        success: false,
                        error: true,
                        errorMessage:
                          "Erreur. La réponse du serveur n'est pas au format attendu.",
                      });
                      setFetchLoading(false);
                    }
                  })
                  .catch((err) => {
                    setResult({
                      formSubmitted: true,
                      success: false,
                      error: true,
                      errorMessage: err.toString(),
                    });
                    setFetchLoading(false);
                  });
              }
            }}
          >
            {fetchLoading ? <p>Chargement...</p> : null}
            {result.formSubmitted && result.error ? (
              <div className="erreur">{result.errorMessage}</div>
            ) : null}
            {result.formSubmitted && result.success ? (
              <>
                <div>
                  Votre mot de passe à bien été réinitialisé. Vous allez être
                  redirigé.e vers la page d&apos;accueil. Nous vous invitons à
                  vous y reconnecter avec votre nouveau mot de passe.
                </div>
                <div>Redirection dans {counter} secondes</div>
              </>
            ) : null}
            {result.formSubmitted && result.success ? null : (
              <>
                <div>
                  <label>Nouveau mot de passe</label>
                  <input
                    placeholder="Mot de passe"
                    type="password"
                    required
                    pattern=".{8,}"
                    title="Minimum 8 caractères"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                  />
                </div>
                <div>
                  <label>Confirmation nouveau mot de passe</label>
                  <input
                    placeholder="Confirmation"
                    type="password"
                    required
                    pattern=".{8,}"
                    title="Minimum 8 caractères"
                    value={pwdConfirm}
                    onChange={(e) => setPwdConfirm(e.target.value)}
                  />
                </div>
                <button type="submit">Réinitialiser</button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
