import React from "react";

function PolyPattern({ uid, patternOptions, children }) {
  return (
    <pattern
      id={uid}
      x={0}
      y={0}
      width={patternOptions.width}
      height={patternOptions.height}
      patternUnits="userSpaceOnUse"
      patternContentUnits="userSpaceOnUse"
      patternTransform={`rotate(${patternOptions.angle})`}
    >
      {children}
    </pattern>
  );
}

export { PolyPattern };
