import React from "react";
import {
  ACTIONS,
  useCollaborativeMapDispatch,
  useCollaborativeMap,
} from "../../../../Contexts/CollaborativeMapProvider";

function ToggleTips({ disabled }) {
  const dispatch = useCollaborativeMapDispatch();
  const { showTips } = useCollaborativeMap();
  const title = showTips ? "Désactiver l'aide" : "Activer l'aide";
  const divClass = showTips ? "aide active" : "aide";
  return (
    <div className={divClass}>
      <button
        className="bt-img"
        onClick={() => dispatch({ type: ACTIONS.SHOW_TIPS_CLICKED })}
        title={title}
        disabled={disabled}
      >
        ?
      </button>
    </div>
  );
}

export { ToggleTips };
