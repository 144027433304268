import React from "react";
import { WysiwygReader } from "../../../../Common/components/WysiwygReader";
import { useCollaborativeMap } from "../../../../Contexts/CollaborativeMapProvider";

function TaskInfos({ task }) {
  const collaborativeMap = useCollaborativeMap();

  if (!collaborativeMap.showTaskInfos) return null;
  return (
    <div className="infos-tache">
      <div className="separateur-panneaux"></div>
      <div className="corps-infos-tache">
        <h1>{task.name}</h1>
        <WysiwygReader raw={task.description} />
      </div>
    </div>
  );
}

export { TaskInfos };
