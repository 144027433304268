import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PROFILE } from "../../../Common/_GraphQL/queries";
import { ProfileMutation } from "../components/ProfileMutation";
import { PasswordMutation } from "../components/PasswordMutation";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { USER_TYPES } from "../../../utils/Constants";
import { Loader } from "../../../Common/components/Loader";
import { LeftMenu, PAGES } from "../../../Common/components/LeftMenu";

function ProfileInfos({ currentUser }) {
  const legalPerson =
    currentUser.profile &&
    currentUser.profile.nature.code === USER_TYPES.LEGAL_PERSON;
  return (
    <>
      <div className="champ">
        <div className="label">{legalPerson ? "Raison sociale" : "Nom"}</div>
        <div className="valeur">
          {legalPerson ? currentUser.profile.name : currentUser.lastName}
        </div>
      </div>
      {legalPerson ? null : (
        <div className="champ">
          <div className="label">Prénom</div>
          <div className="valeur">{currentUser.firstName}</div>
        </div>
      )}
    </>
  );
}

function ProfileLayout({ currentUser }) {
  const [editProfile, setEditProfile] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  function toggleEditProfile() {
    setEditProfile(!editProfile);
  }

  function toggleEditPassword() {
    setEditPassword(!editPassword);
  }

  return (
    <>
      <h1 className="titre">Mon compte</h1>
      <div className="profil-centre">
        <div className="profil-titre-email">{currentUser.email}</div>
        <div className="titre-section">
          <p>Informations</p>
          <div className="separateur"></div>
          {!editPassword && !editProfile ? (
            <div className="action">
              <button onClick={toggleEditProfile}>Modifier</button>
            </div>
          ) : null}
        </div>
        <div className="formulaire-infos">
          {editProfile ? (
            <ProfileMutation
              currentUser={currentUser}
              toggleMode={toggleEditProfile}
            />
          ) : (
            <ProfileInfos currentUser={currentUser} />
          )}
        </div>
        <div className="titre-section">
          <p>Mot de passe</p>
          <div className="separateur"></div>
          {!editPassword && !editProfile ? (
            <div className="action">
              <button onClick={toggleEditPassword}>Modifier</button>
            </div>
          ) : null}
        </div>
        {editPassword ? (
          <PasswordMutation
            currentUser={currentUser}
            editMode={editPassword}
            toggleMode={toggleEditPassword}
          />
        ) : null}
      </div>
    </>
  );
}

function Profile() {
  const { loading, error, data } = useQuery(GET_PROFILE, {
    fetchPolicy: "network-only",
  });

  return (
    <div className="profil page">
      <LeftMenu page={PAGES.PROFILE} />
      <div className="corps-page">
        {loading ? (
          <Loader />
        ) : (
          <>
            {error ? (
              <IciErrorMessage error={error} />
            ) : (
              <ProfileLayout currentUser={data.currUser} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export { Profile };
