import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_TEMP_TOKEN_FOR_FILES_DOWNLOAD } from "../_GraphQL/mutations";
import {
  toggleGlobalSelectedForDownload,
  globalUnselectForDownload,
} from "../../../_GraphQL/mutations";
import FolderList from "../components/FolderList";
import DocumentList from "../components/DocumentList";
import DeleteFoldersAndDocuments from "../components/DeleteFoldersAndDocuments";
import { Loader } from "../../../Common/components/Loader";
import { PAGES } from "../../../utils/Constants";
import {
  addErrorToastMessage,
  getMessageTextFromApolloError,
} from "../../../_GraphQL/message";
import { menuDisabledVar } from "../../../_GraphQL/graphQLClient";
import { DownloadIcon } from "../../../Common/components/Images/DownloadIcon";
import { FileManagerLayout } from "../components/FileManagerLayout";

let domain = "";

if (process.env.NODE_ENV !== "production") {
  let port = parseInt(process.env.REACT_APP_PORT, 10) + 5000;
  domain = `http://localhost:${port}`;
}

const FileList = ({
  website,
  data,
  projectIdOrSlug,
  parentId,
  refetch,
  client,
}) => {
  const { currUserFolder } = data;
  const [filter, setfilter] = useState({
    column: "name",
    direction: "ascending",
  });
  const [disableForDownload, setDisableForDownload] = useState(false);

  const handleSort = (clickedColumn) => () => {
    const { column, direction } = filter;

    if (column !== clickedColumn) {
      setfilter({
        column: clickedColumn,
        direction: "ascending",
      });

      return;
    }

    setfilter({
      direction: direction === "ascending" ? "descending" : "ascending",
      column: filter.column,
    });
  };

  const sorter = (column, direction) => (a, b) => {
    if (a[column] === b[column]) {
      return 0;
    } else if (a[column] === null) {
      return 1;
    } else if (b[column] === null) {
      return -1;
    } else if (direction === "ascending") {
      return a[column].toLowerCase().localeCompare(b[column].toLowerCase());
    } else {
      return b[column].toLowerCase().localeCompare(a[column].toLowerCase());
    }
  };

  const { column, direction } = filter;
  const [createTempTokenForFilesDownload] = useMutation(
    CREATE_TEMP_TOKEN_FOR_FILES_DOWNLOAD,
    {
      onError: (error) => {
        menuDisabledVar(false);
        setDisableForDownload(false);
        let message = getMessageTextFromApolloError(error);
        message.header = "Erreur lors de la création du jeton de sécurité.";
        addErrorToastMessage(message);
      },
      onCompleted: (data) => {
        const url = `${domain}/fichiers/downloadfiles/?rt=${data.createTempTokenForFilesDownload.token}`;
        fetch(url)
          .then(function (response) {
            // Response.ok est une propriété de la réponse de fetch
            // Elle doit être à "true" si tout va bien
            if (!response.ok) {
              throw Error(response.statusText);
            }
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => {
                // process your JSON data further
                throw Error(data.message ? data.message : "Erreur inconnue.");
              });
            }
            return response;
          })
          .then((response) => response.blob())
          .then((blob) => {
            let type = blob.type.split("/").pop();
            type = type === "plain" ? "txt" : type;
            let d = document.createElement("a");
            d.className = "download"; // utile ?
            d.download = currUserFolder.name + "." + type;
            d.href = URL.createObjectURL(blob);
            document.body.appendChild(d);
            d.click();
            d.parentElement.removeChild(d);

            globalUnselectForDownload(client, parentId);
          })
          .catch((err) => {
            addErrorToastMessage({
              header: "Erreur lors du téléchargement des fichiers",
              content: err.message.toString(),
            });
          })
          .finally(() => {
            menuDisabledVar(false);
            setDisableForDownload(false);
          });
      },
    }
  );

  if (disableForDownload) {
    return <Loader />;
  }

  return (
    <div className="liste-fichiers">
      <div className="entete">
        <div
          className="nom"
          sorted={column === "name" ? direction : null}
          onClick={handleSort("name")}
        >
          Nom
        </div>
        <div
          className="date"
          sorted={column === "creationDate" ? direction : null}
          onClick={handleSort("creationDate")}
        >
          date
        </div>
        <div className="selectionner">
          <input
            type="checkbox"
            checked={
              data.currUserFolderSet.filter((f) => f.selectedForDownload)
                .length === data.currUserFolderSet.length &&
              data.currUserDocumentSet.filter((f) => f.selectedForDownload)
                .length === data.currUserDocumentSet.length
            }
            onChange={() => toggleGlobalSelectedForDownload(client, parentId)}
          />
        </div>
        <div className="faux-scroll"></div>
      </div>
      <div className="lignes">
        <FolderList
          data={data}
          parentId={parentId}
          column={column}
          direction={direction}
          sorter={sorter}
          refetch={refetch}
          projectIdOrSlug={projectIdOrSlug}
        />

        <DocumentList
          data={data}
          parentId={parentId}
          column={column}
          direction={direction}
          sorter={sorter}
          website={website}
          refetch={refetch}
          projectIdOrSlug={projectIdOrSlug}
          folderSetOdd={data.currUserFolderSet.length % 2 === 1}
        />
      </div>
      <div className="pied">
        <div className="boutons">
          <div className="telechargement">
            <button
              className="bt-img-rond bt-bleu bt-dl"
              onClick={() => {
                menuDisabledVar(true);
                setDisableForDownload(true);
                createTempTokenForFilesDownload({
                  variables: {
                    folderId: parentId,
                    folderList: data.currUserFolderSet
                      .filter((f) => f.selectedForDownload)
                      .map((f) => f.id),
                    documentList: data.currUserDocumentSet
                      .filter((d) => d.selectedForDownload)
                      .map((f) => f.id),
                  },
                });
              }}
              disabled={
                data.currUserFolderSet.filter((f) => f.selectedForDownload)
                  .length === 0 &&
                data.currUserDocumentSet.filter((f) => f.selectedForDownload)
                  .length === 0
              }
            >
              <DownloadIcon />
            </button>
          </div>
          <DeleteFoldersAndDocuments
            parentId={parentId}
            folderList={data.currUserFolderSet
              .filter((f) => f.selectedForDownload)
              .map((f) => f.id)}
            docList={data.currUserDocumentSet
              .filter((d) => d.selectedForDownload)
              .map((f) => f.id)}
            disabled={
              (data.currUserFolderSet.filter((f) => f.selectedForDownload)
                .length === 0 &&
                data.currUserDocumentSet.filter((f) => f.selectedForDownload)
                  .length === 0) ||
              (data.currUserFolderSet
                .filter((f) => f.selectedForDownload)
                .filter((f) => f.canDelete).length === 0 &&
                data.currUserDocumentSet
                  .filter((f) => f.selectedForDownload)
                  .filter((f) => f.canDelete).length === 0)
            }
          />
        </div>
        <div className="faux-scroll"></div>
      </div>
    </div>
  );
};

const FileListQuery = ({ website }) => {
  return (
    <FileManagerLayout page={PAGES.FM_FILE_LIST}>
      {(props) => <FileList website={website} {...props} />}
    </FileManagerLayout>
  );
};

export { FileListQuery as FileList };
