import React from "react";

function PointIcon({ active }) {
  const stroke = active ? "#ffffff" : "#979797";
  const fill = active ? "rgba(255,255,255,0.4)" : "rgba(151,151,151,0.2)";
  return (
    <svg width="35" height="35" viewBox="0 0 40 40.143">
      <path
        d="M0,14.518C.11,25.349,9.862,25.971,15.086,36,20.439,25.981,29.772,25.938,30,14.518A14.765,14.765,0,0,0,15,0,14.765,14.765,0,0,0,0,14.518Z"
        transform="translate(6 2)"
        fill={fill}
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        id="Path"
        d="M0,5a5,5,0,1,1,5,5A5,5,0,0,1,0,5Z"
        transform="translate(16 10)"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
}

export { PointIcon };
