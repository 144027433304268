import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import {
  USER_TASK_CONTRIB_COUNTER,
  USER_TASK_CHECK_SHOW_HEART,
} from "../_GraphQL/queries";
import coeur from "../../medias/images/coeur.svg";

function HeartCount({ taskIdOrSlug }) {
  const { loading, error, data } = useQuery(USER_TASK_CONTRIB_COUNTER, {
    variables: {
      taskIdOrSlug: taskIdOrSlug,
    },
  });

  if (loading || error || !data) return null;

  return (
    <div className="carre coeur">
      <div className="bt-coeur">
        <img src={coeur} alt="Coeur" />
        <div className="montant">{data.userTaskContribCounter}</div>
      </div>
    </div>
  );
}

function CheckShowHeart({ taskIdOrSlug }) {
  const { loading, error, data } = useQuery(USER_TASK_CHECK_SHOW_HEART, {
    variables: {
      taskIdOrSlug: taskIdOrSlug,
    },
  });

  if (loading || error || !data || !data.getTaskByIdOrSlug.showHeart)
    return null;

  return <HeartCount taskIdOrSlug={taskIdOrSlug} />;
}

function CheckParams() {
  const { projectIdOrSlug, taskIdOrSlug } = useParams();

  if (!projectIdOrSlug || !taskIdOrSlug) return null;
  return <CheckShowHeart taskIdOrSlug={taskIdOrSlug} />;
}

export { CheckParams as Heart };
