import React from "react";

function SvgLine({ color, weight, dashArray }) {
  return (
    <svg viewBox="0 0 50 30">
      <line
        x1="0"
        y1="15"
        x2="50"
        y2="15"
        style={{ stroke: color, strokeWidth: weight }}
        strokeDasharray={dashArray ?? null}
        strokeLinecap="round"
      />
    </svg>
  );
}

export { SvgLine };
