import { gql } from "@apollo/client";
import { FOLDER_FRAG, DOCUMENT_FRAG } from "./fragments";
import {
  DEFAULT_FOLDER_GROUP_PERMISSION_FRAG,
  GROUP_PERMISSION_FRAG,
  DEFAULT_DOCUMENT_GROUP_PERMISSION_FRAG,
  USER_FRAG,
} from "../../../Common/_GraphQL/fragments";

export const GET_GROUPS_FOR_CURRENT_SITE = gql`
  query GetCurrWebsiteGroupSet {
    currWebsiteGroupSet {
      id
      name
    }
  }
`;

export const GET_FILES = gql`
  query Files($parentId: ID) {
    currUserFolder(folderId: $parentId) {
      ...FolderFrag
    }
    currUserFolderSet(parentId: $parentId) {
      ...FolderFrag
    }
    currUserDocumentSet(parentId: $parentId) {
      ...DocumentFrag
    }
    currUserCanAddFolder(parentId: $parentId)
    currUserCanAddDocument(parentId: $parentId)
    currUserCanManagePermissions
    currUserCanViewStats
    currUserCanSendMail
  }
  ${FOLDER_FRAG}
  ${DOCUMENT_FRAG}
`;

export const GET_FOLDER_GENEALOGY = gql`
  query folderGenealogy($folderId: ID) {
    folderGenealogy(folderId: $folderId) {
      id
      name
    }
  }
`;

export const GET_READING_STATS = gql`
  query GetReadingStats($projectIdOrSlug: String!, $documentId: ID!) {
    readerSet(documentId: $documentId) {
      id
      firstName
      lastName
      email
    }
    missingReaderSet(
      projectIdOrSlug: $projectIdOrSlug
      documentId: $documentId
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const DEFAULT_FOLDER_GRP_PERM_SET_CURR_SITE_GRP_SET = gql`
  query defaultFolderGrpPermAndGrpForSite($projectIdOrSlug: String!) {
    defaultFolderGroupPermissionSet(projectIdOrSlug: $projectIdOrSlug) {
      ...DefaultFolderGroupPermissionFrag
    }
    currWebsiteGroupSet {
      id
      name
    }
  }
  ${DEFAULT_FOLDER_GROUP_PERMISSION_FRAG}
`;

export const DEFAULT_DOC_GRP_PERM_SET_CURR_SITE_GRP_SET = gql`
  query defaultDocumentGrpPermAndGrpForSite($projectIdOrSlug: String!) {
    defaultDocumentGroupPermissionSet(projectIdOrSlug: $projectIdOrSlug) {
      ...DefaultDocumentGroupPermissionFrag
    }
    currWebsiteGroupSet {
      id
      name
    }
  }
  ${DEFAULT_DOCUMENT_GROUP_PERMISSION_FRAG}
`;

export const FOLDER_GRP_PERM_SET_CURR_SITE_GRP_SET = gql`
  query folderGrpPermAndGrpForSite($folderId: ID!) {
    folderGroupPermissionSet(folderId: $folderId) {
      ...GroupPermissionFrag
    }
    currWebsiteGroupSet {
      id
      name
    }
  }
  ${GROUP_PERMISSION_FRAG}
`;

export const DOC_GRP_PERM_SET_CURR_SITE_GRP_SET = gql`
  query documentGrpPermAndGrpForSite($documentId: ID!) {
    documentGroupPermissionSet(documentId: $documentId) {
      ...GroupPermissionFrag
    }
    currWebsiteGroupSet {
      id
      name
    }
  }
  ${GROUP_PERMISSION_FRAG}
`;

export const USER_SET = gql`
  query userSet($projectIdOrSlug: String!, $groupIds: [ID]!) {
    userSet(projectIdOrSlug: $projectIdOrSlug, groupIds: $groupIds) {
      ...UserFrag
    }
  }
  ${USER_FRAG}
`;

export const GET_FOLDER_AND_DOCUMENT_HIERARCHY = gql`
  query GetFolderAndDocumentHierarchy($projectIdOrSlug: String!) {
    currUserViewableFolderSet(projectIdOrSlug: $projectIdOrSlug) {
      ...FolderFrag
    }
    currUserViewableDocumentSet(projectIdOrSlug: $projectIdOrSlug) {
      ...DocumentFrag
    }
  }
  ${FOLDER_FRAG}
  ${DOCUMENT_FRAG}
`;
