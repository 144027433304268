// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";

import { Loader } from "../../../Common/components/Loader";
import {
  FOLDER_GRP_PERM_SET_CURR_SITE_GRP_SET,
  DOC_GRP_PERM_SET_CURR_SITE_GRP_SET,
} from "../_GraphQL/queries";

// Components
import ManagePermissionsMutation from "./ManagePermissionsMutation";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";

function ManagePermission({
  query,
  variables,
  fileType,
  folderId,
  documentId,
  handleCancel,
  handleCompleted,
  refetch,
}) {
  const { loading, error, data } = useQuery(query, {
    variables: variables,
  });

  if (loading) return <Loader />;

  if (error) {
    return (
      <IciErrorMessage
        Header={"Erreur lors du chargement des permissions."}
        error={error}
      />
    );
  }

  let groupPermissionSet =
    fileType === "folder"
      ? data.folderGroupPermissionSet
      : data.documentGroupPermissionSet;
  let groups = data.currWebsiteGroupSet;

  return (
    <ManagePermissionsMutation
      groupPermissionSet={groupPermissionSet}
      groups={groups}
      handleCancel={handleCancel}
      handleCompleted={handleCompleted}
      folderId={folderId}
      documentId={documentId}
      fileType={fileType}
      refetch={refetch}
    />
  );
}

function ManagePermissionsQuery({
  fileType,
  handleCancel,
  handleCompleted,
  folderId,
  documentId,
  refetch,
}) {
  const filesTypes = {
    folder: {
      query: FOLDER_GRP_PERM_SET_CURR_SITE_GRP_SET,
      variables: { folderId: folderId },
    },
    document: {
      query: DOC_GRP_PERM_SET_CURR_SITE_GRP_SET,
      variables: { documentId: documentId },
    },
  };

  return (
    <ManagePermission
      query={filesTypes[fileType].query}
      variables={filesTypes[fileType].variables}
      handleCancel={handleCancel}
      handleCompleted={handleCompleted}
      folderId={folderId}
      documentId={documentId}
      fileType={fileType}
      refetch={refetch}
    />
  );
}

export default ManagePermissionsQuery;
