// React
import React, { useEffect, useMemo } from "react";

// Leaflet
import L from "leaflet"; // eslint-disable-line no-unused-vars
import "@geoman-io/leaflet-geoman-free";
import "leaflet.pattern";
import { MapContainer, TileLayer } from "react-leaflet";

// Component
import SelectedForDisplayLayer from "./SelectedForDisplayLayer";
import ApiCarto from "./ApiCarto";
import { useCollaborativeMapDispatch } from "../../../../Contexts/CollaborativeMapProvider";

// Constants
import {
  MAPBOX_TOKEN,
  BASE_MAPS,
  PATTERN_TYPES,
} from "../../../../utils/Constants";
import { ACTIONS } from "../../../../Contexts/CollaborativeMapProvider";

const customStreets =
  "https://api.mapbox.com/styles/v1/yvainm/ckpxr3tbs18mo17mfkgup1jtq/tiles/256/{z}/{x}/{y}?access_token={accessToken}";

const customTopo =
  "https://api.mapbox.com/styles/v1/yvainm/ckpxqql691d2717qu4y0megqq/tiles/256/{z}/{x}/{y}?access_token={accessToken}";

const MapPanel = ({
  activeFeature,
  activeTool,
  baseMap,
  cadastre,
  currentDivision,
  currentMunicipality,
  handleCommuneClick,
  handleDivisionClick,
  handleFeatureClick,
  handleMapCreated,
  handleParcelClick,
  handlePmEdit,
  handleViewPortChanged,
  layerSet,
  mapInstance,
  municipalities,
  tileLayer,
  useApicarto,
  styleSet,
}) => {
  const dispatch = useCollaborativeMapDispatch();

  useEffect(() => {
    if (mapInstance) {
      let stateStyleSet = [];
      styleSet.forEach((style) => {
        if (style.usePattern) {
          if (style.patternTypeCode === PATTERN_TYPES.STRIPES) {
            let stripes = new L.StripePattern(JSON.parse(style.patternOptions));
            stripes.addTo(mapInstance);
            stateStyleSet.push({
              id: style.id,
              pattern: stripes,
            });
          } else {
            let pattern = new L.Pattern(JSON.parse(style.patternOptions));
            let shape;
            if (style.patternTypeCode === PATTERN_TYPES.RECTANGLE) {
              shape = new L.PatternRect(JSON.parse(style.shapeOptions));
            } else if (style.patternTypeCode === PATTERN_TYPES.CIRCLE) {
              shape = new L.PatternCircle(JSON.parse(style.shapeOptions));
            } else {
              shape = new L.PatternPath(JSON.parse(style.shapeOptions));
            }
            pattern.addShape(shape);
            pattern.addTo(mapInstance);
            stateStyleSet.push({
              id: style.id,
              pattern: pattern,
            });
          }
        }
      });
      dispatch({ type: ACTIONS.STYLES_CREATED, styles: stateStyleSet });
    }
  }, [mapInstance, styleSet, dispatch]);

  const position = [tileLayer.latitude, tileLayer.longitude];
  const selectedForDisplayLayerMemo = useMemo(
    () => (
      <SelectedForDisplayLayer
        layers={layerSet}
        handleFeatureClick={handleFeatureClick}
        handlePmEdit={handlePmEdit}
        activeFeature={activeFeature}
      />
    ),
    [layerSet, handleFeatureClick, handlePmEdit, activeFeature]
  );

  return (
    <div className="zone-travail">
      <MapContainer
        center={position}
        zoom={tileLayer.zoomInit}
        zoomControl={false}
        attributionControl={false}
        zoomSnap={0.1}
        zoomDelta={0.1}
        maxZoom={22}
        maxNativeZoom={19}
        whenCreated={handleMapCreated}
        onViewportChanged={handleViewPortChanged}
      >
        {useApicarto ? (
          <ApiCarto
            cadastre={cadastre}
            handleCommuneClick={handleCommuneClick}
            currentMunicipality={currentMunicipality}
            handleDivisionClick={handleDivisionClick}
            currentDivision={currentDivision}
            municipalityCodes={municipalities}
            handleParcelClick={handleParcelClick}
            activeTool={activeTool}
          />
        ) : null}
        {/* A optimiser, faire des composants qui utilisent TileLayer ? */}

        {baseMap === BASE_MAPS.SATELLITE ? (
          <TileLayer
            id={baseMap}
            maxZoom={22}
            maxNativeZoom={22}
            accessToken={MAPBOX_TOKEN}
            url="https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
          />
        ) : null}

        {baseMap === BASE_MAPS.STREETS ? (
          <TileLayer accessToken={MAPBOX_TOKEN} url={customStreets} />
        ) : null}

        {baseMap === BASE_MAPS.TOPOGRAPHIC ? (
          <TileLayer accessToken={MAPBOX_TOKEN} url={customTopo} />
        ) : null}

        {selectedForDisplayLayerMemo}
      </MapContainer>
    </div>
  );
};

export default MapPanel;
