import React, { useState, useRef } from "react";
import { InputField } from "./InputField";
import { GET_FEATURE } from "../../../../Modules/CollaborativeMap/_GraphQL/queries";
import {
  UPDATE_FILLED_QUESTIONNAIRE_FIELD,
  CREATE_FILLED_QUESTIONNAIRE_FIELD,
} from "../../../_GraphQL/mutations";
import { CREATE_FILLED_QUESTIONNAIRE } from "../../../../Modules/Questionnaire/_GraphQL/mutations";
import { useMutation } from "@apollo/client";
import { QUESTIONNAIRE_FIELD_TYPES } from "../../../../utils/Constants";
import { getIciErrorTextsFromApolloError } from "../../ErrorMessage";
import { ErrorField } from "./ErrorField";
import {
  ACTIONS,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";

function EditField({
  featureId,
  filledQId,
  qField,
  filledQField,
  setReadMode,
  sources,
  setSources,
  children,
}) {
  const dispatch = useCollaborativeMapDispatch();
  const childRef = useRef();

  let initValue;

  switch (qField.fieldType.code) {
    case QUESTIONNAIRE_FIELD_TYPES.TEXT:
    case QUESTIONNAIRE_FIELD_TYPES.SELECT:
      initValue = filledQField ? filledQField.text : "";
      break;

    case QUESTIONNAIRE_FIELD_TYPES.RADIO:
      initValue =
        filledQField && filledQField.questionnaireFieldLabel
          ? filledQField.questionnaireFieldLabel.id
          : null;
      break;

    case QUESTIONNAIRE_FIELD_TYPES.DECIMAL:
      initValue = filledQField && filledQField.value ? filledQField.value : "";
      break;

    case QUESTIONNAIRE_FIELD_TYPES.CHECKBOX:
      initValue = filledQField && filledQField.checked;
      break;

    case QUESTIONNAIRE_FIELD_TYPES.DATE:
      initValue = filledQField && filledQField.date ? filledQField.date : "";
      break;

    case QUESTIONNAIRE_FIELD_TYPES.IMAGE:
      initValue = {
        existingImages:
          filledQField && filledQField.questionnairemediaSet
            ? filledQField.questionnairemediaSet
            : [],
        imagesToDelete: [],
        imagesToAdd: [],
      };
      break;

    default:
      break;
  }
  const [value, setValue] = useState(initValue);
  const [
    updateFilledQField,
    { loading: updateLoading, error: mutUpdateError },
  ] = useMutation(UPDATE_FILLED_QUESTIONNAIRE_FIELD, {
    onError() {},
    onCompleted() {
      setReadMode();
      dispatch({ type: ACTIONS.FIELD_EDITION_COMPLETED });
    },
    update(
      cache,
      {
        data: {
          updateFilledQuestionnaireField: { filledQuestionnaire },
        },
      }
    ) {
      cache.modify({
        id: `FilledQuestionnaireType:${filledQuestionnaire.id}`,
        fields: {
          filledquestionnairefieldSet() {
            return filledQuestionnaire.filledquestionnairefieldSet;
          },
        },
      });
    },
  });

  const [
    createFilledQField,
    { loading: createLoading, error: mutCreateError },
  ] = useMutation(CREATE_FILLED_QUESTIONNAIRE_FIELD, {
    onError() {},
    onCompleted() {
      setReadMode();
      dispatch({ type: ACTIONS.FIELD_EDITION_COMPLETED });
    },
    update(
      cache,
      {
        data: {
          createFilledQuestionnaireField: { filledQuestionnaire },
        },
      }
    ) {
      cache.modify({
        id: `FilledQuestionnaireType:${filledQuestionnaire.id}`,
        fields: {
          filledquestionnairefieldSet() {
            return filledQuestionnaire.filledquestionnairefieldSet;
          },
        },
      });
    },
  });

  const [
    createFilledQ,
    { loading: createFilledQLoading, error: mutCreateFilledQError },
  ] = useMutation(CREATE_FILLED_QUESTIONNAIRE, {
    onError() {},
    onCompleted() {
      setReadMode();
      dispatch({ type: ACTIONS.FIELD_EDITION_COMPLETED });
    },
    update(
      cache,
      {
        data: {
          createFilledQuestionnaire: { filledQuestionnaire },
        },
      }
    ) {
      const { feature } = cache.readQuery({
        query: GET_FEATURE,
        variables: {
          featureId: featureId,
        },
      });

      cache.writeQuery({
        query: GET_FEATURE,
        data: {
          feature: {
            ...feature,
            filledQuestionnaireSet: [filledQuestionnaire],
          },
        },
        variables: {
          featureId: featureId,
        },
      });
    },
  });

  function addImages(images) {
    setValue({
      ...value,
      imagesToAdd: images,
    });
  }

  function toggleImageToDelete(imageId) {
    if (value.imagesToDelete.find((image) => image === imageId)) {
      setValue({
        ...value,
        imagesToDelete: value.imagesToDelete.filter(
          (image) => image !== imageId
        ),
      });
    } else {
      setValue({
        ...value,
        imagesToDelete: value.imagesToDelete.concat([imageId]),
      });
    }
  }

  // Gestion erreur
  // Gestion des erreurs remontées

  if (updateLoading || createLoading || createFilledQLoading) {
    // todo: loader propre, avec latence pour éviter l'effet instantané
    return <div>Chargement en cours...</div>;
  }

  const updateError = getIciErrorTextsFromApolloError(mutUpdateError);
  const createError = getIciErrorTextsFromApolloError(mutCreateError);
  const createFilledQError = getIciErrorTextsFromApolloError(
    mutCreateFilledQError
  );

  return (
    <>
      <ErrorField error={updateError ? updateError.body : null} />
      <ErrorField error={createError ? createError.body : null} />
      <ErrorField error={createFilledQError ? createFilledQError.body : null} />
      <InputField
        value={value}
        handleValueChange={setValue}
        addImages={addImages}
        toggleImageToDelete={toggleImageToDelete}
        sources={sources}
        setSources={setSources}
        ref={childRef}
      >
        {children}
      </InputField>
      {/* todo: réutiliser annuler valider du formulaire global ? */}
      <div className="boutons">
        <button
          className="valider"
          onClick={(e) => {
            e.preventDefault();
            const checkResult = childRef.current.checkForErrors();
            if (!checkResult.valid) {
              return;
            }

            if (!filledQId) {
              let filledFeatureField = {
                questionnaireFieldId: qField.id,
              };

              if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.TEXT ||
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.SELECT
              ) {
                filledFeatureField.text = value;
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.RADIO
              ) {
                filledFeatureField.questionnaireFieldLabelId = value;
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.CHECKBOX
              ) {
                filledFeatureField.isChecked = value;
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.DECIMAL
              ) {
                filledFeatureField.value =
                  value === "" ? null : parseFloat(value);
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.IMAGE
              ) {
                filledFeatureField.imagesToAdd = value.imagesToAdd;
                filledFeatureField.imagesToDelete = value.imagesToDelete;
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.DOCUMENT
              ) {
              }
              createFilledQ({
                variables: {
                  featureId: featureId,
                  filledFeatureFields: [filledFeatureField],
                },
              });
            } else {
              let variables = {
                questionnaireFieldId: qField.id,
              };

              if (filledQField) {
                variables.filledQuestionnaireFieldId = filledQField.id;
              } else {
                variables.filledQuestionnaireId = filledQId;
              }

              if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.TEXT ||
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.SELECT
              ) {
                variables.text = value;
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.RADIO
              ) {
                variables.questionnaireFieldLabelId = value;
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.CHECKBOX
              ) {
                variables.checked = value;
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.DECIMAL
              ) {
                variables.value = value === "" ? null : parseFloat(value);
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.IMAGE
              ) {
                variables.imagesToAdd = value.imagesToAdd;
                variables.imagesToDelete = value.imagesToDelete;
              } else if (
                qField.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.DOCUMENT
              ) {
              }

              if (filledQField) {
                updateFilledQField({ variables: variables });
              } else {
                createFilledQField({ variables: variables });
              }
            }
          }}
        >
          Valider
        </button>
        <button
          className="annuler"
          onClick={() => {
            setReadMode();
            dispatch({ type: ACTIONS.FIELD_EDITION_CANCELLED });
          }}
        >
          Annuler
        </button>
      </div>
    </>
  );
}

export { EditField };
