import moveMarkerStep1Img from "../../../medias/images/aide/deplacement-marqueur-etape-1-800x600.png";
import moveMarkerStep2Img from "../../../medias/images/aide/deplacement-marqueur-etape-2-800x600.png";
import moveMarkerStep3Img from "../../../medias/images/aide/deplacement-marqueur-etape-3-800x600.png";
import moveMarkerStep4Img from "../../../medias/images/aide/deplacement-marqueur-etape-4-800x600.png";
import moveMarkerStep5Img from "../../../medias/images/aide/deplacement-marqueur-etape-5-800x600.png";

function HelpMovePoint() {
  return (
    <div>
      <h1>Étapes pour déplacer un marqueur</h1>
      <p>1. Cliquer sur l'outil &laquo;&nbsp;Modifier&nbsp;&raquo;</p>
      <img
        className="capture-aide"
        src={moveMarkerStep1Img}
        alt="Déplacer marqueur - étape 1 - sélectionner outil modifier"
      />
      <p>2. Cliquer sur le marqueur à déplacer</p>
      <img
        className="capture-aide"
        src={moveMarkerStep2Img}
        alt="Déplacer marqueur - étape 2 - cliquer sur le marqueur"
      />
      <p>
        3. Glisser le marqueur vers l'emplacement souhaité en laissant le bouton
        gauche de la souris enfoncé
      </p>
      <img
        className="capture-aide"
        src={moveMarkerStep3Img}
        alt="Déplacer marqueur - étape 3 - déplacer marqueur"
      />
      <p>4. Cliquer sur valider</p>
      <img
        className="capture-aide"
        src={moveMarkerStep4Img}
        alt="Déplacer marqueur - étape 4 - valider"
      />
      <p>5. Le marqueur est maintenant déplacé</p>
      <img
        className="capture-aide"
        src={moveMarkerStep5Img}
        alt="Déplacer marqueur - étape 5 - Fin"
      />
    </div>
  );
}

export { HelpMovePoint };
