import React, { forwardRef, useImperativeHandle } from "react";
import { Field } from "./Common/Field";
import { QUESTIONNAIRE_MODES } from "../../../utils/Constants";
import { CarouselImage } from "../../../Modules/CollaborativeMap/components/MapPanel/Carousel";

function filesAreImages(files) {
  return !Array.from(files).some((file) => {
    const ext = file.type;
    return (
      ext !== "image/jpeg" &&
      ext !== "image/jpg" &&
      ext !== "image/png" &&
      ext !== "image/gif"
    );
  });
}

function verifyValue(field, value) {
  if (value === "" || value.existingImages === undefined) {
    if (field.required && (!value || !value.imagesToAdd)) {
      return {
        valid: false,
        error: "Ce champ est obligatoire.",
      };
    }

    if (!field.multiline && value.imagesToAdd && value.imagesToAdd.length > 1) {
      return {
        valid: false,
        error: "Vous ne pouvez ajouter qu'une image.",
      };
    }
  } else {
    if (field.required) {
      if (
        value.existingImages.length === value.imagesToDelete.length &&
        value.imagesToAdd.length === 0
      ) {
        return {
          valid: false,
          error: "Ce champ est obligatoire.",
        };
      }
    }

    if (
      !field.multiline &&
      value.imagesToAdd &&
      value.imagesToAdd.length === 1 &&
      value.imagesToDelete.length === 0
    ) {
      return {
        valid: false,
        error: "Vous ne pouvez ajouter qu'une image.",
      };
    }
  }

  if (value.imagesToAdd && !filesAreImages(value.imagesToAdd)) {
    return {
      valid: false,
      error: `Seuls les formats .jpeg, .jpg, .png et .gif sont autorisés.`,
    };
  }

  return {
    valid: true,
  };
}

const DeletableImage = ({
  image,
  toDelete,
  toggleImageToDelete,
  sources,
  setSources,
  readOnly,
}) => {
  const className = toDelete ? "existant suppression" : "existant";

  return (
    <div className={className} onClick={() => toggleImageToDelete(image.id)}>
      <div className="apercu">
        <CarouselImage
          media={image}
          sources={sources}
          setSources={setSources}
        />
      </div>
      <div className="selection">
        <input
          type="checkbox"
          checked={toDelete}
          onChange={() => toggleImageToDelete(image.id)}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

const ImageInput = forwardRef(
  (
    {
      qField,
      value,
      setError,
      mode,
      addImages,
      toggleImageToDelete,
      sources,
      setSources,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      checkForErrors() {
        const verif = verifyValue(qField, value);
        if (!verif.valid) {
          setError(verif.error);
        } else {
          setError(null);
        }
        return verif;
      },
    }));

    const handleBlur = () =>
      setTimeout(() => {
        const verif = verifyValue(qField, value);
        if (verif.valid) {
          setError(null);
        } else {
          setError(verif.error);
        }
      }, 100);

    if (mode === QUESTIONNAIRE_MODES.CREATE) {
      return (
        <>
          <label>Image{qField.multiline ? "s" : null} à ajouter</label>
          <input
            onBlur={handleBlur}
            type="file"
            accept=".jpg,.jpeg,.png,.gif,"
            onChange={(e) => addImages(e.target.files)}
            multiple={qField.multiline}
            disabled={qField.readOnly}
          />
        </>
      );
    } else {
      return (
        <>
          {value && value.existingImages ? (
            <div className="existantes">
              <div className="titre">Sélectionner les images à supprimer</div>
              <div className="liste">
                {value.existingImages.map((existingImage) => {
                  return (
                    <DeletableImage
                      key={`deletable-image-${existingImage.id}`}
                      toDelete={
                        value.imagesToDelete.find(
                          (image) => image === existingImage.id
                        ) != null
                      }
                      image={existingImage}
                      toggleImageToDelete={toggleImageToDelete}
                      sources={sources}
                      setSources={setSources}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          <div className="ajout">
            <p>Image{qField.multiline ? "s" : null} à ajouter</p>
            <div>
              <input
                onBlur={handleBlur}
                type="file"
                accept=".jpg,.jpeg,.png,.gif,"
                onChange={(e) => addImages(e.target.files)}
                multiple={qField.multiline}
                disabled={qField.readOnly}
              />
            </div>
          </div>
        </>
      );
    }
  }
);

function ImageField({
  featureId,
  filledQId,
  qField,
  filledQField,
  mode,
  value,
  setValue,
  inputRef,
  labelRef,
  addImages,
  canEdit,
}) {
  if (value === undefined || value === null) value = "";

  return (
    <Field
      featureId={featureId}
      filledQId={filledQId}
      qField={qField}
      filledQField={filledQField}
      mode={mode}
      createValue={value}
      createSetValue={setValue}
      createAddImages={addImages}
      inputRef={inputRef}
      labelRef={labelRef}
      canEdit={canEdit}
    >
      {(
        setError,
        value,
        setValue,
        ref,
        addImages,
        toggleImageToDelete,
        sources,
        setSources
      ) => (
        <ImageInput
          qField={qField}
          value={value}
          setValue={setValue}
          setError={setError}
          ref={ref}
          mode={mode}
          addImages={addImages}
          toggleImageToDelete={toggleImageToDelete}
          sources={sources}
          setSources={setSources}
        />
      )}
    </Field>
  );
}

export { ImageField };
