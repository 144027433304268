import React from "react";
import { PolyPath } from "./PolyPath";

function SvgPoly({ pathOptions }) {
  return (
    <svg viewBox="0 0 100 65">
      <PolyPath
        pathOptions={pathOptions}
        fill={pathOptions.fill ? pathOptions.fillColor : "none"}
      />
    </svg>
  );
}

export { SvgPoly };
