import modifyLineStep1Img from "../../../medias/images/aide/modifier-ligne-etape-1-800x600.png";
import modifyLineStep2Img from "../../../medias/images/aide/modifier-ligne-etape-2-800x600.png";
import modifyLineStep3Img from "../../../medias/images/aide/modifier-ligne-etape-3-800x600.png";
import modifyLineStep4Img from "../../../medias/images/aide/modifier-ligne-etape-4-800x600.png";
import modifyLineStep5Img from "../../../medias/images/aide/modifier-ligne-etape-5-800x600.png";

function HelpModifyLine() {
  return (
    <div>
      <h1>Étapes pour modifier une ligne</h1>
      <p>1. Cliquer sur l'outil &laquo;&nbsp;Modifier&nbsp;&raquo;</p>
      <img
        className="capture-aide"
        src={modifyLineStep1Img}
        alt="Modifier ligne - étape 1 - sélectionner outil modifier"
      />
      <p>2. Cliquer sur la ligne à modifier</p>
      <img
        className="capture-aide"
        src={modifyLineStep2Img}
        alt="Modifier ligne - étape 2 - cliquer sur la ligne"
      />
      <p>3. Faire un clic-droit sur un point pour le supprimer</p>
      <img
        className="capture-aide"
        src={modifyLineStep3Img}
        alt="Modifier ligne - étape 3 - supprimer un point"
      />
      <p>
        4. Glisser déposer les point à déplacer et faire Cliquer sur valider
        pour terminer
      </p>
      <img
        className="capture-aide"
        src={modifyLineStep4Img}
        alt="Modifier ligne - étape 4 - déplacer et valider"
      />
      <p>5. La ligne est maintenant modifiée</p>
      <img
        className="capture-aide"
        src={modifyLineStep5Img}
        alt="Modifier ligne - étape 5 - Fin"
      />
    </div>
  );
}

export { HelpModifyLine };
