import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client, { isConnectedVar } from "./_GraphQL/graphQLClient";
import { MessageDisplayerToast } from "./Common/components/MessageDisplayerToast";
import { CollaborativeMapProvider } from "./Contexts/CollaborativeMapProvider";
import { AUTH_TOKEN } from "./utils/Constants";
import MainContainer from "./Common/routes/Main";

import "sanitize.css";

import "./styles/commun/polices.css";
import "./styles/commun/couleurs.css";
import "./styles/commun/root.css";
import "./styles/commun/erreur.css";
import "./styles/commun/boutons.css";
import "./styles/commun/selection.css";
import "./styles/commun/form.css";
import "./styles/commun/page.css";
import "./styles/commun/composants/barre-chargement.css";
import "./styles/commun/composants/messages.css";
import "./styles/commun/composants/popup.css";
import "./styles/commun/composants/switch.css";
import "./styles/commun/composants/tuiles.css";
import "./styles/commun/composants/bulle-aide.css";

import "./styles/home.css";
import "./styles/inscription.css";
import "./styles/menu.css";
import "./styles/mot-de-passe-oublie.css";
import "./styles/profil.css";
import "./styles/quatre-cent-quatre.css";
import "./styles/notifications.css";

import "./styles/fichiers/fichiers.css";
import "./styles/fichiers/chemin.css";
import "./styles/fichiers/liste-fichiers.css";
import "./styles/fichiers/deplacement.css";
import "./styles/fichiers/arborescence.css";
import "./styles/fichiers/ajout.css";

import "./styles/carte-participative/carte.css";
import "./styles/carte-participative/barre-action.css";
import "./styles/carte-participative/marqueur.css";
import "./styles/carte-participative/outil.css";
import "./styles/carte-participative/infos-tache.css";
import "./styles/carte-participative/idees.css";

import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

// const EnvWarning = () => {
//   const [visible, setVisibility] = useState(true);
//   let env = null;
//   let color = null;

//   if (subdomain.includes("localhost")) {
//     color = "blue";
//     env = "Local";
//   } else if (subdomain.includes("-dev")) {
//     color = "orange";
//     env = "de développement";
//   } else if (subdomain.includes("-test")) {
//     color = "yellow";
//     env = "de test";
//   }

//   if (!env || !visible) return null;

//   return (
//     <div style={{"position": "absolute", "marginTop":"100px", "marginLeft": "10px", "width": "200px"}}>
//       <Message color={color} onDismiss={() => setVisibility(false)}>
//         Environnement {env}
//       </Message>
//       </div>
//   );
// };

// Utile pour afficher les viewports.
// const Root = () => {
//   return (
//     <div>
//     <table>
//       <tbody>
//         <tr>
//           <td>document.documentElement.clientWidth</td>
//           <td>{document.documentElement.clientWidth}</td>
//         </tr>
//         <tr>
//           <td>window.innerWidth</td>
//           <td>{window.innerWidth}</td>
//         </tr>
//         <tr>
//           <td>document.documentElement.clientHeight</td>
//           <td>{document.documentElement.clientHeight}</td>
//         </tr>
//         <tr>
//           <td>window.innerHeight</td>
//           <td>{window.innerHeight}</td>
//         </tr>
//       </tbody>
//     </table>
//     </div>);
// }

const Root = () => (
  <>
    {/* <EnvWarning /> */}
    <ApolloProvider client={client}>
      <CollaborativeMapProvider>
        <BrowserRouter>
          <MainContainer />
        </BrowserRouter>
      </CollaborativeMapProvider>
      <MessageDisplayerToast />
    </ApolloProvider>
  </>
);

if (localStorage.getItem(AUTH_TOKEN) !== null) {
  isConnectedVar(true);
}

ReactDOM.render(<Root />, document.getElementById("root"));
