import React from "react";
import { SvgPolyBasePattern } from "./SvgPolyBasePattern";

function SvgPolyRectPattern({
  styleId,
  pathOptions,
  patternOptions,
  shapeOptions,
}) {
  return (
    <SvgPolyBasePattern
      styleId={styleId}
      pathOptions={pathOptions}
      patternOptions={patternOptions}
    >
      <rect
        stroke={shapeOptions.color ?? "#5ed573"}
        strokeOpacity={shapeOptions.opacity ?? "1"}
        strokeWidth={shapeOptions.weight ?? "1"}
        x={shapeOptions.x ?? 0}
        y={shapeOptions.y ?? 0}
        width={shapeOptions.width ?? 10}
        height={shapeOptions.height ?? 10}
        rx={shapeOptions.rx}
        ry={shapeOptions.rx}
        fill={shapeOptions.fill ? shapeOptions.fillColor : "none"}
        fillOpacity={shapeOptions.fillOpacity ?? null}
      ></rect>
    </SvgPolyBasePattern>
  );
}

export { SvgPolyRectPattern };
