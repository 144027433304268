import React from "react";
import { FolderGroupPermissionsFormRow } from "./FolderGroupPermissionsFormRow";
import { DocumentGroupPermissionsFormRow } from "./DocumentGroupPermissionsFormRow";
import { IciErrorMessage } from "../ErrorMessage";
import { Loader } from "../../../Common/components/Loader";

function GroupPermissionsForm(props) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.handleSubmit();
      }}
    >
      {props.error ? <IciErrorMessage error={props.error} /> : null}
      <div disabled={!props.mutation}>
        <table>
          <thead>
            <tr>
              <th>Groupe</th>
              <th align="center">Accès en lecture</th>
              <th align="center">Peut déplacer</th>
              {props.fileType === "folder" ? (
                <th align="center">Peut éditer</th>
              ) : null}
              <th align="center">Peut supprimer</th>
              {props.fileType === "folder" ? (
                <th align="center">Peut ajouter des dossiers</th>
              ) : null}
              {props.fileType === "folder" ? (
                <th align="center">Peut ajouter des documents</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {props.groupPermissionsRows
              .sort(function (a, b) {
                var textA = a.group.name.toUpperCase();
                var textB = b.group.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((groupPermissionsRow, i) =>
                props.fileType === "folder" ? (
                  <FolderGroupPermissionsFormRow
                    even={i % 2 === 0}
                    key={`table-row-group-${groupPermissionsRow.group.name}`}
                    groupPermissionsRow={groupPermissionsRow}
                    handleCheckChange={props.handleCheckChange}
                  />
                ) : (
                  <DocumentGroupPermissionsFormRow
                    even={i % 2 === 0}
                    key={`table-row-group-${groupPermissionsRow.group.name}`}
                    groupPermissionsRow={groupPermissionsRow}
                    handleCheckChange={props.handleCheckChange}
                  />
                )
              )}
          </tbody>
        </table>
      </div>
      {props.fileType === "folder" ? (
        <div>
          <label>
            <input
              type="checkbox"
              checked={props.applyRecursively}
              onChange={(e) => props.setApplyRecursively(e.target.checked)}
            />
            &nbsp;&nbsp; Appliquer ces permissions à tous les dossiers et
            documents contenus dans ce dossier.
          </label>
        </div>
      ) : null}
      {props.mutation ? (
        <div className="flex-right">
          {props.loading ? (
            <Loader />
          ) : (
            <>
              <button className="bt-texte bt-bleu">Enregistrer</button>
              <button
                className="bt-texte bt-gris"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleCancel();
                }}
              >
                Annuler
              </button>
            </>
          )}
        </div>
      ) : null}
    </form>
  );
}

export { GroupPermissionsForm };
