import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { DELETE_FOLDER } from "../_GraphQL/mutations";
import { GET_FILES } from "../_GraphQL/queries";
import IciErrorMessage from "../../../Common/components/ErrorMessage";
import spinner from "../../../medias/images/roue-chargement.svg";
import { DeleteIcon } from "../../../Common/components/Images/DeleteIcon";

function DeleteFolder({ parentId, name, folderId, cancel }) {
  const [deleteFolder, { loading, error }] = useMutation(DELETE_FOLDER, {
    onError() {}, // Nécessaire pour éviter les erreurs de type "Unhandled Rejection"
    update(cache, { data: { deleteFolder } }) {
      let data = cache.readQuery({
        query: GET_FILES,
        variables: {
          parentId: parentId,
        },
      });

      cache.writeQuery({
        query: GET_FILES,
        variables: {
          parentId: parentId,
        },
        data: {
          ...data,
          currUserFolderSet: data.currUserFolderSet.filter(
            (f) => f.id !== deleteFolder.folder.id
          ),
        },
      });
    },
  });

  return (
    <>
      {error ? <IciErrorMessage error={error} /> : null}
      {loading ? (
        <img src={spinner} alt="icône de chargement" />
      ) : (
        <>
          <div>
            <h1>Suppression du dossier &laquo;&nbsp;{name}&nbsp;&raquo;</h1>
            <p>
              Vous vous apprêtez à supprimer le dossier &laquo;&nbsp;{name}
              &nbsp;&raquo; ainsi que la totalité de ce qu'il contient.
            </p>
            <p>
              <span className="emphase">Cette action est irréversible.</span>
            </p>
            <p>
              Confirmez-vous la suppression du dossier &laquo;&nbsp;{name}
              &nbsp;&raquo; ?
            </p>
          </div>
          <div className="flex-right">
            <button
              className="bt-texte bt-bleu"
              onClick={(e) =>
                deleteFolder({
                  variables: {
                    folderId: folderId,
                  },
                })
              }
            >
              Supprimer
            </button>
            <button className="bt-texte bt-gris" onClick={() => cancel()}>
              Annuler
            </button>
          </div>
        </>
      )}
    </>
  );
}

function DeleteFolderModal({ parentId, name, folderId }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="supprimer">
      <button
        className="bt-img-rond bt-bleu bt-suppr"
        onClick={() => setModalOpen(true)}
        title="Supprimer le dossier"
      >
        <DeleteIcon />
      </button>
      {modalOpen ? (
        <div className="couverture">
          <div className="popup">
            <DeleteFolder
              parentId={parentId}
              name={name}
              folderId={folderId}
              cancel={() => setModalOpen(false)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DeleteFolderModal;
