import React, { createContext, useContext, useReducer } from "react";
import { QUESTIONNAIRE_MODES } from "../utils/Constants";

export const ACTIONS = {
  FIELD_EDITION_STARTED: "FIELD_EDITION_STARTED",
  FIELD_EDITION_COMPLETED: "FIELD_EDITION_COMPLETED",
  FIELD_EDITION_CANCELLED: "FIELD_EDITION_CANCELLED",
  PM_CREATED: "PM_CREATED",
  CREATION_COMPLETED: "CREATION_COMPLETED",
  QUESTIONNAIRE_CLOSED: "QUESTIONNAIRE_CLOSED",
  INFOS_SHOWED: "INFOS_SHOWED",
  INFOS_CLOSED: "INFOS_CLOSED",
  QUESTIONNAIRE_CANCELLED: "QUESTIONNAIRE_CANCELLED",
  SHOW_DELETE: "SHOW_DELETE",
  SHOW_DELETE_FILLED_Q: "SHOW_DELETE_FILLED_Q",
  DELETE_CANCELLED: "DELETE_CANCELLED",
  DELETE_FEATURE_COMPLETED: "DELETE_FEATURE_COMPLETED",
  FEATURE_CLICKED: "FEATURE_CLICKED",
  STYLES_CREATED: "STYLES_CREATED",
  EXPORT_STARTED: "EXPORT_STARTED",
  EXPORT_ENDED: "EXPORT_ENDED",
  ADD_CONTRIB: "ADD_CONTRIB",
  FILLED_QUESTIONNAIRE_CREATED: "FILLED_QUESTIONNAIRE_CREATED",
  FILLED_QUESTIONNAIRE_CLICKED: "FILLED_QUESTIONNAIRE_CLICKED",
  BACK_TO_FILLED_Q_LIST_CLICKED: "BACK_TO_FILLED_Q_LIST_CLICKED",
  NEW_FILLED_Q_CLICKED: "NEW_FILLED_Q_CLICKED",
  NEW_FILLED_Q_CANCELED: "NEW_FILLED_Q_CANCELED",
  DELETE_FILLED_Q_COMPLETED: "DELETE_FILLED_Q_COMPLETED",
  TASK_INFOS_CLICKED: "TASK_INFOS_CLICKED",
  IDEAS_PARTICIPATE_CLICKED: "IDEAS_PARTICIPATE_CLICKED",
  IDEAS_SHOW_CONTRIBUTIONS_CLICKED: "IDEAS_SHOW_CONTRIBUTIONS_CLICKED",
  GEOLOCATE_IDEA: "GEOLOCATE_IDEA",
  SHOW_TIPS_CLICKED: "SHOW_TIPS_CLICKED",
};

export const CollaborativeMapContext = createContext(false);
export const CollaborativeMapDispatchContext = createContext(null);

function collaborativeMapReducer(collaborativeMap, action) {
  switch (action.type) {
    case ACTIONS.FIELD_EDITION_STARTED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.EDIT,
      };
    }
    case ACTIONS.FIELD_EDITION_COMPLETED:
    case ACTIONS.FIELD_EDITION_CANCELLED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.READ,
      };
    }
    case ACTIONS.PM_CREATED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.CREATE,
      };
    }
    case ACTIONS.CREATION_COMPLETED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.CREATION_COMPLETED,
      };
    }
    case ACTIONS.QUESTIONNAIRE_CLOSED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.READ,
      };
    }
    case ACTIONS.INFOS_SHOWED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.READ,
      };
    }
    case ACTIONS.INFOS_CLOSED: {
      return {
        ...collaborativeMap,
        selectedFilledQuestionnaireId: null,
      };
    }
    case ACTIONS.QUESTIONNAIRE_CANCELLED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.READ,
      };
    }
    case ACTIONS.SHOW_DELETE: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.DELETE_FEATURE,
      };
    }
    case ACTIONS.DELETE_CANCELLED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.READ,
      };
    }
    case ACTIONS.DELETE_FEATURE_COMPLETED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.READ,
      };
    }
    case ACTIONS.FEATURE_CLICKED: {
      return {
        ...collaborativeMap,
        selectedFilledQuestionnaireId: null,
      };
    }
    case ACTIONS.STYLES_CREATED: {
      return {
        ...collaborativeMap,
        styles: action.styles,
      };
    }
    case ACTIONS.EXPORT_STARTED: {
      return {
        ...collaborativeMap,
        exportLoading: true,
      };
    }
    case ACTIONS.EXPORT_ENDED: {
      return {
        ...collaborativeMap,
        exportLoading: false,
      };
    }
    case ACTIONS.ADD_CONTRIB: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.CREATE,
      };
    }
    case ACTIONS.FILLED_QUESTIONNAIRE_CREATED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.CREATION_COMPLETED,
        createdFilledQuestionnaireId: action.filledQuestionnaireId,
      };
    }
    case ACTIONS.FILLED_QUESTIONNAIRE_CLICKED: {
      return {
        ...collaborativeMap,
        selectedFilledQuestionnaireId: action.filledQuestionnaireId,
      };
    }
    case ACTIONS.BACK_TO_FILLED_Q_LIST_CLICKED: {
      return {
        ...collaborativeMap,
        selectedFilledQuestionnaireId: null,
      };
    }
    case ACTIONS.NEW_FILLED_Q_CLICKED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.CREATE,
      };
    }
    case ACTIONS.NEW_FILLED_Q_CANCELED: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.READ,
      };
    }
    case ACTIONS.DELETE_FILLED_Q_COMPLETED: {
      return {
        ...collaborativeMap,
        selectedFilledQuestionnaireId: null,
        questionnaireMode: QUESTIONNAIRE_MODES.READ,
      };
    }
    case ACTIONS.SHOW_DELETE_FILLED_Q: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.DELETE_FILLED_Q,
      };
    }
    case ACTIONS.TASK_INFOS_CLICKED: {
      return {
        ...collaborativeMap,
        showTaskInfos: !collaborativeMap.showTaskInfos,
      };
    }
    case ACTIONS.IDEAS_PARTICIPATE_CLICKED: {
      return {
        ...collaborativeMap,
        ideasContributeDirectly: true,
        ideaFeature: action.task.ideaFeature,
        ideaLayer: action.task.ideaFeature.layer,
        questionnaireMode: QUESTIONNAIRE_MODES.CREATE,
      };
    }
    case ACTIONS.IDEAS_SHOW_CONTRIBUTIONS_CLICKED: {
      return {
        ...collaborativeMap,
        ideasShowContributions: true,
        ideaFeature: action.task.ideaFeature,
        ideaLayer: action.task.ideaFeature.layer,
      };
    }
    case ACTIONS.GEOLOCATE_IDEA: {
      return {
        ...collaborativeMap,
        questionnaireMode: QUESTIONNAIRE_MODES.READ,
      };
    }
    case ACTIONS.SHOW_TIPS_CLICKED: {
      return {
        ...collaborativeMap,
        showTips: !collaborativeMap.showTips,
      };
    }
    default: {
      throw Error("Action inconnue : " + action.type);
    }
  }
}

export function CollaborativeMapProvider({ children }) {
  const [collaborativeMap, dispatch] = useReducer(collaborativeMapReducer, {
    questionnaireMode: QUESTIONNAIRE_MODES.READ,
    styles: [],
    exportLoading: false,
    selectedFilledQuestionnaireId: null,
    showTaskInfos: false,
    ideasContributeDirectly: false,
    showTips: false,
  });

  return (
    <CollaborativeMapContext.Provider value={collaborativeMap}>
      <CollaborativeMapDispatchContext.Provider value={dispatch}>
        {children}
      </CollaborativeMapDispatchContext.Provider>
    </CollaborativeMapContext.Provider>
  );
}

export function useCollaborativeMap() {
  return useContext(CollaborativeMapContext);
}

export function useCollaborativeMapDispatch() {
  return useContext(CollaborativeMapDispatchContext);
}
