import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { Loader } from "../../../Common/components/Loader";
import {
  GET_FOLDER_AND_DOCUMENT_HIERARCHY,
  GET_FILES,
} from "../_GraphQL/queries";
import { MOVE_DOCUMENT, MOVE_FOLDER } from "../_GraphQL/mutations";
import { MoveIcon } from "../../../Common/components/Images/MoveIcon";
import openFolder from "../../../medias/images/dossier-ouvert-45x32 - fit.svg";
import closedFolder from "../../../medias/images/dossier-ferme -45x32 - fit.svg";

// Une fois le dossier ou le document déplacé, on ne ferme pas la popup car la liste
// des dossiers et documents est rafraichie et l'état du composant Move est réinitilisé

function HierarchyNode({
  folder,
  root,
  sourceParentFolderId,
  openInit,
  folderSelfChild,
  sameName,
  destFolderId,
  setDestFolderId,
  source,
  childrenFolder,
  childrenDocument,
  childrenFolderNode,
  childrenDocumentSet,
}) {
  const [open, setOpen] = useState(openInit);

  return (
    <li key={`folderNode${folder.id}`}>
      {root ? (
        <img className="racine" src={openFolder} alt="Dossier ouvert" />
      ) : (
        <button onClick={() => setOpen(!open)}>
          <span>{open ? "-" : "+"}</span>
          <img
            src={open ? openFolder : closedFolder}
            alt={open ? "Dossier ouvert" : "Dossier fermé"}
          />
        </button>
      )}
      {folder.id !== sourceParentFolderId && !folderSelfChild && !sameName ? (
        <>
          <input
            className="selectionner"
            type="radio"
            name="folderRadioGroup"
            value={folder.id}
            checked={destFolderId === folder.id}
            onChange={() => setDestFolderId(folder.id)}
          />
        </>
      ) : null}

      {source ? (
        <>
          <span className="source dossier">{folder.name}</span>
          <span className="source">
            <i> ← source</i>
          </span>
        </>
      ) : (
        <>
          <span
            className={
              destFolderId === folder.id ? "dossier selectionne" : "dossier"
            }
          >
            {folder.name}
          </span>
          {destFolderId === folder.id ? (
            <span className="destination">
              <i> ← destination</i>
            </span>
          ) : null}
        </>
      )}

      {open &&
      (childrenFolder.length !== 0 || childrenDocument.length !== 0) ? (
        <ul>
          {childrenFolderNode}
          {childrenDocumentSet}
        </ul>
      ) : null}
    </li>
  );
}

function CreateHierarchy(
  folder,
  folderSet,
  documentSet,
  destFolderId,
  setDestFolderId,
  sorter,
  sourceParentFolderId,
  sourceId,
  sourceType,
  folderSelfChild
) {
  let nodehasSource = false;
  const root = folder.parent == null;
  const source = sourceType === "folder" && folder.id === sourceId;
  nodehasSource = source;

  if (sourceType === "folder" && !folderSelfChild) {
    if (folder.id === sourceId) {
      folderSelfChild = true;
    }
  }

  let childrenFolder = folderSet.filter(
    (f) => f.parent && f.parent.id === folder.id
  );

  let sameName = false;

  // Empêcher de déplacer un dossier dans un dossier qui contient un dossier du même nom
  if (sourceType === "folder") {
    const sourceFolder = folderSet.find((f) => f.id === sourceId);
    if (childrenFolder.find((child) => child.name === sourceFolder.name)) {
      sameName = true;
    }
  }

  const childrenFolderNode = [];
  childrenFolder.sort(sorter("name", "ascending")).forEach((child) => {
    const childHierarchy = CreateHierarchy(
      child,
      folderSet,
      documentSet,
      destFolderId,
      setDestFolderId,
      sorter,
      sourceParentFolderId,
      sourceId,
      sourceType,
      folderSelfChild
    );

    if (childHierarchy.hasSource) {
      nodehasSource = true;
    }

    childrenFolderNode.push(childHierarchy.node);
  });

  const childrenDocument = documentSet.filter((d) => d.folder.id === folder.id);

  // Empêcher de déplacer un document dans un dossier qui contient un document du même nom
  if (sourceType === "document") {
    const sourceDoc = documentSet.find((d) => d.id === sourceId);
    if (childrenDocument.find((child) => child.name === sourceDoc.name)) {
      sameName = true;
    }
  }

  let docHasSource = false;
  let childrenDocumentSet = [];

  childrenDocument.sort(sorter("name", "ascending")).forEach((child) => {
    if (sourceType === "document" && sourceId === child.id) {
      docHasSource = true;
    }
    const source = sourceType === "document" && sourceId === child.id;
    childrenDocumentSet.push(
      <li
        className={source ? "doc source" : "doc"}
        key={`documentNode${child.id}`}
      >
        {child.name}
        {source ? <i> ← source</i> : null}
      </li>
    );
  });

  return {
    hasSource: nodehasSource || docHasSource,
    node: (
      <HierarchyNode
        key={`HierarchyNode-${folder.id}`}
        openInit={root || docHasSource || nodehasSource}
        folder={folder}
        root={root}
        sourceParentFolderId={sourceParentFolderId}
        folderSelfChild={folderSelfChild}
        sameName={sameName}
        destFolderId={destFolderId}
        setDestFolderId={setDestFolderId}
        source={source}
        childrenFolder={childrenFolder}
        childrenDocument={childrenDocument}
        childrenFolderNode={childrenFolderNode}
        childrenDocumentSet={childrenDocumentSet}
      />
    ),
  };
}

const MoveMutation = ({
  sourceId,
  sourceType,
  sourceName,
  closeMove,
  projectIdOrSlug,
  parentId,
  sorter,
}) => {
  const [destFolderId, setDestFolderId] = useState(null);
  const {
    loading: queryLoading,
    error: queryError,
    data,
  } = useQuery(GET_FOLDER_AND_DOCUMENT_HIERARCHY, {
    variables: { projectIdOrSlug: projectIdOrSlug },
    fetchPolicy: "network-only",
  });

  const [moveDocument, { loading: loadingD, error: errorD }] = useMutation(
    MOVE_DOCUMENT,
    {
      onError() {}, // pour éviter les unhandle rejection
      update(cache, { data: { moveDocument } }) {
        let data = cache.readQuery({
          query: GET_FILES,
          variables: { parentId },
        });
        cache.writeQuery({
          query: GET_FILES,
          variables: { parentId },
          data: {
            ...data,
            currUserDocumentSet: data.currUserDocumentSet.filter(
              (doc) => doc.id !== sourceId
            ),
          },
        });

        // Update destination folder, if query was already hit
        try {
          let data = cache.readQuery({
            query: GET_FILES,
            variables: { parentId: destFolderId },
          });
          cache.writeQuery({
            query: GET_FILES,
            variables: { parentId: destFolderId },
            data: {
              ...data,
              currUserDocumentSet: data.currUserDocumentSet.concat([
                moveDocument.document,
              ]),
            },
          });
        } catch (e) {
          /* silent, data not in cache, nothing to do, document will be updated when query hit server */
        }
      },
    }
  );

  const [moveFolder, { loading: loadingF, error: errorF }] = useMutation(
    MOVE_FOLDER,
    {
      onError() {}, // Nécessaire pour éviter les erreurs de type "Unhandled Rejection"
      update(cache, { data: { moveFolder } }) {
        let data = cache.readQuery({
          query: GET_FILES,
          variables: { parentId },
        });
        cache.writeQuery({
          query: GET_FILES,
          variables: { parentId },
          data: {
            ...data,
            currUserFolderSet: data.currUserFolderSet.filter(
              (folder) => folder.id !== sourceId
            ),
          },
        });

        // Update destination folder, if query was already hit
        try {
          let data = cache.readQuery({
            query: GET_FILES,
            variables: { parentId: destFolderId },
          });
          cache.writeQuery({
            query: GET_FILES,
            variables: { parentId: destFolderId },
            data: {
              ...data,
              currUserFolderSet: data.currUserFolderSet.concat([
                moveFolder.folder,
              ]),
            },
          });
        } catch (e) {
          /* silent, data not in cache, nothing to do, document will be updated when query hit server */
        }
      },
    }
  );

  if (loadingD || loadingF || queryLoading) {
    return <Loader />;
  }

  if (errorD || errorF || queryError) {
    return (
      <>
        <IciErrorMessage error={errorD || errorF || queryError} />
        <button className="annuler" onClick={closeMove}>
          Annuler
        </button>
      </>
    );
  }

  const rootFolder = data.currUserViewableFolderSet.find(
    (folder) => folder.parent == null
  );

  return (
    <>
      <h1>
        Déplacement du {sourceType === "document" ? "document" : "dossier"}{" "}
        &laquo;&nbsp;{sourceName}&nbsp;&raquo;
      </h1>
      <h2>Sélection du dossier destination</h2>
      {/* Présentation de l'arborescence complète */}
      <div className="arborescence">
        <ul className="racine">
          {
            CreateHierarchy(
              rootFolder,
              data.currUserViewableFolderSet,
              data.currUserViewableDocumentSet,
              destFolderId,
              setDestFolderId,
              sorter,
              parentId,
              sourceId,
              sourceType,
              false
            ).node
          }
        </ul>
      </div>
      <div className="boutons">
        <button
          className="bt-texte bt-bleu"
          disabled={destFolderId === null}
          onClick={() => {
            if (sourceType === "document") {
              moveDocument({
                variables: {
                  documentId: sourceId,
                  destFolderId: destFolderId,
                  projectIdOrSlug: projectIdOrSlug,
                },
              }).catch((e) => {
                /* Le catch sert à éviter un unhandled rejection error en cas d'erreur réseau. */
              });
            } else {
              moveFolder({
                variables: {
                  sourceFolderId: sourceId,
                  destFolderId: destFolderId,
                  projectIdOrSlug: projectIdOrSlug,
                },
              }).catch((e) => {
                /* Le catch sert à éviter un unhandled rejection error en cas d'erreur réseau. */
              });
            }
          }}
        >
          Déplacer
        </button>
        <button className="bt-texte bt-gris" onClick={closeMove}>
          Annuler
        </button>
      </div>
    </>
  );
};

function MovePopup(props) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className="deplacer">
      <button
        className="bt-img-rond bt-bleu"
        title="Déplacer"
        onClick={() => setModalOpen(true)}
      >
        <MoveIcon />
      </button>
      {modalOpen ? (
        <div className="couverture">
          <div className="popup deplacement">
            <MoveMutation closeMove={() => setModalOpen(false)} {...props} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export { MovePopup as Move };
