import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Questionnaire } from "../../../Questionnaire/components/Questionnaire";
import {
  QUESTIONNAIRE_FIELD_TYPES,
  COLLABORATIVE_MAP_PERMISSIONS,
  QUESTIONNAIRE_MODES,
  RIGHT_PANELS,
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
} from "../../../../utils/Constants";
import { GET_FEATURE } from "../../_GraphQL/queries";
import { TextField } from "../../../../Common/components/Fields/TextField";
import { RadioField } from "../../../../Common/components/Fields/RadioField";
import { SelectField } from "../../../../Common/components/Fields/SelectField";
import { NumberField } from "../../../../Common/components/Fields/NumberField";
import { CheckboxField } from "../../../../Common/components/Fields/CheckboxField";
import { DateField } from "../../../../Common/components/Fields/DateField";
import { ImageField } from "../../../../Common/components/Fields/ImageField";
import { DeleteFeature } from "./DeleteFeature";
import { Contribs } from "./Contribs";
import { QuestionnaireCompleted } from "./QuestionnaireCompleted";
import {
  ACTIONS,
  useCollaborativeMap,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";
import { DeleteFilledQButton } from "./DeleteFilledQButton";
import { DeleteFilledQuestionnaire } from "./DeleteFilledQuestionnaire";
import { getDisplayName } from "../../../../utils/utils";
import { DateTime } from "luxon";
import closeImg from "../../../../medias/images/icone-fermer.svg";

function DeleteButton({ canDeleteFeature }) {
  const collaborativeMap = useCollaborativeMap();
  const dispatch = useCollaborativeMapDispatch();

  if (
    canDeleteFeature &&
    collaborativeMap.questionnaireMode === QUESTIONNAIRE_MODES.READ
  ) {
    return (
      <div className="supprimer">
        <button
          onClick={() => {
            dispatch({ type: ACTIONS.SHOW_DELETE });
          }}
        >
          Supprimer
        </button>
      </div>
    );
  }

  return null;
}

function InfosLayout({
  title = "Informations",
  setActiveRightPanel,
  children,
}) {
  const collaborativeMap = useCollaborativeMap();
  const dispatch = useCollaborativeMapDispatch();
  return (
    <div className="infos">
      <div className="entete-panneau">
        <p>{title}</p>
        {collaborativeMap.questionnaireMode === QUESTIONNAIRE_MODES.READ &&
        setActiveRightPanel ? (
          <button
            className="bt-fermer"
            onClick={() => {
              setActiveRightPanel(RIGHT_PANELS.NONE);
              dispatch({ type: ACTIONS.INFOS_CLOSED });
            }}
          >
            <img src={closeImg} alt="Fermer" />
          </button>
        ) : null}
      </div>
      {children}
    </div>
  );
}

function buildQuestionnaireTreeAndGetRoots(questionnaire) {
  let lookup = [];

  questionnaire.questionnairefieldSet.forEach((quest) => {
    lookup.push({
      id: quest.id,
      node: { associatedObject: quest },
    });
  });

  lookup.forEach((lu) => {
    let item = lu.node;
    if (item.associatedObject.parentQuestionnaireField) {
      const parentFound = lookup.find(
        (lkp) => item.associatedObject.parentQuestionnaireField.id === lkp.id
      );
      if (!parentFound) return;
      let proposedParent = parentFound.node;
      item.parent = proposedParent;

      if (proposedParent.children) {
        proposedParent.children.push(item);
      } else {
        proposedParent.children = [item];
      }
    }
  });

  return lookup.filter((l) => l.node.parent == null);
}

function flaternQuestionnaireTree(tree, sortedFlat) {
  tree.sort((nodeA, nodeB) => {
    let orderA = parseInt(nodeA.associatedObject.order, 10);
    let orderB = parseInt(nodeB.associatedObject.order, 10);
    if (orderA < orderB) return -1;
    if (orderA === orderB) return 0;
    return 1;
  });

  tree.forEach((node) => {
    sortedFlat.push(node.associatedObject);
    if (node.children && node.children.length > 0) {
      flaternQuestionnaireTree(node.children, sortedFlat);
    }
  });
}

function initQuestionnaireRows(
  featureId,
  filledQId,
  questionnaire,
  infos,
  canEdit
) {
  let rows = [];

  // On a besoin de créer l'arbre pour trier par branche
  let tree = buildQuestionnaireTreeAndGetRoots(questionnaire);

  let flatTree = [];

  // Tri par branche
  flaternQuestionnaireTree(
    tree.map((tuple) => tuple.node),
    flatTree
  );

  flatTree.forEach((qField) => {
    let info = null;

    if (infos) {
      info = infos.find((fqf) => fqf.questionnaireField.id === qField.id);
    }

    // Si le parent n'est pas bon, on affiche rien
    if (qField.parentQuestionnaireField != null) {
      const parent = qField.parentQuestionnaireField;
      let parentType = parent.fieldType.code;
      let triggerValue;

      if (parentType === QUESTIONNAIRE_FIELD_TYPES.CHECKBOX) {
        triggerValue = qField.parentQuestionnaireFieldAnswer;
      } else {
        triggerValue = qField.parentQuestionnaireFieldLabel
          ? qField.parentQuestionnaireFieldLabel.id
          : null;
      }

      const parentValue = infos.find(
        (fqf) => fqf.questionnaireField.id === parent.id
      );

      if (parentType === QUESTIONNAIRE_FIELD_TYPES.CHECKBOX) {
        if (
          !parentValue ||
          (parentValue.checked === false && triggerValue === true) ||
          (parentValue.checked === true && triggerValue === false)
        ) {
          return null;
        }
      } else if (parentType === QUESTIONNAIRE_FIELD_TYPES.RADIO) {
        if (!parentValue) return null;
        if (triggerValue) {
          if (parentValue.questionnaireFieldLabel.id !== triggerValue) {
            return null;
          }
        }
      } else if (parentType === QUESTIONNAIRE_FIELD_TYPES.SELECT) {
        if (!parentValue) return null;
        if (triggerValue) {
          const parentValues = parentValue.text.split(",");
          if (!parentValues.includes(triggerValue)) {
            return null;
          }
        }
      }
    }

    const code = qField.fieldType.code;
    if (code === QUESTIONNAIRE_FIELD_TYPES.IMAGE) {
      rows.push(
        <ImageField
          featureId={featureId}
          qField={qField}
          filledQField={info}
          mode={QUESTIONNAIRE_MODES.READ}
          key={`info-${qField.id}`}
          canEdit={canEdit}
          filledQId={filledQId}
        />
      );
    } else if (code === QUESTIONNAIRE_FIELD_TYPES.SELECT) {
      rows.push(
        <SelectField
          featureId={featureId}
          qField={qField}
          filledQField={info}
          mode={QUESTIONNAIRE_MODES.READ}
          key={`info-${qField.id}`}
          canEdit={canEdit}
          filledQId={filledQId}
        />
      );
    } else if (code === QUESTIONNAIRE_FIELD_TYPES.TEXT) {
      rows.push(
        <TextField
          featureId={featureId}
          qField={qField}
          filledQField={info}
          mode={QUESTIONNAIRE_MODES.READ}
          key={`info-${qField.id}`}
          canEdit={canEdit}
          filledQId={filledQId}
        />
      );
    } else if (code === QUESTIONNAIRE_FIELD_TYPES.RADIO) {
      rows.push(
        <RadioField
          featureId={featureId}
          qField={qField}
          filledQField={info}
          mode={QUESTIONNAIRE_MODES.READ}
          key={`info-${qField.id}`}
          canEdit={canEdit}
          filledQId={filledQId}
        />
      );
    } else if (code === QUESTIONNAIRE_FIELD_TYPES.DECIMAL) {
      rows.push(
        <NumberField
          featureId={featureId}
          qField={qField}
          filledQField={info}
          mode={QUESTIONNAIRE_MODES.READ}
          key={`info-${qField.id}`}
          canEdit={canEdit}
          filledQId={filledQId}
        />
      );
    } else if (code === QUESTIONNAIRE_FIELD_TYPES.CHECKBOX) {
      rows.push(
        <CheckboxField
          featureId={featureId}
          qField={qField}
          filledQField={info}
          mode={QUESTIONNAIRE_MODES.READ}
          key={`info-${qField.id}`}
          canEdit={canEdit}
          filledQId={filledQId}
        />
      );
    } else if (code === QUESTIONNAIRE_FIELD_TYPES.DATE) {
      rows.push(
        <DateField
          featureId={featureId}
          qField={qField}
          filledQField={info}
          mode={QUESTIONNAIRE_MODES.READ}
          key={`info-${qField.id}`}
          canEdit={canEdit}
          filledQId={filledQId}
        />
      );
    }
  });

  return rows;
}

function FilledQuestionnaire({
  feature,
  questionnaire,
  canEdit,
  canEditOthers,
  setActiveRightPanel,
  canDeleteFeature,
  creatorVisible,
  multipleFilled,
}) {
  const context = useCollaborativeMap();
  const dispatch = useCollaborativeMapDispatch();
  let filledQ;

  let canEditFilledQ = canEdit;

  if (context.selectedFilledQuestionnaireId) {
    filledQ = feature.filledQuestionnaireSet.find(
      (filledQ) => filledQ.id === context.selectedFilledQuestionnaireId
    );
  } else {
    filledQ = feature.filledQuestionnaireSet[0];
  }

  if (filledQ && !filledQ.currentUserIsOwner) {
    canEditFilledQ = canEditOthers;
  }

  let filledQFieldSet;
  let filledQId;
  if (filledQ) {
    filledQFieldSet = filledQ.filledquestionnairefieldSet;
    filledQId = filledQ.id;
  }

  const rows = initQuestionnaireRows(
    feature.id,
    filledQId,
    questionnaire,
    filledQFieldSet,
    canEditFilledQ
  );

  return (
    <InfosLayout setActiveRightPanel={setActiveRightPanel}>
      {creatorVisible ? (
        <div>
          <div>{filledQ ? getDisplayName(filledQ.user) : null}</div>
          <div>
            {filledQ
              ? DateTime.fromISO(filledQ.creationDate).toFormat(
                  "'Le 'DD' à 'HH' h 'mm"
                )
              : null}
          </div>
        </div>
      ) : null}
      {multipleFilled ? (
        <div className="retour-contributions">
          <button
            onClick={() =>
              dispatch({ type: ACTIONS.BACK_TO_FILLED_Q_LIST_CLICKED })
            }
          >
            <span className="fleche-contrib">&larr;</span>
            <span>Retour à la liste des contributions</span>
          </button>
        </div>
      ) : null}
      {rows}
      {multipleFilled ? (
        <DeleteFilledQButton canDeleteFilledQ={canEditFilledQ} />
      ) : (
        <DeleteButton canDeleteFeature={canDeleteFeature} />
      )}
    </InfosLayout>
  );
}

function FeatureInfos({
  feature,
  canEdit,
  canEditOthers,
  canDeleteFeature,
  questionnaire,
  setActiveRightPanel,
  multipleFilled,
  creatorVisible,
  restrictionsBetweenUsers,
  userCanViewOthersInfos,
  activeLayerFromApolloQuery,
}) {
  const context = useCollaborativeMap();
  const [themes, setThemes] = useState([]);
  const [deco, setDeco] = useState();

  useEffect(() => {
    if (
      !activeLayerFromApolloQuery ||
      !activeLayerFromApolloQuery.layergeometrySet
    ) {
      return null;
    }

    const layerPoly = activeLayerFromApolloQuery.layergeometrySet.find(
      (layergeo) =>
        layergeo.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
    );

    if (!layerPoly || !layerPoly.decorator) {
      return null;
    }

    const theme =
      activeLayerFromApolloQuery.questionnaire.questionnairefieldSet.find(
        (qf) => qf.id === layerPoly.decorator.id
      );

    if (!theme) {
      return null;
    }

    setDeco(layerPoly.decorator);
    setThemes(
      theme.questionnairefieldlabelSet.map((qflabel) => ({
        ...qflabel,
        selected: true,
      }))
    );
  }, [activeLayerFromApolloQuery]);

  function toggleTheme(theme) {
    setThemes(
      themes.map((themeInState) => {
        if (themeInState.id === theme.id) {
          return {
            ...themeInState,
            selected: !themeInState.selected,
          };
        } else {
          return themeInState;
        }
      })
    );
  }

  if (multipleFilled && !context.selectedFilledQuestionnaireId) {
    return (
      <InfosLayout
        setActiveRightPanel={setActiveRightPanel}
        title="Contributions"
      >
        <Contribs
          canEdit={canEdit}
          filledQuestionnaireSet={feature.filledQuestionnaireSet}
          restrictionsBetweenUsers={restrictionsBetweenUsers}
          userCanViewOthersInfos={userCanViewOthersInfos}
          activeLayerFromApolloQuery={activeLayerFromApolloQuery}
          themes={themes}
          toggleTheme={toggleTheme}
          deco={deco}
        />
        <DeleteButton canDeleteFeature={canDeleteFeature} />
      </InfosLayout>
    );
  } else {
    return (
      <FilledQuestionnaire
        multipleFilled={multipleFilled}
        feature={feature}
        canEdit={canEdit}
        canEditOthers={canEditOthers}
        canDeleteFeature={canDeleteFeature}
        questionnaire={questionnaire}
        setActiveRightPanel={setActiveRightPanel}
        creatorVisible={creatorVisible}
      />
    );
  }
}

function FeatureInfosQuery({
  feature,
  canEdit,
  canEditOthers,
  canDeleteFeature,
  questionnaire,
  setActiveRightPanel,
  multipleFilled,
  creatorVisible,
  restrictionsBetweenUsers,
  userCanViewOthersInfos,
  activeLayerFromApolloQuery,
}) {
  const { loading, error, data } = useQuery(GET_FEATURE, {
    variables: { featureId: feature.id },
  });

  if (loading) return null;

  if (error) {
    // If permission error no popup
    // Do we show error here inside another popup ?
  }

  if (!data) return null;

  return (
    <FeatureInfos
      feature={data.feature}
      questionnaire={questionnaire}
      canEdit={canEdit}
      canEditOthers={canEditOthers}
      canDeleteFeature={canDeleteFeature}
      setActiveRightPanel={setActiveRightPanel}
      multipleFilled={multipleFilled}
      creatorVisible={creatorVisible}
      restrictionsBetweenUsers={restrictionsBetweenUsers}
      userCanViewOthersInfos={userCanViewOthersInfos}
      activeLayerFromApolloQuery={activeLayerFromApolloQuery}
    />
  );
}

function Infos({
  task,
  layer,
  feature,
  activeGeometryTypeCode,
  createdLeafletLayer,
  mode,
  cancel,
  questionnaireComplete,
  handleDeleteCompleted,
  closeQuestionnaire,
  showInfos,
  setActiveRightPanel,
  locateIdea,
  activeLayerFromApolloQuery,
}) {
  if (mode === QUESTIONNAIRE_MODES.CREATE) {
    return (
      <InfosLayout title="Questionnaire">
        <Questionnaire
          task={task}
          layer={layer}
          activeGeometryTypeCode={activeGeometryTypeCode}
          createdLeafletLayer={createdLeafletLayer}
          cancel={cancel}
          questionnaireComplete={questionnaireComplete}
          feature={feature}
        />
      </InfosLayout>
    );
  } else if (mode === QUESTIONNAIRE_MODES.CREATION_COMPLETED) {
    return (
      <InfosLayout>
        <QuestionnaireCompleted
          task={task}
          feature={feature}
          closeQuestionnaire={closeQuestionnaire}
          showInfos={showInfos}
          multipleFilled={layer.multipleFilled}
          locateIdea={locateIdea}
        />
      </InfosLayout>
    );
  } else if (mode === QUESTIONNAIRE_MODES.DELETE_FEATURE) {
    return (
      <InfosLayout title="Suppression élément">
        <DeleteFeature
          featureId={feature.id}
          geometryTypeCode={feature.geometryType.code}
          layerId={layer.id}
          handleDeleteCompleted={handleDeleteCompleted}
          taskId={task.id}
        />
      </InfosLayout>
    );
  } else if (mode === QUESTIONNAIRE_MODES.DELETE_FILLED_Q) {
    return (
      <InfosLayout title="Suppression élément">
        <DeleteFilledQuestionnaire featureId={feature.id} taskId={task.id} />
      </InfosLayout>
    );
  } else {
    if (!layer || !feature) {
      return (
        <InfosLayout setActiveRightPanel={setActiveRightPanel}>
          <p>
            Veuillez selectionner un élément pour afficher ses informations.
          </p>
        </InfosLayout>
      );
    }

    const canDeleteFeature =
      layer.editable &&
      layer.toolPermissionSet.find(
        (x) =>
          x.codename ===
          COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER + layer.id
      ) &&
      (!layer.restrictionsBetweenUsers ||
        layer.userCanDeleteOthersGeometries ||
        feature.currentUserIsOwner);

    if (!layer.questionnaire) {
      return (
        <InfosLayout setActiveRightPanel={setActiveRightPanel}>
          <p>Aucune information à afficher pour cet élément.</p>
          <DeleteButton canDeleteFeature={canDeleteFeature} />
        </InfosLayout>
      );
    }

    if (
      layer.questionnaire &&
      layer.editable &&
      layer.restrictionsBetweenUsers &&
      !layer.multipleFilled &&
      !layer.userCanViewOthersInfos &&
      !feature.currentUserIsOwner
    ) {
      return (
        <InfosLayout setActiveRightPanel={setActiveRightPanel}>
          <p className="erreur">
            Vous n'avez pas la permission de voir les informations des éléments
            que vous n'avez pas créé.
          </p>
        </InfosLayout>
      );
    }

    const canEdit =
      layer.editable &&
      layer.toolPermissionSet.some(
        (tp) =>
          tp.codename ===
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER + layer.id
      );

    const canEditOthers =
      canEdit &&
      (!layer.restrictionsBetweenUsers || layer.userCanEditOthersInfos);

    return (
      <FeatureInfosQuery
        feature={feature}
        questionnaire={layer.questionnaire}
        canEdit={canEdit}
        canEditOthers={canEditOthers}
        canDeleteFeature={canDeleteFeature}
        setActiveRightPanel={setActiveRightPanel}
        multipleFilled={layer.multipleFilled}
        creatorVisible={layer.creatorVisible}
        restrictionsBetweenUsers={layer.restrictionsBetweenUsers}
        userCanViewOthersInfos={layer.userCanViewOthersInfos}
        activeLayerFromApolloQuery={activeLayerFromApolloQuery}
      />
    );
  }
}

export { Infos };
