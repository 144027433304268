import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { Loader } from "../../../Common/components/Loader";
import { DELETE_FOLDERS_AND_DOCUMENTS } from "../_GraphQL/mutations";
import { GET_FILES } from "../_GraphQL/queries";
import { DeleteIcon } from "../../../Common/components/Images/DeleteIcon";

const DeleteFoldersAndDocumentsMutation = ({
  parentId,
  folderList,
  docList,
  setModalOpen,
}) => {
  const [
    deleteFoldersAndDocuments,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_FOLDERS_AND_DOCUMENTS, {
    onCompleted: () => setModalOpen(false),
    update: (
      cache,
      {
        data: {
          deleteFoldersAndDocuments: { currUserFolderSet, currUserDocumentSet },
        },
      }
    ) => {
      let data = cache.readQuery({
        query: GET_FILES,
        variables: {
          parentId: parentId,
        },
      });

      cache.writeQuery({
        query: GET_FILES,
        variables: {
          parentId: parentId,
        },
        data: {
          ...data,
          currUserFolderSet: currUserFolderSet,
          currUserDocumentSet: currUserDocumentSet,
        },
      });
    },
  });

  if (mutationLoading) {
    return <Loader />;
  }

  if (mutationError) {
    return (
      <>
        <IciErrorMessage error={mutationError} />
        <button className="bt-texte bt-gris" onClick={() => setModalOpen(false)}>
          Annuler
        </button>
      </>
    );
  }

  return (
    <div>
      <div>
        <h1>Attention, suppression irréversible</h1>
        <p style={{ textAlign: "left" }}>
          Vous vous apprêtez à supprimer des documents et des dossiers ainsi que
          la totalité de ce qu'ils contiennent.
        </p>
        <p style={{ textAlign: "left" }}>Cette action est irréversible.</p>
        <p style={{ textAlign: "left" }}>Confirmez-vous la suppression ?</p>
      </div>
      <div className="flex-right">
        <button
          className="bt-texte bt-bleu"
          onClick={() =>
            deleteFoldersAndDocuments({
              variables: {
                folderId: parentId,
                folderList: folderList,
                documentList: docList,
              },
            }).catch((e) => {
              /* Le catch sert à éviter un unhandled rejection error en cas d'erreur réseau. */
            })
          }
        >
          Supprimer
        </button>
        <button className="bt-texte bt-gris" onClick={() => setModalOpen(false)}>
          Annuler
        </button>
      </div>
    </div>
  );
};

const DeleteFoldersAndDocumentsModal = ({
  parentId,
  folderList,
  docList,
  disabled,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="supprimer">
      <button
        className="bt-img-rond bt-bleu  bt-suppr"
        onClick={() => setModalOpen(true)}
        disabled={disabled}
      >
        <DeleteIcon />
      </button>
      {modalOpen ? (
        <div className="couverture">
          <div className="popup">
            <DeleteFoldersAndDocumentsMutation
              parentId={parentId}
              folderList={folderList}
              docList={docList}
              setModalOpen={setModalOpen}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DeleteFoldersAndDocumentsModal;
