import React, { forwardRef, useImperativeHandle } from "react";
import { Field } from "./Common/Field";

function verifyValue(field, value) {
  if (!value) {
    if (field.required) {
      return {
        valid: false,
        error: "Ce champ est obligatoire.",
      };
    }
  } else {
    if (field.minimum && value.length < field.minimum) {
      return {
        valid: false,
        error: `Ce champ doit comporter au minimum ${field.minimum} caractères.`,
      };
    }
    if (field.maximum && value.length > field.maximum) {
      return {
        valid: false,
        error: `Ce champ doit comporter au maximum ${field.maximum} caractères.`,
      };
    }
  }

  return {
    valid: true,
  };
}

const TextInput = forwardRef(({ qField, value, setValue, setError }, ref) => {
  useImperativeHandle(ref, () => ({
    checkForErrors() {
      const verif = verifyValue(qField, value);
      if (!verif.valid) {
        setError(verif.error);
      } else {
        setError(null);
      }
      return verif;
    },
  }));

  const handleBlur = () =>
    setTimeout(() => {
      const verif = verifyValue(qField, value);
      if (verif.valid) {
        setError(null);
      } else {
        setError(verif.error);
      }
    }, 100);

  if (qField.multiline) {
    return (
      <textarea
        minLength={qField.minimum || 0}
        maxLength={qField.maximum || 3000}
        placeholder={qField.placeHolder || qField.text}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleBlur}
        required={qField.required}
        disabled={qField.readOnly}
      />
    );
  }
  return (
    <input
      placeholder={qField.placeHolder}
      value={value}
      minLength={qField.minimum || 0}
      maxLength={qField.maximum || 100}
      type="text"
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
      required={qField.required}
      disabled={qField.readOnly}
    />
  );
});

function TextField({
  featureId,
  filledQId,
  qField,
  filledQField,
  mode,
  value,
  setValue,
  inputRef,
  labelRef,
  canEdit,
}) {
  if (value === undefined || value === null) value = "";

  return (
    <Field
      featureId={featureId}
      filledQId={filledQId}
      qField={qField}
      filledQField={filledQField}
      mode={mode}
      createValue={value}
      createSetValue={setValue}
      inputRef={inputRef}
      labelRef={labelRef}
      canEdit={canEdit}
    >
      {(setError, value, setValue, ref) => {
        if (value === undefined || value === null) value = "";

        return (
          <TextInput
            qField={qField}
            value={value}
            setValue={setValue}
            setError={setError}
            ref={ref}
          />
        );
      }}
    </Field>
  );
}

export { TextField };
