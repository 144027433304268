import React from "react";

function Switch({ name, value, checked, onChange }) {
  return (
    <div className="switch">
      <input
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        className="checkbox"
      />
      <div className="knobs"></div>
      <div className="layer"></div>
    </div>
  );
}

export { Switch };
