import React from "react";

function CIconLarge({ active }) {
  const fill = active ? "#ffffff" : "#979797";
  return (
    <svg width="32.585" height="37" viewBox="0 0 32.585 37">
      <path
        id="Mask"
        d="M12.746,37c-1.83,0-3.313-1-3.313-2.231v-.934c0-1.232,1.483-2.231,3.312-2.231h9.661c.175,0,.317.1.317.213v2.951C22.724,36,21.24,37,19.411,37ZM3.192,30.448c-1.762,0-3.192-.985-3.192-2.2V8.753c0-1.215,1.429-2.2,3.192-2.2H7.718V28.247c0,1.215-1.428,2.2-3.191,2.2Zm21.676-.771V21.889c0-1.233,1.43-2.233,3.193-2.233h1.162c1.857,0,3.363,1.053,3.363,2.352v5.437c0,1.233-1.43,2.232-3.193,2.232ZM27.9,13.1c-1.676,0-3.035-.905-3.035-2.021V6.552h4.526c1.763,0,3.191.952,3.191,2.126v2.3c0,1.174-1.428,2.126-3.191,2.126ZM9.647,5.4c-.118,0-.214-.065-.214-.144V2.231C9.433,1,10.915,0,12.744,0h6.667c1.829,0,3.313,1,3.313,2.231v.934C22.724,4.4,21.24,5.4,19.411,5.4Z"
        fill={fill}
      />
    </svg>
  );
}

export { CIconLarge };
