import React, { useState } from "react";
import { SEND_SUPPORT_FORM } from "../_GraphQL/mutations";
import { Loader } from "./Loader";
import { useMutation } from "@apollo/client";
import ErrorMessage from "./ErrorMessage";

function SupportForm() {
  const [sendSupportForm, { data, loading, error, reset }] = useMutation(
    SEND_SUPPORT_FORM,
    {
      onError() {},
    }
  );
  const [message, setMessage] = useState("");

  if (loading) return <Loader />;

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendSupportForm({ variables: { message: message } });
        }}
      >
        {error ? <ErrorMessage error={error} /> : null}
        {data && data.sendSupportForm ? (
          <div className="support-message-confirmation">
            <p>Votre message a bien été envoyé !</p>
            <span
              onClick={() => {
                setMessage("");
                reset();
              }}
            >
              X
            </span>{" "}
          </div>
        ) : null}
        <div>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Votre demande..."
          />
        </div>
        <div className="boutons">
          {data && data.sendSupportForm ? null : (
            <button type="submit" className="bt-texte bt-bleu">
              Envoyer
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export { SupportForm };
