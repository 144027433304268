import React from "react";
import { Link } from "react-router-dom";
import { ApolloConsumer, gql } from "@apollo/client";
import { globalUnselectForDownload } from "../../../_GraphQL/mutations";
import { DateTime } from "luxon";
import UpdateFolder from "./UpdateFolder";
import DeleteFolder from "./DeleteFolder";
import { ManagePermissions } from "./ManagePermissions";
import iconeDossier from "../../../medias/images/icone-dossier.svg";
import { Move } from "./Move";

const FolderList = ({
  sorter,
  column,
  direction,
  data,
  parentId,
  refetch,
  projectIdOrSlug,
}) => {
  const folderSet = [...data.currUserFolderSet];

  return (
    <ApolloConsumer>
      {(client) =>
        folderSet.sort(sorter(column, direction)).map((folder, id) => {
          return (
            <div
              className={id % 2 === 0 ? "ligne pair" : "ligne impair"}
              key={folder.id}
            >
              <div className="nom">
                <Link
                  to={`/${projectIdOrSlug}/documents/${folder.id}`}
                  onClick={() => globalUnselectForDownload(client, parentId)}
                >
                  <img className="icone" src={iconeDossier} alt="" />
                  <span>{folder.name}</span>
                </Link>
              </div>
              <div className="date">
                {" "}
                {folder.creationDate === null
                  ? null
                  : DateTime.fromISO(folder.creationDate).toFormat(
                      "'Le 'DD' à 'HH'h'mm"
                    )}{" "}
              </div>
              <div className="actions">
                <div className="permissions">
                  {data.currUserCanManagePermissions ? (
                    <ManagePermissions
                      folderId={folder.id}
                      fileType="folder"
                      refetch={refetch}
                      name={folder.name}
                    />
                  ) : null}
                </div>
                <>
                  {folder.canDelete || folder.canUpdate || folder.canMove ? (
                    <>
                      {folder.canUpdate ? (
                        <UpdateFolder folderId={folder.id} name={folder.name} />
                      ) : null}
                      {folder.canDelete ? (
                        <DeleteFolder
                          folderId={folder.id}
                          parentId={parentId}
                          name={folder.name}
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
                {folder.canMove ? (
                  <Move
                    projectIdOrSlug={projectIdOrSlug}
                    sorter={sorter}
                    sourceType="folder"
                    sourceId={folder.id}
                    sourceName={folder.name}
                    parentId={parentId}
                  />
                ) : null}
              </div>
              <div className="selectionner">
                <input
                  type="checkbox"
                  checked={folder.selectedForDownload}
                  onChange={() =>
                    client.writeFragment({
                      id: `FolderType:${folder.id}`,
                      fragment: gql`
                        fragment FolderSelectedForDownload on FolderType {
                          selectedForDownload
                        }
                      `,
                      data: {
                        selectedForDownload: !folder.selectedForDownload,
                      },
                    })
                  }
                />
              </div>
            </div>
          );
        })
      }
    </ApolloConsumer>
  );
};

export default FolderList;
