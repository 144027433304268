// React
import React from "react";

// GraphQL
import { useMutation } from "@apollo/client";
import { UPDATE_FEATURE_DATA } from "../../_GraphQL/mutations";
import {
  GET_LAYER_WITH_POINT_FEATURES_LIGHT,
  GET_LAYER_WITH_POINT_FEATURES_WITH_FILLED_FOR_DECORATOR,
  GET_LAYER_WITH_LINE_FEATURES_LIGHT,
  GET_LAYER_WITH_LINE_FEATURES_WITH_FILLED_FOR_DECORATOR,
  GET_LAYER_WITH_POLY_FEATURES_LIGHT,
  GET_LAYER_WITH_POLY_FEATURES_WITH_FILLED_FOR_DECORATOR,
} from "../../../../Common/_GraphQL/queries";

// Components
import { Tools } from "./Tools";

// utils
import { addMessage } from "../../../../_GraphQL/message";
import { handleError } from "../../../../utils/ErrorSender";

import {
  COMPONENTS,
  MESSAGE_TYPE,
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
} from "../../../../utils/Constants";

function UpdateFeatureData({
  activeFeature,
  activeFeatureRef,
  activeLayer,
  activeTool,
  featureHistory,
  handleCancelClick,
  handleUpdateFeatureCompleted,
  setActiveTool,
  task,
  mapPanelHeight,
}) {
  const [updateFeatureData, { error }] = useMutation(UPDATE_FEATURE_DATA, {
    onError() {}, // Nécessaire pour éviter les erreurs de type "Unhandled Rejection"
    onCompleted() {
      handleUpdateFeatureCompleted();
    },
    update(cache, { data: { updateFeatureData } }) {
      const geometryTypeCode = activeFeature.geometryType.code;
      const lfs = activeLayer.layergeometrySet;
      const geometryType = lfs.find(
        (lf) => lf.geometryType.code === geometryTypeCode
      );

      if (geometryType) {
        let query = GET_LAYER_WITH_POINT_FEATURES_WITH_FILLED_FOR_DECORATOR;

        if (geometryTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT) {
          if (geometryType.decorator) {
            query = GET_LAYER_WITH_POINT_FEATURES_WITH_FILLED_FOR_DECORATOR;
          } else {
            query = GET_LAYER_WITH_POINT_FEATURES_LIGHT;
          }
        } else if (geometryTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE) {
          if (geometryType.decorator) {
            query = GET_LAYER_WITH_LINE_FEATURES_WITH_FILLED_FOR_DECORATOR;
          } else {
            query = GET_LAYER_WITH_LINE_FEATURES_LIGHT;
          }
        } else if (
          geometryTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
        ) {
          if (geometryType.decorator) {
            query = GET_LAYER_WITH_POLY_FEATURES_WITH_FILLED_FOR_DECORATOR;
          } else {
            query = GET_LAYER_WITH_POLY_FEATURES_LIGHT;
          }
        }

        let variables = { layerId: activeLayer.id };

        if (geometryType.decorator) {
          variables.questionnaireFieldId = geometryType.decorator.id;
        }

        const layerData = cache.readQuery({
          query: query,
          variables: variables,
        });

        if (layerData) {
          const layer = layerData.layer;
          let feat;
          let newFeat;
          let featureSet;
          let newLayer;

          if (layer.pointFeatureSet) {
            feat = layer.pointFeatureSet.find((f) => f.id === activeFeature.id);
            if (feat) {
              newFeat = {
                ...feat,
                data: updateFeatureData.feature.data,
              };

              let prov = layer.pointFeatureSet.filter(
                (f) => f.id !== activeFeature.id
              );

              featureSet = prov.concat([newFeat]);
              newLayer = {
                ...layer,
                pointFeatureSet: featureSet,
              };
              cache.writeQuery({
                query: query,
                variables: variables,
                data: { layer: newLayer },
              });
            }
          }

          if (layer.lineFeatureSet) {
            feat = layer.lineFeatureSet.find((f) => f.id === activeFeature.id);
            if (feat) {
              newFeat = {
                ...feat,
                data: updateFeatureData.feature.data,
              };

              featureSet = layer.lineFeatureSet
                .filter((f) => f.id !== activeFeature.id)
                .concat([newFeat]);
              newLayer = {
                ...layer,
                lineFeatureSet: featureSet,
              };
              cache.writeQuery({
                query: query,
                variables: variables,
                data: { layer: newLayer },
              });
            }
          }

          if (layer.polyFeatureSet) {
            feat = layer.polyFeatureSet.find((f) => f.id === activeFeature.id);
            if (feat) {
              newFeat = {
                ...feat,
                data: updateFeatureData.feature.data,
              };

              featureSet = layer.polyFeatureSet
                .filter((f) => f.id !== activeFeature.id)
                .concat([newFeat]);

              newLayer = {
                ...layer,
                polyFeatureSet: featureSet,
              };

              cache.writeQuery({
                query: query,
                variables: variables,
                data: { layer: newLayer },
              });
            }
          }
        } else {
          addMessage({
            header: "Erreur : La couche n'a pas été trouvé en cache",
            location: COMPONENTS.COMMON.INDEX,
            message:
              "La mise à jour client des données après enregistrement coté serveur a échouée. Veuillez rafraichir cette page pour voir apparaitre les changements.",
            type: MESSAGE_TYPE.NEGATIVE,
            autoClose: false,
          });
        }
      } else {
        addMessage({
          header: "Erreur : Le type de géométrie n'a pas été trouvé",
          location: COMPONENTS.COMMON.INDEX,
          message:
            "La mise à jour client des données après enregistrement coté serveur a échouée. Veuillez rafraichir cette page pour voir apparaitre les changements.",
          type: MESSAGE_TYPE.NEGATIVE,
          autoClose: false,
        });
      }
    },
  });

  function handleValidateClick() {
    // Si il n'y a pas d'activeFeatureRef, c'est que l'utilisateur a cliqué à nouveau sur
    // le bouton éditer sans avoir sélectionner un élément à éditer.
    if (activeFeatureRef) {
      const curr = activeFeatureRef.current;
      if (curr) {
        if (curr.pm.enabled()) {
          updateFeatureData({
            variables: {
              featureId: activeFeature.id,
              geoJson: JSON.stringify(curr.toGeoJSON()),
            },
          });
          curr.pm.disable();
        }
      } else {
        addMessage({
          header: "Erreur : La référence n'existe pas",
          location: COMPONENTS.COMMON.INDEX,
          message:
            "La référence React à l'élément que vous être en train de modifier n'existe pas. Veuillez remonter cette erreur.",
          type: MESSAGE_TYPE.NEGATIVE,
          autoClose: false,
        });
      }
    }
  }

  if (error) {
    handleError(error, COMPONENTS.COMMON.INDEX);
  }

  return (
    <Tools
      activeTool={activeTool}
      activeFeature={activeFeature}
      activeLayer={activeLayer}
      task={task}
      setActiveTool={setActiveTool}
      handleCancelClick={handleCancelClick}
      handleValidateClick={handleValidateClick}
      featureHistory={featureHistory}
      mapPanelHeight={mapPanelHeight}
    />
  );
}

export { UpdateFeatureData as Tools };
