import React from "react";
import L from "leaflet"; // eslint-disable-line no-unused-vars
import { Tip } from "../../../../Common/components/Tip";
import geoIcon from "../../../../medias/images/geo-localisation.svg";
import tipGeolocationImg from "../../../../medias/images/aide/tip/aide-geolocaliser.png";

function Geolocation({ lMap, disabled }) {
  return (
    <div className="geolocalisation">
      <button
        className="bt-img"
        onClick={() => {
          navigator.geolocation.getCurrentPosition(function (position) {
            lMap.setView(
              L.latLng(position.coords.latitude, position.coords.longitude),
              16
            );
          });
        }}
        disabled={disabled}
      >
        <img src={geoIcon} alt="Géolocalisation" />
      </button>

      <Tip>
        <div>
          Géolocalisation
          <br />
          <img
            className="img-ombre-flotte"
            src={tipGeolocationImg}
            alt="Bouton géolocalisation"
          />
          <br />
          permet de centre la carte sur votre position physique actuelle.
        </div>
      </Tip>
    </div>
  );
}

export { Geolocation };
