// GraphQL
import { gql } from "@apollo/client";
import { FOLDER_FRAG, DOCUMENT_FRAG } from "./fragments";
import { GROUP_PERMISSION_FRAG } from "../../../Common/_GraphQL/fragments";

export const CREATE_FOLDER = gql`
  mutation CreateFolder(
    $name: String!
    $parentId: ID
    $projectIdOrSlug: String
    $groupPermissions: [GroupPermissionType]
  ) {
    createFolder(
      name: $name
      parentId: $parentId
      projectIdOrSlug: $projectIdOrSlug
      groupPermissions: $groupPermissions
    ) {
      folder {
        ...FolderFrag
      }
    }
  }
  ${FOLDER_FRAG}
`;

export const UPDATE_FOLDER = gql`
  mutation UpdateFolder($folderId: ID!, $name: String!) {
    updateFolder(folderId: $folderId, name: $name) {
      folder {
        ...FolderFrag
      }
    }
  }
  ${FOLDER_FRAG}
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($documentId: ID!) {
    deleteDocument(documentId: $documentId) {
      document {
        id
        folder {
          id
        }
        name
      }
    }
  }
`;

export const DELETE_FOLDER = gql`
  mutation DeleteFolder($folderId: ID!) {
    deleteFolder(folderId: $folderId) {
      folder {
        id
        parent {
          id
        }
        name
        canUpdate
        canDelete
      }
    }
  }
`;

export const DELETE_FOLDERS_AND_DOCUMENTS = gql`
  mutation DeleteFoldersAndDocuments(
    $folderId: ID!
    $folderList: [ID]!
    $documentList: [ID]!
  ) {
    deleteFoldersAndDocuments(
      folderId: $folderId
      folderList: $folderList
      documentList: $documentList
    ) {
      currUserFolderSet {
        ...FolderFrag
      }
      currUserDocumentSet {
        ...DocumentFrag
      }
    }
  }
  ${FOLDER_FRAG}
  ${DOCUMENT_FRAG}
`;

export const CREATE_TEMP_TOKEN_FOR_DOCUMENT_DOWNLOAD = gql`
  mutation CreateTempTokenForDocumentDownload($documentId: ID!) {
    createTempTokenForDocumentDownload(documentId: $documentId) {
      token
    }
  }
`;

export const CREATE_TEMP_UPLOAD_TOKEN = gql`
  mutation CreateTempUploadToken {
    createTempUploadToken {
      token
    }
  }
`;

export const ADD_REMOVE_FOLDER_GROUP_PERMISSION = gql`
  mutation addRemoveFolderGroupPermission(
    $folderId: ID!
    $groupPermissionToAddSet: [GroupPermissionType]!
    $groupPermissionToRemoveSet: [GroupPermissionType]!
    $applyRecursively: Boolean!
  ) {
    addRemoveFolderGroupPermission(
      folderId: $folderId
      groupPermissionToAddSet: $groupPermissionToAddSet
      groupPermissionToRemoveSet: $groupPermissionToRemoveSet
      applyRecursively: $applyRecursively
    ) {
      folderGroupPermissionSet {
        ...GroupPermissionFrag
      }
    }
  }
  ${GROUP_PERMISSION_FRAG}
`;

export const ADD_REMOVE_DOCUMENT_GROUP_PERMISSION = gql`
  mutation addRemoveDocumentGroupPermission(
    $documentId: ID!
    $groupPermissionToAddSet: [GroupPermissionType]!
    $groupPermissionToRemoveSet: [GroupPermissionType]!
  ) {
    addRemoveDocumentGroupPermission(
      documentId: $documentId
      groupPermissionToAddSet: $groupPermissionToAddSet
      groupPermissionToRemoveSet: $groupPermissionToRemoveSet
    ) {
      documentGroupPermissionSet {
        ...GroupPermissionFrag
      }
    }
  }
  ${GROUP_PERMISSION_FRAG}
`;

export const TOGGLE_GLOBAL_SELECTED_FOR_DOWNLOAD = gql`
  mutation ToggleGlobalSelectedForDownload($parentId: ID!) {
    toggleGlobalSelectedForDownload(parentId: $parentId) @client
  }
`;

export const CREATE_TEMP_TOKEN_FOR_FILES_DOWNLOAD = gql`
  mutation CreateTempTokenForFilesDownload(
    $folderId: ID!
    $folderList: [ID]!
    $documentList: [ID]!
  ) {
    createTempTokenForFilesDownload(
      folderId: $folderId
      folderList: $folderList
      documentList: $documentList
    ) {
      token
    }
  }
`;

export const SEND_EMAIL_AFTER_FILE_UPLOAD = gql`
  mutation SendEmailAfterFileUpload(
    $projectIdOrSlug: String!
    $folderId: ID!
    $mailGroupIds: [ID]!
  ) {
    sendEmailAfterFileUpload(
      projectIdOrSlug: $projectIdOrSlug
      folderId: $folderId
      mailGroupIds: $mailGroupIds
    ) {
      success
    }
  }
`;

export const MOVE_DOCUMENT = gql`
  mutation MoveDocument(
    $documentId: ID!
    $destFolderId: ID!
    $projectIdOrSlug: String!
  ) {
    moveDocument(
      documentId: $documentId
      destFolderId: $destFolderId
      projectIdOrSlug: $projectIdOrSlug
    ) {
      document {
        ...DocumentFrag
      }
    }
  }
  ${DOCUMENT_FRAG}
`;

export const MOVE_FOLDER = gql`
  mutation MoveFolder(
    $sourceFolderId: ID!
    $destFolderId: ID!
    $projectIdOrSlug: String!
  ) {
    moveFolder(
      sourceFolderId: $sourceFolderId
      destFolderId: $destFolderId
      projectIdOrSlug: $projectIdOrSlug
    ) {
      folder {
        ...FolderFrag
      }
    }
  }
  ${FOLDER_FRAG}
`;
