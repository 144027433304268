import React, { forwardRef, useImperativeHandle } from "react";
import { Field } from "./Common/Field";

function verifyValue(field, value) {
  if (field.required) {
    if (!value) {
      return {
        valid: false,
        error: "Ce champ est obligatoire.",
      };
    }
  }

  return {
    valid: true,
  };
}

const CheckboxInput = forwardRef(
  ({ qField, value, setValue, setError }, ref) => {
    useImperativeHandle(ref, () => ({
      checkForErrors() {
        const verif = verifyValue(qField, value);
        if (!verif.valid) {
          setError(verif.error);
        } else {
          setError(null);
        }
        return verif;
      },
    }));

    const handleBlur = () =>
      setTimeout(() => {
        const verif = verifyValue(qField, value);
        if (verif.valid) {
          setError(null);
        } else {
          setError(verif.error);
        }
      }, 100);

    return (
      <input
        checked={value}
        type="checkbox"
        onChange={(e) => setValue(e.target.checked)}
        required={qField.required}
        onBlur={handleBlur}
        disabled={qField.readOnly}
      />
    );
  }
);

function CheckboxField({
  featureId,
  filledQId,
  qField,
  filledQField,
  mode,
  value,
  setValue,
  inputRef,
  labelRef,
  canEdit,
}) {
  if (value === undefined || value === null) value = "";

  return (
    <Field
      canEdit={canEdit}
      filledQId={filledQId}
      featureId={featureId}
      qField={qField}
      filledQField={filledQField}
      mode={mode}
      createValue={value}
      createSetValue={setValue}
      inputRef={inputRef}
      labelRef={labelRef}
    >
      {(setError, value, setValue, ref) => (
        <CheckboxInput
          qField={qField}
          value={value}
          setValue={setValue}
          setError={setError}
          ref={ref}
        />
      )}
    </Field>
  );
}

export { CheckboxField };
