import React, { useState } from "react";
import MessageDisplayer from "../../../Common/components/MessageDisplayer";
import ManagePermissionsQuery from "./ManagePermissionsQuery";
import { COMPONENTS } from "../../../utils/Constants";
import { LockIcon } from "../../../Common/components/Images/LockIcon";

function ManagePermissions({ folderId, documentId, fileType, refetch, name }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button
        className="bt-img-rond bt-bleu"
        title="Modifier les permissions"
        onClick={() => setModalOpen(true)}
      >
        <LockIcon />
      </button>
      {modalOpen ? (
        <div className="couverture">
          <div className="popup permissions">
            <h1>
              Gestion des permissions du{" "}
              {fileType === "document" ? "document" : "dossier"} &laquo;&nbsp;
              {name}&nbsp;&raquo;
            </h1>
            <MessageDisplayer
              location={COMPONENTS.FILEMANAGER.MANAGE_PERMISSIONS}
            />
            <ManagePermissionsQuery
              folderId={folderId}
              documentId={documentId}
              fileType={fileType}
              handleCancel={() => setModalOpen(false)}
              handleCompleted={() => setModalOpen(false)}
              refetch={refetch}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

export { ManagePermissions };
