import React from "react";
import { useMutation } from "@apollo/client";
import {
  GET_LAYER_WITH_POINT_FEATURES_LIGHT,
  GET_LAYER_WITH_LINE_FEATURES_LIGHT,
  GET_LAYER_WITH_POLY_FEATURES_LIGHT,
  GET_LAYER_WITH_POINT_FEATURES_WITH_FILLED_FOR_DECORATOR,
  GET_LAYER_WITH_LINE_FEATURES_WITH_FILLED_FOR_DECORATOR,
  GET_LAYER_WITH_POLY_FEATURES_WITH_FILLED_FOR_DECORATOR,
  USER_TASK_CONTRIB_COUNTER,
} from "../../../Common/_GraphQL/queries";
import {
  CREATE_FEATURE,
  CREATE_FILLED_QUESTIONNAIRE,
} from "../_GraphQL/mutations";
import { FEATURE_FRAG } from "../../../Common/_GraphQL/fragments";
import { GET_FEATURE } from "../../CollaborativeMap/_GraphQL/queries";
import { COLLABORATIVE_MAP_GEOMETRY_TYPES } from "../../../utils/Constants";
import { QuestionnaireForm } from "./QuestionnaireForm";
import { getIciErrorTextsFromApolloError } from "../../../Common/components/ErrorMessage";
import {
  ACTIONS,
  useCollaborativeMapDispatch,
} from "../../../Contexts/CollaborativeMapProvider";

export function updateAfterCreateFeature(cache, feature, layer, taskIdOrSlug) {
  const geometryTypeCode = feature.geometryType.code;
  let hasDecorator = false;
  const layerGeometry = layer.layergeometrySet.find(
    (lf) => lf.geometryType.code === geometryTypeCode
  );
  if (layerGeometry && layerGeometry.decorator) {
    hasDecorator = true;
  }

  let query = null;

  let variables = {
    layerId: layer.id,
  };

  if (hasDecorator) {
    variables.questionnaireFieldId = layerGeometry.decorator.id;
    query = GET_LAYER_WITH_POINT_FEATURES_WITH_FILLED_FOR_DECORATOR;
    if (geometryTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE) {
      query = GET_LAYER_WITH_LINE_FEATURES_WITH_FILLED_FOR_DECORATOR;
    } else if (geometryTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON) {
      query = GET_LAYER_WITH_POLY_FEATURES_WITH_FILLED_FOR_DECORATOR;
    }
  } else {
    query = GET_LAYER_WITH_POINT_FEATURES_LIGHT;
    if (geometryTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE) {
      query = GET_LAYER_WITH_LINE_FEATURES_LIGHT;
    } else if (geometryTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON) {
      query = GET_LAYER_WITH_POLY_FEATURES_LIGHT;
    }
  }

  const data = cache.readQuery({
    query: query,
    variables: variables,
  });

  let newData;

  if (data.layer.pointFeatureSet) {
    newData = {
      layer: {
        ...data.layer,
        pointFeatureSet: data.layer.pointFeatureSet.concat([feature]),
      },
    };
  }

  if (data.layer.lineFeatureSet) {
    newData = {
      layer: {
        ...data.layer,
        lineFeatureSet: data.layer.lineFeatureSet.concat([feature]),
      },
    };
  }

  if (data.layer.polyFeatureSet) {
    newData = {
      layer: {
        ...data.layer,
        polyFeatureSet: data.layer.polyFeatureSet.concat([feature]),
      },
    };
  }

  cache.writeQuery({
    query: query,
    variables: variables,
    data: newData,
  });

  const contribCount = cache.readQuery({
    query: USER_TASK_CONTRIB_COUNTER,
    variables: { taskIdOrSlug: taskIdOrSlug },
  });

  if (contribCount) {
    cache.writeQuery({
      query: USER_TASK_CONTRIB_COUNTER,
      variables: { taskIdOrSlug: taskIdOrSlug },
      data: {
        userTaskContribCounter: contribCount.userTaskContribCounter + 1,
      },
    });
  }
}

function CreateFilledQuestionnaire({
  fields,
  handleSubmit,
  featureId,
  taskId,
}) {
  const dispatch = useCollaborativeMapDispatch();
  const [
    createFeature,
    { loading: mutationLoading, error: mutationError, called },
  ] = useMutation(CREATE_FILLED_QUESTIONNAIRE, {
    onError() {}, // Nécessaire pour éviter les erreurs de type "Unhandled Rejection"
    update(cache, { data: { createFilledQuestionnaire } }) {
      let cacheFeature = cache.readQuery({
        query: GET_FEATURE,
        variables: {
          featureId: featureId,
        },
      });

      if (cacheFeature) {
        const { feature } = cacheFeature;

        cache.writeQuery({
          query: GET_FEATURE,
          data: {
            feature: {
              ...feature,
              filledQuestionnaireSet: [
                ...feature.filledQuestionnaireSet,
                createFilledQuestionnaire.filledQuestionnaire,
              ],
            },
          },
          variables: {
            featureId: featureId,
          },
        });
      } else {
        const feature = cache.readFragment({
          fragment: FEATURE_FRAG,
          fragmentName: "FeatureFrag",
          id: `FeatureType:${featureId}`,
        });

        if (feature) {
          cache.writeFragment({
            fragment: FEATURE_FRAG,
            fragmentName: "FeatureFrag",
            id: `FeatureType:${featureId}`,
            data: {
              ...feature,
              filledQuestionnaireSet: [
                ...feature.filledQuestionnaireSet,
                createFilledQuestionnaire.filledQuestionnaire,
              ],
            },
          });
        }
      }

      const contribCount = cache.readQuery({
        query: USER_TASK_CONTRIB_COUNTER,
        variables: { taskIdOrSlug: taskId },
      });

      if (contribCount) {
        cache.writeQuery({
          query: USER_TASK_CONTRIB_COUNTER,
          variables: { taskIdOrSlug: taskId },
          data: {
            userTaskContribCounter: contribCount.userTaskContribCounter + 1,
          },
        });
      }
    },
    onCompleted(data) {
      dispatch({
        type: ACTIONS.FILLED_QUESTIONNAIRE_CREATED,
        filledQuestionnaireId:
          data.createFilledQuestionnaire.filledQuestionnaire.id,
      });
    },
  });

  const error = getIciErrorTextsFromApolloError(mutationError);

  return (
    <QuestionnaireForm
      mutate={createFeature}
      loading={mutationLoading}
      error={error ? error.body : null}
      called={called}
      fields={fields}
      cancel={() => dispatch({ type: ACTIONS.NEW_FILLED_Q_CANCELED })}
      handleSubmit={handleSubmit}
    />
  );
}

const CreateQuestionnaire = ({
  questionnaireComplete,
  layer,
  fields,
  cancel,
  handleSubmit,
  task,
}) => {
  const [
    createFeature,
    { loading: mutationLoading, error: mutationError, called },
  ] = useMutation(CREATE_FEATURE, {
    onError() {}, // Nécessaire pour éviter les erreurs de type "Unhandled Rejection"
    update(cache, { data: { createFeature } }) {
      updateAfterCreateFeature(cache, createFeature.feature, layer, task.id);
    },
    onCompleted({ createFeature: { feature } }) {
      questionnaireComplete(feature);
    },
  });

  const error = getIciErrorTextsFromApolloError(mutationError);

  return (
    <QuestionnaireForm
      mutate={createFeature}
      loading={mutationLoading}
      error={error ? error.body : null}
      called={called}
      fields={fields}
      cancel={cancel}
      handleSubmit={handleSubmit}
    />
  );
};

export { CreateQuestionnaire, CreateFilledQuestionnaire };
