import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_FOLDER } from "../_GraphQL/mutations";
import IciErrorMessage from "../../../Common/components/ErrorMessage";
import spinner from "../../../medias/images/roue-chargement.svg";
import { EditIcon } from "../../../Common/components/Images/EditIcon";

function UpdateFolder({ folderName, folderId, close }) {
  const [name, setName] = useState(folderName);
  const [updateFolder, { loading, error }] = useMutation(UPDATE_FOLDER, {
    onError() {}, // Nécessaire pour éviter les erreurs de type "Unhandled Rejection"
    update(cache, { data: { updateFolder } }) {
      cache.modify({
        id: `FolderType:${folderId}`,
        fields: {
          nom() {
            return updateFolder.folder.nom;
          },
        },
      });
    },
    onCompleted() {
      close();
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        updateFolder({
          variables: {
            folderId: folderId,
            name: name,
          },
        });
      }}
    >
      {error ? <IciErrorMessage error={error} /> : null}
      <div>
        <input
          required={true}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="flex-right">
        {loading ? (
          <img src={spinner} alt="icône de chargement" />
        ) : (
          <>
            <button className="bt-texte bt-bleu" type="submit">
              Enregistrer
            </button>
            <button className="bt-texte bt-gris" onClick={(e) => close()}>
              Annuler
            </button>
          </>
        )}
      </div>
    </form>
  );
}

function UpdateFolderModal({ name, folderId }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="renommer">
      <button
        className="bt-img-rond bt-bleu"
        onClick={() => setModalOpen(true)}
        title="Renommer le dossier"
      >
        <EditIcon />
      </button>
      {modalOpen ? (
        <div className="couverture">
          <div className="popup">
            <h1>Renommer le dossier &laquo;&nbsp;{name}&nbsp;&raquo;</h1>
            <UpdateFolder
              folderId={folderId}
              folderName={name}
              close={() => setModalOpen(false)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default UpdateFolderModal;
