// GraphQL
import { gql } from "@apollo/client";

import { MESSAGE_FRAG } from "./fragments";

export const GET_ALL_MESSAGES = gql`
  query GetAllMessages {
    messageSet @client {
      ...MessageFrag
    }
  }
  ${MESSAGE_FRAG}
`;

export const IS_CONNECTED = gql`
  query IsConnected {
    isConnected @client
  }
`;
