import React, { createContext, useContext, useReducer } from "react";

export const FullScreenContext = createContext(false);
export const FullScreenDispatchContext = createContext(null);

function fullScreenReducer(fullScreen, action) {
  switch (action.type) {
    case "fullScreen": {
      return !fullScreen;
    }
    default: {
      throw Error("Action inconnue : " + action.type);
    }
  }
}

export function FullScreenProvider({ children }) {
  const [isFullScreen, dispatch] = useReducer(fullScreenReducer, false);

  return (
    <FullScreenContext.Provider value={isFullScreen}>
      <FullScreenDispatchContext.Provider value={dispatch}>
        {children}
      </FullScreenDispatchContext.Provider>
    </FullScreenContext.Provider>
  );
}

export function useFullScreen() {
  return useContext(FullScreenContext);
}

export function useFullScreenDispatch() {
  return useContext(FullScreenDispatchContext);
}
