import React, { forwardRef, useImperativeHandle } from "react";
import { Field } from "./Common/Field";
import { sorter } from "../../../utils/utils";

function verifyValue(field, value) {
  if (field.required) {
    if (!value) {
      return {
        valid: false,
        error: "Ce champ est obligatoire.",
      };
    }
  }

  return {
    valid: true,
  };
}

function SelectItem({ text, selected, handleClick }) {
  const className = selected ? "select-elem selected" : "select-elem";
  return (
    <div className={className} onClick={handleClick}>
      <p>{text}</p>
    </div>
  );
}

const SelectInput = forwardRef(({ qField, value, setValue, setError }, ref) => {
  useImperativeHandle(ref, () => ({
    checkForErrors() {
      const verif = verifyValue(qField, value);
      if (!verif.valid) {
        setError(verif.error);
      } else {
        setError(null);
      }
      return verif;
    },
  }));

  const handleBlur = () =>
    setTimeout(() => {
      const verif = verifyValue(qField, value);
      if (verif.valid) {
        setError(null);
      } else {
        setError(verif.error);
      }
    }, 100);
  const selectedItems = value ? value.split(",") : [];

  function handleClick(labelId) {
    if (qField.readOnly) return;

    if (qField.multiselect) {
      if (selectedItems.includes(labelId)) {
        setValue(selectedItems.filter((item) => item !== labelId).join(","));
      } else {
        setValue([labelId, ...selectedItems].join(","));
      }
    } else {
      if (selectedItems.includes(labelId)) {
        setValue("");
      } else {
        setValue(`${labelId}`);
      }
    }
  }

  return (
    <div className="element select" onBlur={handleBlur}>
      {[...qField.questionnairefieldlabelSet]
        .sort(sorter("text", "ascending"))
        .map((label) => {
          return (
            <SelectItem
              key={`${qField.id}-${label.id}`}
              text={label.text}
              selected={selectedItems.includes(label.id)}
              handleClick={() => handleClick(label.id)}
            />
          );
        })}
    </div>
  );
});

function SelectField({
  featureId,
  filledQId,
  qField,
  filledQField,
  mode,
  value,
  setValue,
  inputRef,
  labelRef,
  canEdit,
}) {
  if (value === undefined || value === null) value = "";

  return (
    <Field
      featureId={featureId}
      filledQId={filledQId}
      qField={qField}
      filledQField={filledQField}
      mode={mode}
      createValue={value}
      createSetValue={setValue}
      inputRef={inputRef}
      labelRef={labelRef}
      canEdit={canEdit}
    >
      {(setError, value, setValue, ref) => (
        <SelectInput
          qField={qField}
          value={value}
          setValue={setValue}
          setError={setError}
          ref={ref}
        />
      )}
    </Field>
  );
}

export { SelectField };
