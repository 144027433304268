import React from "react";
import Menu from "../../../Common/components/Menu";
import { useReactiveVar } from "@apollo/client";
import {
  isConnectedVar,
  menuDisabledVar,
} from "../../../_GraphQL/graphQLClient";
import { FullScreenProvider } from "../../../Contexts/FullScreenProvider";
import { Outlet } from "react-router-dom";

function SiteLayout() {
  const isConnected = useReactiveVar(isConnectedVar);
  const menuDisabled = useReactiveVar(menuDisabledVar);

  return (
    <FullScreenProvider>
      {isConnected ? <Menu menuDisabled={menuDisabled} /> : null}
      <Outlet />
    </FullScreenProvider>
  );
}

export default SiteLayout;
