import { USER_TYPES } from "./Constants";

export const buildTreeAndGetRoots = (questionnairefieldSet) => {
  let lookup = [];

  questionnairefieldSet.forEach((qf) => {
    lookup.push({
      id: qf.id,
      node: { associatedObject: qf },
    });
  });

  lookup.forEach((lu) => {
    let item = lu.node;
    if (item.associatedObject.parentQuestionnaireField) {
      let proposedParent = lookup.find(
        (lkp) => item.associatedObject.parentQuestionnaireField.id === lkp.id
      ).node;
      lu.node.parent = proposedParent;

      if (proposedParent.children) {
        proposedParent.children.push(item);
      } else {
        proposedParent.children = [item];
      }
    }
  });

  return lookup.filter((l) => l.node.parent == null).map((item) => item.node);
};

// From SO
// https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try
export function tryParseJSON(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}

  return false;
}

export const sorter = (column, direction) => (a, b) => {
  if (a[column] === b[column]) {
    return 0;
  } else if (a[column] === null) {
    return 1;
  } else if (b[column] === null) {
    return -1;
  } else if (direction === "ascending") {
    return a[column].toLowerCase().localeCompare(b[column].toLowerCase());
  } else {
    return b[column].toLowerCase().localeCompare(a[column].toLowerCase());
  }
};

export function positionSort(a, b) {
  const posA = a.position;
  const posB = b.position;
  if (posA === posB) return 0;
  if (posA === null) return 1;
  if (posB === null) return -1;
  if (posA < posB) return -1;
  return 1;
}

export function isElementOutViewport(el) {
  var rect = el.getBoundingClientRect();
  return rect.bottom > window.innerHeight;
}

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function hexToRgbA(hex, opacity) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )}, ${opacity})`;
  }
  throw new Error("Bad Hex");
}

export function getInitials(user) {
  let displayName = `${user.email[0]}`;

  if (user.profile && user.profile.nature.code === USER_TYPES.LEGAL_PERSON) {
    if (user.profile.name) {
      displayName = user.profile.name.substring(0, 2);
    }
  } else {
    if (user.firstName && user.lastName) {
      displayName = `${user.firstName[0]} ${user.lastName[0]}`;
    } else if (user.firstName && !user.lastName) {
      displayName = `${user.firstName[0]}`;
    } else if (!user.firstName && user.lastName) {
      displayName = `${user.lastName[0]}`;
    }
  }

  return displayName.toUpperCase();
}

export function getDisplayName(user) {
  let displayName = `${user.email[0]}`;

  if (user.profile && user.profile.nature.code === USER_TYPES.LEGAL_PERSON) {
    if (user.profile.name) {
      displayName = user.profile.name;
    }
  } else {
    if (user.firstName && user.lastName) {
      displayName = `${user.firstName} ${user.lastName}`;
    } else if (user.firstName && !user.lastName) {
      displayName = `${user.firstName}`;
    } else if (!user.firstName && user.lastName) {
      displayName = `${user.lastName}`;
    }
  }

  return displayName.toUpperCase();
}

export function truncateDecimals(num, digits) {
  var numS = num.toString(),
    decPos = numS.indexOf("."),
    substrLength = decPos === -1 ? numS.length : 1 + decPos + digits,
    trimmedResult = numS.substr(0, substrLength),
    finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

  return parseFloat(finalResult);
}
