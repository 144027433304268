// React
import React, { useEffect, useRef } from "react";
import L from "leaflet"; // eslint-disable-line no-unused-vars
import { Marker, Polyline, Polygon } from "react-leaflet";
import { COLLABORATIVE_MAP_GEOMETRY_TYPES } from "../../../../utils/Constants";
import {
  ACTIONS,
  useCollaborativeMap,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";

// Le composant feature reçoit son iconMarker déjà construit.
// Il n'a pas besoin des infos du questionnaire pour le construire.
// Le composant écoute le clic, les infos éventuelles sont chargées au clic.
export function Feature({
  active,
  positions,
  markerIcon,
  handleFeatureClick,
  feature,
  handlePmEdit,
  layer,
  color,
  geometryTypeCode,
  style,
}) {
  const featureRef = useRef();
  const dispatch = useCollaborativeMapDispatch();
  const collaborativeMap = useCollaborativeMap();

  useEffect(() => {
    if (featureRef.current) {
      featureRef.current.on("pm:edit", handlePmEdit);
    }

    // todo: suppr warning
    // return () => {
    //   if (featureRef.current) {
    //       featureRef.current.off("pm:edit", handlePmEdit);
    //   }
    // }
  }, [feature, featureRef, handlePmEdit]);

  useEffect(() => {
    if (featureRef.current != null && featureRef.current._path) {
      if (active) {
        featureRef.current._path.classList.add("geometrie-active");
      } else {
        featureRef.current._path.classList.remove("geometrie-active");
      }
    }
  }, [active]);

  let pathOptions = {};
  if (style) {
    pathOptions = JSON.parse(style.pathOptions);
    if (style.usePattern) {
      const styleTuple = collaborativeMap.styles.find((s) => s.id === style.id);
      if (styleTuple) {
        pathOptions = {
          ...pathOptions,
          fillPattern: styleTuple.pattern,
        };
      }
    }
  }

  switch (geometryTypeCode) {
    case COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT:
      return (
        <Marker
          ref={featureRef}
          position={positions}
          icon={markerIcon}
          eventHandlers={{
            click: () => {
              dispatch({
                type: ACTIONS.FEATURE_CLICKED,
                layer: layer,
                feature: feature,
                featureRef: featureRef,
              });
              handleFeatureClick(layer, feature, featureRef);
            },
          }}
        />
      );

    case COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE:
      return (
        <Polyline
          ref={featureRef}
          positions={positions}
          eventHandlers={{
            click: () => {
              handleFeatureClick(layer, feature, featureRef);
              dispatch({
                type: ACTIONS.FEATURE_CLICKED,
                layer: layer,
                feature: feature,
                featureRef: featureRef,
              });
            },
          }}
          color={color}
          pathOptions={pathOptions}
        />
      );

    case COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON:
      return (
        <Polygon
          ref={featureRef}
          positions={positions}
          eventHandlers={{
            click: () => {
              handleFeatureClick(layer, feature, featureRef);
              dispatch({
                type: ACTIONS.FEATURE_CLICKED,
                layer: layer,
                feature: feature,
                featureRef: featureRef,
              });
            },
          }}
          color={color}
          pathOptions={pathOptions}
        />
      );
    default:
      return null;
  }
}
