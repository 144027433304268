import { gql } from "@apollo/client";
import { FEATURE_FRAG } from "../../../Common/_GraphQL/fragments";

export const CREATE_TEMP_TOKEN_FOR_EXPORT_SHP = gql`
  mutation CreateTempTokenForExportShp($taskId: ID!) {
    createTempTokenForExportShp(taskId: $taskId) {
      token
    }
  }
`;

export const CREATE_TEMP_TOKEN_FOR_THUMBNAIL = gql`
  mutation CreateTempTokenForThumbnail($thumbnailId: ID!) {
    createTempTokenForThumbnail(thumbnailId: $thumbnailId) {
      token
    }
  }
`;

export const UPDATE_FEATURE_DATA = gql`
  mutation updateFeatureData($featureId: ID!, $geoJson: String!) {
    updateFeatureData(featureId: $featureId, data: $geoJson) {
      feature {
        id
        data
      }
    }
  }
`;

export const DELETE_FEATURE = gql`
  mutation deleteFeature($featureId: ID!) {
    deleteFeature(featureId: $featureId) {
      id
    }
  }
`;

export const GEOLOCATE_CONTRIBUTION = gql`
  mutation GeolocateContribution(
    $layerId: ID!
    $geometryTypeCode: String!
    $geoJson: String!
    $filledQuestionnaireId: ID
    $questionnaireFieldId: ID
  ) {
    geolocateContribution(
      layerId: $layerId
      geometryTypeCode: $geometryTypeCode
      data: $geoJson
      filledQuestionnaireId: $filledQuestionnaireId
    ) {
      feature {
        selectedFieldForDecorator(questionnaireFieldId: $questionnaireFieldId)
        ...FeatureFrag
      }
    }
  }
  ${FEATURE_FRAG}
`;
