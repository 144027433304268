import { Link } from "react-router-dom";
import drawLineStep1Img from "../../../medias/images/aide/tracer-ligne-etape-1-800x600.png";
import drawLineStep2Img from "../../../medias/images/aide/tracer-ligne-etape-2-800x600.png";
import drawLineStep3Img from "../../../medias/images/aide/tracer-ligne-etape-3-800x600.png";
import drawLineStep4Img from "../../../medias/images/aide/tracer-ligne-etape-4-800x600.png";
import drawLineStep5Img from "../../../medias/images/aide/tracer-ligne-etape-5-800x600.png";
import drawLineStep6Img from "../../../medias/images/aide/tracer-ligne-etape-6-800x600.png";
import drawLineStep7Img from "../../../medias/images/aide/tracer-ligne-etape-7-800x600.png";
import drawLineStep8Img from "../../../medias/images/aide/tracer-ligne-etape-8-800x600.png";
import drawLineStep9Img from "../../../medias/images/aide/tracer-ligne-etape-9-800x600.png";
import drawLineStep10Img from "../../../medias/images/aide/tracer-ligne-etape-10-800x600.png";

function HelpDrawLine() {
  return (
    <div>
      <h1>Étapes pour tracer une ligne</h1>
      <p>
        1. Naviguer sur la carte pour se positionner à l'emplacement souhaité.
        Voir <Link to="../deplacement">Se déplacer sur la carte</Link>
      </p>
      <p>2. Cliquer sur l'outil &laquo;&nbsp;Ligne&nbsp;&raquo;</p>
      <img
        className="capture-aide"
        src={drawLineStep1Img}
        alt="Tracer ligne - étape 1 - Cliquer sur l'outil ligne"
      />
      <p>3. Si une liste de thèmes est proposée, en choisir un</p>
      <img
        className="capture-aide"
        src={drawLineStep2Img}
        alt="Tracer ligne - étape 2 - Choisir un thème"
      />
      <p>4. Commencer à déssiner en cliquand sur la carte</p>
      <img
        className="capture-aide"
        src={drawLineStep3Img}
        alt="Tracer ligne - étape 3 - départ tracé"
      />
      <p>5. Continuer à placer des points</p>
      <img
        className="capture-aide"
        src={drawLineStep4Img}
        alt="Tracer ligne - étape 5 - placer des points"
      />
      <br />
      <img
        className="capture-aide"
        src={drawLineStep5Img}
        alt="Tracer ligne - étape 5 - placer des points"
      />
      <p>
        6. Une fois le tracé terminé, cliquer sur le dernier point pour le
        finaliser
      </p>
      <img
        className="capture-aide"
        src={drawLineStep6Img}
        alt="Tracer ligne - étape 6 - Fin"
      />
      <p>7. Répondre au questionnaire</p>
      <img
        className="capture-aide"
        src={drawLineStep7Img}
        alt="Tracer ligne - étape 7 - Questionnaire"
      />
      <p>8. Cliquer sur enregistrer</p>
      <img
        className="capture-aide"
        src={drawLineStep8Img}
        alt="Tracer ligne - étape 7 - Enregistrer"
      />
      <p>9. Cliquer sur fermer</p>
      <img
        className="capture-aide"
        src={drawLineStep9Img}
        alt="Tracer ligne - étape 8 - Fermer"
      />
      <p>10. Le tracé est terminé</p>
      <img
        className="capture-aide"
        src={drawLineStep10Img}
        alt="Tracer ligne - étape 8 - Fin"
      />
    </div>
  );
}

export { HelpDrawLine };
