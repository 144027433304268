import React from "react";
import { Link, Routes, Route } from "react-router-dom";
import { SupportForm } from "../components/SupportForm";
import { HelpNavigate } from "../components/Help/HelpNavigate";
import { HelpPlacePoint } from "../components/Help/HelpPlacePoint";
import { HelpMovePoint } from "../components/Help/HelpMovePoint";
import { HelpDrawLine } from "../components/Help/HelpDrawLine";
import { HelpModifyLine } from "../components/Help/HelpModifyLine";
import { HelpDrawPolygon } from "../components/Help/HelpDrawPolygon";
import { HelpModifyPolygon } from "../components/Help/HelpModifyPolygon";
import { HelpDeleteFeature } from "../components/Help/HelpDeleteFeature";
import { HelpModifyInfos } from "../components/Help/HelpModifyInfos";
import { HelpChangeBaseMap } from "../components/Help/HelpChangeBaseMap";
import { HelpLegend } from "../components/Help/HelpLegend";

import phoneImg from "../../medias/images/icone-telephone.svg";
import emailImg from "../../medias/images/icone-email.svg";
import markerImg from "../../medias/images/icon-lieux.svg";
import { LeftMenu, PAGES } from "../components/LeftMenu";

function GoBack({ children }) {
  return (
    <div className="assistance-aide">
      <Link className="bt-texte bt-bleu" to="/assistance">
        &lsaquo;&nbsp;Retour
      </Link>
      {children}
    </div>
  );
}

function HelpLink({ to, text }) {
  return (
    <Link className="elem-quest-assistance" to={to}>
      <img src={markerImg} alt="Marqueur" />
      <p>{text}</p>
    </Link>
  );
}

function HelpList() {
  return (
    <>
      <h1>Je souhaite...</h1>
      <div className="liste-quest-assistance">
        <HelpLink to="deplacement" text="...me déplacer sur la carte" />
        <HelpLink to="placer-marqueur" text="...placer un marqueur" />
        <HelpLink
          to="deplacer-marqueur"
          text="...déplacer un marqueur présent sur la carte"
        />
        <HelpLink to="tracer-ligne" text="...tracer une ligne" />
        <HelpLink
          to="modifier-ligne"
          text="...modifier le tracé d'une ligne existante sur la carte"
        />
        <HelpLink to="dessiner-surface" text="...dessiner une surface" />
        <HelpLink
          to="modifier-surface"
          text="...modifier le dessin d'une surface présent sur la carte"
        />
        <HelpLink
          to="supprimer-element"
          text="...supprimer un élément sur la carte"
        />
        <HelpLink
          to="modifier-infos"
          text="...modifier les informations d'un élément présent sur la carte"
        />
        <HelpLink to="changer-carte" text="...changer de fond de carte" />
        <HelpLink
          to="selectionner-elements"
          text="...sélectionner les éléments à afficher sur la carte"
        />
      </div>
    </>
  );
}

function HelpHome() {
  return (
    <>
      <div className="assistance-lien-aide">
        <HelpList />
      </div>
      <div className="assistance-contact">
        <p>
          Vous ne trouvez pas une réponse ou souhaitez nous faire part de
          suggestions d’amélioration ?
        </p>
        <p>
          N’hésitez pas à nous contacter par téléphone, mail ou via le
          formulaire ci-après.
        </p>
        <p>
          <img className="assistance-picto" src={phoneImg} alt="Téléphone" />
          02 40 08 03 80
        </p>
        <p>
          <img className="assistance-picto" src={emailImg} alt="Email" />
          support@realta-inno.fr
        </p>
        <SupportForm />
      </div>
    </>
  );
}

function HelpPage() {
  return (
    <GoBack>
      <Routes>
        <Route path="deplacement" element={<HelpNavigate />} />
        <Route path="placer-marqueur" element={<HelpPlacePoint />} />
        <Route path="deplacer-marqueur" element={<HelpMovePoint />} />
        <Route path="tracer-ligne" element={<HelpDrawLine />} />
        <Route path="modifier-ligne" element={<HelpModifyLine />} />
        <Route path="dessiner-surface" element={<HelpDrawPolygon />} />
        <Route path="modifier-surface" element={<HelpModifyPolygon />} />
        <Route path="supprimer-element" element={<HelpDeleteFeature />} />
        <Route path="modifier-infos" element={<HelpModifyInfos />} />
        <Route path="changer-carte" element={<HelpChangeBaseMap />} />
        <Route path="selectionner-elements" element={<HelpLegend />} />
      </Routes>
    </GoBack>
  );
}

function Help() {
  return (
    <div className="assistance page">
      <LeftMenu page={PAGES.HELP} />
      <div className="corps-page">
        <Routes>
          <Route index element={<HelpHome />} />
          <Route path="*" element={<HelpPage />} />
        </Routes>
      </div>
    </div>
  );
}

export { Help };
