import React, { useState, forwardRef, useRef } from "react";
import { ErrorField } from "./ErrorField";

const InputField = forwardRef(
  (
    {
      children,
      value,
      handleValueChange,
      addImages,
      toggleImageToDelete,
      sources,
      setSources,
    },
    ref
  ) => {
    const [error, setError] = useState(null);
    const inputField = useRef(null);

    return (
      <div ref={inputField}>
        <ErrorField error={error} />
        <div className="element">
          {children(
            setError,
            value,
            handleValueChange,
            ref,
            addImages,
            toggleImageToDelete,
            sources,
            setSources
          )}
        </div>
      </div>
    );
  }
);

export { InputField };
