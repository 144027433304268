// GraphQL
import { gql } from "@apollo/client";

export const FOLDER_FRAG = gql`
  fragment FolderFrag on FolderType {
    id
    parent {
      id
    }
    name
    canUpdate
    canDelete
    canMove
    creationDate
    selectedForDownload @client
  }
`;

export const DOCUMENT_FRAG = gql`
  fragment DocumentFrag on DocumentType {
    id
    folder {
      id
    }
    name
    active
    public
    canDelete
    canMove
    creationDate
    selectedForDownload @client
  }
`;
