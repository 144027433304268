import { Link } from "react-router-dom";
import placeMarkerStep1Img from "../../../medias/images/aide/placement-marqueur-etape-1-800x600.png";
import placeMarkerStep2Img from "../../../medias/images/aide/placement-marqueur-etape-2-800x600.png";
import placeMarkerStep3Img from "../../../medias/images/aide/placement-marqueur-etape-3-800x600.png";
import placeMarkerStep4Img from "../../../medias/images/aide/placement-marqueur-etape-4-800x600.png";
import placeMarkerStep5Img from "../../../medias/images/aide/placement-marqueur-etape-5-800x600.png";

function HelpPlacePoint() {
  return (
    <div>
      <h1>Étapes pour placer un marqueur</h1>
      <p>
        1. Naviguer sur la carte pour se positionner à l'emplacement souhaité.
        Voir <Link to="../deplacement">Se déplacer sur la carte</Link>
      </p>
      <p>
        2. Sélectionner l'outil &laquo;&nbsp;Point&nbsp;&raquo; dans le panneau
        situé sur la gauche
      </p>
      <img
        className="capture-aide"
        src={placeMarkerStep1Img}
        alt="Placer marqueur - étape 1 - sélectionner l'outil point"
      />
      <p>3. Cliquer sur la carte à l'emplacement souhaité</p>
      <img
        className="capture-aide"
        src={placeMarkerStep2Img}
        alt="Placer marqueur - étape 2 - cliquer sur la carte"
      />
      <p>
        3. Répondre au questionnaire le cas échéant et cliquer sur enregistrer
      </p>
      <img
        className="capture-aide"
        src={placeMarkerStep3Img}
        alt="Placer marqueur - étape 3 - cliquer sur la carte"
      />
      <p>4. Cliquer sur le bouton fermer</p>
      <img
        className="capture-aide"
        src={placeMarkerStep4Img}
        alt="Placer marqueur - étape 4 - fermer"
      />
      <p>5. Le marqueur est maintenant sur la carte</p>
      <img
        className="capture-aide"
        src={placeMarkerStep5Img}
        alt="Placer marqueur - étape 5 - Fin"
      />
    </div>
  );
}

export { HelpPlacePoint };
