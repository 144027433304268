import React from "react";
import spinner from "../../medias/images/roue-chargement.svg";

function Loader() {
  return (
    <div className="chargement">
      <img src={spinner} alt="Chargement en cours" />
    </div>
  );
}

export { Loader };
