import modifyInfosStep1Img from "../../../medias/images/aide/modifier-informations-etape-1-1080-810.png";
import modifyInfosStep2Img from "../../../medias/images/aide/modifier-informations-etape-2-1080-810.png";
import modifyInfosStep3Img from "../../../medias/images/aide/modifier-informations-etape-3-1080-810.png";
import modifyInfosStep4Img from "../../../medias/images/aide/modifier-informations-etape-4-1080-810.png";

function HelpModifyInfos() {
  return (
    <div>
      <h1>Étapes pour modifier les informations d'un élément</h1>
      <p>
        1. Cliquer sur l'élément , le panneau d'information s'ouvre : cliquer
        sur l'icone crayon du champ à modifier.
      </p>
      <img
        className="capture-aide"
        src={modifyInfosStep1Img}
        alt="Illustration de la sélection d'un élément."
      />
      <p>2. Apporter les modifications et valider.</p>
      <img
        className="capture-aide"
        src={modifyInfosStep2Img}
        alt="Illustration bouton édition de champ."
      />
      <p>
        3. La modification est enregistré. Cliquer sur la croix pour fermer.
      </p>
      <img
        className="capture-aide"
        src={modifyInfosStep3Img}
        alt="Illustration d'un élément supprimé."
      />
      <p>3. La panneau d'informations est fermé.</p>
      <img
        className="capture-aide"
        src={modifyInfosStep4Img}
        alt="Illustration d'un élément supprimé."
      />
    </div>
  );
}

export { HelpModifyInfos };
