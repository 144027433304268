import legendStep1Img from "../../../medias/images/aide/legende-etape-1-1080-810.png";
import legendStep2Img from "../../../medias/images/aide/legende-etape-2-1080-810.png";
import legendStep3Img from "../../../medias/images/aide/legende-etape-3-1080-810.png";
import legendStep4Img from "../../../medias/images/aide/legende-etape-4-1080-810.png";
import legendStep5Img from "../../../medias/images/aide/legende-etape-5-1080-810.png";

function HelpLegend() {
  return (
    <div>
      <h1>Étapes pour sélectionner les éléments affichés sur la carte</h1>
      <p>1. Cliquer le bouton légende sur la droite de l'écran</p>
      <img
        className="capture-aide"
        src={legendStep1Img}
        alt="Illustration du clic sur le bouton légende."
      />
      <p>
        2. Cliquer sur une icone &laquo;&nbsp;œil fermé&nbsp;&raquo; pour
        afficher des éléments masqués
      </p>
      <img
        className="capture-aide"
        src={legendStep2Img}
        alt="Illustration du clic sur un oeil fermé."
      />
      <p>
        3. Les éléments correspondants ont été affichés. Cliquer sur une icone
        &laquo;&nbsp;œil ouvert&nbsp;&raquo; pour masquer des éléments affichés
      </p>
      <img
        className="capture-aide"
        src={legendStep3Img}
        alt="Illustration de la carte avec un fond de carte différent."
      />
      <p>
        4. Les éléments correspondant ont été masqués. Cliquer à nouveau sur le
        bouton légende pour fermer le panneau
      </p>
      <img
        className="capture-aide"
        src={legendStep4Img}
        alt="Illustration du clic sur le bouton légende."
      />
      <p>5. Le panneau est maintenant fermé</p>
      <img
        className="capture-aide"
        src={legendStep5Img}
        alt="Illustration du panneau fermé."
      />
    </div>
  );
}

export { HelpLegend };
