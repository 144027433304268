import React from "react";
import { ContextBar } from "./ContextBar";
import { Zoom } from "./Zoom";
import { QuickTask } from "./QuickTask";
import { Geocoding } from "./Geocoding";
import { Geolocation } from "./Geolocation";
import { FullScreen } from "./FullScreen";
import { ToggleTips } from "./ToggleTips";
import { COLLABORATIVE_MAP_TOOLS } from "../../../../utils/Constants";

function ActionBar({
  lMap,
  activeTool,
  activeLayer,
  activeFeature,
  featureHistory,
  geocodingActive,
}) {
  const disabled = activeTool !== COLLABORATIVE_MAP_TOOLS.NONE;
  return (
    <div className="barre-action">
      <div className="remplissage"></div>
      <QuickTask disabled={disabled} />
      <ContextBar
        activeTool={activeTool}
        activeLayer={activeLayer}
        activeFeature={activeFeature}
        featureHistory={featureHistory}
      />
      {geocodingActive && lMap ? (
        <Geocoding lMap={lMap} disabled={disabled} />
      ) : null}
      <ToggleTips disabled={disabled} />
      <FullScreen disabled={disabled} />
      {lMap ? <Geolocation lMap={lMap} disabled={disabled} /> : null}
      {lMap ? <Zoom lMap={lMap} disabled={disabled} /> : null}
    </div>
  );
}

export { ActionBar };
