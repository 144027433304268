import React from "react";

function ModifyIcon({ active }) {
  const fill = active ? "#ffffff" : "#979797";
  return (
    <svg width="41.053" height="40" viewBox="0 0 41.053 40">
      <defs>
        <clipPath id="clipPathModify">
          <path
            id="Mask"
            d="M0,28V22.43L16.862,6l5.717,5.57L5.718,28ZM20.526,2.892,23.085.4a1.417,1.417,0,0,1,1.971,0l3.272,3.188a1.331,1.331,0,0,1,0,1.921L25.769,8Z"
            fill="#979797"
          />
        </clipPath>
      </defs>
      <g
        id="colors_moyen"
        data-name="colors/moyen"
        transform="translate(6.158 6)"
      >
        <g
          id="colors_moyen-2"
          data-name="colors/moyen"
          transform="translate(0)"
          clipPath="url(#clipPathModify)"
        >
          <g id="colors_moyen-3" transform="translate(-6.501 -6)">
            <rect id="Rectangle" width="41.053" height="40" fill={fill} />
          </g>
        </g>
      </g>
    </svg>
  );
}

export { ModifyIcon };
