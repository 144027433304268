import React from "react";

function FolderGroupPermissionsFormRow({
  groupPermissionsRow,
  handleCheckChange,
  even,
}) {
  return (
    <tr className={even ? "pair" : "impair"}>
      <td>{groupPermissionsRow.group.name}</td>
      <td align="center">
        <input
          type="checkbox"
          value="view_folder_"
          checked={groupPermissionsRow.canViewFolder}
          onChange={({ target: { value, checked } }) =>
            handleCheckChange(groupPermissionsRow.group, value, checked)
          }
        />
      </td>
      <td align="center">
        <input
          type="checkbox"
          value="move_folder_"
          checked={groupPermissionsRow.canMoveFolder}
          onChange={({ target: { value, checked } }) =>
            handleCheckChange(groupPermissionsRow.group, value, checked)
          }
        />
      </td>
      <td align="center">
        <input
          type="checkbox"
          value="change_folder_"
          checked={groupPermissionsRow.canChangeFolder}
          onChange={({ target: { value, checked } }) =>
            handleCheckChange(groupPermissionsRow.group, value, checked)
          }
        />
      </td>
      <td align="center">
        <input
          type="checkbox"
          value="delete_folder_"
          checked={groupPermissionsRow.canDeleteFolder}
          onChange={({ target: { value, checked } }) =>
            handleCheckChange(groupPermissionsRow.group, value, checked)
          }
        />
      </td>
      <td align="center">
        <input
          type="checkbox"
          value="add_folder_to_folder_"
          checked={groupPermissionsRow.canAddFolder}
          onChange={({ target: { value, checked } }) =>
            handleCheckChange(groupPermissionsRow.group, value, checked)
          }
        />
      </td>
      <td align="center">
        <input
          type="checkbox"
          value="add_document_to_folder_"
          checked={groupPermissionsRow.canAddDocument}
          onChange={({ target: { value, checked } }) =>
            handleCheckChange(groupPermissionsRow.group, value, checked)
          }
        />
      </td>
    </tr>
  );
}

export { FolderGroupPermissionsFormRow };
