import React from "react";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_PROJECT_BY_ID_OR_SLUG } from "../../../Common/_GraphQL/queries";
import { Loader } from "../../../Common/components/Loader";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";

function FileManager() {
  const { projectIdOrSlug } = useParams();

  const { loading, error, data } = useQuery(GET_PROJECT_BY_ID_OR_SLUG, {
    variables: {
      projectIdOrSlug: projectIdOrSlug,
    },
  });

  if (loading) return <Loader />;

  if (error)
    return (
      <IciErrorMessage
        header={"Impossible de récupérer le projet."}
        error={error}
      />
    );

  if (!data.projectByIdOrSlug.rootFolder) {
    return (
      <div>
        Ce projet n&apos;est pas configuré correctement pour le gesionnaire de
        fichiers. Il manque un dossier racine.
      </div>
    );
  }

  if (!data.projectByIdOrSlug.rootFolder.canRead) {
    return (
      <div>Vous n'êtes pas habilité à accéder aux documents de ce projet.</div>
    );
  }

  return (
    <Navigate
      to={`/${projectIdOrSlug}/documents/${data.projectByIdOrSlug.rootFolder.id}`}
      replace
    />
  );
}

export { FileManager };
