import React, { useEffect, useRef, useState } from "react";
import { useCollaborativeMap } from "../../Contexts/CollaborativeMapProvider";

function ChildPosition({ children }) {
  const [style, setStyle] = useState({});
  const ref = useRef();

  useEffect(() => {
    if (ref) {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      const domRect = ref.current.getBoundingClientRect();

      let styleLocal = {};

      if (domRect.right > vw - 10) {
        styleLocal.right = 10
      } else {
        styleLocal.left = domRect.x + 10
      }

      if (domRect.bottom > vh - 10) {
        styleLocal.bottom = 10
      } else {
        styleLocal.top = domRect.y + 10
      }

      setStyle(styleLocal);
    }
  }, [ref]);

  return (
    <div className="contenu-bulle-aide" ref={ref} style={style}>
      {children}
    </div>
  );
}

function Tip({ children }) {
  const [showThisTip, setShowThisTip] = useState(false);
  const { showTips } = useCollaborativeMap();

  if (!showTips) return null;

  const className = showThisTip ? "bulle-aide bulle-active" : "bulle-aide";
  return (
    <div className={className}>
      <div
        className="bulle-aide-click"
        onClick={() => setShowThisTip(!showThisTip)}
        onMouseEnter={() => setShowThisTip(true)}
        onMouseLeave={() => setShowThisTip(false)}
      >
        ?
        {showThisTip ? (
          <ChildPosition>{children}</ChildPosition>
        ) : null}
      </div>
    </div>
  );
}

export { Tip };
