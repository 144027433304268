// import React, { useState, useEffect } from "react";
import React from "react";
// import { Editor, EditorState, convertFromRaw } from "draft-js";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CURR_USER_TASK_SET_FROM_SLUG_OR_ID } from "../_GraphQL/queries";
import { Loader } from "../../../Common/components/Loader";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { TASK_TYPES } from "../../../utils/Constants";

// function TaskDescription({ description }) {
//   const [editorState, setEditorState] = useState(() =>
//     EditorState.createEmpty()
//   );

//   useEffect(() => {
//     try {
//       setEditorState(
//         EditorState.createWithContent(convertFromRaw(JSON.parse(description)))
//       );
//     } catch (e) {}
//   }, [description]);

//   return <Editor editorState={editorState} readOnly={true} />;
// }

function TaskSelector() {
  const { projectIdOrSlug } = useParams();
  const { loading, error, data } = useQuery(
    CURR_USER_TASK_SET_FROM_SLUG_OR_ID,
    { variables: { projectSlugOrId: projectIdOrSlug } }
  );

  if (loading) return <Loader />;

  return (
    <div className="selection">
      {error ? (
        <IciErrorMessage
          error={error}
          header="Une erreur s'est produite lors de la récupération des inventaires"
        />
      ) : null}
      {data && data.currUserTaskSetFromSlugOrId ? (
        <>
          <div>
            <h1>{data.projectByIdOrSlug.name}</h1>
            <h2>À quel inventaire souhaitez-vous contribuer&nbsp;?</h2>
          </div>
          <div className="tuiles">
            {data.currUserTaskSetFromSlugOrId
              .filter((task) => task.taskType.code === TASK_TYPES.INVENTORY)
              .map((task) => {
                // On n'utilise plus ?? car la chaine vide "" est considérée comme vrai
                let taskIdentification = task.id;
                if (task.slug) {
                  taskIdentification = task.slug;
                }
                return (
                  <Link
                    key={`select-task-item-${task.id}`}
                    className="tuile"
                    to={`${window.location.pathname}/${taskIdentification}`}
                  >
                    {/* <div className="tuile-tache">
                    <h2 className="titre-tache">{task.name}</h2>
                    <h3 className="sous-titre-tache">{task.subtitle}</h3>
                    <div className="illustration">
                      <img
                        src={`/media/tasks/${task.illustrationPath}`}
                        alt={`Illustration tâche ${task.name} manquante`}
                      />
                    </div>
                    <div className="description-tache">
                      {task.excerpt ? (
                        <TaskDescription description={task.excerpt} />
                      ) : null}
                    </div>
                  </div> */}
                    <div className="illustration">
                      {task.illustrationPath ? (
                        <img
                          src={`/media/tasks/${task.illustrationPath}`}
                          alt={`Illustration tâche ${task.name} manquante`}
                        />
                      ) : null}
                    </div>
                    <h2>{task.name}</h2>
                  </Link>
                );
              })}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default TaskSelector;
