import React from "react";
import { RIGHT_PANELS } from "../../../../utils/Constants";
import { BaseMapSelector } from "./BaseMapSelector";
import { Legend } from "./Legend";
import { Infos } from "./Infos";
import { useCollaborativeMap } from "../../../../Contexts/CollaborativeMapProvider";
import { Tip } from "../../../../Common/components/Tip";
import tipBasemapImg from "../../../../medias/images/aide/tip/aide-selection-carte.png";
import tipLegendImg from "../../../../medias/images/aide/tip/aide-legende.png";
import tipInfosImg from "../../../../medias/images/aide/tip/aide-infos.png";
import { MapsIcon } from "../../../../Common/components/Images/MapsIcon";
import { LegendIcon } from "../../../../Common/components/Images/LegendIcon";
import { CIconLarge } from "../../../../Common/components/Images/CIcon";

function RightPanels({
  activeGeometryTypeCode,
  activeRightPanel,
  baseMap,
  cadastre,
  cancel,
  closeQuestionnaire,
  createdLeafletLayer,
  feature,
  handleDeleteCompleted,
  layer,
  layerSet,
  questionnaireComplete,
  setActiveRightPanel,
  setBaseMap,
  setCadastre,
  showInfos,
  task,
  useApicarto,
  locateIdea,
  activeLayerFromApolloQuery,
}) {
  const collaborativeMap = useCollaborativeMap();
  return (
    <>
      {/* <div className="separateur-panneaux"></div> */}
      <div className="selecteurs-panneaux">
        <div className="separateur-panneaux"></div>
        <div
          className={
            activeRightPanel === RIGHT_PANELS.TILE_TYPE_SELECTOR
              ? "selecteur actif"
              : "selecteur"
          }
        >
          <button
            onClick={() => setActiveRightPanel(RIGHT_PANELS.TILE_TYPE_SELECTOR)}
          >
            <MapsIcon
              active={activeRightPanel === RIGHT_PANELS.TILE_TYPE_SELECTOR}
            />
            <span>Cartes</span>
          </button>
          <Tip>
            <div>
              Cartes
              <br />
              <img
                className="img-ombre-flotte"
                src={tipBasemapImg}
                alt="Sélecteur de cartes"
              />
              <br />
              permet de sélectionner parmi trois fonds de carte :
              <ul>
                <li>Satellite</li>
                <li>Rues</li>
                <li>Topographique</li>
              </ul>
            </div>
          </Tip>
        </div>
        <div
          className={
            activeRightPanel === RIGHT_PANELS.LEGEND
              ? "selecteur actif"
              : "selecteur"
          }
        >
          <button onClick={() => setActiveRightPanel(RIGHT_PANELS.LEGEND)}>
            <LegendIcon active={activeRightPanel === RIGHT_PANELS.LEGEND} />
            <span>Légende</span>
          </button>
          <Tip>
            <div>
              Légende
              <br />
              <img
                className="img-ombre-flotte"
                src={tipLegendImg}
                alt="Légende"
              />
              <br />
              permet de :
              <ul>
                <li>Détailler la signification des symboles sur la carte</li>
                <li>Choisir les éléments à afficher et à masquer</li>
              </ul>
            </div>
          </Tip>
        </div>
        <div
          className={
            activeRightPanel === RIGHT_PANELS.INFOS
              ? "selecteur actif"
              : "selecteur"
          }
        >
          <button onClick={() => setActiveRightPanel(RIGHT_PANELS.INFOS)}>
            <CIconLarge active={activeRightPanel === RIGHT_PANELS.INFOS} />
            <span>Infos</span>
          </button>
          <Tip>
            <div>
              Infos
              <br />
              <img className="img-ombre-flotte" src={tipInfosImg} alt="Infos" />
              <br /> Permet :
              <ul>
                <li>
                  d'afficher les informations liées à l'élément sélectionné sur
                  la carte
                </li>
                <li>de modifier ces informations</li>
                <li>de supprimer l'élément</li>
              </ul>
            </div>
          </Tip>
        </div>
      </div>
      {activeRightPanel === RIGHT_PANELS.NONE ? null : (
        <div className="panneaux">
          <div className="separateur-panneaux"></div>
          {activeRightPanel === RIGHT_PANELS.LEGEND ? (
            <Legend
              useApicarto={useApicarto}
              cadastre={cadastre}
              setCadastre={setCadastre}
              layerSet={layerSet}
            />
          ) : null}
          {activeRightPanel === RIGHT_PANELS.TILE_TYPE_SELECTOR ? (
            <BaseMapSelector baseMap={baseMap} setBaseMap={setBaseMap} />
          ) : null}
          {activeRightPanel === RIGHT_PANELS.INFOS ? (
            <Infos
              task={task}
              layer={layer}
              feature={feature}
              activeGeometryTypeCode={activeGeometryTypeCode}
              createdLeafletLayer={createdLeafletLayer}
              mode={collaborativeMap.questionnaireMode}
              cancel={cancel}
              questionnaireComplete={questionnaireComplete}
              handleDeleteCompleted={handleDeleteCompleted}
              closeQuestionnaire={closeQuestionnaire}
              showInfos={showInfos}
              setActiveRightPanel={setActiveRightPanel}
              locateIdea={locateIdea}
              activeLayerFromApolloQuery={activeLayerFromApolloQuery}
            />
          ) : null}
        </div>
      )}
    </>
  );
}

export { RightPanels };
