import React from "react";
import { BASE_MAPS } from "../../../../utils/Constants";
import satelliteTile from "../../../../medias/images/carte/miniature-carte-satellite.png";
import streetsTile from "../../../../medias/images/carte/miniature-carte-rue.png";
import topoTile from "../../../../medias/images/carte/miniature-carte-topographique.png";
import eye from "../../../../medias/images/icone-oeil-ouvert.svg";
import closedEye from "../../../../medias/images/icone-oeil-ferme.svg";

function BaseMap({ baseMap, type, setBaseMap, tile, name }) {
  return (
    <div
      className={
        baseMap === type ? "selecteur-carte active" : "selecteur-carte"
      }
      onClick={() => setBaseMap(type)}
    >
      <img
        className="oeil"
        alt="oeil"
        src={baseMap === type ? eye : closedEye}
      />
      <img className="type-carte" alt="Miniature satellite" src={tile} />
      <p>{name}</p>
    </div>
  );
}

function BaseMapSelector({ baseMap, setBaseMap }) {
  return (
    <div className="selecteurs-carte">
      <div className="entete-panneau">
        <p>Cartes</p>
      </div>
      <BaseMap
        baseMap={baseMap}
        type={BASE_MAPS.SATELLITE}
        name="Satellite"
        setBaseMap={setBaseMap}
        tile={satelliteTile}
      />
      <BaseMap
        baseMap={baseMap}
        type={BASE_MAPS.STREETS}
        name="Rues"
        setBaseMap={setBaseMap}
        tile={streetsTile}
      />
      <BaseMap
        baseMap={baseMap}
        type={BASE_MAPS.TOPOGRAPHIC}
        name="Topographique"
        setBaseMap={setBaseMap}
        tile={topoTile}
      />
    </div>
  );
}

export { BaseMapSelector };
