import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { DELETE_DOCUMENT } from "../_GraphQL/mutations";
import { GET_FILES } from "../_GraphQL/queries";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { DeleteIcon } from "../../../Common/components/Images/DeleteIcon";
import { Loader } from "../../../Common/components/Loader";

function DeleteFile({ parentId, documentName, documentId, cancel }) {
  const [deleteDocument, { loading, error }] = useMutation(DELETE_DOCUMENT, {
    onError() {}, // Nécessaire pour éviter les erreurs de type "Unhandled Rejection"
    update(cache, { data: { deleteDocument } }) {
      let data = cache.readQuery({
        query: GET_FILES,
        variables: {
          parentId: parentId,
        },
      });
      cache.writeQuery({
        query: GET_FILES,
        variables: { parentId: parentId },
        data: {
          ...data,
          currUserDocumentSet: data.currUserDocumentSet.filter(
            (f) => f.id !== deleteDocument.document.id
          ),
        },
      });
    },
  });

  return (
    <>
      {error ? <IciErrorMessage error={error} /> : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <p>
            Confirmez-vous la suppression du document &laquo;&nbsp;
            {documentName}&nbsp;&raquo; ?
          </p>
          <div className="flex-right">
            <button
              className="bt-texte bt-bleu"
              onClick={() =>
                deleteDocument({
                  variables: {
                    documentId: documentId,
                  },
                })
              }
            >
              Supprimer
            </button>
            <button className="bt-texte bt-gris" onClick={() => cancel()}>
              Annuler
            </button>
          </div>
        </>
      )}
    </>
  );
}

function DeleteFileModal({ documentName, documentId, parentId }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="supprimer">
      <button
        className="bt-img-rond bt-bleu bt-suppr"
        title="Supprimer le document"
        onClick={() => setModalOpen(true)}
      >
        <DeleteIcon />
      </button>
      {modalOpen ? (
        <div className="couverture">
          <div className="popup">
            <DeleteFile
              parentId={parentId}
              documentId={documentId}
              documentName={documentName}
              cancel={() => setModalOpen(false)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export { DeleteFileModal as DeleteFile };
