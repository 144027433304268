import React from "react";
import { SvgPolyBasePattern } from "./SvgPolyBasePattern";

function SvgPolyStripesPattern({ styleId, pathOptions, patternOptions }) {
  const d = `M0 ${patternOptions.weight / 2} H 8`;
  const dSpace = `M0 ${
    patternOptions.spaceWeight / 2 + patternOptions.weight
  } H 8`;
  patternOptions.width = 8;
  return (
    <SvgPolyBasePattern
      styleId={styleId}
      pathOptions={pathOptions}
      patternOptions={patternOptions}
    >
      <>
        <path
          stroke={patternOptions.color}
          strokeOpacity={patternOptions.opacity}
          strokeWidth={patternOptions.weight}
          fill="none"
          pointerEvents="none"
          d={d}
        ></path>
        <path
          stroke={patternOptions.spaceColor}
          strokeOpacity={patternOptions.spaceOpacity}
          strokeWidth={patternOptions.spaceWeight}
          fill="none"
          pointerEvents="none"
          d={dSpace}
        ></path>
      </>
    </SvgPolyBasePattern>
  );
}

export { SvgPolyStripesPattern };
