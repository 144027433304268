import { Link } from "react-router-dom";
import drawPolygonStep1Img from "../../../medias/images/aide/dessiner-polygone-etape-1-1080x810.png";
import drawPolygonStep2Img from "../../../medias/images/aide/dessiner-polygone-etape-2-1080x810.png";
import drawPolygonStep3Img from "../../../medias/images/aide/dessiner-polygone-etape-3-1080x810.png";
import drawPolygonStep4Img from "../../../medias/images/aide/dessiner-polygone-etape-4-1080x810.png";
import drawPolygonStep5Img from "../../../medias/images/aide/dessiner-polygone-etape-5-1080x810.png";
import drawPolygonStep6Img from "../../../medias/images/aide/dessiner-polygone-etape-6-1080x810.png";
import drawPolygonStep7Img from "../../../medias/images/aide/dessiner-polygone-etape-7-1080x810.png";

function HelpDrawPolygon() {
  return (
    <div>
      <h1>Étapes pour dessiner une surface</h1>
      <p>
        1. Naviguer sur la carte pour se positionner à l'emplacement souhaité.
        Voir <Link to="../deplacement">Se déplacer sur la carte</Link>
      </p>
      <p>2. Cliquer sur l'outil &laquo;&nbsp;Surface&nbsp;&raquo;</p>
      <img
        className="capture-aide"
        src={drawPolygonStep1Img}
        alt="Illustration de la sélection de l'outil surface."
      />
      <p>3. Commencer à déssiner en cliquant sur la carte</p>
      <img
        className="capture-aide"
        src={drawPolygonStep2Img}
        alt="Illustration du début d'un dessin de surface."
      />
      <p>4. Continuer à placer des points</p>
      <img
        className="capture-aide"
        src={drawPolygonStep3Img}
        alt="Illustration d'un dessin de surface en cours."
      />
      <p>5. Pour finaliser le dessin, cliquer sur le premier point placé</p>
      <img
        className="capture-aide"
        src={drawPolygonStep4Img}
        alt="Illustration de la fin d'un dessin de surface."
      />
      <p>6. Répondre au questionnaire et enregistrer</p>
      <img
        className="capture-aide"
        src={drawPolygonStep5Img}
        alt="Illustration d'un questionnaire ouvert."
      />
      <p>7. Cliquer sur fermer</p>
      <img
        className="capture-aide"
        src={drawPolygonStep6Img}
        alt="Illustration du message d'enregistrement d'un questionnaire."
      />
      <p>8. Le polygone est terminé</p>
      <img
        className="capture-aide"
        src={drawPolygonStep7Img}
        alt="Illustration d'un dessin de surface terminé."
      />
    </div>
  );
}

export { HelpDrawPolygon };
