import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";
import editIcon from "../../../../medias/images/icon-map.svg";

import { useOutsideAlerter } from "../../../../utils/useOutsideAlerter";
import {
  ACTIONS,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";

function ChildPosition({ children }) {
  const [style, setStyle] = useState({});
  const ref = useRef();

  useEffect(() => {
    if (ref) {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      const domRect = ref.current.getBoundingClientRect();
      let styleLocal = {};

      if (domRect.right > vw - 10) {
        styleLocal.right = 10;
      } else {
        styleLocal.left = domRect.x + 10;
      }

      if (domRect.bottom > vh - 10) {
        styleLocal.bottom = 10;
      } else {
        styleLocal.top = domRect.y + 10;
      }

      setStyle(styleLocal);
    }
  }, [ref]);

  return (
    <div className="texte-aide-question" ref={ref} style={style}>
      {children}
    </div>
  );
}

const LabelField = forwardRef(
  ({ text, helptext, edit, handleEditClick, optional }, ref) => {
    const dispatch = useCollaborativeMapDispatch();
    const [showHelp, setShowHelp] = useState(false);
    const lblRef = useRef();
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowHelp(false));

    useImperativeHandle(ref, () => ({
      focus() {
        lblRef.current.scrollIntoView({ behavior: "smooth" });
      },
    }));

    return (
      <>
        <div className="entete" ref={lblRef}>
          <p>
            {text}
            {optional ? <span className="optionnel"> (Optionnel)</span> : null}
          </p>
          {helptext ? (
            <>
              <div
                onClick={() => setShowHelp(!showHelp)}
                className="aide-question"
                title={helptext}
                ref={wrapperRef}
              >
                ?{showHelp ? <ChildPosition>{helptext}</ChildPosition> : null}
              </div>
            </>
          ) : null}

          <div className="trait">
            <div className="espace"></div>
            <div className="plein"></div>
          </div>
          {edit ? (
            <div className="edit">
              <button
                onClick={() => {
                  handleEditClick();
                  dispatch({ type: ACTIONS.FIELD_EDITION_STARTED });
                }}
              >
                <img src={editIcon} alt="edit" />
              </button>
            </div>
          ) : null}
        </div>
      </>
    );
  }
);

export { LabelField };
