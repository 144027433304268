import React from "react";
import {
  ACTIONS,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";
import { TASK_TYPES } from "../../../../utils/Constants";

function QuestionnaireCompleted({
  closeQuestionnaire,
  showInfos,
  multipleFilled,
  locateIdea,
  task,
  feature,
}) {
  const dispatch = useCollaborativeMapDispatch();

  return (
    <>
      <p>Merci d'avoir contribué !</p>
      <p>Que souhaitez-vous faire maintenant ?</p>
      <div className="separateur-boutons">
        {/* Doit être une tâhce de type idée et ne doit pas déjà être localisé : être rattaché à la feature par défaut */}
        {task.taskType.code === TASK_TYPES.IDEAS && feature.id === task.ideaFeature.id ?
          <button className="bt-texte bt-gris"
            onClick={() => locateIdea()}>
            Géolocaliser mon idée
          </button>
          : null}
        <button className="bt-texte bt-gris" onClick={closeQuestionnaire}>
          Fermer
        </button>
        <button className="bt-texte bt-gris" onClick={showInfos}>
          Voir les informations
        </button>
        {multipleFilled ? (
          <button
            className="bt-texte bt-gris"
            onClick={() => dispatch({ type: ACTIONS.ADD_CONTRIB })}
          >
            Ajouter une nouvelle contribution
          </button>
        ) : null}
      </div>
    </>
  );
}

export { QuestionnaireCompleted };
