import React from "react";
import { SvgPolyBasePattern } from "./SvgPolyBasePattern";

function SvgPolyCirclePattern({
  styleId,
  pathOptions,
  patternOptions,
  shapeOptions,
}) {
  return (
    <SvgPolyBasePattern
      styleId={styleId}
      pathOptions={pathOptions}
      patternOptions={patternOptions}
    >
      <circle
        stroke={shapeOptions.color ?? "#5ed573"}
        strokeOpacity={shapeOptions.opacity ?? "1"}
        strokeWidth={shapeOptions.weight ?? "1"}
        strokeDasharray={shapeOptions.dashArray ?? "1"}
        fill={shapeOptions.fill ? shapeOptions.fillColor : "none"}
        fillOpacity={shapeOptions.fillOpacity ?? null}
        cx={shapeOptions.x ?? 0}
        cy={shapeOptions.y ?? 0}
        r={shapeOptions.radius ?? 0}
      ></circle>
    </SvgPolyBasePattern>
  );
}

export { SvgPolyCirclePattern };
