import React from "react";

function DeleteIcon() {
  return (
    <svg
      id="icontoolbox_trash"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="35"
      height="35"
      viewBox="0 0 44.8 40"
    >
      <defs>
        <clipPath id="delete-clip-path">
          <path
            id="Mask"
            d="M19.631,29.23H5.353a3.424,3.424,0,0,1-3.569-3.248V6.5H23.2V25.982A3.423,3.423,0,0,1,19.631,29.23Zm-7.138-9.077h0L16.275,23.6l2.516-2.29-3.783-3.443,3.783-3.443-2.516-2.29-3.783,3.443-3.8-3.443L6.174,14.42l3.8,3.443L6.192,21.306,8.708,23.6l3.784-3.441ZM24.984,4.872H0V1.624H6.247L8.031,0h8.923l1.785,1.624h6.246V4.871Z"
            transform="translate(0 0)"
          />
        </clipPath>
      </defs>
      <g id="colors_noir" transform="translate(9.943 5.885)">
        <path
          id="Mask-2"
          d="M19.631,29.23H5.353a3.424,3.424,0,0,1-3.569-3.248V6.5H23.2V25.982A3.423,3.423,0,0,1,19.631,29.23Zm-7.138-9.077h0L16.275,23.6l2.516-2.29-3.783-3.443,3.783-3.443-2.516-2.29-3.783,3.443-3.8-3.443L6.174,14.42l3.8,3.443L6.192,21.306,8.708,23.6l3.784-3.441ZM24.984,4.872H0V1.624H6.247L8.031,0h8.923l1.785,1.624h6.246V4.871Z"
          transform="translate(0 0)"
        />
        <g id="colors_noir-2" clipPath="url(#delete-clip-path)">
          <g id="colors_noir-3" transform="translate(-9.943 -5.654)">
            <rect id="Rectangle" width="44.8" height="40" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export { DeleteIcon };
