import React from "react";

function Body({ color, children }) {
  return (
    <svg
      id="icon_lieux"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Mask"
            d="M18.345,45.094a2.325,2.325,0,0,1-1.524-.567A71.687,71.687,0,0,1,4.208,30.688,29.376,29.376,0,0,1,1.052,24.5,17.171,17.171,0,0,1,0,18.791a19.862,19.862,0,0,1,1.511-7.751A17.812,17.812,0,0,1,5.557,5.116a18,18,0,0,1,5.851-3.785,18.677,18.677,0,0,1,13.85,0,18,18,0,0,1,5.851,3.785,17.812,17.812,0,0,1,4.046,5.925,19.863,19.863,0,0,1,1.511,7.751A17.179,17.179,0,0,1,35.614,24.5a29.409,29.409,0,0,1-3.153,6.19A71.782,71.782,0,0,1,19.869,44.527,2.323,2.323,0,0,1,18.345,45.094ZM18.334,9.167a8.25,8.25,0,1,0,8.25,8.25A8.259,8.259,0,0,0,18.334,9.167Z"
            transform="translate(0 0)"
            stroke="rgba(0,0,0,0.39)"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g id="colors_bordeaux" transform="translate(9.167 4.583)">
        <path
          id="Mask-2"
          d="M18.345,45.094a2.325,2.325,0,0,1-1.524-.567A71.687,71.687,0,0,1,4.208,30.688,29.376,29.376,0,0,1,1.052,24.5,17.171,17.171,0,0,1,0,18.791a19.862,19.862,0,0,1,1.511-7.751A17.812,17.812,0,0,1,5.557,5.116a18,18,0,0,1,5.851-3.785,18.677,18.677,0,0,1,13.85,0,18,18,0,0,1,5.851,3.785,17.812,17.812,0,0,1,4.046,5.925,19.863,19.863,0,0,1,1.511,7.751A17.179,17.179,0,0,1,35.614,24.5a29.409,29.409,0,0,1-3.153,6.19A71.782,71.782,0,0,1,19.869,44.527,2.323,2.323,0,0,1,18.345,45.094ZM18.334,9.167a8.25,8.25,0,1,0,8.25,8.25A8.259,8.259,0,0,0,18.334,9.167Z"
          transform="translate(0 0)"
          stroke="rgba(255,255,255,1)"
          strokeMiterlimit="10"
          strokeWidth="7"
        />
        <g id="colors_bordeaux-2" clipPath="url(#clip-path)">
          <rect id="Rectangle" width="55" height="55" fill={color} />
        </g>
        <circle cx="18.5" cy="19" r="13" fill="#ffffff" />
        {children}
      </g>
    </svg>
  );
}

function Picto({ icon }) {
  return (
    <image
      x="9.5"
      y="9.5"
      width="18"
      height="18"
      href={`/static/back/resources/media/markers/${icon}`}
    />
  );
}

function Marker({ color, icon }) {
  return (
    <Body color={color ?? "#3388ff"}>
      {icon ? <Picto icon={icon} /> : null}
    </Body>
  );
}

export { Marker };
