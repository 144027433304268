import React, { useState } from "react";
import { IciErrorMessage } from "../../../Common/components/ErrorMessage";
import { AnimatedError } from "../../../Common/components/Errors/AnimatedError";

function PasswordForm({
  toggleMode,
  updatePassword,
  error,
  editMode,
  loading,
}) {
  const [formCheckError, setFormCheckError] = useState(null);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (password !== repeatPassword) {
          setFormCheckError("Les mots de passe ne correspondent pas.");
          return;
        }
        updatePassword({
          variables: {
            password: password,
          },
        });
      }}
    >
      {error ? <IciErrorMessage error={error} /> : null}
      <AnimatedError error={formCheckError} />
      <div className="champs-form">
        <div className="champ">
          <label className="label">Nouveau mot de passe</label>
          <input
            autoFocus
            className="rond gris"
            required
            type="password"
            disabled={!editMode}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="champ">
          <label className="label">Confirmation du nouveau mot de passe</label>
          <input
            className="rond gris"
            required
            type="password"
            disabled={!editMode}
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="boutons">
        {loading ? (
          <p>Chargement...</p>
        ) : (
          <div>
            <button className="annuler" onClick={() => toggleMode()}>
              Annuler
            </button>
            <button className="valider" type="submit">
              Enregistrer
            </button>
          </div>
        )}
      </div>
    </form>
  );
}

export { PasswordForm };
