import React, { useEffect } from "react";
import { gql, ApolloConsumer, useQuery, useReactiveVar } from "@apollo/client";
import openEyeImg from "../../../../medias/images/icone-oeil-ouvert.svg";
import closedEyeImg from "../../../../medias/images/icone-oeil-ferme.svg";
import {
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  DEFAULT_MARKER_SYMBOL,
  LOCAL_ONLY_FIELD_INIT,
} from "../../../../utils/Constants";
import { Preview } from "./Preview/Preview";
import { Marker } from "./Preview/Marker";
import { DECORATOR_NOT_FILLED } from "../../_GraphQL/queries";
import { decoratorsNotFilledVar } from "../../../../_GraphQL/graphQLClient";
import { positionSort } from "../../../../utils/utils";

function toggleLayerSelect(client, layerId, isCheckedForDisplay) {
  client.writeFragment({
    id: `LayerType:${layerId}`,
    fragment: gql`
      fragment LayerIsCheckedForDisplay on LayerType {
        isCheckedForDisplay
      }
    `,
    data: {
      isCheckedForDisplay: !isCheckedForDisplay,
    },
  });
}

function toggleGeometrySelect(client, geometryId, isSelectedForDisplay) {
  client.writeFragment({
    id: `LayerGeometryType:${geometryId}`,
    fragment: gql`
      fragment LayerGeometryIsSelectedForDisplay on LayerGeometryType {
        isSelectedForDisplay
      }
    `,
    data: {
      isSelectedForDisplay: !isSelectedForDisplay,
    },
  });
}

export function toggleLabelSelect(client, qFieldId, isSelectedForDisplay) {
  client.writeFragment({
    id: `QuestionnaireFieldLabelType:${qFieldId}`,
    fragment: gql`
      fragment QuestionnaireFieldLabelIsSelectedForDisplay on QuestionnaireFieldLabelType {
        isSelectedForDisplay
      }
    `,
    data: {
      isSelectedForDisplay: !isSelectedForDisplay,
    },
  });
}

function NotFilled({ layerId, decoratorId, geometry }) {
  const decosNotFilled = useReactiveVar(decoratorsNotFilledVar);

  const decoNotFilledId = `${layerId}-${decoratorId}`;
  const decoNotFilledObj = decosNotFilled.find(
    (deco) => deco.id === decoNotFilledId
  );

  useEffect(() => {
    if (!decoNotFilledObj) {
      decoratorsNotFilledVar([
        ...decosNotFilled,
        { id: decoNotFilledId, isSelectedForDisplay: true },
      ]);
    }
  }, [decosNotFilled, decoNotFilledId, decoNotFilledObj]);

  const isSelectedForDisplay =
    decoNotFilledObj && decoNotFilledObj.isSelectedForDisplay;

  const labelSrc = isSelectedForDisplay ? openEyeImg : closedEyeImg;
  const labelAlt = isSelectedForDisplay ? "Oeil ouvert" : "Oeil fermé";

  return (
    <div className="element decorateur" key={`element-deco-legend-(-1)`}>
      <span>↳</span>
      <img
        onClick={() =>
          decoratorsNotFilledVar([
            ...decosNotFilled.filter((deco) => deco.id !== decoNotFilledId),
            {
              id: decoNotFilledId,
              isSelectedForDisplay: !isSelectedForDisplay,
            },
          ])
        }
        src={labelSrc}
        alt={labelAlt}
      />
      <div className="picto-legende">
        {geometry.geometryType.code !==
        COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT ? (
          <Preview geoTypeCode={geometry.geometryType.code} />
        ) : (
          <Marker icon={DEFAULT_MARKER_SYMBOL} />
        )}
      </div>
      <p className="titre">Non renseigné</p>
    </div>
  );
}

function NotFilledQuery({ decoratorId, layerId, geometry }) {
  const { loading, error, data } = useQuery(DECORATOR_NOT_FILLED, {
    variables: { decoratorId: decoratorId, layerId: layerId },
  });

  if (loading || error || !data || !data.decoratorNotFilled) return null;

  return (
    <NotFilled
      decoratorId={decoratorId}
      geometry={geometry}
      layerId={layerId}
    />
  );
}

function LegendItem({ client, layer, checked, onClick, label, geometry }) {
  if (layer && !layer.editable) {
    return (
      <InfosLegendItem
        client={client}
        layer={layer}
        checked={checked}
        onClick={onClick}
        label={label}
        geometry={geometry}
      />
    );
  }

  const src = checked ? openEyeImg : closedEyeImg;
  const alt = checked ? "Oeil ouvert" : "Oeil fermé";

  const titleClass =
    geometry && geometry.decorator ? "titre sans-picto" : "titre";

  let noStyleColor = null;
  if (layer && geometry && geometry.geometryType) {
    if (
      geometry.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
    ) {
      noStyleColor = layer.polyColor;
    } else if (
      geometry.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE
    ) {
      noStyleColor = layer.lineColor;
    }
  }

  return (
    <>
      <div className="element">
        <img onClick={onClick} src={src} alt={alt} />
        {geometry && geometry.decorator ? null : (
          <div className="picto-legende">
            {!layer ? (
              <Preview
                style={{
                  pathOptions: JSON.stringify({
                    stroke: true,
                    color: "#ffffff",
                    weight: 1,
                    opacity: 1,
                    fill: false,
                  }),
                }}
                geoTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON}
              />
            ) : (
              <>
                {geometry &&
                COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT ===
                  geometry.geometryType.code ? (
                  <Marker color={layer.markerColor} icon={layer.marker} />
                ) : (
                  <Preview
                    style={
                      geometry &&
                      COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON ===
                        geometry.geometryType.code
                        ? layer.polyStyle
                        : layer.lineStyle
                    }
                    noStyleColor={noStyleColor}
                    geoTypeCode={
                      !geometry
                        ? COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
                        : geometry.geometryType.code
                    }
                  />
                )}
              </>
            )}
          </div>
        )}
        <p className={titleClass}>{label}</p>
      </div>
      {geometry && checked && geometry.decorator ? (
        <>
          {layer.questionnaire.questionnairefieldSet
            .find((qfield) => qfield.id === geometry.decorator.id)
            .questionnairefieldlabelSet.map((label) => {
              let labelIsSelectedForDisplay = true;
              if (label.isSelectedForDisplay === LOCAL_ONLY_FIELD_INIT) {
                labelIsSelectedForDisplay = layer.visibleByDefault;
              } else {
                labelIsSelectedForDisplay = label.isSelectedForDisplay;
              }
              const labelSrc = labelIsSelectedForDisplay
                ? openEyeImg
                : closedEyeImg;
              const labelAlt = labelIsSelectedForDisplay
                ? "Oeil ouvert"
                : "Oeil fermé";
              return (
                <div
                  className="element decorateur"
                  key={`element-deco-legend-${label.id}`}
                >
                  <span>↳</span>
                  <img
                    onClick={() =>
                      toggleLabelSelect(
                        client,
                        label.id,
                        labelIsSelectedForDisplay
                      )
                    }
                    src={labelSrc}
                    alt={labelAlt}
                  />
                  <div className="picto-legende">
                    {geometry.geometryType.code !==
                    COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT ? (
                      <Preview
                        style={
                          COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON ===
                          geometry.geometryType.code
                            ? label.polyStyle
                            : label.lineStyle
                        }
                        noStyleColor={
                          COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON ===
                          geometry.geometryType.code
                            ? label.polyColor
                            : label.lineColor
                        }
                        geoTypeCode={geometry.geometryType.code}
                      />
                    ) : (
                      <Marker color={label.markerColor} icon={label.marker} />
                    )}
                  </div>
                  <p className="titre">{label.text}</p>
                </div>
              );
            })}
          <NotFilledQuery
            decoratorId={geometry.decorator.id}
            layerId={layer.id}
            geometry={geometry}
          />
        </>
      ) : null}
    </>
  );
}

function InfosLegendItem({ client, layer, checked, onClick, label, geometry }) {
  const src = checked ? openEyeImg : closedEyeImg;
  const alt = checked ? "Oeil ouvert" : "Oeil fermé";

  const titleClass = "titre";

  return (
    <>
      <div className="element">
        <img onClick={onClick} src={src} alt={alt} />
        <div className="picto-legende">
          {geometry &&
          COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT ===
            geometry.geometryType.code ? (
            <Marker color={layer.markerColor} icon={layer.marker} />
          ) : (
            <Preview
              style={
                geometry &&
                COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON ===
                  geometry.geometryType.code
                  ? layer.polyStyle
                  : layer.lineStyle
              }
              noStyleColor={layer.polyColor ? layer.polyColor : layer.lineColor}
              geoTypeCode={
                !geometry
                  ? COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
                  : geometry.geometryType.code
              }
            />
          )}
        </div>
        <p className={titleClass}>{label}</p>
      </div>
    </>
  );
}

function LayerLegendItem({
  layer,
  name,
  isCheckedForDisplay,
  layerId,
  geometry,
}) {
  return (
    <ApolloConsumer>
      {(client) => (
        <LegendItem
          client={client}
          layer={layer}
          geometry={geometry}
          label={name}
          checked={
            geometry ? geometry.isSelectedForDisplay : layer.isCheckedForDisplay
          }
          onClick={() => {
            if (!geometry) {
              toggleLayerSelect(client, layerId, isCheckedForDisplay);
            } else {
              if (!isCheckedForDisplay) {
                toggleLayerSelect(client, layerId, isCheckedForDisplay);
                toggleGeometrySelect(
                  client,
                  geometry.id,
                  geometry.isSelectedForDisplay
                );
              } else {
                // au moins une géométrie est sélectionnée.
                if (!geometry.isSelectedForDisplay) {
                  toggleGeometrySelect(
                    client,
                    geometry.id,
                    geometry.isSelectedForDisplay
                  );
                } else {
                  // on déselectionne aussi la layer si c'est la dernière
                  let anotherSelected = false;
                  layer.layergeometrySet
                    .filter((geo) => geo.id !== geometry.id)
                    .forEach((geo) => {
                      if (geo.isSelectedForDisplay) {
                        anotherSelected = true;
                      }
                    });
                  if (anotherSelected) {
                    toggleGeometrySelect(
                      client,
                      geometry.id,
                      geometry.isSelectedForDisplay
                    );
                  } else {
                    toggleLayerSelect(client, layerId, isCheckedForDisplay);
                    toggleGeometrySelect(
                      client,
                      geometry.id,
                      geometry.isSelectedForDisplay
                    );
                  }
                }
              }
            }
          }}
        />
      )}
    </ApolloConsumer>
  );
}

const Legend = ({ layerSet, useApicarto, cadastre, setCadastre }) => (
  <div className="legende">
    <div className="entete-panneau">
      <p>Légende</p>
    </div>
    {useApicarto ? (
      <LegendItem label="Cadastre" checked={cadastre} onClick={setCadastre} />
    ) : null}
    {layerSet
      .filter((l) => !l.editable)
      .sort(positionSort)
      .map((layer) => {
        return (
          <LayerLegendItem
            key={`LayerCheckbox${layer.id}`}
            layer={layer}
            name={layer.name}
            isCheckedForDisplay={layer.isCheckedForDisplay}
            layerId={layer.id}
            geometry={layer.layergeometrySet ? layer.layergeometrySet[0] : null}
          />
        );
      })}
    {layerSet
      .filter((l) => l.editable)
      .sort(positionSort)
      .map((layer) => {
        return layer.layergeometrySet.map((geometry) => (
          <LayerLegendItem
            key={`LayerCheckbox${layer.id}${geometry.id}`}
            layer={layer}
            name={layer.name}
            isCheckedForDisplay={layer.isCheckedForDisplay}
            layerId={layer.id}
            geometry={geometry}
          />
        ));
      })}
  </div>
);

export { Legend };
