import React from "react";

function Initials({ displayName }) {
  return (
    <div className="bt-profil">
      <div className="bt-profil-grid">
        <div className="cercle"></div>
        <div className="lettres">
          <svg viewBox="0 0 36 10">
            <text x="18" y="10" textAnchor="middle">
              {displayName}
            </text>
          </svg>
        </div>
      </div>
    </div>
  );
}

export { Initials };
