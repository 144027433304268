// React
import React, { Component } from "react";

// GraphQL
import { ApolloConsumer, useMutation } from "@apollo/client";
import { addMessage } from "../../../_GraphQL/message";

// Leaflet
import L from "leaflet"; // eslint-disable-line no-unused-vars

// Components
import { Tools } from "./ControlPanel/UpdateFeatureData";
import MapPanel from "./MapPanel/MapPanel";
import { ActionBar } from "./ActionBar/ActionBar";
import MessageDisplayer from "../../../Common/components/MessageDisplayer";
import { TaskInfos } from "./ControlPanel/TaskInfos";

import { menuDisabledVar } from "../../../_GraphQL/graphQLClient";
// Constants
import {
  COLLABORATIVE_MAP_TOOL_TO_GEOMETRY_TYPE_CODE,
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  COLLABORATIVE_MAP_TOOLS,
  COLLABORATIVE_MAP_PERMISSIONS,
  QUESTIONNAIRE_MODES,
  MESSAGE_TYPE,
  COMPONENTS,
  RIGHT_PANELS,
  MARKER_HTML_TEMPLATE,
  MARKER_IMAGE,
  DEFAULT_MARKER_COLOR,
  SHADOW_EFFECT,
  SHADOW_LINK,
} from "../../../utils/Constants";
import { RightPanels } from "./RightPanels/RightPanels";
import { hexToRgbA } from "../../../utils/utils";
import {
  ACTIONS,
  useCollaborativeMap,
  useCollaborativeMapDispatch,
} from "../../../Contexts/CollaborativeMapProvider";
import spinner from "../../../medias/images/roue-chargement.svg";
import { GEOLOCATE_CONTRIBUTION } from "../_GraphQL/mutations";
import { updateAfterCreateFeature } from "../../Questionnaire/components/CreateQuestionnaire";

function createMarkerIcon(color, marker, active) {
  let divIcon = {
    className: "leaflet-marker-icon",
    iconSize: [55, 55],
    iconAnchor: [27.5, 55],
  };

  let markerHtmlColor;
  if (active) {
    markerHtmlColor = MARKER_HTML_TEMPLATE.replace(
      "SHADOW_EFFECT",
      SHADOW_EFFECT
    )
      .replace("SHADOW_LINK", SHADOW_LINK)
      .replace("STROKE_COLOR", hexToRgbA("#ffd700", 1));
  } else {
    markerHtmlColor = MARKER_HTML_TEMPLATE.replace("SHADOW_EFFECT", "")
      .replace("SHADOW_EFFECT_LINK", "")
      .replace("STROKE_COLOR", hexToRgbA("#ffffff", 1));
  }

  if (!color) {
    markerHtmlColor = markerHtmlColor.replace(
      "MARKER_BG_COLOR",
      DEFAULT_MARKER_COLOR
    );
  } else {
    markerHtmlColor = markerHtmlColor.replace("MARKER_BG_COLOR", color);
  }

  let markerImage = "";

  if (marker) {
    markerImage = MARKER_IMAGE.replace("MARKER_ICON", marker);
  }

  divIcon.html = markerHtmlColor.replace("MARKER_IMAGE", markerImage);

  return L.divIcon(divIcon);
}

class Controller extends Component {
  constructor(props) {
    super(props);

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleUpdateFeatureCompleted =
      this.handleUpdateFeatureCompleted.bind(this);

    this.state = {
      activeTool: COLLABORATIVE_MAP_TOOLS.NONE,
      activeLayer: null,
      activeFeature: null,
      activeFeatureRef: null,
      currentMunicipality: null,
      currentDivision: null,
      cadastre: true,
      mapInstance: null,
      activeRightPanel: RIGHT_PANELS.NONE,
      baseMap: this.props.task.tileLayer.mapId,
      featureHistory: {
        count: 0,
        index: 0,
        elems: [],
      },
    };

    this.carteRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.collaborativeMap.ideasContributeDirectly) {
      // prendre l'id de la feature, trouver la feature en question dans la liste des existantes et la mettre en active
      // mettre sa layer en active
      this.setState({
        activeRightPanel: RIGHT_PANELS.INFOS,
        activeFeature: this.props.collaborativeMap.ideaFeature,
        activeLayer: this.props.task.layerSet.find(
          (layer) => layer.id === this.props.collaborativeMap.ideaLayer.id
        ),
      });
    }
    if (this.props.collaborativeMap.ideasShowContributions) {
      this.setState({
        activeRightPanel: RIGHT_PANELS.INFOS,
        activeFeature: this.props.collaborativeMap.ideaFeature,
        activeLayer: this.props.task.layerSet.find(
          (layer) => layer.id === this.props.collaborativeMap.ideaLayer.id
        ),
      });
    }
    if (this.carteRef && this.carteRef.current) {
      const elements =
        this.carteRef.current.getElementsByClassName("zone-travail");
      if (elements) {
        this.setState({
          mapPanelHeight: elements[0].offsetHeight,
        });
      }
    }
  }

  // ***********************
  // Controls
  // ***********************
  handleMapCreated = (map) => {
    map.pm.setLang("fr");
    map.on("pm:create", this.handlePmCreate);
    map.createPane("noneditable");
    map.getPane("noneditable").style.zIndex = 300;

    const self = this;
    map.on("pm:drawstart", ({ workingLayer }) => {
      workingLayer.on("pm:vertexadded", (e) => {
        const prev = self.state.featureHistory;
        self.setState({
          featureHistory: {
            count: prev.count + 1,
            index: prev.index + 1,
            elems: [...prev.elems, e.latlng],
          },
        });
      });
    });

    this.setState({ mapInstance: map });
  };

  setCurrentEditingFeature = (featureInfos) =>
    this.setState({
      activeLayer: featureInfos.layer,
      activeFeature: featureInfos.feature,
      activeFeatureRef: featureInfos.ref,
    });

  setActiveTool = (tool, layer) => {
    // Change le mode de dessin au niveau leaflet
    // agit sur la référence de la carte
    this.setDrawMode(tool, layer);

    // Modification de l'état du composant
    // outil sélectionné, couche sélectionnée (ancienne notion)
    this.setState({
      activeTool: tool,
      activeLayer: layer,
      activeFeature: null,
      activeFeatureRef: null,
      activeRightPanel: RIGHT_PANELS.NONE,
      featureHistory: {
        count: 0,
        index: 0,
        elems: [],
      },
    });

    if (tool === COLLABORATIVE_MAP_TOOLS.NONE) {
      menuDisabledVar(false);
    } else {
      menuDisabledVar(true);
    }
  };

  // Change le mode de dessin au niveau leaflet
  // agit sur la référence de la carte
  setDrawMode(tool, layer) {
    const options = {
      hintlineStyle: {
        color: "purple",
      },
      snappable: false,
    };

    if (tool === COLLABORATIVE_MAP_TOOLS.ADD_POINT) {
      this.state.mapInstance.pm.enableDraw("Marker", {
        ...options,

        markerStyle: {
          icon: createMarkerIcon(layer.markerColor, layer.marker),
        },
      });
    } else if (tool === COLLABORATIVE_MAP_TOOLS.ADD_LINE) {
      this.state.mapInstance.pm.enableDraw("Line", options);
    } else if (tool === COLLABORATIVE_MAP_TOOLS.ADD_POLYGON) {
      this.state.mapInstance.pm.enableDraw("Poly", options);
    } else {
      if (this.state.activeFeatureRef && this.state.activeFeatureRef.current) {
        this.state.activeFeatureRef.current.pm.disable();
      }
      this.state.mapInstance.pm.disableDraw();
    }
  }

  // *************************
  // Create Feature
  // *************************

  handlePmCreate = (pmCreateEvent) => {
    if (!this.state.geolocateIdea) {
      this.state.mapInstance.pm.disableDraw();

      this.setState({
        createdLeafletLayer: pmCreateEvent.layer,
        activeRightPanel: RIGHT_PANELS.INFOS,
      });
      this.props.dispatch({ type: ACTIONS.PM_CREATED });
      menuDisabledVar(true);
    } else {
      this.state.mapInstance.pm.disableDraw();

      this.state.mapInstance.removeLayer(pmCreateEvent.layer);
      this.props.geolocateContribution({
        variables: {
          layerId: this.state.activeLayer.id,
          geometryTypeCode:
            COLLABORATIVE_MAP_TOOL_TO_GEOMETRY_TYPE_CODE[this.state.activeTool],
          geoJson: JSON.stringify(pmCreateEvent.layer.toGeoJSON()),
          filledQuestionnaireId:
            this.props.collaborativeMap.createdFilledQuestionnaireId,
        },
        update: (cache, { data: { geolocateContribution } }) => {
          updateAfterCreateFeature(
            cache,
            geolocateContribution.feature,
            this.state.activeLayer,
            this.props.task.id
          );
        },
        onCompleted: ({ geolocateContribution: { feature } }) => {
          this.setState({
            activeTool: COLLABORATIVE_MAP_TOOLS.NONE,
            activeFeature: feature,
            activeRightPanel: RIGHT_PANELS.INFOS,
            geolocateIdea: false,
          });
        },
      });
      menuDisabledVar(false);
    }
  };

  questionnaireComplete = (feature) => {
    this.setDrawMode(COLLABORATIVE_MAP_TOOLS.NONE);
    this.state.mapInstance.removeLayer(this.state.createdLeafletLayer);
    this.setState({
      activeTool: COLLABORATIVE_MAP_TOOLS.NONE,
      activeFeature: feature,
      createdLeafletLayer: null,
    });
    this.props.dispatch({ type: ACTIONS.CREATION_COMPLETED });
  };

  closeQuestionnaire = () => {
    this.setState({
      activeFeature: null,
      activeLayer: null,
      activeRightPanel: RIGHT_PANELS.NONE,
    });
    menuDisabledVar(false);
    this.props.dispatch({ type: ACTIONS.QUESTIONNAIRE_CLOSED });
  };

  showInfos = () => {
    this.setState({
      activeRightPanel: RIGHT_PANELS.INFOS,
    });
    this.props.dispatch({ type: ACTIONS.INFOS_SHOWED });
    menuDisabledVar(false);
  };

  locateIdea = () => {
    const { ideaLayer: layer } = this.props.collaborativeMap;

    const options = {
      hintlineStyle: {
        color: "purple",
      },
      snappable: false,
    };

    this.state.mapInstance.pm.enableDraw("Marker", {
      ...options,

      markerStyle: {
        icon: createMarkerIcon(layer.markerColor, layer.marker),
      },
    });

    // Modification de l'état du composant
    // outil sélectionné, couche sélectionnée (ancienne notion)
    this.setState({
      activeTool: COLLABORATIVE_MAP_TOOLS.ADD_POINT,
      activeLayer: layer,
      activeFeature: null,
      activeFeatureRef: null,
      activeRightPanel: RIGHT_PANELS.NONE,
      geolocateIdea: true,
      featureHistory: {
        count: 0,
        index: 0,
        elems: [],
      },
    });

    menuDisabledVar(true);
    this.props.dispatch({ type: ACTIONS.GEOLOCATE_IDEA });
  };

  // *************************
  // Questionnaire
  // *************************

  cancelQuestionnaire = () => {
    this.setDrawMode(COLLABORATIVE_MAP_TOOLS.NONE);
    this.state.mapInstance.removeLayer(this.state.createdLeafletLayer);
    this.setState({
      activeTool: COLLABORATIVE_MAP_TOOLS.NONE,
      activeFeature: null,
      createdLeafletLayer: null,
      activeRightPanel: RIGHT_PANELS.NONE,
    });
    menuDisabledVar(false);
    this.props.dispatch({ type: ACTIONS.QUESTIONNAIRE_CANCELLED });
  };

  // *************************
  // A TRIER
  // *************************

  // Gère le clic sur un élément présent sur la carte
  handleFeatureClick = (layer, feature, featureRef) => {
    if (this.state.activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY) {
      // Est-ce que l'utilisateur à la permission d'éditer sur cette couche ?
      if (
        !layer.toolPermissionSet.find(
          (x) =>
            x.codename ===
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER + layer.id
        )
      ) {
        addMessage({
          header: "Permission refusée",
          content:
            "Vous n'avez pas la permission d'éditer d'élément sur cette couche.",
          location: COMPONENTS.COLLABORATIVE_MAP.CONTROLLER,
          type: MESSAGE_TYPE.NEGATIVE,
        });
        return;
      }

      if (
        layer.editable &&
        layer.restrictionsBetweenUsers &&
        !layer.userCanEditOthersGeometries &&
        !feature.currentUserIsOwner
      ) {
        addMessage({
          header: "Permission refusée",
          content:
            "Vous n'avez pas la permission de modifier les éléments que vous n'avez pas créé.",
          location: COMPONENTS.COLLABORATIVE_MAP.CONTROLLER,
          type: MESSAGE_TYPE.NEGATIVE,
        });
        return;
      }

      if (this.state.activeFeature) return;

      let options = {
        // makes the layer draggable
        draggable: true,

        // makes the vertices snappable to other layers
        // temporarily disable snapping during drag by pressing ALT
        snappable: false,

        // distance in pixels that needs to be undercut to trigger snapping
        // default: 30
        snapDistance: 30,

        // self intersection allowed?
        allowSelfIntersection: true,

        // disable the removal of markers/vertexes via right click
        preventMarkerRemoval: false,

        // disable the possibility to edit vertexes
        preventVertexEdit: false,
      };

      featureRef.current.pm.enable(options);

      this.setCurrentEditingFeature({
        layer: layer,
        feature: feature,
        ref: featureRef,
      });

      // if(feature.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT) {
      //   L.DomUtil.addClass(featureRef.current._icon, 'ici-marker-active');
      // }
    } else if (this.state.activeTool === COLLABORATIVE_MAP_TOOLS.NONE) {
      if (
        this.state.activeFeature &&
        this.state.activeFeature.id === feature.id
      ) {
        let newState = {
          activeLayer: null,
          activeFeature: null,
        };
        if (this.state.activeRightPanel === RIGHT_PANELS.INFOS) {
          newState.activeRightPanel = RIGHT_PANELS.NONE;
        }
        this.setState(newState);
      } else {
        this.setState({
          activeLayer: layer,
          activeFeature: feature,
          activeRightPanel: RIGHT_PANELS.INFOS,
        });
      }
    }
  };

  zoomToBounds = (bbox) => {
    const corner1 = L.latLng(bbox[1], bbox[0]),
      corner2 = L.latLng(bbox[3], bbox[2]),
      bounds = L.latLngBounds(corner1, corner2);
    this.state.mapInstance.fitBounds(bounds);
  };

  handleCommuneClick = (codeInsee, bounds) => {
    if (this.state.activeTool === COLLABORATIVE_MAP_TOOLS.NONE) {
      this.state.mapInstance.fitBounds(bounds);
      this.setState({ currentMunicipality: codeInsee });
    }
  };

  handleDivisionClick = (codeDivision, bounds) => {
    if (this.state.activeTool === COLLABORATIVE_MAP_TOOLS.NONE) {
      this.state.mapInstance.fitBounds(bounds);
      this.setState({ currentDivision: codeDivision });
    }
  };

  handleParcelClick = (bounds) => {
    if (this.state.activeTool === COLLABORATIVE_MAP_TOOLS.NONE) {
      this.state.mapInstance.fitBounds(bounds);
    }
  };

  handleViewPortChanged = ({ zoom }) => {
    if (zoom < 13) {
      this.setState({ currentDivision: null });
    }
    if (zoom < 12) {
      this.setState({ currentMunicipality: null });
    }
  };

  handlePmEdit = (e) => {
    // si tool edit
    // si ActiveFeatureRef
    // si pas déjà activé
    if (
      this.state.activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY &&
      this.state.activeFeature &&
      this.state.activeFeatureRef
    ) {
      const updatedGeoJson = JSON.stringify(e.layer.toGeoJSON());
      const cache = this.props.client.cache;
      cache.modify({
        id: cache.identify(this.state.activeFeature),
        fields: {
          data() {
            return updatedGeoJson;
          },
        },
      });

      const featHist = this.state.featureHistory;
      this.setState({
        featureHistory: {
          count: featHist.count + 1,
          index: featHist.index + 1,
          elems:
            featHist.count === featHist.index
              ? [...featHist.elems, updatedGeoJson]
              : [...featHist.elems.slice(0, featHist.index), updatedGeoJson],
        },
      });
    }
  };

  handleCancelClick() {
    if (this.state.activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY) {
      if (this.state.activeFeatureRef && this.state.activeFeature) {
        if (
          this.state.activeFeature.geometryType.code ===
          COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT
        ) {
          const featHist = this.state.featureHistory;
          if (featHist.index > 0) {
            const cache = this.props.client.cache;
            cache.modify({
              id: cache.identify(this.state.activeFeature),
              fields: {
                data() {
                  return featHist.elems[featHist.index - 2];
                },
              },
            });

            this.setState({
              featureHistory: {
                count: featHist.count,
                index: featHist.index - 1,
                elems: [...featHist.elems],
              },
            });
          } else {
            this.state.activeFeatureRef.current.pm.disable();
            this.setState({
              activeLayer: null,
              activeFeature: null,
              activeFeatureRef: null,
            });
          }
        } else {
          const data = this.state.activeFeature;
          const cache = this.props.client.cache;
          cache.modify({
            id: cache.identify(this.state.activeFeature),
            fields: {
              data(existingdataRefs, { DELETE }) {
                return DELETE;
              },
            },
          });
          this.setActiveTool(COLLABORATIVE_MAP_TOOLS.NONE);
          cache.modify({
            id: cache.identify(this.state.activeFeature),
            fields: {
              data() {
                return data;
              },
            },
          });
        }
      } else {
        this.setActiveTool(COLLABORATIVE_MAP_TOOLS.NONE);
      }
    } else if (this.state.activeTool === COLLABORATIVE_MAP_TOOLS.ADD_POINT) {
      this.setActiveTool(COLLABORATIVE_MAP_TOOLS.NONE);
    } else if (this.state.activeTool === COLLABORATIVE_MAP_TOOLS.ADD_LINE) {
      const mapInstance = this.state.mapInstance;
      const draw = mapInstance.pm.Draw;
      // si 1 ou plus de points, supprimer le dernier
      if (draw.Line._layer._latlngs.length > 1) {
        draw.Line._removeLastVertex();
      } else if (draw.Line._layer._latlngs.length === 1) {
        // On relance la création du polygone quand il n'y a plus qu'un élément tracé
        // car sinon on se retrouve avec l'impossibilité de placer à nouveau des points
        // comme si le tracé était désactivé en desous d'un point.
        mapInstance.pm.disableDraw();
        mapInstance.pm.enableDraw("Line", {
          hintlineStyle: {
            color: "purple",
          },
          snappable: false,
        });
      } else {
        // sinon désélectionner l'outil ligne.
        this.setActiveTool(COLLABORATIVE_MAP_TOOLS.NONE);
      }

      if (this.state.featureHistory.index > 0) {
        const prev = this.state.featureHistory;
        this.setState({
          featureHistory: {
            count: prev.count - 1,
            index: prev.index - 1,
            elems: prev.elems.slice(-1),
          },
        });
      }
    } else if (this.state.activeTool === COLLABORATIVE_MAP_TOOLS.ADD_POLYGON) {
      const mapInstance = this.state.mapInstance;
      const draw = mapInstance.pm.Draw;
      // si 1 ou plus de points, supprimer le dernier

      if (draw.Polygon._layer._latlngs.length > 1) {
        draw.Polygon._removeLastVertex();
      } else if (draw.Polygon._layer._latlngs.length === 1) {
        // On relance la création du polygone quand il n'y a plus qu'un élément tracé
        // car sinon on se retrouve avec l'impossibilité de placer à nouveau des points
        // comme si le tracé était désactivé en desous d'un point.
        mapInstance.pm.disableDraw();
        mapInstance.pm.enableDraw("Poly", {
          hintlineStyle: {
            color: "purple",
          },
          snappable: false,
        });
      } else {
        // sinon désélectionner l'outil polygone.
        this.setActiveTool(COLLABORATIVE_MAP_TOOLS.NONE);
      }

      if (this.state.featureHistory.index > 0) {
        const prev = this.state.featureHistory;
        this.setState({
          featureHistory: {
            count: prev.count - 1,
            index: prev.index - 1,
            elems: prev.elems.slice(-1),
          },
        });
      }
    }
  }

  handleUpdateFeatureCompleted() {
    this.setState({
      activeLayer: null,
      activeFeature: null,
      activeFeatureRef: null,
      activeTool: COLLABORATIVE_MAP_TOOLS.NONE,
      featureHistory: {
        count: 0,
        index: 0,
        elems: [],
      },
    });
  }

  render() {
    return (
      <div className="carte" ref={this.carteRef}>
        {this.props.collaborativeMap.questionnaireMode !==
        QUESTIONNAIRE_MODES.READ ? (
          <div className="couverture-carte"></div>
        ) : null}
        {this.props.collaborativeMap.exportLoading ? (
          <div className="couverture">
            <div className="popup center">
              <img src={spinner} alt="icône de chargement" />
              <p>Export en cours de création...</p>
            </div>
          </div>
        ) : null}
        <MapPanel
          activeFeature={this.state.activeFeature}
          activeTool={this.state.activeTool}
          baseMap={this.state.baseMap}
          cadastre={this.state.cadastre}
          currentDivision={this.state.currentDivision}
          currentMunicipality={this.state.currentMunicipality}
          handleCommuneClick={this.handleCommuneClick}
          handleDivisionClick={this.handleDivisionClick}
          handleFeatureClick={this.handleFeatureClick}
          handleMapCreated={this.handleMapCreated}
          handleParcelClick={this.handleParcelClick}
          handlePmEdit={this.handlePmEdit}
          handleViewPortChanged={this.handleViewPortChanged}
          layerSet={this.props.task.layerSet}
          mapInstance={this.state.mapInstance}
          municipalities={this.props.task.municipalities}
          tileLayer={this.props.task.tileLayer}
          useApicarto={this.props.task.useApicarto}
          styleSet={this.props.task.styleSet}
        />
        <ActionBar
          lMap={this.state.mapInstance}
          activeTool={this.state.activeTool}
          activeLayer={this.state.activeLayer}
          activeFeature={this.state.activeFeature}
          featureHistory={this.state.featureHistory}
          geocodingActive={this.props.task.geocodingActive}
        />
        <div className="message-carte">
          <MessageDisplayer
            location={COMPONENTS.COLLABORATIVE_MAP.CONTROLLER}
          />
        </div>
        <TaskInfos task={this.props.task} />
        <Tools
          activeFeature={this.state.activeFeature}
          activeFeatureRef={this.state.activeFeatureRef}
          activeLayer={this.state.activeLayer}
          activeTool={this.state.activeTool}
          featureHistory={this.state.featureHistory}
          handleCancelClick={this.handleCancelClick}
          handleUpdateFeatureCompleted={this.handleUpdateFeatureCompleted}
          setActiveTool={this.setActiveTool}
          task={this.props.task}
          mapPanelHeight={this.state.mapPanelHeight}
        />
        <RightPanels
          activeGeometryTypeCode={
            COLLABORATIVE_MAP_TOOL_TO_GEOMETRY_TYPE_CODE[this.state.activeTool]
          }
          activeRightPanel={this.state.activeRightPanel}
          baseMap={this.state.baseMap}
          cadastre={this.state.cadastre}
          cancel={this.cancelQuestionnaire}
          closeQuestionnaire={this.closeQuestionnaire}
          createdLeafletLayer={this.state.createdLeafletLayer}
          feature={this.state.activeFeature}
          handleDeleteCompleted={() =>
            this.setState({
              activeFeature: null,
              activeLayer: null,
              activeTool: COLLABORATIVE_MAP_TOOLS.NONE,
              activeRightPanel: RIGHT_PANELS.NONE,
            })
          }
          layer={this.state.activeLayer}
          layerSet={this.props.task.layerSet}
          questionnaireComplete={this.questionnaireComplete}
          setActiveRightPanel={(panel) => {
            if (this.state.activeRightPanel === panel) {
              this.setState({
                activeRightPanel: RIGHT_PANELS.NONE,
                activeFeature: null,
                activeFeatureRef: null,
                activeLayer: null,
              });
            } else {
              let newState = {
                activeRightPanel: panel,
              };
              if (panel !== RIGHT_PANELS.INFOS) {
                newState.activeFeature = null;
                newState.activeFeatureRef = null;
                newState.activeLayer = null;
              }
              this.setState(newState);
            }
          }}
          setBaseMap={(baseMap) => this.setState({ baseMap: baseMap })}
          setCadastre={() => this.setState({ cadastre: !this.state.cadastre })}
          showInfos={this.showInfos}
          task={this.props.task}
          useApicarto={this.props.task.useApicarto}
          locateIdea={this.locateIdea}
          activeLayerFromApolloQuery={
            this.state.activeLayer
              ? this.props.task.layerSet.find(
                  (layer) => layer.id === this.state.activeLayer.id
                )
              : null
          }
        />
      </div>
    );
  }
}

const ControllerMessage = (props) => {
  const dispatch = useCollaborativeMapDispatch();
  const collaborativeMap = useCollaborativeMap();
  const [geolocateContribution, { loading, error }] = useMutation(
    GEOLOCATE_CONTRIBUTION
  );

  return (
    <ApolloConsumer>
      {(client) => (
        <Controller
          {...props}
          client={client}
          dispatch={dispatch}
          collaborativeMap={collaborativeMap}
          geolocateContribution={geolocateContribution}
          geolocateContributionLoading={loading}
          geolocateContributionError={error}
        />
      )}
    </ApolloConsumer>
  );
};

export { ControllerMessage as Controller, createMarkerIcon };
