import React, { useState, forwardRef } from "react";
import { LabelField } from "./LabelField";
import { QUESTIONNAIRE_MODES } from "../../../../utils/Constants";
import { InputField } from "./InputField";
import { ReadField } from "./ReadField";
import { EditField } from "./EditField";

const Common = forwardRef(
  ({ qField, edit, setFieldMode, children, optional, readOnly }, ref) => {
    const className = readOnly ? "champ lecture-seule" : "champ";
    return (
      <div className={className}>
        <LabelField
          text={qField.text}
          helptext={qField.helptext}
          edit={edit}
          handleEditClick={() => setFieldMode(QUESTIONNAIRE_MODES.EDIT)}
          ref={ref}
          optional={optional}
        />
        {children}
      </div>
    );
  }
);

function Field({
  featureId,
  filledQId,
  qField,
  filledQField,
  mode,
  children,
  createValue,
  createSetValue,
  inputRef,
  labelRef,
  createAddImages,
  canEdit,
}) {
  const [fieldMode, setFieldMode] = useState(mode);
  const [sources, setSources] = useState([]);

  if (fieldMode === QUESTIONNAIRE_MODES.CREATE) {
    return (
      <Common
        qField={qField}
        edit={false}
        ref={labelRef}
        optional={!qField.required}
        readOnly={qField.readOnly}
      >
        <InputField
          value={createValue}
          handleValueChange={createSetValue}
          addImages={createAddImages}
          ref={inputRef}
        >
          {children}
        </InputField>
      </Common>
    );
  } else if (fieldMode === QUESTIONNAIRE_MODES.READ) {
    return (
      <Common
        qField={qField}
        edit={canEdit && !qField.readOnly}
        setFieldMode={setFieldMode}
        readOnly={false}
      >
        <ReadField
          qField={qField}
          filledQField={filledQField}
          sources={sources}
          setSources={setSources}
        ></ReadField>
      </Common>
    );
  } else if (fieldMode === QUESTIONNAIRE_MODES.EDIT) {
    return (
      <Common
        qField={qField}
        edit={false}
        optional={!qField.required}
        readOnly={qField.readOnly}
      >
        <EditField
          featureId={featureId}
          filledQId={filledQId}
          qField={qField}
          filledQField={filledQField}
          sources={sources}
          setSources={setSources}
          setReadMode={() => setFieldMode(QUESTIONNAIRE_MODES.READ)}
        >
          {children}
        </EditField>
      </Common>
    );
  }

  return null;
}

export { Field };
