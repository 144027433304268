import React from "react";
import { DocumentGroupPermissionsFormRow } from "../../../Common/components/GroupPermission/DocumentGroupPermissionsFormRow";

class ManageDocumentPermissionsForm extends React.Component {
  componentDidMount() {
    this.props.initialGroupPermissionsRows.forEach((groupPermissionsRow) => {
      if (groupPermissionsRow.canViewDocument) {
        this.props.addGroupPermission(
          groupPermissionsRow.group.id,
          "view_document_"
        );
      }
      if (groupPermissionsRow.canDeleteDocument) {
        this.props.addGroupPermission(
          groupPermissionsRow.group.id,
          "delete_document_"
        );
      }
      if (groupPermissionsRow.canMoveDocument) {
        this.props.addGroupPermission(
          groupPermissionsRow.group.id,
          "move_document_"
        );
      }
    });
    this.props.initGroupPermissionsRows(this.props.initialGroupPermissionsRows);
  }

  render() {
    const { style } = this.props;
    return (
      <>
        <p>Gestion des permissions</p>

        <div style={style}>
          <table>
            <thead>
              <tr>
                <th>Groupe</th>
                <th align="center">Accès en lecture</th>
                <th align="center">Peut déplacer</th>
                <th align="center">Peut supprimer</th>
              </tr>
            </thead>
            <tbody>
              {this.props.groupPermissionsRows.map((groupPermissionsRow, i) => (
                <DocumentGroupPermissionsFormRow
                  key={`table-row-group-${groupPermissionsRow.group.id}`}
                  groupPermissionsRow={groupPermissionsRow}
                  handleCheckChange={this.props.handleCheckChange}
                  even={i % 2 === 0}
                />
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export { ManageDocumentPermissionsForm };
