import modifyPolygonStep1Img from "../../../medias/images/aide/modifier-surface-etape-1-1080x810.png";
import modifyPolygonStep2Img from "../../../medias/images/aide/modifier-surface-etape-2-1080x810.png";
import modifyPolygonStep3Img from "../../../medias/images/aide/modifier-surface-etape-3-1080x810.png";
import modifyPolygonStep4Img from "../../../medias/images/aide/modifier-surface-etape-4-1080x810.png";

function HelpModifyPolygon() {
  return (
    <div>
      <h1>Étapes pour modifier une surface</h1>
      <p>1. Cliquer sur l'outil &laquo;&nbsp;Modifier&nbsp;&raquo;</p>
      <img
        className="capture-aide"
        src={modifyPolygonStep1Img}
        alt="Illustration de la sélection de l'outil modifier."
      />
      <p>2. Cliquer sur la surface à modifier</p>
      <img
        className="capture-aide"
        src={modifyPolygonStep2Img}
        alt="Illustration de la sélection de la surface à modifier."
      />
      <p>
        3. Faire un glisser déposer sur chaque point à modifier et cliquer sur
        valider
      </p>
      <img
        className="capture-aide"
        src={modifyPolygonStep3Img}
        alt="Illustration de la sélection de l'outil surface."
      />
      <p>5. La ligne est maintenant modifiée</p>
      <img
        className="capture-aide"
        src={modifyPolygonStep4Img}
        alt="Illustration d'une surface modifiée."
      />
    </div>
  );
}

export { HelpModifyPolygon };
