import React from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { PROJECTS_ASSIGNED_TO_CURRENT_USER_FOR_CURRENT_SITE } from "../_GraphQL/queries";
import { Loader } from "../components/Loader";
import { IciErrorMessage } from "../components/ErrorMessage";
import { TASK_TYPES } from "../../utils/Constants";

function TitleDoc() {
  return (
    <h1>
      De quel projet souhaitez-vous voir les{" "}
      <span className="bleu">documents</span> ?
    </h1>
  );
}

function TitleIdeas() {
  return (
    <h1>
      A quel projet souhaitez-vous apporter vos{" "}
      <span className="bleu">idées</span> ?
    </h1>
  );
}

function TitleInventory() {
  return (
    <h1>
      De quel projet souhaitez-vous contribuer aux{" "}
      <span className="bleu">inventaires</span> ?
    </h1>
  );
}

function ProjectSelector() {
  const { loading, error, data } = useQuery(
    PROJECTS_ASSIGNED_TO_CURRENT_USER_FOR_CURRENT_SITE
  );

  if (loading) return <Loader />;

  if (error)
    return (
      <IciErrorMessage
        header={"Impossible de récupérer les projets."}
        error={error}
      />
    );

  let projectSet = [];
  let Title = null;
  if (window.location.pathname === "/documents") {
    projectSet = data.currUserCurrSiteAssignedProjectSet.filter(
      (project) => project.rootFolder && project.rootFolder.canRead
    );
    Title = TitleDoc;
  } else if (window.location.pathname === "/idees") {
    projectSet = data.currUserCurrSiteAssignedProjectSet.filter((project) =>
      project.currUserTaskSet.some(
        (task) => task.taskType.code === TASK_TYPES.IDEAS
      )
    );
    Title = TitleIdeas;
  } else {
    projectSet = data.currUserCurrSiteAssignedProjectSet.filter((project) =>
      project.currUserTaskSet.some(
        (task) => task.taskType.code === TASK_TYPES.INVENTORY
      )
    );
    Title = TitleInventory;
  }

  if (projectSet.length === 0) {
    return <p>Vous n&apos;avez accès à aucun projet.</p>;
  }

  if (projectSet.length === 1) {
    const project = projectSet[0];
    return (
      <Navigate
        to={`/${project.slug ?? project.id}${window.location.pathname}`}
        replace
      />
    );
  }

  return (
    <div className="selection">
      <Title />
      <div className="tuiles">
        {projectSet.map((project) => {
          let projectIdentification = project.id;
          if (project.slug) {
            projectIdentification = project.slug;
          }
          return (
            <Link
              key={`select-project-item-${project.id}`}
              className="tuile"
              to={`/${projectIdentification}${window.location.pathname}`}
            >
              <div className="illustration">
                {project.illustrationPath ? (
                  <img
                    src={`/media/projects/${project.illustrationPath}`}
                    alt={project.name}
                  />
                ) : null}
              </div>
              <h2>{project.name}</h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export { ProjectSelector };
