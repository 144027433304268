import { GET_FILES } from "../Modules/FileManager/_GraphQL/queries";

export function toggleGlobalSelectedForDownload(client, parentId) {
  const variables = { parentId: parentId };
  const data = client.readQuery({ query: GET_FILES, variables });

  if (
    data.currUserFolderSet.filter((f) => f.selectedForDownload).length ===
      data.currUserFolderSet.length &&
    data.currUserDocumentSet.filter((f) => f.selectedForDownload).length ===
      data.currUserDocumentSet.length
  ) {
    // tout est coché -> on décoche tout
    client.writeQuery({
      query: GET_FILES,
      variables,
      data: {
        ...data,
        currUserFolderSet: data.currUserFolderSet.map((f) => ({
          ...f,
          selectedForDownload: false,
        })),
        currUserDocumentSet: data.currUserDocumentSet.map((d) => ({
          ...d,
          selectedForDownload: false,
        })),
      },
    });
  } else {
    // tout n'est pas coché -> on coche tout
    client.writeQuery({
      query: GET_FILES,
      variables,
      data: {
        ...data,
        currUserFolderSet: data.currUserFolderSet.map((f) => ({
          ...f,
          selectedForDownload: true,
        })),
        currUserDocumentSet: data.currUserDocumentSet.map((d) => ({
          ...d,
          selectedForDownload: true,
        })),
      },
    });
  }
}

export function globalUnselectForDownload(client, parentId) {
  const variables = { parentId: parentId };
  const data = client.readQuery({ query: GET_FILES, variables });

  client.writeQuery({
    query: GET_FILES,
    variables,
    data: {
      ...data,
      currUserFolderSet: data.currUserFolderSet.map((f) => ({
        ...f,
        selectedForDownload: false,
      })),
      currUserDocumentSet: data.currUserDocumentSet.map((d) => ({
        ...d,
        selectedForDownload: false,
      })),
    },
  });
}
