import React from "react";
import { Link } from "react-router-dom";
import { useReactiveVar, useQuery } from "@apollo/client";
import { isConnectedVar } from "../../../_GraphQL/graphQLClient";
import Login from "../../../Common/components/Login";
import logo from "../../../medias/images/logo-et-texte-blanc.svg";
import docImg from "../../../medias/images/icone-dossiers-bis.svg";
import inventImg from "../../../medias/images/icone-inventaires-bis.svg";
import { GET_PROFILE } from "../../../Common/_GraphQL/queries";
import { Loader } from "../../../Common/components/Loader";
import ErrorMessage from "../../../Common/components/ErrorMessage";
import ideesImg from "../../../medias/images/icone-idees-bis.svg";

function ConnectedSiteHome() {
  const { loading, error, data } = useQuery(GET_PROFILE);

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  if (!data) return null;

  const { hasAccessToCollaborativeMap, hasAccessToIdeas, hasAccessToFiles } =
    data.currUser;

  if (!hasAccessToCollaborativeMap && !hasAccessToFiles && !hasAccessToIdeas) {
    return <div className="home home-choix">Vous n'avez aucun accès.</div>;
  }

  return (
    <div className="home home-choix">
      <h1>
        <span className="bonjour">Bonjour !</span> Que souhaitez-vous faire
        aujourd'hui&nbsp;?
      </h1>
      <div className="elements">
        <div className="flux">
          {hasAccessToCollaborativeMap ? (
            <Link to="/inventaires" className="element">
              <img src={inventImg} alt="Illustration inventaires" />
              <p>
                Contribuer aux <span className="emphase">inventaires</span>
              </p>
            </Link>
          ) : null}
          {hasAccessToFiles ? (
            <Link to="/documents" className="element home-doc">
              <img src={docImg} alt="Illustration documents" />
              <p>
                Accéder aux <span className="emphase">documents</span>
              </p>
            </Link>
          ) : null}

          {hasAccessToIdeas ? (
            <Link to="/idees" className="element">
              <img src={ideesImg} alt="Illustration appel à idées" />
              <p>
                Participer à un <span className="emphase">appel à idées</span>
              </p>
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function SiteHome() {
  const isConnected = useReactiveVar(isConnectedVar);

  if (isConnected) {
    return <ConnectedSiteHome />;
  } else {
    return (
      <div className="home home-login">
        <div className="logo">
          <div className="courbes">
            <div className="courbe-bleue">
              <div className="courbe-blanche"></div>
            </div>
          </div>
          <div>
            <img
              src={logo}
              alt="Logo - Initiative Cartographique Interactive"
            />
          </div>
        </div>
        <div className="corps">
          <div className="centre">
            <div className="pres">
              <h1>
                ICI est un espace virtuel de contribution au service des
                projets.
              </h1>
              <p>
                En nous rejoignant, vous pourrez participer à des inventaires
                cartographiques, suggérer des pistes de projet lorsque des
                appels à idées sont ouverts ou encore consulter des documents en
                lien avec la démarche en cours.
              </p>
            </div>
            <Login />
          </div>
        </div>
      </div>
    );
  }
}

export { SiteHome };
