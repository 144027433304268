import { gql } from "@apollo/client";
import { USER_FRAG, QUESTIONNAIRE_FIELD_FRAG } from "./fragments";

export const UPDATE_USER = gql`
  mutation updateUser(
    $userId: ID!
    $firstName: String
    $lastName: String
    $name: String
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      name: $name
    ) {
      user {
        ...UserFrag
      }
    }
  }
  ${USER_FRAG}
`;

export const GET_LOGIN_TOKEN = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`;

export const UPDATE_FILLED_QUESTIONNAIRE_FIELD = gql`
  mutation UpdateFilledQuestionnaireField(
    $questionnaireFieldId: ID!
    $filledQuestionnaireFieldId: ID!
    $text: String
    $checked: Boolean
    $value: Float
    $questionnaireFieldLabelId: ID
    $date: Date
    $imagesToAdd: [Upload]
    $imagesToDelete: [ID]
  ) {
    updateFilledQuestionnaireField(
      filledQuestionnaireFieldId: $filledQuestionnaireFieldId
      text: $text
      checked: $checked
      value: $value
      questionnaireFieldLabelId: $questionnaireFieldLabelId
      date: $date
      imagesToAdd: $imagesToAdd
      imagesToDelete: $imagesToDelete
    ) {
      filledQuestionnaire {
        id
        user {
          id
          email
          firstName
          lastName
          profile {
            avatar
            avatarPath
            name
            nature {
              id
              code
            }
          }
        }
        currentUserIsOwner
        creationDate
        filledquestionnairefieldSet {
          id
          questionnaireField {
            ...QuestionnaireFieldFrag
          }
          questionnaireFieldLabel {
            id
            text
          }
          questionnaireFieldLabelSet {
            id
            text
          }
          text
          checked
          value
          date
          questionnairemediaSet {
            id
            name
          }
        }
      }
      feature {
        id
        selectedFieldForDecorator(questionnaireFieldId: $questionnaireFieldId)
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_FRAG}
`;

export const CREATE_FILLED_QUESTIONNAIRE_FIELD = gql`
  mutation CreateFilledQuestionnaireField(
    $filledQuestionnaireId: ID!
    $questionnaireFieldId: ID!
    $text: String
    $checked: Boolean
    $value: Float
    $questionnaireFieldLabelId: ID
    $date: Date
    $imagesToAdd: [Upload]
    $imagesToDelete: [ID]
  ) {
    createFilledQuestionnaireField(
      filledQuestionnaireId: $filledQuestionnaireId
      questionnaireFieldId: $questionnaireFieldId
      text: $text
      checked: $checked
      value: $value
      questionnaireFieldLabelId: $questionnaireFieldLabelId
      date: $date
      imagesToAdd: $imagesToAdd
      imagesToDelete: $imagesToDelete
    ) {
      filledQuestionnaire {
        id
        user {
          id
          email
          firstName
          lastName
          profile {
            avatar
            avatarPath
            name
            nature {
              id
              code
            }
          }
        }
        currentUserIsOwner
        creationDate
        filledquestionnairefieldSet {
          id
          questionnaireField {
            ...QuestionnaireFieldFrag
          }
          questionnaireFieldLabel {
            id
            text
          }
          questionnaireFieldLabelSet {
            id
            text
          }
          text
          checked
          value
          date
          questionnairemediaSet {
            id
            name
          }
        }
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_FRAG}
`;

export const SEND_SUPPORT_FORM = gql`
  mutation SendSupportForm($message: String) {
    sendSupportForm(message: $message)
  }
`;

export const SEND_NOTIFICATIONS = gql`
  mutation SendNotifications(
    $projectId: ID!
    $groupIds: [ID]!
    $subject: String!
    $body: String!
  ) {
    sendNotifications(
      projectId: $projectId
      groupIds: $groupIds
      subject: $subject
      body: $body
    )
  }
`;

export const MARK_NOTIF_AS_READ = gql`
  mutation MarkNotifAsRead($notificationId: ID!) {
    markNotifAsRead(notificationId: $notificationId) {
      notification {
        id
        read
        subject
        body
        creationDate
      }
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($notificationId: ID!) {
    deleteNotification(notificationId: $notificationId)
  }
`;
