import { gql } from "@apollo/client";

export const PUBLIC_GET_PROJECT_NAME_BY_ID_OR_SLUG = gql`
  query PublicGetProjectNameByIdOrSlug($projectIdOrSlug: String!) {
    publicGetProjectNameByIdOrSlug(projectIdOrSlug: $projectIdOrSlug)
  }
`;

export const PUBLIC_OPEN_SIGN_IN = gql`
  query PublicOpenSignIn($projectIdOrSlug: String!, $signInSlug: String!) {
    publicOpenSignIn(
      projectIdOrSlug: $projectIdOrSlug
      signInSlug: $signInSlug
    ) {
      id
      noticePath
      welcome
    }
  }
`;
