import React from "react";

function DocumentIcon({ extension }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.717"
      height="26.6"
      viewBox="0 0 25.717 26.6"
    >
      <g id="iconGF_PDF" transform="translate(0 0.8)">
        <g id="PDF" transform="translate(2)">
          <path
            id="Combined_Shape"
            d="M12.6,0,21,8.333v13.75A2.928,2.928,0,0,1,18.06,25H2.94A2.928,2.928,0,0,1,0,22.083V2.917A2.928,2.928,0,0,1,2.94,0Z"
            fill="#7a8efd"
            stroke="#3049d7"
            strokeMiterlimit="10"
            strokeWidth="1.6"
          />
          <path
            id="Path"
            d="M0,0H5.059A3.048,3.048,0,0,1,8,2.941V8Z"
            transform="translate(21 8) rotate(-180)"
            fill="#3049d7"
          />
        </g>
        <rect
          id="Rectangle"
          width="20.833"
          height="12.5"
          rx="2.88"
          transform="translate(4.083 11.458)"
          fill="#fff"
          stroke="#3049d7"
          strokeMiterlimit="10"
          strokeWidth="1.6"
        />
        <text width="20" style={{ fontSize: "8px" }} y="20" height="9.5" x="7">
          {extension ? extension.toUpperCase() : null}
        </text>
      </g>
    </svg>
  );
}

export { DocumentIcon };
