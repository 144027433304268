import React from "react";
import { Navigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { isConnectedVar } from "../../_GraphQL/graphQLClient";

const HomeRedirect = ({ from }) => {
  const isConnected = useReactiveVar(isConnectedVar);

  if (isConnected) {
    return (
      <Navigate
        to={{
          pathname: from.pathname,
          search: from.search,
        }}
        replace
      />
    );
  }
  return null;
};

export default HomeRedirect;
