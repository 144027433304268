import changeBaseMapStep1Img from "../../../medias/images/aide/changer-fond-carte-etape-1-1080-810.png";
import changeBaseMapStep2Img from "../../../medias/images/aide/changer-fond-carte-etape-2-1080-810.png";
import changeBaseMapStep3Img from "../../../medias/images/aide/changer-fond-carte-etape-3-1080-810.png";

function HelpChangeBaseMap() {
  return (
    <div>
      <h1>Étapes pour changer de fond de carte</h1>
      <p>
        1. Cliquer le bouton &laquo;&nbsp;Cartes&nbsp;&raquo; sur la droite de
        l'écran
      </p>
      <img
        className="capture-aide"
        src={changeBaseMapStep1Img}
        alt="Illustration du clic sur le bouton carte."
      />
      <p>2. Cliquer sur un fond de carte</p>
      <img
        className="capture-aide"
        src={changeBaseMapStep2Img}
        alt="Illustration du clic sur un fond de carte."
      />
      <p>3. Le fond de carte a été remplacé</p>
      <img
        className="capture-aide"
        src={changeBaseMapStep3Img}
        alt="Illustration de la carte avec un fond de carte différent."
      />
    </div>
  );
}

export { HelpChangeBaseMap };
