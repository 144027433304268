import mouseImg from "../../../medias/images/aide/souris-bleue-5-espace.svg";
import zoomImg from "../../../medias/images/aide/tip/aide-zoom.png";
import cadastreImg from "../../../medias/images/aide/cadastre-legende.png";
import cadastreStep1Img from "../../../medias/images/aide/cadastre-commune-200-230.png";
import cadastreStep2Img from "../../../medias/images/aide/cadastre-commune-divisions-200-230.png";
import cadastreStep3Img from "../../../medias/images/aide/cadastre-commune-parcelles-200.png";

function HelpNavigate() {
  return (
    <div>
      <h1>Se déplacer sur la carte</h1>
      <h2>
        A l'aide de la souris{" "}
        <img className="souris" src={mouseImg} alt="souris" />
      </h2>
      <p>Pour zoom/dézoomer sur la carte, il existe deux options :</p>
      <ul>
        <li>à l'aide de la molette de la souris</li>
        <li>
          à l'aide des boutons{" "}
          <img className="img-ombre-flotte" src={zoomImg} alt="Zoom" /> situés
          en haut à droite de l'écran
        </li>
      </ul>
      <p>
        Pour se déplacer sur la carte, laisser le bouton gauche de la souris
        enfoncé et déplacer le curseur.
      </p>
      <h2>Depuis un écran tactile</h2>
      <p>
        Pour zoomer/dézoomer sur la carte, utiliser deux doigts simulanément et
        les éloigner ou les rapprocher.
      </p>
      <p>
        Pour déplacer la carte, laisser un doigt sur la surface et le déplacer.
      </p>
      <h2>À l'aide du cadastre</h2>
      <p>
        Lorsque le cadastre est disponible, les contours des communes sont
        représentés par un contour blanc. Cliquer (ou toucher) sur la commune
        permet de centrer et zoomer sur celle-ci.
        <br />
        <img
          className="img-ombre-flotte"
          src={cadastreStep1Img}
          alt="Communes"
        />
        <br />
        Les divisions parcellaires sont alors affichées. Il est alors possible
        de cliquer sur l'une d'entre-elles pour zoomer et centrer dessus.
        <br />
        <img
          className="img-ombre-flotte"
          src={cadastreStep2Img}
          alt="Divisions"
        />
        <br /> Les parcelles de cette divisions sont alors affichées.
        <br />
        <img
          className="img-ombre-flotte"
          src={cadastreStep3Img}
          alt="Parcelles"
        />
        <br /> Pour savoir si le cadastre existe, il faut vérifier au niveau de
        la légende si un élément "Cadastre" existe et que le pictogramme "oeil"
        n'est pas barré.
        <br />
        <img className="img-ombre-flotte" src={cadastreImg} alt="Cadastre" />
      </p>
    </div>
  );
}

export { HelpNavigate };
