import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Progress } from "../../../Common/components/Progress";
import axios from "axios";
import { useMutation } from "@apollo/client";
import {
  CREATE_TEMP_UPLOAD_TOKEN,
  CREATE_FOLDER,
  SEND_EMAIL_AFTER_FILE_UPLOAD,
} from "../_GraphQL/mutations";
import { ManageDocumentPermissionsQuery } from "../components/ManageDocumentPermissionsQuery";
import ManageFolderPermissionsQuery from "../components/ManageFolderPermissionsQuery";
import { AddDocumentMail } from "../components/AddDocumentMail";
import DropFiles from "../components/DropFiles";
import openFolder from "../../../medias/images/dossier-ouvert-45x32 - fit.svg";
import { getCookie } from "../../../utils/utils";

// Constants
import {
  AUTH_TOKEN,
  FILEMANAGER_PERMISSIONS,
  PAGES,
} from "../../../utils/Constants";

// Message
import { addErrorToastMessage } from "../../../_GraphQL/message";
import { FileManagerLayout } from "../components/FileManagerLayout";
import { Switch } from "../../../Common/components/Switch";

const viewDocTrigger = ["delete_document_", "move_document_"];

const viewFolderTrigger = [
  "change_folder_",
  "move_folder_",
  "delete_folder_",
  "add_folder_",
  "add_document_",
];

const nodeTypes = {
  doc: "doc",
  folder: "folder",
};

let nodeId = 0;

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

class AddDocument extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tree: [],
      files: [],
      percent: 0,
      mail: false,
      mailGroupIds: [],
      defaultPermissions: true,
      documentGroupPermissionsRows: [],
      documentGroupPermissions: [],
      folderGroupPermissionsRows: [],
      folderGroupPermissions: [],
      processing: false,
      hasFolder: false,
      processingError: false,
    };
    this.csrftoken = getCookie("csrftoken");
  }

  setChildrenOnError = (nodeId) => {
    const self = this;

    self.state.tree
      .filter((n) => n.parentId === nodeId)
      .forEach((child) => {
        self.setState((prevState) => {
          const tree = prevState.tree.map((node) => {
            if (node.id === child.id) {
              return {
                ...node,
                uploading: false,
                creating: false,
                error: true,
                text: `Échec du téléversement`,
              };
            }
            return node;
          });
          return {
            tree: tree,
          };
        });
        self.setChildrenOnError(child.id);
      });
  };

  handleError = (header, error, currNode) => {
    const self = this;
    const { graphQLErrors, networkError, response, request, message } = error;

    let messageList, content;
    let rawHtml = false;

    if (graphQLErrors && graphQLErrors.length > 0) {
      messageList = graphQLErrors.map((gqle) => gqle.message);
    }

    if (networkError) {
      content = networkError.toString();
    }

    if (!networkError && !graphQLErrors) {
      if (response) {
        content = response.data;
        rawHtml = true;
      } else if (request) {
        content = request;
      } else if (message) {
        content = message;
      } else {
        content = error.toString();
        rawHtml = true;
      }
    }

    self.setState(
      (prevState) => {
        const tree = prevState.tree.map((node) => {
          if (node.id === currNode.id) {
            return {
              ...node,
              uploading: false,
              creating: false,
              error: true,
              text: `Échec du téléversement`,
            };
          }
          return node;
        });
        return {
          tree: tree,
          processing: false,
          processingError: true,
        };
      },
      () => {
        addErrorToastMessage({
          header: header,
          messageList: messageList,
          content: content,
          rawHtml: rawHtml,
        });
        self.setChildrenOnError(currNode.id);
      }
    );
  };

  handleFileChange = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  // type Node = {
  //     id: int,
  //     parentId: int,
  //     type: doc | folder,
  //     name: string,
  //     children: int[]
  // }

  findOrCreate(nodeList, node, arrayPath, file) {
    if (arrayPath.length === 1) {
      // nous sommes arrivés au document
      // on l'ajoute en tant que noeud

      nodeId++;
      let parentId = node ? node.id : null;
      nodeList.push({
        id: nodeId,
        parentId: parentId,
        type: nodeTypes.doc,
        name: file.name,
        fileObj: file,
      });
      node.children.push(nodeId);
      return;
    }

    const folderName = arrayPath.shift();

    // dans la nodeList trouver tous les noeuds enfants et voir si un dossier avec
    // le même nom existe
    let existingNode = null;

    let childrenNodes = [];
    if (!node) {
      childrenNodes = nodeList.filter((nodeItem) => !nodeItem.parentId);
    } else {
      childrenNodes = nodeList.filter(
        (nodeItem) => nodeItem.parentId === node.id
      );
    }

    childrenNodes.forEach((child) => {
      if (child.name === folderName) {
        existingNode = child;
      }
    });

    if (existingNode) {
      // le dossier existe déjà
      this.findOrCreate(nodeList, existingNode, arrayPath, file);
    } else {
      nodeId++;
      let parentId = node ? node.id : null;
      let newFolderNode = {
        id: nodeId,
        parentId: parentId,
        type: nodeTypes.folder,
        name: folderName,
        children: [],
      };
      if (node) {
        node.children.push(nodeId);
      }
      nodeList.push(newFolderNode);
      this.findOrCreate(nodeList, newFolderNode, arrayPath, file);
    }
  }

  onDropFilesChange = (flatFileList) => {
    let tree = [];
    let hasFolder = false;
    flatFileList.forEach((flatFile) => {
      if (flatFile.path === flatFile.name) {
        nodeId++;
        tree.push({
          id: nodeId,
          parentId: null,
          type: nodeTypes.doc,
          name: flatFile.name,
          fileObj: flatFile,
        });
        return;
      }
      hasFolder = true;
      let arrayPath = flatFile.path.split("/");
      arrayPath.shift();
      this.findOrCreate(tree, null, arrayPath, flatFile);
    });
    this.setState({
      files: [...this.state.files, ...flatFileList],
      tree: tree,
      hasFolder: hasFolder,
    });
  };

  handleMailChange = () =>
    this.setState((prevState) => ({ mail: !prevState.mail }));

  handleMailGroupChange = ({ target: { value, checked } }) => {
    if (checked) {
      this.setState((prevState) => ({
        mailGroupIds: prevState.mailGroupIds.concat([value]),
      }));
    } else {
      this.setState((prevState) => ({
        mailGroupIds: prevState.mailGroupIds.filter((i) => i !== value),
      }));
    }
  };

  changeDefaultPermissions = () =>
    this.setState((prevState) => ({
      defaultPermissions: !prevState.defaultPermissions,
    }));

  addDocumentGroupPermission = (groupId, permission) => {
    this.setState((prevState) => ({
      documentGroupPermissions: prevState.documentGroupPermissions.concat([
        { groupId: groupId, permission: permission },
      ]),
    }));
  };

  addFolderGroupPermission = (groupId, permission) => {
    this.setState((prevState) => ({
      folderGroupPermissions: prevState.folderGroupPermissions.concat([
        { groupId: groupId, permission: permission },
      ]),
    }));
  };

  handleDocumentCheckChange = (group, permission, checked) => {
    let addViewDoc = false;
    let removeAll = false;
    this.setState(
      (prevState) => {
        let a = prevState.documentGroupPermissionsRows.find(
          (al) => al.group.id === group.id
        );

        let perm = FILEMANAGER_PERMISSIONS[permission];
        a[perm] = checked;
        if (checked) {
          if (viewDocTrigger.includes(permission) && !a.canViewDocument) {
            a.canViewDocument = true;
            addViewDoc = true;
          }
        } else {
          if (permission === "view_document_") {
            removeAll = true;
            a.canViewDocument = false;
            a.canMoveDocument = false;
            a.canDeleteDocument = false;
          }
        }
        return {
          documentGroupPermissionsRows: prevState.documentGroupPermissionsRows,
        };
      },
      () => {
        if (checked) {
          if (addViewDoc) {
            this.setState((prevState) => ({
              documentGroupPermissions:
                prevState.documentGroupPermissions.concat([
                  {
                    groupId: group.id,
                    permission: permission,
                  },
                  {
                    groupId: group.id,
                    permission: "view_document_",
                  },
                ]),
            }));
          } else {
            this.setState((prevState) => ({
              documentGroupPermissions:
                prevState.documentGroupPermissions.concat({
                  groupId: group.id,
                  permission: permission,
                }),
            }));
          }
        } else {
          if (removeAll) {
            this.setState((prevState) => ({
              documentGroupPermissions:
                prevState.documentGroupPermissions.filter(
                  (gp) => gp.groupId !== group.id
                ),
            }));
          } else {
            this.setState((prevState) => ({
              documentGroupPermissions:
                prevState.documentGroupPermissions.filter(
                  (gp) =>
                    !(gp.groupId === group.id && gp.permission === permission)
                ),
            }));
          }
        }
      }
    );
  };

  handleFolderCheckChange = (group, permission, checked) => {
    let addViewFolder = false;
    let removeAll = false;
    this.setState(
      (prevState) => {
        let a = prevState.folderGroupPermissionsRows.find(
          (al) => al.group.id === group.id
        );

        let perm = FILEMANAGER_PERMISSIONS[permission];
        a[perm] = checked;
        if (checked) {
          if (viewFolderTrigger.includes(permission) && !a.canViewFolder) {
            a.canViewFolder = true;
            addViewFolder = true;
          }
        } else {
          if (permission === "view_folder_") {
            removeAll = true;
            a.canViewFolder = false;
            a.canMoveFolder = false;
            a.canChangeFolder = false;
            a.canDeleteFolder = false;
            a.canAddFolder = false;
            a.canAddDocument = false;
          }
        }
        return {
          folderGroupPermissionsRows: prevState.folderGroupPermissionsRows,
        };
      },
      () => {
        if (checked) {
          if (addViewFolder) {
            this.setState((prevState) => ({
              folderGroupPermissions: prevState.folderGroupPermissions.concat([
                {
                  groupId: group.id,
                  permission: permission,
                },
                {
                  groupId: group.id,
                  permission: "view_folder_",
                },
              ]),
            }));
          } else {
            this.setState((prevState) => ({
              folderGroupPermissions: prevState.folderGroupPermissions.concat({
                groupId: group.id,
                permission: permission,
              }),
            }));
          }
        } else {
          if (removeAll) {
            this.setState((prevState) => ({
              folderGroupPermissions: prevState.folderGroupPermissions.filter(
                (gp) => gp.groupId !== group.id
              ),
            }));
          } else {
            this.setState((prevState) => ({
              folderGroupPermissions: prevState.folderGroupPermissions.filter(
                (gp) =>
                  !(gp.groupId === group.id && gp.permission === permission)
              ),
            }));
          }
        }
      }
    );
  };

  initDocumentGroupPermissionsRows = (documentGroupPermissionsRows) =>
    this.setState({
      documentGroupPermissionsRows: documentGroupPermissionsRows,
    });

  initFolderGroupPermissionsRows = (folderGroupPermissionsRows) =>
    this.setState({ folderGroupPermissionsRows: folderGroupPermissionsRows });

  readableSize = (sizeS) => {
    const size = parseInt(sizeS, 10);

    if (size > 999999999) {
      let display = Math.trunc(size / 1000000000);
      return display + " Go";
    }
    if (size > 999999) {
      let display = Math.trunc(size / 1000000);
      return display + " Mo";
    }
    if (size > 999) {
      let display = Math.trunc(size / 1000);
      return display + " ko";
    }
    return size + " octets";
  };

  displayFileTree = (tree, parentId) => (
    <ul>
      {tree
        .filter((n) => n.parentId === parentId)
        .filter((child) => child.type === nodeTypes.folder)
        .map((c) => (
          <li key={c.name + c.type} className="dossier">
            <img className="racine" src={openFolder} alt="Dossier ouvert" />
            {c.name}&nbsp;&nbsp;
            {c.creating ? <span>Transfert {c.text}</span> : null}
            {c.created ? <span className="succes">Transféré</span> : null}
            {c.error ? <span className="echec">Échec du transfert</span> : null}
            {this.displayFileTree(tree, c.id)}
          </li>
        ))}
      {tree
        .filter((n) => n.parentId === parentId)
        .filter((child) => child.type === nodeTypes.doc)
        .map((c) => (
          <li key={c.name + c.type} className="document">
            {c.name} - {this.readableSize(c.fileObj.size)}&nbsp;&nbsp;
            {c.uploading ? <Progress percent={c.percent} /> : null}
            {c.uploaded ? <span className="succes">Transféré</span> : null}
            {c.error ? <span className="echec">Échec du transfert</span> : null}
          </li>
        ))}
    </ul>
  );

  async createFiles(nodeId, parentFolderId, projectIdOrSlug) {
    const self = this;
    // racine : level 0
    // création des dossiers
    // pour chaque dossier
    // création du dossier (voir besoin graphql jwt) -> permissions par défaut pour le moment
    // mise à jour état
    // appel récursif createFiles
    await asyncForEach(
      self.state.tree
        .filter((n) => n.parentId === nodeId)
        .filter((child) => child.type === nodeTypes.folder),
      async (c) => {
        // Mise à jour de l'état du dossier
        // trouver et changer le bon noeud dans le stateTree
        self.setState((prevState) => {
          const tree = prevState.tree.map((node) => {
            if (node.id === c.id) {
              return {
                ...node,
                creating: true,
                text: `Création du dossier`,
              };
            }
            return node;
          });
          return { tree };
        });
        // "création du dossier"
        // envoyer la requête graphql

        let folderPermissions = [];

        if (!this.state.defaultPermissions) {
          folderPermissions = this.state.folderGroupPermissions;
        }

        await self.props
          .createFolder({
            variables: {
              parentId: parentFolderId,
              projectIdOrSlug: projectIdOrSlug,
              name: c.name,
              groupPermissions: folderPermissions,
            },
          })
          .then(async (res) => {
            // récupération id nouveau dossier pour passage en paramètre au suivant
            // mettre à nouveau à jour le state quand c'est ok
            // res.data.createFolder.folder.id
            self.setState((prevState) => {
              const tree = prevState.tree.map((node) => {
                if (node.id === c.id) {
                  return {
                    ...node,
                    creating: false,
                    created: true,
                  };
                }
                return node;
              });
              return { tree };
            });

            await self.createFiles(
              c.id,
              res.data.createFolder.folder.id,
              projectIdOrSlug
            );
          })
          .catch((err) => {
            // Gestion de l'erreur
            // On s'arrête la, le processus ne peut pas aller plus loin pour ce dossier
            // mettre à nouveau à jour le state quand c'est en erreur
            // todo: gestion plus fine de l'erreur : network ou graphql, unique ou liste de message
            self.handleError(
              `Erreur lors de la création du dossier ${c.name}`,
              err,
              c
            );
          });
      }
    );

    // Création des documents
    // pour chaque document :
    // upload
    // mise à jour du progress
    // état "envoyé"

    await asyncForEach(
      this.state.tree
        .filter((n) => n.parentId === nodeId)
        .filter((child) => child.type === nodeTypes.doc),
      async (c) => {
        // change state to "uploading"
        // prepare progress bar
        this.setState((prevState) => {
          const tree = prevState.tree.map((node) => {
            if (node.id === c.id) {
              return {
                ...node,
                uploading: true,
                text: `Téléversement du document`,
              };
            }
            return node;
          });
          return { tree };
        });

        // upload document
        await self.props
          .createTempUploadToken()
          .then(async (res) => {
            let formdata = new FormData();
            formdata.append("file", c.fileObj);
            formdata.append("folder_id", parentFolderId);
            formdata.append("project_id_or_slug", projectIdOrSlug);

            formdata.append(
              "default_permissions",
              this.state.defaultPermissions
            );

            if (!this.state.defaultPermissions) {
              formdata.append(
                "group_permissions",
                JSON.stringify(this.state.documentGroupPermissions)
              );
            }

            await axios
              .post(
                `/fichiers/upload/?rt=${res.data.createTempUploadToken.token}`,
                formdata,
                {
                  onUploadProgress: function (progressEvent) {
                    self.setState((prevState) => {
                      const tree = prevState.tree.map((node) => {
                        if (node.id === c.id) {
                          return {
                            ...node,
                            percent: Math.round(
                              (progressEvent.loaded * 100) / progressEvent.total
                            ),
                          };
                        }
                        return node;
                      });
                      return { tree };
                    });
                  },
                  headers: {
                    Authorization: "JWT " + localStorage.getItem(AUTH_TOKEN),
                    "X-CSRFToken": this.csrftoken,
                  },
                }
              )
              .then(() => {
                // Mise à jour de l'état de traitement dans l'arbre en cours de traitement
                this.setState((prevState) => {
                  const tree = prevState.tree.map((node) => {
                    if (node.id === c.id) {
                      return {
                        ...node,
                        uploading: false,
                        uploaded: true,
                      };
                    }
                    return node;
                  });
                  return { tree };
                });
              })
              .catch((err) => {
                self.handleError(
                  `Erreur lors du transfert de document ${c.name}`,
                  err.response.data,
                  c
                );
              });
          })
          .catch((err) => {
            self.handleError(
              `Erreur lors de la récupération du jeton de sécurité pour le document ${c.name}`,
              err,
              c
            );
          });
      }
    );
  }

  render() {
    const self = this;
    let projectIdOrSlug = this.props.projectIdOrSlug;
    let parentId = this.props.parentId;
    let {
      currUserDocumentSet,
      currUserFolderSet,
      currUserCanManagePermissions,
      currUserCanSendMail,
    } = this.props.data;
    const style =
      this.state.processing || this.state.processingError
        ? { display: "none" }
        : {};

    return (
      <div className="ajout">
        <h1>Ajout de documents</h1>
        <div>
          {this.state.showProgress ? (
            <Progress percent={this.state.percent} />
          ) : (
            <form
              encType="multipart/form-data"
              onSubmit={async (e) => {
                e.preventDefault();

                self.setState({ processing: true });

                // Vérifier qu'au moins un document est présent
                if (this.state.tree.length === 0) {
                  addErrorToastMessage({
                    header: "Aucun document",
                    content: "Au minimum, un document doit être sélectionné.",
                  });
                  self.setState({ processing: false });
                  return;
                }

                // On vérifie qu'aucun document ou dossier n'existe pas déjà avec le même nom
                let fileAlreadyExists = false;

                this.state.tree
                  .filter((n) => n.parentId === null)
                  .filter((child) => child.type === nodeTypes.doc)
                  .forEach((c) => {
                    if (currUserDocumentSet.find((f) => f.name === c.name)) {
                      fileAlreadyExists = true;
                      self.setState({ processing: false });
                      return;
                    }
                  });

                if (fileAlreadyExists) {
                  addErrorToastMessage({
                    header: "Un document avec le même nom existe déjà",
                    content:
                      "Vous ne pouvez pas ajouter deux documents portants le même nom dans un même dossier.",
                  });
                  self.setState({ processing: false });
                  return;
                }

                this.state.tree
                  .filter((n) => n.parentId === null)
                  .filter((child) => child.type === nodeTypes.folder)
                  .forEach((c) => {
                    if (currUserFolderSet.find((f) => f.name === c.name)) {
                      fileAlreadyExists = true;
                      return;
                    }
                  });

                if (fileAlreadyExists) {
                  addErrorToastMessage({
                    header: "Un dossier avec le même nom existe déjà",
                    content:
                      "Vous ne pouvez pas ajouter deux dossiers portants le même nom dans un même dossier.",
                  });
                  self.setState({ processing: false });
                  return;
                }

                // On vérifie que les noms de dossiers et documents sont bien inférieurs ou égal à 100
                for (let i = 0; i < this.state.tree.length; i++) {
                  if (this.state.tree[i].name.length > 100) {
                    addErrorToastMessage({
                      header: "Nom trop long !",
                      content: `Le nom ${this.state.tree[i].name} est trop long. La longueur ne doit pas excéder 100 caractères.`,
                    });
                    self.setState({ processing: false });
                    return;
                  }
                }

                // Pour chaque dossier :
                // parcourir l'arbre
                // appel recursif pour les dossier
                // fonction qui créé un dossier et rentre dedans une fois que c'est fait pour chaque dossier
                // On lui fourni createTempUploadToken, l'arbre, il a accès à l'état pour mettre à jour la progression
                // On affiche les étapes les unes en dessous des autres pour que ça reste visible à l'utilisateur.

                self
                  .createFiles(null, parentId, projectIdOrSlug)
                  .then(() => {
                    if (self.state.processingError) {
                      return;
                    }

                    // On rafraichit le dossier courant
                    self.props.refetch();

                    if (self.state.processingError) return;

                    self.setState({ counter: 3 });

                    // Si l'envoie d'email est demandé on l'execute une seule fois
                    if (self.state.mail) {
                      self.props.sendEmailAfterFileUpload({
                        variables: {
                          projectIdOrSlug: projectIdOrSlug,
                          folderId: parentId,
                          mailGroupIds: self.state.mailGroupIds,
                        },
                      });
                    }

                    // On attend 3 secondes avant de fermer pour que l'utilisateur ait le temps de lire
                    let ms = 3000;
                    const interval = 1000;
                    const countdown = setInterval(() => {
                      ms = ms - interval;
                      self.setState({ counter: ms / 1000 });
                      if (ms <= 0) {
                        clearInterval(countdown);
                        self.props.navigate(
                          `/${projectIdOrSlug}/documents/${parentId}`
                        );
                      }
                    }, interval);
                  })
                  .catch((err) => {
                    self.handleError(
                      `Erreur pendant le traitement de l'arborescence.`,
                      err
                    );
                    self.setState({
                      processing: false,
                      processingError: true,
                    });
                    return;
                  });
              }}
            >
              <div className="formulaire">
                <DropFiles
                  onDropFilesChange={this.onDropFilesChange}
                  cssStyle={style}
                />
                <div className="arborescence">
                  <ul>{this.displayFileTree(this.state.tree, null)}</ul>
                </div>
              </div>
              {currUserCanManagePermissions ? (
                <div className="permissions" style={style}>
                  <div className="choix">
                    <label htmlFor="defaultPermission">
                      Permissions par défaut
                    </label>
                    <Switch
                      value="defaultPermission"
                      checked={this.state.defaultPermissions}
                      onChange={this.changeDefaultPermissions}
                    />
                  </div>
                  {!this.state.defaultPermissions ? (
                    <>
                      <ManageDocumentPermissionsQuery
                        style={style}
                        projectIdOrSlug={projectIdOrSlug}
                        addGroupPermission={this.addDocumentGroupPermission}
                        handleCheckChange={this.handleDocumentCheckChange}
                        groupPermissionsRows={
                          this.state.documentGroupPermissionsRows
                        }
                        initGroupPermissionsRows={
                          this.initDocumentGroupPermissionsRows
                        }
                      />
                      {this.state.hasFolder ? (
                        <ManageFolderPermissionsQuery
                          style={style}
                          projectIdOrSlug={projectIdOrSlug}
                          addGroupPermission={this.addFolderGroupPermission}
                          handleCheckChange={this.handleFolderCheckChange}
                          groupPermissionsRows={
                            this.state.folderGroupPermissionsRows
                          }
                          initGroupPermissionsRows={
                            this.initFolderGroupPermissionsRows
                          }
                        />
                      ) : null}
                    </>
                  ) : null}
                </div>
              ) : null}
              {currUserCanSendMail &&
              !this.state.processing &&
              !this.state.processingError ? (
                <AddDocumentMail
                  sendMails={this.state.mail}
                  setSendMails={this.handleMailChange}
                  handleGroupChange={this.handleMailGroupChange}
                  projectIdOrSlug={projectIdOrSlug}
                  mailGroupIds={this.state.mailGroupIds}
                />
              ) : null}
              {this.state.counter ? (
                <div>Fermeture dans {this.state.counter} secondes</div>
              ) : null}
              <div className="boutons" style={style}>
                <Link
                  className="bt-texte bt-bleu-secondaire"
                  to={`/${projectIdOrSlug}/documents/${parentId}`}
                >
                  Annuler
                </Link>
                <button className="bt-texte bt-bleu" type="submit">
                  Envoyer
                </button>
              </div>
              {this.state.processingError ? (
                <div>
                  <button
                    onClick={() => {
                      // On rafraichit le dossier courant
                      self.props.refetch();
                      self.props.navigate(
                        `/${projectIdOrSlug}/documents/${parentId}`
                      );
                    }}
                  >
                    Retour au dossier
                  </button>
                </div>
              ) : null}
            </form>
          )}
        </div>
      </div>
    );
  }
}

function AddDocumentMutations(props) {
  const [createTempUploadToken] = useMutation(CREATE_TEMP_UPLOAD_TOKEN);
  const [createFolder] = useMutation(CREATE_FOLDER);
  const [sendEmailAfterFileUpload] = useMutation(SEND_EMAIL_AFTER_FILE_UPLOAD, {
    onError() {},
  }); // onError Nécessaire pour éviter les erreurs de type "Unhandled Rejection"
  const navigate = useNavigate();

  return (
    <AddDocument
      {...props}
      createFolder={createFolder}
      sendEmailAfterFileUpload={sendEmailAfterFileUpload}
      createTempUploadToken={createTempUploadToken}
      navigate={navigate}
    />
  );
}

function AddDocumentQuery() {
  return (
    <FileManagerLayout page={PAGES.FM_ADD_DOC}>
      {(props) => <AddDocumentMutations {...props} />}
    </FileManagerLayout>
  );
}

export { AddDocumentQuery as AddDocument };
