import React, { useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  CURR_USER_NOTIFICATIONS,
  CURR_USER_CAN_SEND_NOTIFICATIONS,
  GET_PROFILE,
  CURR_USER_NOTIFICATIONS_PROJECTS,
  CURR_USER_HAS_UNREAD_NOTIFICATIONS,
} from "../_GraphQL/queries";
import {
  SEND_NOTIFICATIONS,
  MARK_NOTIF_AS_READ,
  DELETE_NOTIFICATION,
} from "../_GraphQL/mutations";
import { Loader } from "../components/Loader";
import { IciErrorMessage } from "../components/ErrorMessage";
import { NotFound } from "./NotFound";
import { DateTime } from "luxon";
import { LeftMenu, PAGES } from "../components/LeftMenu";
import delIcon from "../../medias/images/picto-suppr.svg";

const SCREENS = {
  SHOW_ALL: "SHOW_ALL",
  SHOW_NOT_READ: "SHOW_NOT_READ",
  SHOW_READ: "SHOW_READ",
  SEND_NEW: "SEND_NEW",
};

function SendNotificationForm({ projectSet, groupSet, cancel, refetch }) {
  const [projectId, setProjectId] = useState("");
  const [groupids, setGroupIds] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const groupsRef = useRef();
  const [completed, setCompleted] = useState(false);
  const [sendNotifications, { loading, error, reset }] = useMutation(
    SEND_NOTIFICATIONS,
    {
      onError() {},
      onCompleted() {
        setProjectId("");
        setGroupIds([]);
        setSubject("");
        setBody("");
        refetch();
        reset();
        setCompleted(true);
      },
      refetchQueries: [CURR_USER_HAS_UNREAD_NOTIFICATIONS],
    }
  );

  const projectOptions = projectSet.map((proj) => {
    return (
      <option key={proj.id} value={proj.id}>
        {proj.name}
      </option>
    );
  });

  if (completed) {
    return (
      <div className="envoi confirmation">
        <h1>Envoi notification</h1>
        <p>La notification a bien été envoyée</p>
        <div>
          <button className="bt-texte bt-bleu" onClick={() => cancel()}>
            Terminer
          </button>
        </div>
        <div>
          <button
            className="bt-texte bt-bleu"
            onClick={() => setCompleted(false)}
          >
            Envoyer une nouvelle notification
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="envoi">
      <h1>Envoi notification</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendNotifications({
            variables: {
              projectId: projectId,
              groupIds: groupids,
              subject: subject,
              body: body,
            },
          });
        }}
      >
        {error ? <IciErrorMessage error={error} /> : null}
        <div>
          <label htmlFor="notif-select-project">
            Envoyer une notification aux utilisateurs du projet&nbsp;:
          </label>
          <br />
          <select
            id="notif-select-project"
            value={projectId}
            onChange={(e) => setProjectId(e.target.value)}
            required={true}
          >
            <option value="">-- Sélectionner un projet --</option>
            {projectOptions}
          </select>
        </div>
        <div>
          <p>Appartenant aux groupes :</p>
          <div className="notif-liste-grp" ref={groupsRef}>
            {groupSet.map((group) => (
              <div key={group.id}>
                <label>
                  <input
                    type="checkbox"
                    checked={groupids.includes(group.id)}
                    required={groupids.length === 0}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Veuillez renseigner au moins un groupe"
                      )
                    }
                    onInput={(e) =>
                      [
                        ...groupsRef.current.getElementsByTagName("input"),
                      ].forEach((input) => input.setCustomValidity(""))
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setGroupIds([...groupids, group.id]);
                      } else {
                        setGroupIds(
                          groupids.filter((groupId) => groupId !== group.id)
                        );
                      }
                    }}
                  />
                  &nbsp;&nbsp;{group.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label>
            Sujet :<br />
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required={true}
            />
          </label>
        </div>
        <div>
          <label>
            Message :<br />
            <textarea
              value={body}
              required={true}
              onChange={(e) => setBody(e.target.value)}
            />
          </label>
        </div>
        <div className="bt-envoi-notif">
          {loading ? (
            <Loader />
          ) : (
            <>
              <button className="bt-texte bt-gris" onClick={() => cancel()}>
                Annuler
              </button>
              <button type="submit" className="bt-texte bt-bleu">
                Envoyer la notification
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
}

function SendNotification({ cancel, refetch }) {
  const { loading, error, data } = useQuery(CURR_USER_NOTIFICATIONS_PROJECTS);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <IciErrorMessage error={error} />;
  }

  if (data && data.currUserNotificationsProjects && data.currWebsiteGroupSet) {
    return (
      <SendNotificationForm
        projectSet={data.currUserNotificationsProjects}
        groupSet={data.currWebsiteGroupSet}
        cancel={cancel}
        refetch={refetch}
      />
    );
  }

  return null;
}

function SendNotificationButton({ screen, setScreen }) {
  return (
    <>
      <div className="separateur"></div>
      <div>
        <span
          className={
            screen === SCREENS.SEND_NEW ? "active lien-notif" : "lien-notif"
          }
          onClick={() => setScreen(SCREENS.SEND_NEW)}
        >
          Envoi
        </span>
      </div>
    </>
  );
}

function SendNotificationPermission({ screen, setScreen }) {
  const { loading, error, data } = useQuery(CURR_USER_CAN_SEND_NOTIFICATIONS);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <IciErrorMessage error={error} />;
  }

  if (data && data.currUserCanSendNotifications) {
    return <SendNotificationButton screen={screen} setScreen={setScreen} />;
  }

  return null;
}

function Notification({ notification }) {
  const [open, setOpen] = useState(false);
  const [markAsRead] = useMutation(MARK_NOTIF_AS_READ, {
    variables: {
      notificationId: notification.id,
    },
    onError() {},
    refetchQueries: [CURR_USER_HAS_UNREAD_NOTIFICATIONS],
  });
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    variables: {
      notificationId: notification.id,
    },
    onError() {},
    update(cache) {
      cache.modify({
        fields: {
          currUserNotifications(existingNotifications = []) {
            return existingNotifications.filter(
              (notif) => notif.__ref !== `NotificationType:${notification.id}`
            );
          },
        },
      });
    },
    refetchQueries: [CURR_USER_HAS_UNREAD_NOTIFICATIONS],
  });
  const dt = DateTime.fromISO(notification.creationDate).setLocale("fr-fr");
  const notifClass = open ? "notification ouverte" : "notification ferme";
  const btClass = open ? "bt-ouvert" : "bt-ferme";
  return (
    <div
      className={notifClass}
      onClick={() => {
        setOpen(!open);
        if (!notification.read) {
          markAsRead();
        }
      }}
    >
      <div className={notification.read ? "date lue" : "date non-lue"}>
        <div className="date-jour">{dt.toLocaleString()}</div>
        <div>{dt.toFormat("HH' h 'mm")}</div>
      </div>
      <div className="corps-notif">
        <h1>Sujet : {notification.subject}</h1>
        <p>{notification.body}</p>
      </div>
      <div className="bt-droit">
        <div className={btClass}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="bt-bas">
        <button
          onClick={(e) => {
            e.stopPropagation();
            deleteNotification();
          }}
        >
          <img src={delIcon} alt="Supprimer la notification" />
        </button>
      </div>
    </div>
  );
}

function Notifications({ notificationSet, refetch }) {
  const [screen, setScreen] = useState(SCREENS.SHOW_ALL);

  return (
    <>
      <div className="choix-ecran">
        <div>
          <span
            className={
              screen === SCREENS.SHOW_ALL ? "active lien-notif" : "lien-notif"
            }
            onClick={() => setScreen(SCREENS.SHOW_ALL)}
          >
            Toutes
          </span>
        </div>
        <div>
          <span
            className={
              screen === SCREENS.SHOW_NOT_READ
                ? "active lien-notif"
                : "lien-notif"
            }
            onClick={() => setScreen(SCREENS.SHOW_NOT_READ)}
          >
            Non Lues
          </span>
        </div>
        <div>
          <span
            className={
              screen === SCREENS.SHOW_READ ? "active lien-notif" : "lien-notif"
            }
            onClick={() => setScreen(SCREENS.SHOW_READ)}
          >
            Lues
          </span>
        </div>

        <SendNotificationPermission screen={screen} setScreen={setScreen} />
      </div>
      {screen === SCREENS.SEND_NEW ? (
        <SendNotification
          cancel={() => setScreen(SCREENS.SHOW_ALL)}
          refetch={refetch}
        />
      ) : (
        <div className="liste-notifs">
          {notificationSet
            .filter((notification) => {
              if (screen === SCREENS.SHOW_NOT_READ) {
                return notification.read === false;
              } else if (screen === SCREENS.SHOW_READ) {
                return notification.read === true;
              } else {
                return true;
              }
            })
            .map((notification) => (
              <Notification key={notification.id} notification={notification} />
            ))}
        </div>
      )}
    </>
  );
}

function GetNotifications() {
  const { loading, error, data, refetch } = useQuery(CURR_USER_NOTIFICATIONS);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <IciErrorMessage error={error} />;
  }

  if (data && data.currUserNotifications) {
    return (
      <Notifications
        notificationSet={data.currUserNotifications}
        refetch={refetch}
      />
    );
  }

  return null;
}

function NotificationsPage() {
  const { loading, error, data } = useQuery(GET_PROFILE);

  if (data && !data.currUser.hasAccessToNotifications) {
    return <NotFound />;
  }

  return (
    <div className="notifications page">
      {loading ? (
        <Loader />
      ) : (
        <>
          {error ? (
            <IciErrorMessage error={error} />
          ) : (
            <>
              <LeftMenu page={PAGES.NOTIFICATIONS} />
              <div className="corps-page">
                <GetNotifications />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

// todo: redirection 404 si l'utilisateur n'a aucun projet avec notifications activées

export { NotificationsPage as Notifications };
