import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="quatre-cent-quatre">
      <div className="haut">
        <div className="oups-gros">
          <div className="oups">oups, erreur</div>
          <div className="gros">404</div>
        </div>
        <div className="suggestion">
          La page que vous demandez n’existe pas. Vous souhaitez peut-être
        </div>
      </div>
      <div className="liens">
        <div className="courbes">
          <div className="courbe-bleue">
            <div className="courbe-blanche"></div>
          </div>
        </div>
        <div className="colonne gauche">
          <div>
            <Link to="/inventaires">Contribuer à un inventaire ?</Link>
          </div>
          <div>
            <Link to="/documents">Consulter un document ?</Link>
          </div>
        </div>
        <div className="colonne droite">
          <div>
            <Link to="/">Revenir à la page d’accueil ?</Link>
          </div>
          <div>
            <Link to="/assistance">Trouver de l'aide ?</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export { NotFound };
