import { gql } from "@apollo/client";

export const MESSAGE_FRAG = gql`
  fragment MessageFrag on MessageType {
    id
    autoClose
    closeTime
    location
    type
    header
    content
    messageList
    rawHtml
  }
`;
