import React from "react";
import { useMutation } from "@apollo/client";
import { GET_PROFILE } from "../../../Common/_GraphQL/queries";
import { UPDATE_USER } from "../../../Common/_GraphQL/mutations";
import { ProfileForm } from "./ProfileForm";
import { COMPONENTS } from "../../../utils/Constants";
import { addSuccessMessage } from "../../../_GraphQL/message";

function ProfileMutation({ currentUser, toggleMode }) {
  const [updateProfile, { error, loading }] = useMutation(UPDATE_USER, {
    onError() {},
    update(cache, { data: { user } }) {
      cache.writeQuery({
        query: GET_PROFILE,
        variables: {
          userId: currentUser.id,
        },
        data: {
          currUser: user,
        },
      });
    },
    onCompleted() {
      addSuccessMessage({
        location: COMPONENTS.COMMON.PROFILE,
        header: "Informations mises à jour",
        content: "Les informations ont été mises à jour.",
      });
      toggleMode();
    },
  });
  return (
    <ProfileForm
      error={error}
      loading={loading}
      toggleMode={toggleMode}
      currentUser={currentUser}
      updateProfile={updateProfile}
    />
  );
}

export { ProfileMutation };
