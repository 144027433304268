import React from "react";

function EyeIcon() {
  return (
    <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 53">
      <path
        d="M190.85,206.48a43,43,0,0,0-40,26.5,43.44,43.44,0,0,0,80,0A43,43,0,0,0,190.85,206.48Zm0,44.16A17.67,17.67,0,1,1,209,233,17.92,17.92,0,0,1,190.85,250.64Z"
        transform="translate(-150.85 -206.48)"
      />
      <path
        d="M190.88,222.38h0A10.74,10.74,0,0,0,179.94,233v0a10.74,10.74,0,0,0,10.88,10.6h0A10.75,10.75,0,0,0,201.76,233v0A10.74,10.74,0,0,0,190.88,222.38Z"
        transform="translate(-150.85 -206.48)"
      />
    </svg>
  );
}

export { EyeIcon };
