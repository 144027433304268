import React from "react";

function LegendIcon({ active }) {
  const fill = active ? "#ffffff" : "#979797";
  return (
    <svg width="37.95" height="26.45" viewBox="0 0 37.95 26.45">
      <path
        d="M0,16.716l3.415-1.752,15.538,7.968,15.56-7.982,3.436,1.766L18.975,26.45Zm3.437-5.167L0,9.775,18.975,0,37.95,9.775l-3.457,1.773-15.517,8Z"
        fill={fill}
      />
    </svg>
  );
}

export { LegendIcon };
