import React from "react";
import { Routes, Route } from "react-router-dom";
import { RequireAuth } from "../../utils/Privateroute";
import { CollaborativeMap } from "../../Modules/CollaborativeMap/pages/CollaborativeMap";
import { ProjectSelector } from "../pages/ProjectSelector";
import { FileList } from "../../Modules/FileManager/pages/FileList";
import { FileManager } from "../../Modules/FileManager/pages/FileManager";
import { Home } from "../../Modules/Home/Home";
import { Profile } from "../../Modules/Profile/pages/Profile";
import { PasswordResetRequest } from "../../Modules/Profile/pages/PasswordResetRequest";
import { PasswordReset } from "../../Modules/Profile/pages/PasswordReset";
import TaskSelector from "../../Modules/CollaborativeMap/pages/TaskSelector";
import { AddDocument } from "../../Modules/FileManager/pages/AddDocument";
import { AddFolder } from "../../Modules/FileManager/pages/AddFolder";
import { SignIn } from "../../Modules/Profile/pages/SignIn";
import { Help } from "../pages/Help";
import { Notifications } from "../pages/Notifications";
import { NotFound } from "../pages/NotFound";
import SiteLayout from "../../Sites/Cittanova/pages/SiteLayout";
import { Ideas } from "../../Modules/CollaborativeMap/pages/Ideas";

const MainContainer = () => {
  return (
    <Routes>
      <Route path="/" element={<SiteLayout />}>
        <Route index element={<Home />} />
        <Route
          path="profil"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="inventaires"
          element={
            <RequireAuth>
              <ProjectSelector />
            </RequireAuth>
          }
        />
        <Route
          exact
          path=":projectIdOrSlug/inscription/:signInSlug/"
          element={<SignIn />}
        />
        <Route
          path=":projectIdOrSlug/documents/:folderId/ajout-documents"
          element={
            <RequireAuth>
              <AddDocument />
            </RequireAuth>
          }
        />
        <Route
          path=":projectIdOrSlug/documents/:folderId/nouveau-dossier"
          element={
            <RequireAuth>
              <AddFolder />
            </RequireAuth>
          }
        />
        <Route
          path=":projectIdOrSlug/documents/:folderId"
          element={
            <RequireAuth>
              <FileList />
            </RequireAuth>
          }
        />
        <Route
          path=":projectIdOrSlug/documents"
          element={
            <RequireAuth>
              <FileManager />
            </RequireAuth>
          }
        />
        <Route
          path=":projectIdOrSlug/inventaires/:taskIdOrSlug"
          element={
            <RequireAuth>
              <CollaborativeMap />
            </RequireAuth>
          }
        />
        <Route
          path=":projectIdOrSlug/inventaires"
          element={
            <RequireAuth>
              <TaskSelector />
            </RequireAuth>
          }
        />
        <Route
          path="assistance/*"
          element={
            <RequireAuth>
              <Help />
            </RequireAuth>
          }
        />
        <Route
          path="notifications/*"
          element={
            <RequireAuth>
              <Notifications />
            </RequireAuth>
          }
        />
        <Route
          path="idees"
          element={
            <RequireAuth>
              <ProjectSelector />
            </RequireAuth>
          }
        />
        <Route
          path=":projectIdOrSlug/idees"
          element={
            <RequireAuth>
              <Ideas />
            </RequireAuth>
          }
        />
        <Route
          path=":projectIdOrSlug/idees/:taskIdOrSlug"
          element={
            <RequireAuth>
              <CollaborativeMap />
            </RequireAuth>
          }
        />
        <Route
          path="documents"
          element={
            <RequireAuth>
              <ProjectSelector />
            </RequireAuth>
          }
        />
        <Route path="compte/mdp-oublie" element={<PasswordResetRequest />} />
        <Route path="compte/nouveau-mdp" element={<PasswordReset />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default MainContainer;
