import React from "react";
import { FolderGroupPermissionsFormRow } from "../../../Common/components/GroupPermission/FolderGroupPermissionsFormRow";

class ManageFolderPermissionsForm extends React.Component {
  componentDidMount() {
    this.props.initialGroupPermissionsRows.forEach((groupPermissionsRow) => {
      if (groupPermissionsRow.canViewFolder) {
        this.props.addGroupPermission(
          groupPermissionsRow.group.id,
          "view_folder_"
        );
      }
      if (groupPermissionsRow.canChangeFolder) {
        this.props.addGroupPermission(
          groupPermissionsRow.group.id,
          "change_folder_"
        );
      }
      if (groupPermissionsRow.canMoveFolder) {
        this.props.addGroupPermission(
          groupPermissionsRow.group.id,
          "move_folder_"
        );
      }
      if (groupPermissionsRow.canDeleteFolder) {
        this.props.addGroupPermission(
          groupPermissionsRow.group.id,
          "delete_folder_"
        );
      }
      if (groupPermissionsRow.canAddFolder) {
        this.props.addGroupPermission(
          groupPermissionsRow.group.id,
          "add_folder_to_folder_"
        );
      }
      if (groupPermissionsRow.canAddDocument) {
        this.props.addGroupPermission(
          groupPermissionsRow.group.id,
          "add_document_to_folder_"
        );
      }
    });
    this.props.initGroupPermissionsRows(this.props.initialGroupPermissionsRows);
  }

  render() {
    const { style } = this.props;
    return (
      <>
        <div style={style}>
          <label>Gestion des permissions</label>
        </div>
        <div style={style}>
          <table>
            <thead>
              <tr>
                <th>Groupe</th>
                <th align="center">Accès en lecture</th>
                <th align="center">Peut déplacer</th>
                <th align="center">Peut éditer</th>
                <th align="center">Peut supprimer</th>
                <th align="center">Peut ajouter des dossiers</th>
                <th align="center">Peut ajouter des documents</th>
              </tr>
            </thead>
            <tbody>
              {this.props.groupPermissionsRows
                .sort(function (a, b) {
                  var textA = a.group.name.toUpperCase();
                  var textB = b.group.name.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                })
                .map((groupPermissionsRow, i) => (
                  <FolderGroupPermissionsFormRow
                    key={`table-row-group-${groupPermissionsRow.group.id}`}
                    groupPermissionsRow={groupPermissionsRow}
                    handleCheckChange={this.props.handleCheckChange}
                    even={i % 2 === 0}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export { ManageFolderPermissionsForm };
