import { gql } from "@apollo/client";
import {
  FEATURE_FRAG,
  QUESTIONNAIRE_FIELD_FRAG,
} from "../../../Common/_GraphQL/fragments";

export const CREATE_FEATURE = gql`
  mutation CreateFeature(
    $layerId: ID!
    $geometryTypeCode: String!
    $geoJson: String!
    $filledFeatureFields: [CustomFilledQuestionnaireFieldType]
    $questionnaireFieldId: ID
  ) {
    createFeature(
      layerId: $layerId
      geometryTypeCode: $geometryTypeCode
      data: $geoJson
      filledFeatureFields: $filledFeatureFields
    ) {
      feature {
        selectedFieldForDecorator(questionnaireFieldId: $questionnaireFieldId)
        ...FeatureFrag
      }
    }
  }
  ${FEATURE_FRAG}
`;

export const CREATE_FILLED_QUESTIONNAIRE = gql`
  mutation CreateFilledQuestionnaire(
    $featureId: ID!
    $filledFeatureFields: [CustomFilledQuestionnaireFieldType]
  ) {
    createFilledQuestionnaire(
      featureId: $featureId
      filledFeatureFields: $filledFeatureFields
    ) {
      filledQuestionnaire {
        id
        user {
          id
          email
          firstName
          lastName
          profile {
            avatar
            avatarPath
            name
            nature {
              id
              code
            }
          }
        }
        currentUserIsOwner
        creationDate
        filledquestionnairefieldSet {
          id
          questionnaireField {
            ...QuestionnaireFieldFrag
          }
          questionnaireFieldLabel {
            id
            text
          }
          questionnaireFieldLabelSet {
            id
            text
          }
          text
          checked
          value
          date
          questionnairemediaSet {
            id
            name
          }
        }
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_FRAG}
`;

export const DELETE_FILLED_QUESTIONNAIRE = gql`
  mutation DeleteFilledQuestionnaire($filledQuestionnaireId: ID!) {
    deleteFilledQuestionnaire(filledQuestionnaireId: $filledQuestionnaireId) {
      filledQuestionnaireId
    }
  }
`;
