// GraphQL
import { gql } from "@apollo/client";
import {
  PROJECT_FRAG,
  ROOT_FOLDER_FRAG,
  FEATURE_FRAG,
  FEATURE_LIGHT_FRAG,
  USER_FRAG,
  STYLE_FRAG,
} from "./fragments";

export const GET_PROFILE = gql`
  query GetCurrentUser {
    currUser {
      ...UserFrag
    }
  }
  ${USER_FRAG}
`;

export const GET_PROJECT = gql`
  query project($projectId: ID!) {
    project(projectId: $projectId) {
      ...ProjectFrag
    }
  }
  ${PROJECT_FRAG}
`;

export const GET_PROJECT_BY_ID_OR_SLUG = gql`
  query projectByIdOrSlug($projectIdOrSlug: String!) {
    projectByIdOrSlug(projectIdOrSlug: $projectIdOrSlug) {
      ...ProjectFrag
    }
  }
  ${PROJECT_FRAG}
`;

export const PROJECTS_ASSIGNED_TO_CURRENT_USER_FOR_CURRENT_SITE = gql`
  query currUserCurrSiteAssignedProjectSet {
    currUserCurrSiteAssignedProjectSet {
      id
      name
      slug
      rootFolder {
        ...RootFolderFrag
      }
      illustrationPath
      description
      currUserTaskSet {
        id
        taskType {
          id
          code
        }
      }
    }
  }
  ${ROOT_FOLDER_FRAG}
`;

export const GET_LAYER_WITH_FEATURES = gql`
  query LayerWithFeatures($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      pointFeatureSet {
        ...FeatureFrag
      }
      lineFeatureSet {
        ...FeatureFrag
      }
      polyFeatureSet {
        ...FeatureFrag
      }
    }
  }
  ${FEATURE_FRAG}
`;

export const GET_LAYER_WITH_POINT_FEATURES_WITH_FILLED_FOR_DECORATOR = gql`
  query LayerWithPointFeaturesWithFilledForDecorator(
    $layerId: ID!
    $questionnaireFieldId: ID!
  ) {
    layer(layerId: $layerId) {
      id
      pointFeatureSet {
        ...FeatureLightFrag
        selectedFieldForDecorator(questionnaireFieldId: $questionnaireFieldId)
        currentUserIsOwner
      }
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_LINE_FEATURES_WITH_FILLED_FOR_DECORATOR = gql`
  query LayerWithLineFeaturestWithFilledForDecorator(
    $layerId: ID!
    $questionnaireFieldId: ID!
  ) {
    layer(layerId: $layerId) {
      id
      lineFeatureSet {
        ...FeatureLightFrag
        selectedFieldForDecorator(questionnaireFieldId: $questionnaireFieldId)
        currentUserIsOwner
      }
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_POLY_FEATURES_WITH_FILLED_FOR_DECORATOR = gql`
  query LayerWithPolyFeaturesWithFilledForDecorator(
    $layerId: ID!
    $questionnaireFieldId: ID!
  ) {
    layer(layerId: $layerId) {
      id
      polyFeatureSet {
        ...FeatureLightFrag
        selectedFieldForDecorator(questionnaireFieldId: $questionnaireFieldId)
        currentUserIsOwner
      }
      polyStyle {
        ...StyleFrag
      }
    }
  }
  ${FEATURE_LIGHT_FRAG}
  ${STYLE_FRAG}
`;

export const GET_LAYER_WITH_FEATURES_LIGHT = gql`
  query LayerWithFeaturesLight($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      pointFeatureSet {
        ...FeatureLightFrag
      }
      lineFeatureSet {
        ...FeatureLightFrag
      }
      polyFeatureSet {
        ...FeatureLightFrag
      }
      polyColor
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_POINT_FEATURES_LIGHT = gql`
  query LayerWithPointFeaturesLight($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      pointFeatureSet {
        ...FeatureLightFrag
      }
      markerColor
      marker
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_LINE_FEATURES_LIGHT = gql`
  query LayerWithLineFeaturesLight($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      lineFeatureSet {
        ...FeatureLightFrag
      }
      lineColor
      lineStyle {
        ...StyleFrag
      }
    }
  }
  ${FEATURE_LIGHT_FRAG}
  ${STYLE_FRAG}
`;

export const GET_LAYER_WITH_POLY_FEATURES_LIGHT = gql`
  query LayerWithPolyFeaturesLight($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      polyFeatureSet {
        ...FeatureLightFrag
      }
      polyColor
      polyStyle {
        ...StyleFrag
      }
    }
  }
  ${FEATURE_LIGHT_FRAG}
  ${STYLE_FRAG}
`;

export const CURR_USER_CURR_SITE_HAS_ACCESS = gql`
  query currUserCurrSiteHasAccess {
    currUserCurrSiteHasAccess
  }
`;

export const PUBLIC_CURR_WEBSITE = gql`
  query GetPublicCurrentWebsite {
    publicCurrWebsite {
      id
      homeFolder
      mainModule {
        id
        name
        menu
        private
      }
      site {
        id
        domain
        name
        moduleSet {
          id
          name
          menu
          private
        }
        projectSet {
          id
          name
        }
      }
    }
  }
`;

export const USER_TASK_CONTRIB_COUNTER = gql`
  query UserTaskContribCounter($taskIdOrSlug: String!) {
    userTaskContribCounter(taskIdOrSlug: $taskIdOrSlug)
  }
`;

export const USER_TASK_CHECK_SHOW_HEART = gql`
  query UserTaskContribCounter($taskIdOrSlug: String!) {
    getTaskByIdOrSlug(idOrSlug: $taskIdOrSlug) {
      id
      showHeart
    }
  }
`;

export const CURR_USER_HAS_UNREAD_NOTIFICATIONS = gql`
  query CurrUserHasUnreadNotifications {
    currUserHasUnreadNotifications
  }
`;

export const CURR_USER_NOTIFICATIONS = gql`
  query CurrUserNotifications {
    currUserNotifications {
      id
      read
      subject
      body
      creationDate
    }
  }
`;

export const CURR_USER_CAN_SEND_NOTIFICATIONS = gql`
  query CurrUserCanSendNotifications {
    currUserCanSendNotifications
  }
`;

export const CURR_USER_NOTIFICATIONS_PROJECTS = gql`
  query CurrUserNotificationsProjects {
    currUserNotificationsProjects {
      id
      name
    }
    currWebsiteGroupSet {
      id
      name
    }
  }
`;
