import React from "react";
import tipZoomImg from "../../../../medias/images/aide/tip/aide-zoom.png";
import { Tip } from "../../../../Common/components/Tip";

function Zoom({ lMap, disabled }) {
  return (
    <div className="zoom">
      <div className="bouton-gauche">
        <button
          className="bt-img"
          onClick={() => lMap.zoomOut()}
          disabled={disabled}
        >
          -
        </button>
      </div>
      <div className="bouton-droit">
        <button onClick={() => lMap.zoomIn()} disabled={disabled}>
          +
        </button>
      </div>

      <Tip>
        <div>
          Zoom
          <br />
          <img
            className="img-ombre-flotte"
            src={tipZoomImg}
            alt="Boutons zoom"
          />
          <br />
          permet de zoomer et dézoomer la carte.
        </div>
      </Tip>
    </div>
  );
}

export { Zoom };
