import React from "react";

function MoveIcon() {
  return (
    <svg
      id="arrow_right_alt-24px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 19"
    >
      <path
        id="Path"
        d="M12.01,3H0V5H12.01V8L16,4,12.01,0Z"
        transform="translate(16 8) rotate(180)"
      />
      <path
        id="Path-2"
        data-name="Path"
        d="M12.01,3H0V5H12.01V8L16,4,12.01,0Z"
        transform="translate(0 11)"
      />
    </svg>
  );
}

export { MoveIcon };
