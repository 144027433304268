import React from "react";
import HomeRedirect from "./HomeRedirect";
import { SiteHome } from "./Ici/SiteHome";
import { useLocation } from "react-router-dom";

function Home() {
  const location = useLocation();

  return (
    <>
      {location && location.state && location.state.from ? (
        <HomeRedirect from={location.state.from} />
      ) : null}
      <SiteHome />
    </>
  );
}

export { Home };
