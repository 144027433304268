import React from "react";
import { QUESTIONNAIRE_MODES } from "../../../../utils/Constants";
import {
  ACTIONS,
  useCollaborativeMap,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";

function DeleteFilledQButton({ canDeleteFilledQ }) {
  const collaborativeMap = useCollaborativeMap();
  const dispatch = useCollaborativeMapDispatch();
  if (
    canDeleteFilledQ &&
    collaborativeMap.questionnaireMode === QUESTIONNAIRE_MODES.READ
  ) {
    return (
      <div className="supprimer">
        <button
          onClick={() => {
            dispatch({ type: ACTIONS.SHOW_DELETE_FILLED_Q });
          }}
        >
          Supprimer la contribution
        </button>
      </div>
    );
  }

  return null;
}

export { DeleteFilledQButton };
