import React from "react";
import { GET_PROFILE } from "../_GraphQL/queries";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

export const PAGES = {
  NOTIFICATIONS: "NOTIFICATIONS",
  PROFILE: "PROFILE",
  HELP: "HELP",
};

function NotificationLink({ active }) {
  const { loading, error, data } = useQuery(GET_PROFILE);

  if (loading || error || !data || !data.currUser.hasAccessToNotifications)
    return null;

  return (
    <div className={active ? "active" : null}>
      <Link to="/notifications">Notifications</Link>
    </div>
  );
}

function LeftMenu({ page }) {
  return (
    <div className="bandeau-lateral-gauche">
      <div className={page === PAGES.PROFILE ? "active" : null}>
        <Link to="/profil">Mon compte</Link>
      </div>
      <NotificationLink active={page === PAGES.NOTIFICATIONS} />
      <div className={page === PAGES.HELP ? "active" : null}>
        <Link to="/assistance">Assistance</Link>
      </div>
    </div>
  );
}

export { LeftMenu };
