import React from "react";
import { QUESTIONNAIRE_FIELD_TYPES } from "../../../../utils/Constants";
import { Carousel } from "../../../../Modules/CollaborativeMap/components/MapPanel/Carousel";
import { truncateDecimals } from "../../../../utils/utils";

function ReadField({ qField, filledQField, sources, setSources }) {
  let text;

  if (filledQField) {
    switch (qField.fieldType.code) {
      case QUESTIONNAIRE_FIELD_TYPES.TEXT:
        text = filledQField.text;
        break;

      case QUESTIONNAIRE_FIELD_TYPES.SELECT:
        if (
          filledQField.text !== "" &&
          filledQField.text !== null &&
          filledQField.text !== undefined
        ) {
          let values = filledQField.text.split(",");
          text = (
            <ul>
              {values.map((value) => (
                <li key={`tile-select-${value}`}>
                  {
                    qField.questionnairefieldlabelSet.find(
                      (qfl) => qfl.id === value
                    ).text
                  }
                </li>
              ))}
            </ul>
          );
        }
        break;

      case QUESTIONNAIRE_FIELD_TYPES.RADIO:
        text = filledQField.questionnaireFieldLabel
          ? filledQField.questionnaireFieldLabel.text
          : null;
        break;

      case QUESTIONNAIRE_FIELD_TYPES.DECIMAL:
        if(!filledQField.value) {
          text = "";
        } else {
          if(isNaN(filledQField.value)){
            text = "";
          } else {
          if(qField.decimal) {
            if (qField.decimalDigits) {
              text = truncateDecimals(filledQField.value, qField.decimalDigits).toString()
            } else {
              text = filledQField.value.toLocaleString()
            }
          } else {
            text = parseInt(filledQField.value).toString()
          }}
        }
        break;

      case QUESTIONNAIRE_FIELD_TYPES.CHECKBOX:
        text = filledQField.checked ? "Oui" : "Non";
        break;

      case QUESTIONNAIRE_FIELD_TYPES.DATE:
        text = filledQField.date;
        break;

      case QUESTIONNAIRE_FIELD_TYPES.IMAGE:
        return (
          <Carousel
            key={`info-${qField.id}`}
            fqf={filledQField}
            sources={sources}
            setSources={setSources}
          />
        );

      default:
        break;
    }
  }

  return <div className="lecture">{text}</div>;
}

export { ReadField };
