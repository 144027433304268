import React from "react";
import { Tip } from "../../../../Common/components/Tip";
import { useCollaborativeMap } from "../../../../Contexts/CollaborativeMapProvider";
import {
  COLLABORATIVE_MAP_TOOLS,
  QUESTIONNAIRE_MODES,
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
} from "../../../../utils/Constants";
import tipContextImg from "../../../../medias/images/aide/tip/aide-contexte.png";

function ContextBar({
  activeTool,
  activeLayer,
  activeFeature,
  featureHistory,
}) {
  const { questionnaireMode } = useCollaborativeMap();
  let action = "Navigation";
  let indication = "";

  if (activeTool === COLLABORATIVE_MAP_TOOLS.ADD_POINT) {
    action = `Placer un point${activeLayer ? ` (${activeLayer.name})` : null}`;
    if (questionnaireMode === QUESTIONNAIRE_MODES.READ) {
      indication = "Cliquer sur la carte à l'emplacement souhaité";
    } else if (questionnaireMode === QUESTIONNAIRE_MODES.CREATE) {
      indication = "Remplir le questionnaire dans le panneau de droite";
    } else if (questionnaireMode === QUESTIONNAIRE_MODES.CREATION_COMPLETED) {
      indication = "Fermer la fenêtre ou lire les informations renseignées";
    }
  } else if (activeTool === COLLABORATIVE_MAP_TOOLS.ADD_LINE) {
    action = `Dessiner une ligne${
      activeLayer ? ` (${activeLayer.name})` : null
    }`;
    if (questionnaireMode === QUESTIONNAIRE_MODES.READ) {
      if (featureHistory.count === 0) {
        indication = "Cliquer sur la carte pour commencer à dessiner";
      } else {
        indication = "Pour finaliser la ligne, cliquer sur le dernier marqueur";
      }
    } else if (questionnaireMode === QUESTIONNAIRE_MODES.CREATE) {
      indication = "Remplir le questionnaire dans le panneau de droite";
    } else if (questionnaireMode === QUESTIONNAIRE_MODES.CREATION_COMPLETED) {
      indication = "Fermer la fenêtre ou lire les informations renseignées";
    }
  } else if (activeTool === COLLABORATIVE_MAP_TOOLS.ADD_POLYGON) {
    action = `Dessiner une surface${
      activeLayer ? ` (${activeLayer.name})` : null
    }`;
    if (questionnaireMode === QUESTIONNAIRE_MODES.READ) {
      if (featureHistory.count === 0) {
        indication = "Cliquer sur la carte pour commencer à dessiner";
      } else if (featureHistory.count < 3) {
        indication =
          "Cliquer sur la carte pour ajouter des points à votre surface";
      } else {
        indication =
          "Pour finaliser la surface, cliquer sur le premier marqueur";
      }
    } else if (questionnaireMode === QUESTIONNAIRE_MODES.CREATE) {
      indication = "Remplir le questionnaire dans le panneau de droite";
    } else if (questionnaireMode === QUESTIONNAIRE_MODES.CREATION_COMPLETED) {
      indication = "Fermer la fenêtre ou lire les informations renseignées";
    }
  } else if (activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_GEOMETRY) {
    action = `Modifier un élément`;

    if (!activeFeature) {
      indication = "Cliquer sur un élément pour le modifier";
    } else {
      if (
        activeFeature.geometryType.code ===
        COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT
      ) {
        indication =
          "Déplacer le marqueur à l'emplacement souhaiter, puis cliquer sur le bouton valider";
      } else {
        indication =
          "Déplacer les marqueurs, puis cliquer sur le bouton valider";
      }
    }
  } else if (activeTool === COLLABORATIVE_MAP_TOOLS.EDIT_QUESTIONNAIRE) {
    action = `Éditer un champ`;
    indication = "Renseigner le champ puis valider";
  } else if (activeTool === COLLABORATIVE_MAP_TOOLS.DELETE_FEATURE) {
    action = `Supprimer un élément`;
  } else if (activeTool === COLLABORATIVE_MAP_TOOLS.EXPORT_SHP) {
    action = "Exporter";
    indication = "En attente de l'export";
  }

  return (
    <div className="contexte">
      <div className="action-en-cours">
        <p>Action en cours :</p>
      </div>
      <div>
        <p className="action">{action}</p>
        {indication ? <p className="indication">{indication}</p> : null}
      </div>
      <Tip>
        <div>
          Le texte contextuel
          <br />
          <img
            className="img-ombre-flotte"
            src={tipContextImg}
            alt="Text contextuel"
          />
          <br />
          indique :
          <ul>
            <li>l'outil sélectionné</li>
            <li>l'action à réaliser ensuite</li>
          </ul>
        </div>
      </Tip>
    </div>
  );
}

export { ContextBar };
