// React
import React from "react";

// GraphQL
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  GET_LAYER_WITH_POINT_FEATURES_WITH_FILLED_FOR_DECORATOR,
  GET_LAYER_WITH_POINT_FEATURES_LIGHT,
  GET_LAYER_WITH_LINE_FEATURES_WITH_FILLED_FOR_DECORATOR,
  GET_LAYER_WITH_LINE_FEATURES_LIGHT,
  GET_LAYER_WITH_POLY_FEATURES_WITH_FILLED_FOR_DECORATOR,
  GET_LAYER_WITH_POLY_FEATURES_LIGHT,
} from "../../../../Common/_GraphQL/queries";
import { decoratorsNotFilledVar } from "../../../../_GraphQL/graphQLClient";

// Leaflet
import L from "leaflet"; // eslint-disable-line no-unused-vars

// Component
import { Feature } from "./Feature";
import { IciErrorMessage } from "../../../../Common/components/ErrorMessage";
import { Loader } from "../../../../Common/components/Loader";

import {
  DEFAULT_COLOR,
  DEFAULT_MARKER_SYMBOL,
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  LOCAL_ONLY_FIELD_INIT,
} from "../../../../utils/Constants";
import { createMarkerIcon } from "../Controller";

function createPosition(data) {
  let invertedPositions = {};
  let positions = [];

  invertedPositions = JSON.parse(data).geometry.coordinates;

  if (invertedPositions.length === 0) {
    return null;
  }

  if (Array.isArray(invertedPositions[0])) {
    if (Array.isArray(invertedPositions[0][0])) {
      if (Array.isArray(invertedPositions[0][0][0])) {
        positions = invertedPositions.map((a) =>
          a.map((p) => p.map((c) => c.reverse()))
        );
      } else {
        positions = invertedPositions.map((a) => a.map((p) => p.reverse()));
      }
    } else {
      positions = invertedPositions.map((p) => p.reverse());
    }
  } else {
    positions = L.latLng(invertedPositions[1], invertedPositions[0]);
  }

  return positions;
}

const DefaultPointListContainer = (props) => {
  const { loading, error, data } = useQuery(
    GET_LAYER_WITH_POINT_FEATURES_LIGHT,
    {
      variables: { layerId: props.layer.id },
    }
  );

  // todo: voir comment afficher le chargement quelque part
  if (loading) return null;

  // todo: voir comment afficher les erreurs quelque part
  if (error) return null;

  if (!data.layer.pointFeatureSet) return null;

  return (
    <>
      {data.layer.pointFeatureSet.map((feature) => (
        <Feature
          key={`feature-${feature.id}`}
          positions={createPosition(feature.data)}
          feature={feature}
          geometryTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT}
          layer={props.layer}
          handleFeatureClick={props.handleFeatureClick}
          handlePmEdit={props.handlePmEdit}
          markerIcon={createMarkerIcon(
            data.layer.markerColor,
            data.layer.marker,
            props.activeFeature && feature.id === props.activeFeature.id
          )}
        />
      ))}
    </>
  );
};

const DefaultLineListContainer = (props) => {
  const { loading, error, data } = useQuery(
    GET_LAYER_WITH_LINE_FEATURES_LIGHT,
    {
      variables: { layerId: props.layer.id },
    }
  );

  // todo: voir comment afficher le chargement quelque part
  if (loading) return null;

  // todo: voir comment afficher les erreurs quelque part
  if (error) return null;

  if (!data.layer.lineFeatureSet) return null;

  // Deux cas possibles :
  // - pas de décoration
  // - décoration de couche
  return (
    <>
      {data.layer.lineFeatureSet.map((feature) => (
        <Feature
          key={`feature-${feature.id}`}
          positions={createPosition(feature.data)}
          feature={feature}
          geometryTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE}
          layer={props.layer}
          handleFeatureClick={props.handleFeatureClick}
          handlePmEdit={props.handlePmEdit}
          color={data.layer.lineColor || DEFAULT_COLOR}
          active={props.activeFeature && feature.id === props.activeFeature.id}
          style={data.layer.lineStyle}
        />
      ))}
    </>
  );
};

const DefaultPolyListContainer = (props) => {
  const { loading, error, data } = useQuery(
    GET_LAYER_WITH_POLY_FEATURES_LIGHT,
    {
      variables: { layerId: props.layer.id },
    }
  );

  // todo: voir comment afficher le chargement quelque part
  if (loading) return null;

  // todo: voir comment afficher les erreurs quelque part
  if (error) return null;

  if (!data.layer.polyFeatureSet) return null;

  // Deux cas possibles :
  // - pas de décoration
  // - décoration de couche
  return (
    <>
      {data.layer.polyFeatureSet.map((feature) => (
        <Feature
          key={`feature-${feature.id}`}
          positions={createPosition(feature.data)}
          feature={feature}
          geometryTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON}
          layer={props.layer}
          handleFeatureClick={props.handleFeatureClick}
          handlePmEdit={props.handlePmEdit}
          color={data.layer.polyColor || DEFAULT_COLOR}
          active={props.activeFeature && feature.id === props.activeFeature.id}
          style={data.layer.polyStyle}
        />
      ))}
    </>
  );
};

const DecoratedPointListContainer = (props) => {
  const { loading, error, data } = useQuery(
    GET_LAYER_WITH_POINT_FEATURES_WITH_FILLED_FOR_DECORATOR,
    {
      variables: {
        layerId: props.layer.id,
        questionnaireFieldId: props.decoratorId,
      },
    }
  );

  const decosNotFilled = useReactiveVar(decoratorsNotFilledVar);

  // Loader à revoir puisque pas géré par map leaflet
  if (loading) return <Loader />;

  if (error) return <IciErrorMessage error={error} />;

  if (!data.layer.pointFeatureSet) return null;

  const emptyDecoId = `${data.layer.id}-${props.decoratorId}`;
  const emptyDecoObj = decosNotFilled.find((deco) => deco.id === emptyDecoId);
  let emptyDecoIsSelectedForDisplay = true;
  if (emptyDecoObj) {
    emptyDecoIsSelectedForDisplay = emptyDecoObj.isSelectedForDisplay;
  }

  // Est-ce que la couche possède des points ?
  // Si oui, on est dans du medium donc décorateur de questionnaire.

  const defaultMarkerIcon = createMarkerIcon(
    DEFAULT_COLOR,
    DEFAULT_MARKER_SYMBOL
  );

  const markerIconSet = [];

  // Préparation des marqueurs possibles par réponses possibles.

  const qfSet = props.layer.questionnaire.questionnairefieldSet;

  // recherche du champ de questionnaire qui sert de décorateur
  const qf = qfSet.find((qf) => qf.id === props.decoratorId);
  qf.questionnairefieldlabelSet.forEach((qfl) => {
    markerIconSet.push({
      id: qfl.id,
      active: false,
      markerIcon: createMarkerIcon(qfl.markerColor, qfl.marker, false),
    });
    markerIconSet.push({
      id: qfl.id,
      active: true,
      markerIcon: createMarkerIcon(qfl.markerColor, qfl.marker, true),
    });
  });

  const selectedFieldForDisplay = qf.questionnairefieldlabelSet
    .filter(
      (qfl) =>
        qfl.isSelectedForDisplay === true ||
        (qfl.isSelectedForDisplay === LOCAL_ONLY_FIELD_INIT &&
          props.layer.visibleByDefault)
    )
    .map((qfl) => parseInt(qfl.id, 10));

  return (
    <>
      {data.layer.pointFeatureSet
        .filter(
          (feature) =>
            selectedFieldForDisplay.includes(
              feature.selectedFieldForDecorator
            ) ||
            ((!feature.selectedFieldForDecorator ||
              feature.selectedFieldForDecorator === -2) &&
              emptyDecoIsSelectedForDisplay)
        )
        .map((feature) => {
          let markerIcon;
          if (props.activeFeature && feature.id === props.activeFeature.id) {
            markerIcon = markerIconSet.find(
              (mi) =>
                parseInt(mi.id, 10) === feature.selectedFieldForDecorator &&
                mi.active
            );
          } else {
            markerIcon = markerIconSet.find(
              (mi) =>
                parseInt(mi.id, 10) === feature.selectedFieldForDecorator &&
                !mi.active
            );
          }

          const icon = markerIcon ? markerIcon.markerIcon : defaultMarkerIcon;
          return (
            <Feature
              key={`feature-${feature.id}`}
              positions={createPosition(feature.data)}
              feature={feature}
              geometryTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT}
              layer={props.layer}
              handleFeatureClick={props.handleFeatureClick}
              handlePmEdit={props.handlePmEdit}
              markerIcon={icon}
            />
          );
        })}
    </>
  );
};

const DecoratedLineListContainer = (props) => {
  const { loading, error, data } = useQuery(
    GET_LAYER_WITH_LINE_FEATURES_WITH_FILLED_FOR_DECORATOR,
    {
      variables: {
        layerId: props.layer.id,
        questionnaireFieldId: props.decoratorId,
      },
    }
  );

  const decosNotFilled = useReactiveVar(decoratorsNotFilledVar);

  // Loader à revoir puisque pas géré par map leaflet
  if (loading) return <Loader />;

  if (error) return <IciErrorMessage error={error} />;

  if (!data.layer.lineFeatureSet) return null;

  const emptyDecoId = `${data.layer.id}-${props.decoratorId}`;
  const emptyDecoObj = decosNotFilled.find((deco) => deco.id === emptyDecoId);
  let emptyDecoIsSelectedForDisplay = true;
  if (emptyDecoObj) {
    emptyDecoIsSelectedForDisplay = emptyDecoObj.isSelectedForDisplay;
  }

  const lineColorSet = [];

  const qfSet = props.layer.questionnaire.questionnairefieldSet;

  // recherche du champ de questionnaire qui sert de décorateur
  const qf = qfSet.find((qf) => qf.id === props.decoratorId);
  qf.questionnairefieldlabelSet.forEach((qfl) => {
    lineColorSet.push({
      id: qfl.id,
      color: qfl.lineColor,
      style: qfl.lineStyle,
    });
  });

  const selectedFieldForDisplay = qf.questionnairefieldlabelSet
    .filter(
      (qfl) =>
        qfl.isSelectedForDisplay === true ||
        (qfl.isSelectedForDisplay === LOCAL_ONLY_FIELD_INIT &&
          props.layer.visibleByDefault)
    )
    .map((qfl) => parseInt(qfl.id, 10));

  return (
    <>
      {data.layer.lineFeatureSet
        .filter(
          (feature) =>
            selectedFieldForDisplay.includes(
              feature.selectedFieldForDecorator
            ) ||
            ((!feature.selectedFieldForDecorator ||
              feature.selectedFieldForDecorator === -2) &&
              emptyDecoIsSelectedForDisplay)
        )
        .map((feature) => {
          const lineColor = lineColorSet.find(
            (mi) => parseInt(mi.id, 10) === feature.selectedFieldForDecorator
          );
          let color = DEFAULT_COLOR;
          let style;
          if (lineColor) {
            color = lineColor.color || props.layer.lineColor || DEFAULT_COLOR;
            if (lineColor.style) {
              style = lineColor.style;
            }
          }

          return (
            <Feature
              key={`feature-${feature.id}`}
              positions={createPosition(feature.data)}
              feature={feature}
              geometryTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE}
              layer={props.layer}
              handleFeatureClick={props.handleFeatureClick}
              handlePmEdit={props.handlePmEdit}
              color={color}
              active={
                props.activeFeature && feature.id === props.activeFeature.id
              }
              style={style}
            />
          );
        })}
    </>
  );
};

const DecoratedPolyListContainer = (props) => {
  const { loading, error, data } = useQuery(
    GET_LAYER_WITH_POLY_FEATURES_WITH_FILLED_FOR_DECORATOR,
    {
      variables: {
        layerId: props.layer.id,
        questionnaireFieldId: props.decoratorId,
      },
    }
  );

  const decosNotFilled = useReactiveVar(decoratorsNotFilledVar);

  // Loader à revoir puisque pas géré par map leaflet
  if (loading) return <Loader />;

  if (error) return <IciErrorMessage error={error} />;

  if (!data.layer.polyFeatureSet) return null;

  const emptyDecoId = `${data.layer.id}-${props.decoratorId}`;
  const emptyDecoObj = decosNotFilled.find((deco) => deco.id === emptyDecoId);
  let emptyDecoIsSelectedForDisplay = true;
  if (emptyDecoObj) {
    emptyDecoIsSelectedForDisplay = emptyDecoObj.isSelectedForDisplay;
  }

  const polyColorSet = [];

  const qfSet = props.layer.questionnaire.questionnairefieldSet;

  // recherche du champ de questionnaire qui sert de décorateur
  const qf = qfSet.find((qf) => qf.id === props.decoratorId);
  qf.questionnairefieldlabelSet.forEach((qfl) => {
    polyColorSet.push({
      id: qfl.id,
      color: qfl.polyColor,
      style: qfl.polyStyle,
    });
  });

  const selectedFieldForDisplay = qf.questionnairefieldlabelSet
    .filter(
      (qfl) =>
        qfl.isSelectedForDisplay === true ||
        (qfl.isSelectedForDisplay === LOCAL_ONLY_FIELD_INIT &&
          props.layer.visibleByDefault)
    )
    .map((qfl) => parseInt(qfl.id, 10));

  return (
    <>
      {data.layer.polyFeatureSet
        .filter(
          (feature) =>
            selectedFieldForDisplay.includes(
              feature.selectedFieldForDecorator
            ) ||
            ((!feature.selectedFieldForDecorator ||
              feature.selectedFieldForDecorator === -2) &&
              emptyDecoIsSelectedForDisplay)
        )
        .map((feature) => {
          const polyColor = polyColorSet.find(
            (mi) => parseInt(mi.id, 10) === feature.selectedFieldForDecorator
          );

          let color = DEFAULT_COLOR;
          let style;
          if (polyColor) {
            color = polyColor.color || props.layer.polyColor || DEFAULT_COLOR;
            if (polyColor.style) {
              style = polyColor.style;
            }
          }

          return (
            <Feature
              key={`feature-${feature.id}`}
              positions={createPosition(feature.data)}
              feature={feature}
              geometryTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON}
              layer={props.layer}
              handleFeatureClick={props.handleFeatureClick}
              handlePmEdit={props.handlePmEdit}
              color={color}
              active={
                props.activeFeature && feature.id === props.activeFeature.id
              }
              style={style}
            />
          );
        })}
    </>
  );
};

const FeatureListContainer = (props) => {
  const lgs = props.layer.layergeometrySet;
  const point = lgs.find(
    (lf) => lf.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT
  );
  const line = lgs.find(
    (lf) => lf.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE
  );
  const poly = lgs.find(
    (lf) => lf.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
  );
  return (
    <>
      {point && point.isSelectedForDisplay ? (
        point.decorator ? (
          <DecoratedPointListContainer
            {...props}
            decoratorId={point.decorator.id}
          />
        ) : (
          <DefaultPointListContainer {...props} />
        )
      ) : null}
      {line && line.isSelectedForDisplay ? (
        line.decorator ? (
          <DecoratedLineListContainer
            {...props}
            decoratorId={line.decorator.id}
          />
        ) : (
          <DefaultLineListContainer {...props} />
        )
      ) : null}
      {poly && poly.isSelectedForDisplay ? (
        poly.decorator ? (
          <DecoratedPolyListContainer
            {...props}
            decoratorId={poly.decorator.id}
          />
        ) : (
          <DefaultPolyListContainer {...props} />
        )
      ) : null}
    </>
  );
};

export default FeatureListContainer;
