import React, { useState, useRef } from "react";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GET_PROFILE,
  GET_PROJECT_BY_ID_OR_SLUG,
  CURR_USER_HAS_UNREAD_NOTIFICATIONS,
} from "../_GraphQL/queries";
import { AUTH_TOKEN, QUESTIONNAIRE_MODES } from "../../utils/Constants";
import ErrorMessage from "./ErrorMessage";
import { isConnectedVar } from "../../_GraphQL/graphQLClient";
import logo from "../../medias/images/logo.svg";
import docImg from "../../medias/images/icone-dossiers-bis.svg";
import inventImg from "../../medias/images/icone-inventaires-bis.svg";
import ideesImg from "../../medias/images/icone-idees-bis.svg";
import burgerImgOpen from "../../medias/images/icone-burger-menu-ouvert.svg";
import burgerImgClosed from "../../medias/images/icone-burger-menu-ferme.svg";
import burgerImgOpenNotif from "../../medias/images/icone-burger-menu-ouvert-notif.svg";
import burgerImgClosedNotif from "../../medias/images/icone-burger-menu-ferme-notif.svg";
import exportImg from "../../medias/images/icone-exporter.svg";
import { useOutsideAlerter } from "../../utils/useOutsideAlerter";
import { useFullScreen } from "../../Contexts/FullScreenProvider";
import { useCollaborativeMap } from "../../Contexts/CollaborativeMapProvider";
import { Initials } from "./Initials";
import { getInitials } from "../../utils/utils";
import { Loader } from "../../Common/components/Loader";
import { Heart } from "./Heart";
import { NotificationsIcon } from "./NotificationsIcon";

function ProfileMenu({ disabled }) {
  const navigate = useNavigate();

  const { client, loading, error, data } = useQuery(GET_PROFILE, {
    fetchPolicy: "network-only",
  });

  function logout() {
    localStorage.removeItem(AUTH_TOKEN);
    isConnectedVar(false);
    client.clearStore().then(() => {
      client.resetStore();
      navigate("/");
    });
  }

  const [profileActive, setProfileActive] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setProfileActive(false));

  if (loading) return null;

  if (error) return <ErrorMessage error={error} />;

  const { currUser } = data;
  let displayName = getInitials(currUser);

  return (
    <div ref={wrapperRef} className="carre elem-profil">
      <div
        className="bt-profil"
        onClick={() => {
          if (!disabled) {
            setProfileActive(!profileActive);
          }
        }}
      >
        <Initials displayName={displayName} />
      </div>
      {profileActive ? (
        <>
          <div className="connecteur"></div>
          <ul className="sous-menu menu-profil">
            <li>
              <button
                onClick={() => {
                  setProfileActive(false);
                  navigate("/profil");
                }}
                className="mon-profil"
              >
                <Initials displayName={displayName} />
                <span>Mon profil</span>
              </button>
            </li>
            <li>
              <button
                disabled={disabled}
                onClick={() => {
                  setProfileActive(false);
                  logout();
                }}
              >
                <img className="deco" src={exportImg} alt="deconnexion" />
                <span>Déconnexion</span>
              </button>
            </li>
          </ul>
        </>
      ) : null}
    </div>
  );
}

function BurgerMenu({ disabled, currUser }) {
  const { loading, error, data } = useQuery(CURR_USER_HAS_UNREAD_NOTIFICATIONS);
  const navigate = useNavigate();
  const [burgerActive, setBurgerActive] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setBurgerActive(false));

  let burgerOpenSrc = burgerImgOpen;
  let burgerClosedSrc = burgerImgClosed;

  if (!loading && !error && data && data.currUserHasUnreadNotifications) {
    burgerOpenSrc = burgerImgOpenNotif;
    burgerClosedSrc = burgerImgClosedNotif;
  }
  return (
    <div ref={wrapperRef} className="carre croix">
      <div className="bt-croix">
        {burgerActive ? (
          <img
            src={burgerOpenSrc}
            alt="on"
            onClick={() => {
              if (!disabled) {
                setBurgerActive(false);
              }
            }}
          />
        ) : (
          <img
            src={burgerClosedSrc}
            alt="off"
            onClick={() => {
              if (!disabled) {
                setBurgerActive(true);
              }
            }}
          />
        )}
      </div>
      {burgerActive ? (
        <>
          <div className="connecteur"></div>
          <ul className="sous-menu menu-croix">
            {currUser.hasAccessToNotifications ? (
              <li>
                <button
                  onClick={() => {
                    setBurgerActive(false);
                    navigate("/notifications");
                  }}
                >
                  <NotificationsIcon />
                  <span>Notifications</span>
                </button>
              </li>
            ) : null}
            <li>
              <button
                onClick={() => {
                  setBurgerActive(false);
                  navigate("/assistance");
                }}
              >
                <img src={ideesImg} alt="assistance" />
                <span>Assistance</span>
              </button>
            </li>
            {currUser.hasAccessToCollaborativeMap ? (
              <li className="petit">
                <button
                  onClick={() => {
                    setBurgerActive(false);
                    navigate("/inventaires");
                  }}
                >
                  <img src={inventImg} alt="inventaires" />
                  <span>Inventaires</span>
                </button>
              </li>
            ) : null}
            {currUser.hasAccessToFiles ? (
              <li className="petit sous-menu-document">
                <button
                  onClick={() => {
                    setBurgerActive(false);
                    navigate("/documents");
                  }}
                >
                  <img src={docImg} alt="document" />
                  <span>Documents</span>
                </button>
              </li>
            ) : null}
            {currUser.hasAccessToIdeas ? (
              <li className="petit sous-menu-document">
                <button
                  onClick={() => {
                    setBurgerActive(false);
                    navigate("/idees");
                  }}
                >
                  <img src={ideesImg} alt="idées" />
                  <span>Appel à idées</span>
                </button>
              </li>
            ) : null}
          </ul>
        </>
      ) : null}
    </div>
  );
}

function ProjectName({ projectIdOrSlug }) {
  const { loading, error, data } = useQuery(GET_PROJECT_BY_ID_OR_SLUG, {
    variables: { projectIdOrSlug: projectIdOrSlug },
  });

  if (
    loading ||
    error ||
    !data ||
    !data.projectByIdOrSlug ||
    !data.projectByIdOrSlug.name
  )
    return null;
  return <div className="nom-projet">{data.projectByIdOrSlug.name}</div>;
}

function LinkMenu({ disabled, currUser }) {
  const location = useLocation();
  const navigate = useNavigate();

  let inventoryClass = "menu-item inventaires";

  if (location.pathname.includes("inventaire")) {
    inventoryClass += " active";
  }

  let docClass = "menu-item documents";

  if (location.pathname.includes("document")) {
    docClass += " active";
  }

  let ideasClass = "menu-item idees";

  if (location.pathname.includes("idees")) {
    ideasClass += " active";
  }

  return (
    <div className="menu-3-items">
      {currUser.hasAccessToCollaborativeMap ? (
        <div className={inventoryClass}>
          <button
            disabled={disabled}
            className="bt-lien"
            onClick={() => navigate("/inventaires")}
          >
            <img src={inventImg} alt="Illustration inventaires" />
            <span>Inventaires</span>
          </button>
        </div>
      ) : null}
      {currUser.hasAccessToCollaborativeMap && currUser.hasAccessToFiles ? (
        <div className="menu-item nav-separator"></div>
      ) : null}
      {currUser.hasAccessToFiles ? (
        <div className={docClass}>
          <button
            disabled={disabled}
            className="bt-lien"
            onClick={() => navigate("/documents")}
          >
            <img src={docImg} alt="Illustration documents" />
            <span>Documents</span>
          </button>
        </div>
      ) : null}
      {currUser.hasAccessToIdeas &&
      (currUser.hasAccessToFiles || currUser.hasAccessToCollaborativeMap) ? (
        <div className="menu-item nav-separator"></div>
      ) : null}
      {currUser.hasAccessToIdeas ? (
        <div className={ideasClass}>
          <button
            disabled={disabled}
            className="bt-lien"
            onClick={() => navigate("/idees")}
          >
            <img src={ideesImg} alt="Illustration appel à idées" />
            <span>Appels à idées</span>
          </button>
        </div>
      ) : null}
    </div>
  );
}

function MenuConnected({ menuDisabled }) {
  let match = useMatch("/:projectIdOrSlug/*");
  const fullScreen = useFullScreen();
  const collaborativeMap = useCollaborativeMap();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_PROFILE);

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  if (!data) return null;

  if (fullScreen) return null;

  const disabled =
    menuDisabled ||
    collaborativeMap.questionnaireMode !== QUESTIONNAIRE_MODES.READ;

  return (
    <div className="menu">
      {collaborativeMap.questionnaireMode !== QUESTIONNAIRE_MODES.READ ? (
        <div className="couverture-menu"></div>
      ) : null}
      <div className="carre logo">
        <button
          className="bt-lien"
          disabled={disabled}
          onClick={() => navigate("/")}
        >
          <img src={logo} alt="Logo" />
        </button>
      </div>
      {match && match.params && match.params.projectIdOrSlug ? (
        <ProjectName projectIdOrSlug={match.params.projectIdOrSlug} />
      ) : null}
      {!window.location.pathname || window.location.pathname === "/" ? null : (
        <LinkMenu disabled={disabled} currUser={data.currUser} />
      )}

      <Heart />

      <ProfileMenu disabled={disabled} />
      <BurgerMenu disabled={disabled} currUser={data.currUser} />
    </div>
  );
}

export default MenuConnected;
