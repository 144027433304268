import { useLocation, Navigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { isConnectedVar } from "../_GraphQL/graphQLClient";

function RequireAuth({ children }) {
  const isAuthenticated = useReactiveVar(isConnectedVar);
  const location = useLocation();
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
}

export { RequireAuth };
