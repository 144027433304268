import React, { forwardRef, useImperativeHandle } from "react";
import { Field } from "./Common/Field";

function verifyValue(field, value) {
  if (field.required) {
    if (!value) {
      return {
        valid: false,
        error: "Ce champ est obligatoire.",
      };
    }
  }

  return {
    valid: true,
  };
}

const RadioInput = forwardRef(({ qField, value, setValue, setError }, ref) => {
  useImperativeHandle(ref, () => ({
    checkForErrors() {
      const verif = verifyValue(qField, value);
      if (!verif.valid) {
        setError(verif.error);
      } else {
        setError(null);
      }
      return verif;
    },
  }));

  const handleBlur = () =>
    setTimeout(() => {
      const verif = verifyValue(qField, value);
      if (verif.valid) {
        setError(null);
      } else {
        setError(verif.error);
      }
    }, 100);
  const radioFormFieldName = `radioFormFieldName-${qField.id}`;

  return (
    <div className="element groupe-radio" onBlur={handleBlur}>
      {qField.questionnairefieldlabelSet.map((label, i) => {
        const radioFormFieldKey = `radioFormFieldKey-${qField.id}-${label.id}`;

        return (
          <div key={radioFormFieldKey}>
            <label htmlFor={radioFormFieldKey}>{label.text}</label>
            <input
              type="radio"
              id={radioFormFieldKey}
              required={qField.required}
              name={radioFormFieldName}
              value={label.id}
              checked={value === label.id}
              onClick={() => {
                if (value === label.id) {
                  setValue(null);
                }
              }}
              onChange={(e) => {
                setValue(value === label.id ? null : e.target.value);
              }}
              disabled={qField.readOnly}
            />
          </div>
        );
      })}
    </div>
  );
});

function RadioField({
  featureId,
  filledQId,
  qField,
  filledQField,
  mode,
  value,
  setValue,
  inputRef,
  labelRef,
  canEdit,
}) {
  if (value === undefined || value === null) value = "";

  return (
    <Field
      featureId={featureId}
      filledQId={filledQId}
      qField={qField}
      filledQField={filledQField}
      mode={mode}
      createValue={value}
      createSetValue={setValue}
      inputRef={inputRef}
      labelRef={labelRef}
      canEdit={canEdit}
    >
      {(setError, value, setValue, ref) => (
        <RadioInput
          qField={qField}
          value={value}
          setValue={setValue}
          setError={setError}
          ref={ref}
        />
      )}
    </Field>
  );
}

export { RadioField };
