import React from "react";
import {
  ACTIONS,
  useCollaborativeMapDispatch,
} from "../../../../Contexts/CollaborativeMapProvider";
import { Contrib } from "./Contrib";
import { IdeasFilter } from "./IdeasFilter";

function Contribs({
  filledQuestionnaireSet,
  canEdit,
  restrictionsBetweenUsers,
  userCanViewOthersInfos,
  themes,
  toggleTheme,
  deco,
}) {
  const dispatch = useCollaborativeMapDispatch();

  let filledQSet = filledQuestionnaireSet;

  if (restrictionsBetweenUsers && !userCanViewOthersInfos) {
    filledQSet = filledQuestionnaireSet.filter((fq) => fq.currentUserIsOwner);
  }

  return (
    <div>
      <IdeasFilter
        themes={themes}
        toggleTheme={toggleTheme}
      />
      {canEdit ? (
        <div className="nouvelle-contribution">
          <button
            onClick={() => dispatch({ type: ACTIONS.NEW_FILLED_Q_CLICKED })}
          >
            <span className="contrib-plus">+</span>
            <span>Proposer une nouvelle contribution</span>
          </button>
        </div>
      ) : null}

      {filledQSet
        .filter((filledQuestionnaire) => {
          if (!deco) return true;

          // trouver filledQuestionnaireField qui répond au déco
          const filledDeco = filledQuestionnaire.filledquestionnairefieldSet.find((fqf) => fqf.questionnaireField.id === deco.id)

          if (!filledDeco) return true;

          const theme = themes.find((themeInState) => themeInState.id === filledDeco.text)
          if (!theme) return true;

          if (theme.selected) return true;

          return false;
        })
        .map((filledQuestionnaire) => (
          <Contrib
            key={`contrib-${filledQuestionnaire.id}`}
            filledQuestionnaire={filledQuestionnaire}
          />
        ))}
    </div>
  );
}

export { Contribs };
